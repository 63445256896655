import React, {useEffect, useState} from 'react';
import MemberHomepageService from '../../../../../../../Services/memberHomepage';
import {IoMdClose} from 'react-icons/io';
import {
  getCategoryMode,
  getEntityParams,
  getItem,
  getUserMode,
} from '../../../../../../../utils/HelperService';
import Constants from '../../../../../../../Constants/Constants';
import './styles.css';
import {CircularProgress} from '@material-ui/core';
import {BsSearch} from 'react-icons/bs';
import EventList from '../EventList';
import {TitleForm} from '../TopicDefinition/styles';
import {PlainInput} from '../styles';
import {
  convertQueryParamsToObject,
  generateFormOnObject,
} from '../../../../../../../Helpers/api';
import {ApiClient} from '../../../../../../../Services/base';
import ExtraRatingsBlock from '../TopicDefinition/ExtraRatingsBlock';
import Loading from '../../../../../../UI/Loading';

export default function EventInfoModal(props) {
  const {handleCloseModal, eventInfo} = props;

  let [extraRatings, setExtraRatings] = useState();

  const getAPI = () => {
    const requests = convertQueryParamsToObject(eventInfo.event_details_link);

    let queryParams = `cid=${requests.cid}&tech_id=${requests.tech_id}`;

    if (requests.mode_id) {
      queryParams = `${queryParams}&mode_id=${requests.mode_id}`;
    }

    if (requests.category_id) {
      queryParams = `${queryParams}&category_id=${requests.category_id}`;
    }

    if (
      requests.mode_name.toLowerCase() !== requests.cat.toLowerCase() ||
      requests.mode_id === '3' ||
      requests.tech_id !== '-1'
    ) {
      return ApiClient({
        method: 'get',
        url: `/react_gi_v2a_eval_entity_display.php?${queryParams}`,
      });
    } else {
      return ApiClient({
        method: 'get',
        url: `/react_gi_v1_mode_category_query_for_new_content_creation.php?${queryParams}`,
      });
    }
  };

  useEffect(() => {
    getAPI().then(res => {
      if (res.data) {
        const requests = convertQueryParamsToObject(
          eventInfo.event_details_link,
        );
        const body = generateFormOnObject({
          ...requests,
          external_id: requests.tech_id,
          freeform_id: res.data.assessment_summary[0].assessment_freeform_id,
          topic_id: res.data.assessment_summary[0].assessment_topic_id,
          category_name: requests.cat,
        });

        ApiClient({
          method: 'post',
          data: body,
          url: eventInfo.event_details_link,
        }).then(eventRes => {
          const topicInfo = eventRes.data;
          if (topicInfo?.assessment_summary) {
            if (topicInfo.assessment_summary[0].extra_ratings) {
              let newExtraRatings = [];
              topicInfo.assessment_summary[0].extra_ratings.forEach(e =>
                newExtraRatings.push(e),
              );
              setExtraRatings(newExtraRatings);
            }
          }
        });
      }
    });
  }, [eventInfo]);

  return (
    <div
      className={
        'flex flex-col relative event-info-modal-container rounded-lg'
      }>
      <div className={'flex flex-col flex-1 mt-4 space-y-4 items-center px-4'}>
        <div className={'flex flex-row space-x-2 items-center'}>
          <p className={'text-lg font-semibold text-center text-black'}>
            {eventInfo.event_name}
          </p>
        </div>
      </div>
      <IoMdClose
        className={'w-6 h-6 absolute top-4 right-4 text-white cursor-pointer'}
        color={'black'}
        onClick={() => handleCloseModal()}
      />
      <div className={`event-info-list-container`}>
        <p className={'text-base font-medium text-center text-black mb-4'}>
          {eventInfo.event_description}
        </p>
        <div className={'flex justify-center mb-6'}>
          <img
            src={eventInfo.event_image}
            alt={`${name} Portrait`}
            style={{
              // height: 400,
              borderRadius: 16,
              objectPosition: 'center',
              objectFit: 'cover',
            }}
            className={'w-full sm:w-1/2'}
          />
        </div>
        <div className={'px-2'} style={{pointerEvents: 'none'}}>
          {extraRatings ? (
            <ExtraRatingsBlock
              extraRatings={extraRatings}
              setExtraRatings={() => {}}
              isEditable={false}
            />
          ) : (
            <div className={'w-full flex items-center justify-center'}>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
