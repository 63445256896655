export const setNewBlock = (
  allBlocks,
  currentBlock,
  setCurrentBlock,
  indexBlock,
  setIndexBlock,
  max,
  setMax,
  blockLength,
) => {
  if (max) {
    return;
  }

  const entitiesLength = allBlocks.length;
  const currentBlockLength = currentBlock.length;

  if (currentBlockLength + blockLength > entitiesLength) {
    const currentBlockRange = entitiesLength - currentBlockLength;

    if (currentBlockRange >= 1) {
      setIndexBlock(prevIndex => prevIndex + currentBlockRange);
      setCurrentBlock(allBlocks.slice(0, indexBlock + currentBlockRange));
      setMax(true);
    }
  } else {
    setIndexBlock(prevIndex => prevIndex + blockLength);
    setCurrentBlock(allBlocks.slice(0, indexBlock + blockLength));
    setMax(currentBlockLength === entitiesLength);
  }
};
