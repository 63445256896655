import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 0;
  @media ${device.laptop} {
    order: ${({orderRequired}) => (orderRequired ? 999 : 0)};
  }
`;

const TitleOverview = styled.div`
  color: ${({theme}) => theme.colors.main};
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
`;

const Card = styled.div`
  padding: ${({pd}) => `${pd}px`};
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  width: 100%;

  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: ${({bottomGap}) => (bottomGap ? '25px' : '0')};
  }
`;

const ExplanationTopic = styled.div`
  color: ${({theme}) => theme.colors.main};
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
`;

const ContentContainer = styled.div`
  color: #a2a2a2;
  font-size: 15px;
  font-weight: 500;

  @media ${device.mobileM} {
    font-size: 12px;
  }
`;

const PortraitImg = styled.img`
  margin-top: 15px;
  width: 100%;
  height: auto;
  border-radius: 5px;
`;

const NoImageText = styled.div`
  font-weight: 600;
  opacity: 0.4;
  font-style: italic;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100px;
`;

export {
  Base,
  TitleOverview,
  Card,
  ExplanationTopic,
  ContentContainer,
  PortraitImg,
  NoImageText,
};
