import React, {useCallback, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LandingButton from '../Components/Homepage/UI/LandingButton';
import useFetchContent from '../hooks/useFetchContent';
import CommonService from '../Services/CommonService';
import ServerContent from '../hoc/ServerContent';

const PrivacyText = styled.h6`
  color: white;
  font-size: 0.9rem;
  line-height: 3vh;
  //text-align: center;
  white-space: pre-line;
`;

export default function PrivacyPolicy() {
  const [
    privacyData,
    loadingPrivacyData,
    errorPrivacyData,
    callLoadPrivacyData,
  ] = useFetchContent(
    {},
    CommonService.getPrivacyPolicyData,
    useCallback(({data}) => data, []),
  );

  useEffect(() => {
    callLoadPrivacyData();
  }, []);

  return (
    <ServerContent
      loading={loadingPrivacyData}
      error={errorPrivacyData}
      className={
        'flex flex-col w-screen h-auto sm:h-screen bg-giga-primary-blue_500 items-center'
      }>
      <div className={'flex flex-col max-w-3xl p-8'}>
        <span className={'font-semibold text-3xl text-white self-center mb-10'}>
          Privacy Policy
        </span>
        <PrivacyText>
          {privacyData.section_narrative
            ? privacyData.section_narrative[0].section_html_narrative.replaceAll(
                '\n<br>',
                '\n',
              )
            : ''}
        </PrivacyText>
        <div
          className={
            'flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8 items-center justify-center mt-16'
          }>
          <div>
            <Link style={{textDecoration: 'none', color: 'white'}} to="/home">
              <LandingButton Backgroundcolor="rgba(0,0,0, 0.5)">
                <h3 style={{padding: '0', margin: '0'}}>Go Home</h3>
              </LandingButton>
            </Link>
          </div>
        </div>
      </div>
    </ServerContent>
  );
}
