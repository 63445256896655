import React, {useEffect} from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import Constants from './../Constants/Constants';
import Homepage from './Homepage';
import Signin from './signin';
import Signup from './signup';
import MemberHomepage from './MemberHomepage';
import Page404 from './404Page';
import store from '../Store/store';
import HistoryService from '../utils/HistoryService';
import {connect} from 'react-redux';
import RoutePublic from './RoutePublic';
import ResetPassword from './ResetPassword';
import About from '../Pages/About';
import MessageUs from '../Pages/MessageUs';
import Faq from '../Pages/FAQ Page/Faq';
import LearnMorePage from '../Components/Homepage/LearnMorePage';
import {getItem, removeItem} from '../utils/HelperService';
import * as userActions from '../Store/sagas/UserSaga/actions';
import VerifyResetPassword from '../Components/ResetPassword/VerifyResetPassword';
import CreatePassword from '../Components/ResetPassword/CreatePassword';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import Guests from '../Pages/Guests';
import InquiryPage from '../Pages/InquiryPage';
import Membership from '../Pages/Membership';
import Training from '../Pages/Training';
import ReservedPathList from '../Constants/ReservedPathList';
import ChannelService from '../utils/ChannelService';

const Routes = ({sessionId, channel, actions}) => {
  useEffect(() => {
    store.dispatch({type: 'StartUp/FetchInfo'});
  }, []);

  useEffect(() => {
    let pathName = window.location.pathname;
    console.log('pathName', pathName);
    let pathNames = pathName.split('/');
    console.log('pathNames', pathNames);
    if (pathNames.length >= 3 || ReservedPathList.includes(pathNames[1])) {
      actions.updateChannel(getItem(Constants.AS_CHANNEL_INFO));
      return;
    }

    if (pathName === '/') {
      actions.updateChannel({});
      removeItem(Constants.AS_CHANNEL_INFO);
      return;
    }

    pathName = pathName.replace('/', '');
    actions.updateChannel(null);
    ChannelService.fetchChannelInfo(pathName, actions.updateChannel);
  }, [window.location.pathname]);

  const pathName = window.location.pathname;
  let pathNames = pathName.split('/');
  if (
    !(pathNames.length >= 3 || ReservedPathList.includes(pathNames[1])) &&
    channel == null
  ) {
    return null;
  }

  return (
    <Router history={HistoryService}>
      <Switch>
        <RoutePublic
          authCheck={sessionId != null && sessionId !== ''}
          path="/signin"
          component={Signin}
        />
        <RoutePublic
          authCheck={sessionId != null && sessionId !== ''}
          path="/reset-password"
          component={ResetPassword}
        />
        <RoutePublic
          authCheck={sessionId != null && sessionId !== ''}
          path="/reset-password-process"
          component={CreatePassword}
        />
        <RoutePublic
          authCheck={sessionId != null && sessionId !== ''}
          path="/reset-password-confirm"
          component={VerifyResetPassword}
        />
        <Route path={`/training`} component={Training} />
        <Route path="/guests" component={Guests} />
        <Route path="/membership" component={Membership} />
        <Route path="/about" component={About} />
        <Route path="/privacy" component={PrivacyPolicy} />
        {/*<Route path="/certification" component={CertificationPage} />*/}
        <Route path="/inquiry" component={InquiryPage} />
        <Route path="/messageus" component={MessageUs} />
        <Route path="/faq" component={Faq} />
        <RoutePublic
          authCheck={sessionId != null && sessionId !== ''}
          path="/signup"
          component={Signup}
        />

        <Route path="/home" component={MemberHomepage} />

        <RoutePublic
          authCheck={sessionId != null && sessionId !== ''}
          exact
          path="/"
          component={Homepage}
        />
        <RoutePublic
          authCheck={sessionId != null && sessionId !== ''}
          exact
          path="/learnMore"
          component={LearnMorePage}
        />
        {/*<RoutePublic*/}
        {/*  authCheck={sessionId != null && sessionId !== ''}*/}
        {/*  path="/danabarrosbasketball"*/}
        {/*  component={() => (*/}
        {/*    <Homepage variant={Constants.DANA_BARROS_BASKETBALL} />*/}
        {/*  )}*/}
        {/*/>*/}
        {/*<RoutePublic*/}
        {/*  authCheck={sessionId != null && sessionId !== ''}*/}
        {/*  path="/danabarrosbasketballpodcasts"*/}
        {/*  component={() => (*/}
        {/*    <Homepage variant={Constants.DANA_BARROS_BASKETBALL_PODCASTS} />*/}
        {/*  )}*/}
        {/*/>*/}

        {/*<RoutePublic*/}
        {/*  authCheck={sessionId != null && sessionId !== ''}*/}
        {/*  exact*/}
        {/*  path={ChannelList[0].routePath}*/}
        {/*  component={() => <Homepage channel={ChannelList[0]} />}*/}
        {/*/>*/}

        {channel?.routePath && (
          <RoutePublic
            key={channel.name}
            authCheck={sessionId != null && sessionId !== ''}
            path={channel.routePath}
            component={() => <Homepage channel={channel} />}
          />
        )}

        <Route path="/404" component={Page404} />

        <Redirect to="/404" />
      </Switch>
    </Router>
  );
};

const mapStateToProps = ({user: {sessionId}, UserData: {channel}}) => ({
  channel,
  sessionId,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateChannel: ch => {
      dispatch(userActions.updateChannel(ch));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
