import React from 'react';
import {withRouter} from 'react-router-dom';

import {Base} from './styles';

import HomeNav1 from '@material-ui/icons/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import SendIcon3 from '@material-ui/icons/Send';
import AccountNav4 from '@material-ui/icons/AccountCircleOutlined';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import GGModal from '../../Modal';
import ChooseMC from './ChooseMC';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {connect} from 'react-redux';

const VerticalNav = ({history: {push}, actions}) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  const handleOpenModal = () => {
    // setModalVisible(true)
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleGo = (mode, selCategory) => {
    // setModalVisible(false)
    // const tech_id = -1
    // storeEntityParams({
    //   mode_id: mode.mode_id,
    //   mode_name: mode.mode_name,
    //   category_id: selCategory.category_id,
    //   tech_id,
    // })
    // storeUserMode(mode)
    // storeCategoryMode(selCategory)
    // actions.updateCreationModes([mode], [selCategory])
    // removeItem(Constants.AS_CURRENT_SECTION)
    // push(`/home/create/analysis?tech_id=${tech_id}&cid=${selCategory.cid}`)
  };

  const handleMessageClick = () => {
    push('/home');
    actions.updateMessageModeSelected(true);
  };

  return (
    <Base>
      <HomeNav1 className="Icon" onClick={() => push('/home')} />
      <NotificationsNoneOutlinedIcon
        className="Icon"
        onClick={() => push('/home/notifications')}
      />

      {/* <Fab
      onClick={() => push('/home/create')}
      className="Fab"
      size="small"
      style={{ backgroundColor: '#2A99E8', boxShadow: 'none' }}
    >
      <AddIcon style={{ fill: '#fff' }} />
    </Fab> */}

      <Fab
        className="Fab"
        size="small"
        onClick={() => handleOpenModal()}
        style={{backgroundColor: '#2A99E8', boxShadow: 'none'}}>
        <AddIcon style={{fill: '#fff'}} />
      </Fab>

      <SendIcon3 className="Icon" onClick={handleMessageClick} />

      <AccountNav4 className="Icon" onClick={() => push('/home/user')} />

      <GGModal
        modalVisible={modalVisible}
        handleCloseModal={handleCloseModal}
        renderContent={() => (
          <ChooseMC handleCloseModal={handleCloseModal} handleGo={handleGo} />
        )}
      />
    </Base>
  );
};

const mapStateToProps = state => ({
  messageModeSelected: state.UserData.messageModeSelected,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateCreationModes: (modes, categories) => {
      dispatch(userActions.updateCreationModes(modes, categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerticalNav),
);
