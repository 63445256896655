import React, {useCallback, useEffect, useState} from 'react';
import useFetchContent from '../../hooks/useFetchContent';
import CommonService from '../../Services/CommonService';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import Button from '../../Components/DesignSystem/button/button';
import PriceCard from '../../Components/Homepage/PriceCard'
import './styles.scss';
import ServerContent from '../../hoc/ServerContent';
import Header from '../../Components/Create/Header'
import HomeScheme from '../../hoc/HomeScheme'
import header from '../../Components/Create/Header'
import Footer from '../../Components/Homepage/Footer'

export default function Training() {
  const [
    trainingData,
    loadingTrainingData,
    errorTrainingData,
    callLoadTrainingData,
  ] = useFetchContent(
    {},
    CommonService.getTrainingData,
    useCallback(({data}) => data, []),
  );

  useEffect(() => {
    callLoadTrainingData();
  }, []);

  const handleSubmit = () => {};

  const [doneSub, setDoneSub] = useState('');

  const handleDoneSub = (value) => {
    setDoneSub(value);
  };

  return (
    <div className={'flex flex-row flex-1 justify-center'}>
      <ServerContent
        className={'flex flex-col items-center mx-8 pb-12 max-w-6xl'}
        loading={loadingTrainingData}
        error={errorTrainingData}>
        {trainingData.header != null && (
          <>
          <HomeScheme showHeaderSection={true}>
            {header}
            <div className={'mt-10 space-y-2 mb-10'}></div>
            <h2 className={'text-4xl font-bold lg:text-5xl pb-8'}>{trainingData.header.topic_name}</h2>

            <div  className={'flex flex-row flex-wrap justify-center'}>
              {trainingData.courses?.map((e, i) => (
                  <PriceCard key={i} course = {e} handleDoneSub={handleDoneSub} />
              ))}
            </div>
            <div className={'mt-10'}></div>
            <Footer/>
          </HomeScheme>
          </>
        )}

      </ServerContent>
    </div>
  );
}
