import React from 'react';

import {BlockDesc, BlockTitle} from '../styles';

import LandingTextBlock from '../../UI/LandingTextBlock';
import LandingButton from '../../UI/LandingButton';

import PortraitImg from 'assets/img/landing/learnMoreBlocks/portrait-1.png';

import device, {customDevice} from 'mediaQueries';
import HistoryService from '../../../../utils/HistoryService';

const containerCSS = `
  @media ${device.tablet} {
    margin-top: 90px;
  }

  @media ${customDevice(450)} {
    margin-top: 120px;
  }
`;

const portraitCSS = `
  @media ${device.tablet} {
    width: 55%;
  } 
`;

const textCSS = `
  @media ${device.tablet} {
    width: initial;
    flex: 1;
  } 
`;

const SmallTalkBlock = ({isDana, variant}) => (
  <LandingTextBlock
    portraitSrc={PortraitImg}
    directionText="right"
    imageWidth="70%"
    textWidth="30%"
    containerCSS={containerCSS}
    portraitCSS={portraitCSS}
    textCSS={textCSS}>
    <BlockTitle color="#2ca3ff">Let&apos;s skip the small talk.</BlockTitle>
    <BlockDesc>
      Giga is a platform to discuss ideas. Implementing an easy to follow
      structured approach. Enabling faster, quicker, better understanding of
      points of view. Leading to a community of members who respect individual
      opinion.
    </BlockDesc>
    <LandingButton
      bgCol="rgba(0,0,0, 0.8)"
      onClick={() =>
        HistoryService.push({
          pathname: '/signup',
          state: {
            isDana,
            variant,
          },
        })
      }>
      Get Started
    </LandingButton>
  </LandingTextBlock>
);

export default SmallTalkBlock;
