import React from 'react';

import {
  Base,
  ButtonsContainer,
  Portrait,
  Subtitle,
  Title,
  TitleContainer,
} from './styles';

const AlertContainer = ({children, title, subtitle, imgPortrait}) => (
  <Base>
    <TitleContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TitleContainer>

    <Portrait src={imgPortrait} alt="Portrait Alert" />

    <ButtonsContainer>{children}</ButtonsContainer>
  </Base>
);

export default AlertContainer;
