import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import {Base} from './styles';

const Loading = () => (
  <Base>
    <CircularProgress thickness={5} className="Loading" disableShrink />
  </Base>
);

export default Loading;
