import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={4} variant="filled" {...props} />;
}

export default function GigaAlert(props) {
  const {alertInfo, handleClose, className} = props;
  return (
    <Snackbar
      key={alertInfo.message}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={className ?? ''}
      open={alertInfo.open}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert severity={alertInfo.severity ?? 'success'} onClose={handleClose}>
        {alertInfo.message}
      </Alert>
    </Snackbar>
  );
}
