import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {Container, MainImg, Phrase} from './styles';

import Logo from 'Components/UI/Logo';

import useTitle from 'hooks/useTitle';

const VerifyEmail = ({currentSignupPhase, history}) => {
  useTitle('Email Verification', true);

  useEffect(() => {
    if (currentSignupPhase !== 'verifyEmail') {
      history.push('/signup/createUser');
    }
  }, [currentSignupPhase, history]);

  return (
    <Container>
      <Logo size="150" fixedTopLeft="true" specialSize={'150'} />
      <MainImg />
      <Phrase>
        Check your email to verify your identity, and set up your password
      </Phrase>
    </Container>
  );
};

const mapStateToProps = ({signup: {currentPhase}}) => ({
  currentSignupPhase: currentPhase,
});

export default connect(mapStateToProps)(VerifyEmail);
