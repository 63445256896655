import React from 'react';

import {
  Base,
  DescriptionContainer,
  NameContainer,
  PortraitContainer,
} from './styles';

import ShowHTMLString from 'hoc/ShowHTMLString';

const SubjectProfile = ({
  name,
  image,
  newImage,
  description,
  imgSize = '300px',
  bottomGap,
}) => (
  <Base bottomGap={bottomGap}>
    <PortraitContainer imgSize={imgSize}>
      <img
        src={newImage ? URL.createObjectURL(newImage) : image}
        alt={`${name} Portrait`}
      />
    </PortraitContainer>

    <NameContainer>{name}</NameContainer>

    <DescriptionContainer>
      <ShowHTMLString htmlString={description} />
    </DescriptionContainer>
  </Base>
);

export default SubjectProfile;
