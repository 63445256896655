import React from 'react';

import AlertContainer from '../../AlertContainer';
import Button from 'Components/DesignSystem/button/button';

import CompleteDefinitionImg from 'assets/img/3d/complete-definition.png';

import {Base} from './styles';

const PublishScreen = () => {
  return (
    <Base>
      <AlertContainer
        title="Nice! You have created an assessment."
        subtitle="You have gained a point towards your GigaRank"
        imgPortrait={CompleteDefinitionImg}>
        <Button>See my Assessment</Button>
      </AlertContainer>
    </Base>
  );
};

export default PublishScreen;
