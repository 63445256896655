import React from 'react';

import {Base, SrcContainer, TypeContainer} from './styles';

const AttachmentContent = ({type, src, fileName}) => {
  return (
    <Base>
      <SrcContainer title={fileName}>{fileName}</SrcContainer>
      {type != null && <TypeContainer>{type}</TypeContainer>}
    </Base>
  );
};

export default AttachmentContent;
