import React, {memo} from 'react';

import HeaderTab from 'Components/UI/HeaderTab';

import {getOpacityTab} from '../Helpers/tabs';

// const tabsInfo = [
//   { id: 1, isSummary: true, label: 'Summary' },
//   {
//     id: 2,
//     isSummary: false,
//     label: 'Dribbling',
//     keyFactorId: 134,
//     keyFactorCid: 'gispbbnbaecatbcpl.574.ent.16',
//   },
// ];

const baseContent = `
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #c5c5c5;
  margin-bottom: 30px;

  width: 100%;
`;

const AnalysisKeyFactorsHeader = ({
  currentSection,
  setCurrentSection,
  sections,
}) => {
  const tabs = sections.map((tab, index) => (
    <HeaderTab
      mode="dark"
      key={tab.id}
      content={tab.label}
      clicked={() => setCurrentSection(sections[index])}
      opacity={getOpacityTab(
        currentSection.id,
        tab.id,
        tab.topicsCompleted.find(({isRequired}) => isRequired)?.isCompleted,
      )}
    />
  ));

  if (tabs == null || tabs.length === 0) {
    return null;
  }

  return (
    <div className={'flex justify-center'}>
      {/*<Title>Assessment</Title>*/}
      {/*<OverflowContainer*/}
      {/*  vertical*/}
      {/*  verticalBreakpoint={620}*/}
      {/*  tabsInfo={tabs}*/}
      {/*  width="100"*/}
      {/*  emptyMessage="Loading..."*/}
      {/*  baseStyles={baseContent}>*/}
      {/*  {tabs}*/}
      {/*</OverflowContainer>*/}
      <p className={'text-giga-main font-semibold text-3xl mt-4 mb-8'}>
        {currentSection.label}
      </p>
    </div>
  );
};

export default memo(AnalysisKeyFactorsHeader);
