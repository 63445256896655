import React, {useCallback, useEffect, useState} from 'react';

import Flow from '../Flow';

import generateSubjectNamePhase from './FlowPhases/subjectName';
import generateTopicsPhase from './FlowPhases/topics';
import generatePublishScreenPhase from './FlowPhases/publishScreen';

import {convertQueryParamsToObject} from 'Helpers/api';
import {checkStrLength} from 'Helpers/validators';

import {
  getCompletedTopicsList,
  getIncompletedTopicIndex,
  setNewCompletedTopic,
  setNewCompletedTopicInSection,
} from './bl';
import {getItem} from '../../../../utils/HelperService';
import Constants from '../../../../Constants/Constants';

const AnalysisFlow = ({location: {search}, history: {push}}) => {
  const [sections, setSections] = useState([]);
  const [currentSection, setCurrentSection] = useState(
    getItem(Constants.AS_CURRENT_SECTION) ?? {
      id: -1,
      isSummary: true,
      label: 'Summary',
    },
  );
  const [hasMadeRedirectToSummary, setHasMadeRedirectToSummary] =
    useState(false);

  const [subjectName, setSubjectName] = useState('');
  const [subjectNameErrorStatus, setSubjectNameErrorStatus] = useState('');

  const [cid, setCid] = useState('');
  const [techId, setTechId] = useState('');
  const [initialIndex, setInitialIndex] = useState(1);

  const [topicsArr, setTopicsArr] = useState([]);

  console.log('topicsArr', topicsArr);
  console.log('sections', sections);

  const [completedTopics, setCompletedTopics] = useState([]);

  console.log('completedTopics', completedTopics);

  const [indexTopic, setIndexTopic] = useState(0);
  const [currentTopic, setCurrentTopic] = useState({});
  const [errorTopic, setErrorTopic] = useState('');
  const [loadingTopic, setLoadingTopic] = useState(false);

  const [errorAssessment, setErrorAssessment] = useState('');

  const [redirectToRequiredTopic, setRedirectToRequiredTopic] = useState(false); // Redirect state to check if the user has already been redirected to the required topic

  useEffect(() => {
    const {tech_id, cid} = convertQueryParamsToObject(search || '');

    if (tech_id && cid) {
      setTechId(tech_id);
      setCid(cid);
      setInitialIndex(1);
    }
  }, [search]);

  useEffect(() => {
    setCompletedTopics(getCompletedTopicsList(topicsArr));
  }, [topicsArr]);

  useEffect(() => {
    console.log(
      'run change completedTopics useEffect',
      currentSection.topicsCompleted,
    );

    if (
      currentSection.topicsCompleted &&
      currentSection.topicsCompleted.length > 0
    ) {
      setCompletedTopics(currentSection.topicsCompleted);
    }
  }, [currentSection]);

  useEffect(() => {
    if (sections.length > 0) {
      const completedTopicsList = getCompletedTopicsList(topicsArr);

      setSections(prevSections =>
        prevSections.map(section => ({
          ...section,
          topicsCompleted: completedTopicsList,
        })),
      );
    }
  }, [sections.length, topicsArr]);

  useEffect(() => {
    console.log('run change summmary section');

    const summary = sections[0];

    if (summary?.topicsCompleted && !hasMadeRedirectToSummary) {
      // setCurrentSection(summary)
      setHasMadeRedirectToSummary(true);
    }
  }, [hasMadeRedirectToSummary, sections]);

  useEffect(() => {
    setErrorTopic('');
    setErrorAssessment('');
  }, [indexTopic]);

  useEffect(() => {
    setCurrentTopic(topicsArr[indexTopic]);
  }, [indexTopic, topicsArr]);

  // delete this useEffect to make Current first topic
  useEffect(() => {
    if (
      currentTopic &&
      Object.keys(currentTopic).length > 0 &&
      !loadingTopic &&
      !redirectToRequiredTopic
    ) {
      const result = topicsArr.findIndex(
        ({assessment_topic_required}) => assessment_topic_required === '1',
      );

      setIndexTopic(result !== -1 ? result : 0);
      setRedirectToRequiredTopic(true);
    }
  }, [topicsArr, currentTopic, loadingTopic, redirectToRequiredTopic]);

  const submitSubjectName = advanceIndexPhase => {
    let isValid = checkStrLength(subjectName, 0, 50);

    setSubjectNameErrorStatus(isValid ? 'success' : 'error');

    if (isValid) {
      advanceIndexPhase();
    }
  };

  const completeTopic = useCallback(
    (contentObj, topicNotRequired) => {
      if (topicNotRequired != null) {
        push('/home');
        return;
      }
      console.log('contentObj', contentObj);

      const mainTopic = contentObj[contentObj.length - 1]; // The main topic is the last topic

      const assessment_topic = mainTopic.assessment_topic;
      const isMainTopicCompleted = mainTopic.selectedRating.length > 0;

      setErrorTopic('');

      const indexIncompleted = getIncompletedTopicIndex(
        assessment_topic,
        completedTopics,
      );

      if (indexIncompleted !== false) {
        setIndexTopic(indexIncompleted);
      } else {
        setErrorTopic(
          'There are no incompleted topics. You can submit this assessment.',
        );
      }

      if (isMainTopicCompleted) {
        setNewCompletedTopic(setCompletedTopics, assessment_topic, true);
        setNewCompletedTopicInSection(
          currentSection.id,
          setSections,
          assessment_topic,
          true,
        );
      } else {
        setNewCompletedTopic(setCompletedTopics, assessment_topic, false);
        setNewCompletedTopicInSection(
          currentSection.id,
          setSections,
          assessment_topic,
          false,
        );
      }
    },
    [completedTopics, currentSection],
  );

  const completeAssessment = advanceIndexPhase => {
    advanceIndexPhase();

    // const requiredTopic = topicsArr.find(
    //   ({assessment_topic_required}) => assessment_topic_required === '1',
    // );
    //
    // setErrorAssessment('');
    //
    // if (requiredTopic) {
    //   const requiredTopicName = requiredTopic.assessment_topic;
    //   const isRequiredTopicCompleted = completedTopics.find(
    //     ({assessment_topic}) => assessment_topic === requiredTopicName,
    //   ).isCompleted;
    //
    //   if (isRequiredTopicCompleted) {
    //     advanceIndexPhase();
    //   } else {
    //     setErrorAssessment(
    //       `The ${requiredTopicName} topic is required and needs to be completed`,
    //     );
    //   }
    // } else {
    //   advanceIndexPhase();
    // }
  };

  const flow = [
    generateSubjectNamePhase(
      setSubjectName,
      subjectNameErrorStatus,
      submitSubjectName,
    ),
    generateTopicsPhase(
      topicsArr,
      indexTopic,
      setIndexTopic,
      cid,
      techId,
      errorTopic,
      loadingTopic,
      setLoadingTopic,
      setTopicsArr,
      push,
      completeTopic,
      completeAssessment,
      currentTopic,
      setErrorTopic,
      completedTopics,
      errorAssessment,
      currentSection,
      () => {},
      sections,
      setSections,
    ),
    generatePublishScreenPhase(),
  ];

  return <Flow index={initialIndex} nameFlow="Analysis" arrFlow={flow} />;
};

export default AnalysisFlow;
