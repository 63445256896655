const findAssessmentTopic = (arr, assessment_topic) =>
  arr.findIndex(topic => topic.assessment_topic === assessment_topic);

const addCompletedTopic = (prevCompleted, assessment_topic, valueCompleted) => {
  const completedTopicIndex = findAssessmentTopic(
    prevCompleted,
    assessment_topic,
  );

  const newCompletedTopic = {
    ...prevCompleted[completedTopicIndex],
    isCompleted: valueCompleted,
  };

  return Object.assign([], prevCompleted, {
    [completedTopicIndex]: newCompletedTopic,
  });
};

export const setNewCompletedTopic = (
  setCompletedTopics,
  assessment_topic,
  valueCompleted,
) => {
  setCompletedTopics(prevCompleted =>
    addCompletedTopic(prevCompleted, assessment_topic, valueCompleted),
  );
};

export const setNewCompletedTopicInSection = (
  sectionID,
  setSections,
  assessment_topic,
  valueCompleted,
) => {
  setSections(prevSections =>
    prevSections.map(({topicsCompleted, id}, index) =>
      sectionID === id
        ? {
            ...prevSections[index],
            topicsCompleted: addCompletedTopic(
              topicsCompleted,
              assessment_topic,
              valueCompleted,
            ),
          }
        : prevSections[index],
    ),
  );
};

export const getIncompletedTopicIndex = (assessment_topic, completedTopics) => {
  const indexTopic = findAssessmentTopic(completedTopics, assessment_topic);
  const afterTopic = completedTopics.slice(
    indexTopic + 1,
    completedTopics.length,
  );
  const beforeTopic = completedTopics.slice(0, indexTopic - 1);

  const getIncompletedTopic = arr =>
    completedTopics.findIndex(
      ({assessment_topic}) =>
        assessment_topic ===
        arr.find(({isCompleted}) => !isCompleted)?.assessment_topic,
    );

  const afterIndex = getIncompletedTopic(afterTopic);

  if (afterIndex !== -1) {
    return afterIndex;
  }

  const beforeIndex = getIncompletedTopic(beforeTopic);

  if (beforeIndex !== -1) {
    return beforeIndex;
  }

  return false; // No incompleted inputs
};

export const getCompletedTopicsList = topicsArr =>
  topicsArr.map(
    ({assessment_topic, isCompleted, assessment_topic_required}) => ({
      assessment_topic,
      isCompleted: isCompleted ?? false,
      isRequired: assessment_topic_required === '1',
    }),
  );
