import React, {memo, useCallback, useRef} from 'react';

import {Base} from './styles';
import {uploadAttachmentBasedOnFile} from './bl';

import AttachInput from '../AttachInput';

import {getFileType} from 'Helpers/files/index.js';

const AttachInputs = ({
  hidden,
  errorFile,
  setErrorFile,
  addNewAttachment,
  noNeedSubmitBtn = false,
}) => {
  const fileInput = useRef(null);

  const handleFileUploadSubmit = useCallback(
    e => {
      e.preventDefault();

      setErrorFile('');

        const fileArray = [...fileInput.current.files];
        fileArray.map((val)=>{
          const fileToUpload = val;

          if (fileToUpload) {
              const mimeType = fileToUpload.type;
              const fileName = fileToUpload.name;
              const fileType = getFileType(mimeType);

              if (fileType) {
                  uploadAttachmentBasedOnFile(
                      fileToUpload,
                      addNewAttachment,
                      fileType,
                      fileName,
                  );
              } else {
                  setErrorFile('File format is not supported.');
              }
          }
      })

    },
    [setErrorFile, addNewAttachment],
  );

  return (
    <Base hidden={hidden}>
      <AttachInput
        handleSubmit={handleFileUploadSubmit}
        ref={fileInput}
        type="file"
        forId="Upload File"
        noNeedSubmitBtn={noNeedSubmitBtn}
        errorMessage={errorFile}>
        Upload the File Here
      </AttachInput>
    </Base>
  );
};

export default memo(AttachInputs);
