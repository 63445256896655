import * as actions from './constants';

export const fetchUserData = (groupCategoryId = '-1') => ({
  type: actions.FETCH_USER_DATA,
  groupCategoryId,
});

export const updateChannel = channel => ({
  type: actions.UPDATE_CHANNEL,
  channel,
});

export const updateMessageModeSelected = messageModeSelected => ({
  type: actions.UPDATE_MESSAGE_MODE_SELECTED,
  messageModeSelected,
});

export const updateDisplayCategories = displayCategories => ({
  type: actions.UPDATE_CATEGORY_DATA,
  displayCategories,
});

// export const updateDisplayModes = displayModes => ({
//   type: actions.UPDATE_MODE_DATA,
//   displayModes,
// });

export const updateCreationModes = (creationModes, creationCategories) => ({
  type: actions.UPDATE_CREATION_MODES,
  creationModes,
  creationCategories,
});

export const updateHomeSteps = homeSteps => ({
  type: actions.UPDATE_HOME_STEPS,
  homeSteps,
});

export const updateHomeCurrentCategory = homeCurrentCategory => ({
  type: actions.UPDATE_HOME_CURRENT_CATEGORY,
  homeCurrentCategory,
});

export const updateManageSteps = manageSteps => ({
  type: actions.UPDATE_MANAGE_STEPS,
  manageSteps,
});

export const updateManageCurrentCategory = manageCurrentCategory => ({
  type: actions.UPDATE_MANAGE_CURRENT_CATEGORY,
  manageCurrentCategory,
});

export const updateSearchBar = searchString => ({
  type: actions.UPDATE_HOME_SEARCH_STRING,
  searchString,
});

export const updateSearchFlag = searchFlag => ({
  type: actions.UPDATE_HOME_SEARCH_FLAG,
  searchFlag,
});
