import Topics from '../Topics';

const generateTopicsPhase = (
  topicsArr,
  indexTopic,
  setIndexTopic,
  cid,
  techId,
  errorTopic,
  loadingTopic,
  setLoadingTopic,
  setTopicsArr,
  historyPush,
  completeTopic,
  completeAssessment,
  currentTopic,
  setErrorTopic,
  completedTopics,
  errorAssessment,
  currentSection,
  setCurrentSection,
  sections,
  setSections,
) => ({
  type: 'custom',
  Component: Topics,
  propsComponent: {
    topicsArr,
    indexTopic,
    cid,
    techId,
    setIndexTopic,
    errorTopic,
    loadingTopic,
    setLoadingTopic,
    setTopicsArr,
    historyPush,
    completeTopic,
    completeAssessment,
    currentTopic,
    setErrorTopic,
    completedTopics,
    errorAssessment,
    currentSection,
    setCurrentSection,
    sections,
    setSections,
  },
});

export default generateTopicsPhase;
