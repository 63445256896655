import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({headerHeight}) => (headerHeight ? `${headerHeight}%` : '50%')};
  width: 100%;

  @media ${device.tablet} {
    height: ${({headerHeight}) =>
      headerHeight ? `${headerHeight + 10}%` : '60%'};
  }
`;

const PhaseContainer = styled.div`
  letter-spacing: 2.04px;
  font-size: 19px;
`;

const TitleFormContainer = styled.div`
  letter-spacing: 1.92px;
  font-size: 27px;
  font-weight: 600;
  overflow-wrap: break-word;

  @media ${customDevice(620)} {
    font-size: 22px;
  }

  @media ${device.mobileM} {
    font-size: 19px;
  }
`;

export {Base, PhaseContainer, TitleFormContainer};
