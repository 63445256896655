import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopicName = styled.div`
  font-size: 30px;
  font-weight: 800;
  margin: 15px 0;
  text-align: center;
  text-decoration: underline;
  color: red;
`;

export {Base, TopicName};
