import React from 'react';
import styled from 'styled-components';

import {checkAbsolute, checkColorStatus} from './bl';

const Base = styled.span`
  ${({absolute}) => checkAbsolute(absolute)}
  width: 10px;
  height: 10px;
  background-color: ${({status}) => checkColorStatus(status)};
  border-radius: 10px;
  transition: all 0.3s;
`;

const ErrorIndicator = ({absolute, status}) => (
  <Base absolute={absolute} status={status} />
);

export default ErrorIndicator;
