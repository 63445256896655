import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const HeaderBase = styled.header`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 7vh;
  min-height: 50px;
  padding: 0 2rem;
  //position: fixed;
  width: 100vw;
  z-index: 100;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  @media ${device.laptop} {
    flex-direction: column;
    justify-content: center;
    height: initial;
    padding: 18px 0;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: left;

  @media ${customDevice(1200)} {
    justify-content: left;
  }

  @media ${device.tablet} {
    position: static;
    justify-content: center;
  }
`;

export {HeaderBase, LogoContainer};
