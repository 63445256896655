import React, {memo} from 'react';

import {Base} from './styles';

import ExampleView from './ExampleView';
import CreateDefinition from './CreateDefinition';

const DescPage = ({
  type,
  url,
  setUrl,
  desc,
  setDesc,
  error,
  handleDescSubmit,
  advanceIndexPhase,
  setFileImage,
  custom,
  customObj,
  hideHeaderTitle = false,
  orderRequired = false,
}) => (
  <Base>
    <ExampleView
      url={url}
      type={type}
      subjectDesc={desc}
      custom={custom}
      customText={customObj}
      hideHeaderTitle={hideHeaderTitle}
      orderRequired={orderRequired}
    />
    {custom ? (
      customObj.definitionComp
    ) : (
      <CreateDefinition
        submitLabel={`Save My ${type}`}
        setDesc={setDesc}
        imageUrl={url}
        setImageUrl={setUrl}
        fileLabel={`${type} Portrait`}
        RTELabel={`${type} Description`}
        handleSubmit={handleDescSubmit.bind(null, advanceIndexPhase)}
        error={error}
        setFileImage={setFileImage}
      />
    )}
  </Base>
);

export default memo(DescPage);
