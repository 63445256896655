import React, {useCallback, useEffect, useState} from 'react';
import {Route} from 'react-router-dom';

import InitialForm from 'Components/SignUp/InitialForm';
import VerifyEmail from 'Components/SignUp/VerifyEmail';
import CreateUser from 'Components/SignUp/CreateUser';
import Confirmation from 'Components/SignUp/Confirmation';

import PageContainer from 'hoc/PageContainer';

// import {} from './styles';
import {checkErrorEmail, validateInitialEmail} from './bl';
import Constants from '../../Constants/Constants';

// this is used to prevent color of ErrorIndicator change
// when useEffect is initially called

let initialEmailInit = true;

const Signup = ({history, match: {path}, ...rest}) => {
  const isDana = rest?.location?.state?.isDana ?? false;
  const variant = rest?.location?.state?.variant ?? Constants.GIGA_INTELLIGENCE;

  const [initialStateError, setInitialStateError] = useState({
    state: '',
    message: '',
  });
  const [initialEmail, setInitialEmail] = useState('');
  const [initialCheckedEmail, setInitialCheckedEmail] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [userPassword, setUserPassword] = useState('');
  const [confirmUserPassword, setConfirmUserPassword] = useState('');
  const [userPrivacy, setUserPrivacy] = useState(false);

  const [exampleEmail, setExampleEmail] = useState('');

  console.log('Signup render');

  useEffect(() => {
    /*When the input change it will check after .4s if the input is valid or not and change the
      ErrorIndicator color and message. In the case the input change again (that means the user
      is still typing) the setTimeout will be cleared.*/

    const validation = () => {
      const [error, errorMessage] = validateInitialEmail(initialEmail);

      checkErrorEmail(
        error,
        errorMessage,
        initialStateError.state,
        setInitialStateError,
        setInitialCheckedEmail,
        initialEmail,
      );
    };

    let counterTime;

    if (!initialEmailInit) {
      counterTime = setTimeout(validation, 400);
    } else {
      initialEmailInit = false;
    }

    return () => clearTimeout(counterTime);
  }, [initialEmail, initialStateError.state]);

  const setInputInitialEmail = useCallback(
    ({target: {value}}) => setInitialEmail(value),
    [],
  );

  const setInputUserPassword = useCallback(
    ({target: {value}}) => setUserPassword(value),
    [],
  );
  const setInputConfirmUserPassword = useCallback(
    ({target: {value}}) => setConfirmUserPassword(value),
    [],
  );

  return (
    <PageContainer>
      <Route
        exact
        path={`${path}/`}
        render={() => (
          <InitialForm
            stateError={initialStateError}
            email={initialEmail}
            checkedEmail={initialCheckedEmail}
            setEmail={setInputInitialEmail}
            isCaptchaVerified={isCaptchaVerified}
            setIsCaptchaVerified={setIsCaptchaVerified}
            history={history}
            isDana={isDana}
            variant={variant}
          />
        )}
      />

      <Route exact path={`${path}/verifyEmail`} component={VerifyEmail} />

      <Route
        exact
        path={`${path}/createUser`}
        render={() => (
          <CreateUser
            password={userPassword}
            setPassword={setInputUserPassword}
            confirmPassword={confirmUserPassword}
            setConfirmPassword={setInputConfirmUserPassword}
            privacy={userPrivacy}
            setPrivacy={setUserPrivacy}
            exampleEmail={exampleEmail}
            setExampleEmail={setExampleEmail}
          />
        )}
      />

      <Route exact path={`${path}/confirmation`} component={Confirmation} />
    </PageContainer>
  );
};

export default Signup;
