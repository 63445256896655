import React from 'react';

import {Base, TopicName} from './styles';

import AssessmentTopics from '../AssesmentTopics';

const AssessmentDetailTopics = ({assesmentTopics}) => {
  const assessmentDetailComponents = assesmentTopics.map(
    ({topic, subtopics}) => {
      const subtopicsSection = <AssessmentTopics assesmentTopics={subtopics} />;

      if (!subtopics.some(t => t.narrative)) {
        return null;
      }

      return (
        <Base key={topic}>
          <TopicName>{topic}</TopicName>
          {subtopicsSection}
        </Base>
      );
    },
  );

  return assessmentDetailComponents;
};

export default AssessmentDetailTopics;
