import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media ${device.laptopL} {
    flex-direction: column;
  }
`;

const TextBox = styled.div`
  background: linear-gradient(#2ca3ff 0%, #424dd1 100%);
  padding: 100px 50px;
  width: 50%;

  display: flex;
  flex-direction: column;

  @media ${device.laptopL} {
    width: 70%;
  }

  @media ${device.laptop} {
    width: 80%;
  }

  @media ${customDevice(650)} {
    width: 100%;
    padding: 70px 30px;
  }

  @media ${device.mobileL} {
    padding: 40px 20px;
    font-size: 10px;
    align-items: center;
  }

  button {
    width: 60%;

    @media ${device.mobileL} {
      width: 90%;
    }
  }
`;

const PortraitImg = styled.img`
  flex: 1;
  height: auto;
  margin-right: 30px;

  @media ${device.laptopL} {
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }
`;

export {TextBox, ContentWrapper, PortraitImg};
