import Constants from '../Constants/Constants';

const tabsHeaderRequired = dbName => {
  return (
    dbName === Constants.GIGA_INTELLIGENCE ||
    dbName === Constants.GIGA_MEDIA ||
    dbName === Constants.GIGA_GUEST
  );
};

const footerLogoRequired = dbName => {
  return (
    dbName === Constants.GIGA_INTELLIGENCE || dbName === Constants.GIGA_MEDIA
  );
};

export default {
  tabsHeaderRequired,
  footerLogoRequired,
};
