import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const TitleContainer = styled.div`
  font-weight: 700;
  font-size: 15px;
  margin: 0 auto;
  margin-bottom: 5px;
  text-align: center;

  @media ${device.desktopL} {
    font-size: 0.8vw;
  }

  @media ${customDevice(2560)} {
    font-size: 0.9vw;
  }

  @media ${device.desktop} {
    font-size: 17px;
  }

  @media ${device.laptop} {
    font-size: 15px;
  }
`;

const RatingBlock = styled.span`
  color: red;
`;

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: left;
  margin: 10px 0;

  p {
    margin: 5px 0;
  }

  img {
    margin: 0 auto;
    height: auto;

    @media ${device.desktopL} {
      width: 300px;
    }

    @media ${customDevice(2560)} {
      width: 300px;
    }

    @media ${device.laptopL} {
      width: 230px;
    }

    @media ${device.laptop} {
      width: 200px;
    }

    @media ${device.tablet} {
      width: 150px;
    }
  }

  @media ${device.desktopL} {
    font-size: 0.8vw;
  }

  @media ${customDevice(2560)} {
    font-size: 0.9vw;
  }

  @media ${device.desktop} {
    font-size: 17px;
  }

  @media ${device.laptop} {
    font-size: 15px;
  }
`;

const ErrorText = styled.div`
  font-weight: 600;
  opacity: 0.4;
  font-style: italic;
  text-align: center;
  padding: 15px 0;
`;

export {Base, TitleContainer, AnswerContainer, ErrorText, RatingBlock};
