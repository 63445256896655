import React from 'react';

import {Base, CardTitle, Portrait, PortraitContainer} from './styles';

const SectionCard = ({cardHeight, title, src, color}) => (
  <Base>
    <PortraitContainer height={cardHeight}>
      <Portrait src={src} alt={`${title} Portrait`} />
    </PortraitContainer>

    <CardTitle color={color}>{title}</CardTitle>
  </Base>
);

export default SectionCard;
