import React, {memo, useCallback, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Base} from './styles';
import MemberHomepageService from '../../../Services/memberHomepage';
import Button from '../../DesignSystem/button/button';

import GigaAlert from '../../UI/GigaAlert';
import HistoryService from '../../../utils/HistoryService';
import {DropzoneArea} from 'material-ui-dropzone';
import {useStyles} from '../Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import {
  getMCLSteps,
  popMCLSteps,
  setMCLSteps,
} from '../../../utils/HelperService';
import HomeSteps from '../Home/HomeSteps';
import {connect} from 'react-redux';
import ManageCategorySteps from '../ManageCategoryList/ManageCategorySteps';

function CreateSubject(props) {
  const classes = useStyles();

  let {subjectId, categoryId, parentCategoryId} = useParams();
  subjectId = subjectId.split('?')[0];
  console.log('subjectId', subjectId);
  const isEditMode = subjectId !== '-1';

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [searchText, setSearchText] = useState('');
  const [image, setImage] = useState();
  const [initialImage, setInitialImage] = useState();

  const [isURLMode, setIsURLMode] = useState(false);
  const [imageURL, setImageURL] = useState('');

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [alertInfo, setAlertInfo] = React.useState({open: false});

  useEffect(() => {
    const steps = getMCLSteps();
    if (steps.length > 1) {
      setMCLSteps(steps.slice(0, 1));
    }
    if (isEditMode) {
      MemberHomepageService.callEndpoint(
        `/react_gi_v1_manage_subjects_display.php?category_id=${categoryId}&parent_category_id=${parentCategoryId}&external_id=${subjectId}`,
      ).then(res => {
        console.log('CreateSubPage - display', res);
        const resData =
          res?.data?.subjects?.length > 0 ? res.data.subjects[0] : null;
        if (resData) {
          setDescription(resData.subject_description);
          setName(resData.subject_name);
          setInitialImage(resData.subject_image_url);
        }
      });
    }
  }, []);

  const handleSubmit = () => {
    let message = '';
    if (name === '') {
      message = 'The name field is required';
    }

    if (description === '') {
      message = 'The description field is required';
    }

    if (message !== '') {
      setAlertInfo({
        open: true,
        message,
        severity: 'warning',
      });
      return;
    }

    const body = {
      external_id: subjectId,
      category_id: categoryId,
      parent_category_id: parentCategoryId,
      mode_id: 3,
      folder_id: 1,

      subject_name: name,
      subject_description: description,
      subject_image_url: imageURL,
    };

    console.log('CreateSubPage - body', body);

    MemberHomepageService.createSubject(body, image)
      .then(res => {
        console.log(res);
        setAlertInfo({
          open: true,
          message: `${isEditMode ? 'Updated' : 'Created'} successfully!`,
        });
        setTimeout(() => {
          popMCLSteps();
          HistoryService.goBack();
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          {isEditMode ? 'Edit' : 'Create'} Subject
        </span>
      </Base>
      <Base className={'flex flex-row px-4 md:px-0'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={props.manageSteps}
          handleClickStep={() => {}}
        />
      </Base>
      <Base className={'flex flex-row px-4 md:px-0'}>
        <ManageCategorySteps />
      </Base>
      <Base loading={false} error={false} className={'px-4 md:px-0'}>
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  id="description"
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md border"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Image
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col">
                {!isEditMode && (
                  <div className="flex items-center">
                    <input
                      id={'url-mode'}
                      name="url-mode"
                      type="radio"
                      checked={isURLMode}
                      onClick={() => setIsURLMode(true)}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor={'url-mode'}
                      className="ml-3 block text-sm font-medium text-gray-700">
                      URL
                    </label>
                  </div>
                )}
                {!isEditMode && (
                  <div
                    className={`${
                      isURLMode ? '' : 'pointer-events-none opacity-70'
                    } mt-2`}>
                    <input
                      type="text"
                      name="image-url"
                      value={imageURL}
                      onChange={e => setImageURL(e.target.value)}
                      id="image-url"
                      className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md border"
                    />
                  </div>
                )}
                {!isEditMode && (
                  <div className="flex items-center mt-2">
                    <input
                      id={'file-mode'}
                      name="file-mode"
                      type="radio"
                      checked={!isURLMode}
                      onClick={() => setIsURLMode(false)}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor={'file-mode'}
                      className="ml-3 block text-sm font-medium text-gray-700">
                      File
                    </label>
                  </div>
                )}
                <div
                  className={`${
                    isURLMode ? 'opacity-70 pointer-events-none' : ''
                  }`}>
                  {isEditMode ? (
                    <div className={'flex justify-start mt-2'}>
                      {initialImage && (
                        <img
                          src={initialImage}
                          alt={`${name} Portrait`}
                          style={{
                            height: 'auto',
                            width: 'auto',
                            borderRadius: 16,
                            objectFit: 'contain',
                            maxHeight: 400,
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className={classes.categoryDropzoneContainer}
                      style={{
                        pointerEvents:
                          isEditMode || isURLMode ? 'none' : 'auto',
                      }}>
                      <DropzoneArea
                        acceptedFiles={['image/*']}
                        // initialFiles={[
                        //   initialImage !== ''
                        //     ? initialImage
                        //     : 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Change_in_Average_Temperature.svg/300px-Change_in_Average_Temperature.svg.png',
                        // ]}
                        filesLimit={1}
                        maxFileSize={18000000}
                        dropzoneText={'Drag and drop an image here or click'}
                        dropzoneClass={classes.dropzone}
                        dropzoneParagraphClass={classes.dropzoneText}
                        previewGridClasses={{
                          container: classes.dropzonePreview,
                          item: classes.dropzonePreviewItem,
                        }}
                        onChange={files => {
                          setImage(files[0]);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            'flex flex-col lg:flex-row justify-center mt-12 mb-12 lg:space-x-12'
          }>
          <div className={'mcl-create-new'}>
            <Button
              loading={loadingSubmit}
              onClick={() => handleSubmit()}
              bottomGap>
              Submit
            </Button>
          </div>
        </div>
        <GigaAlert
          alertInfo={alertInfo}
          handleClose={() => setAlertInfo({open: false})}
        />
      </Base>
    </>
  );
}

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  manageSteps: state.UserData.manageSteps,
  manageCurrentCategory: state.UserData.manageCurrentCategory,
  searchString: state.UserData.searchString,
});

export default connect(mapStateToProps, null)(memo(CreateSubject));
