import styled from 'styled-components';
import device from '../../../../../mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  //width: 270px;
  height: 320px;

  @media ${device.mobile} {
    height: 240px;
  }
`;

const PortraitEntity = styled.div`
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
  transition: all 0.3s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
  }
`;

export {Base, PortraitEntity};
