import React, {useCallback, useEffect} from 'react';
import './styles.scss';
import {Base} from './styles';
import Button from '../../DesignSystem/button/button';
import HistoryService from '../../../utils/HistoryService';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {Icon} from '@material-ui/core'
import {MdDelete, MdEdit} from 'react-icons/md'

export default function ManageSubscriptionList(props) {
  const masterEntityType =
    props.location?.state?.master_entity_type ?? 'Subscription';

  const [
    subscriptions,
    loadingSubscriptions,
    errorSubscriptions,
    callLoadSubscriptions,
  ] = useFetchContent(
    [],
    MemberHomepageService.fetchManagements,
    useCallback(({data}) => data.entity, []),
  );

  useEffect(() => {
    callLoadSubscriptions(masterEntityType);
  }, [masterEntityType]);

  console.log('subscriptions', subscriptions);

  return (
    <Base loading={loadingSubscriptions} error={errorSubscriptions}>
      <span className={'text-2xl font-semibold self-center'}>
        Manage {masterEntityType} List
      </span>
      <div className="my-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                    <span className="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {subscriptions?.map((item, index) => (
                  <tr
                    key={`${index}`}
                    className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {item.entity_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.entity_description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                      <button
                        className="inline-flex items-center px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium rounded-md"
                        onClick={e => {
                          e.preventDefault();
                          HistoryService.push({
                            pathname: `/home/subscription/${item.entity_id}`,
                            state: {
                              masterEntityType,
                              entity: item,
                            },
                          });
                        }}>
                        <MdEdit/>
                        &nbsp;Edit
                      </button>
                      <button
                        className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-md">
                        <MdDelete/>
                        &nbsp;Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={'mkf-create-new'}>
        <Button
          onClick={() => {
            HistoryService.push({
              pathname: `/home/subscription/-1`,
              state: {
                masterEntityType,
              },
            });
          }}
          bottomGap>
          Create New List
        </Button>
      </div>
    </Base>
  );
}
