import React, {forwardRef, memo} from 'react';

const AttachInput = (
  {
    value,
    type,
    forId,
    children,
    handleSubmit,
    errorMessage,
    noNeedSubmitBtn,
    ...inputProps
  },
  inputRef,
) => (
  <form onSubmit={handleSubmit}>
    <label htmlFor={forId}>{children}</label>
    <input
      required
      ref={inputRef}
      type={type}
      id={forId}
      name={forId}
      value={value}
      className={'border border-giga-main mr-2 p-2 mt-2 rounded'}
      onChange={e => {
        if (noNeedSubmitBtn) {
          handleSubmit(e);
        }
      }}
      multiple
      {...inputProps}
    />
    {!noNeedSubmitBtn && (
      <input
        type="submit"
        className={
          'px-3 py-1.5 rounded-md transform transition duration-300 ease-out hover:scale-105 mt-2'
        }
      />
    )}
    {errorMessage}
  </form>
);

export default memo(forwardRef(AttachInput));
