import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: 'white',
    borderRadius: '8px',
    height: '50px',
    textAlign: 'center',
    '&[disabled]': {
      opacity: 0.5,
    },
    opacity: 0.85,
    overflow: 'hidden',
    position: 'relative',
  },
  label: {
    fontSize: '18px',
    fontWeight: 'bold',
    textTransform: 'initial',
    fontFamily: 'Poppins',
  },
});

export default useStyles;
