const historyLimit = 15;

export const setNewViewedItem = (
  type,
  title,
  newCid,
  newCat,
  newTechId,
  otherProps = {},
) => {
  // type: 'entities' | 'assesments'

  const nameCollection = `${type === 'entity' ? 'entitie' : type}sCached`;
  let objCollection = JSON.parse(localStorage.getItem(nameCollection));

  if (!objCollection) {
    objCollection = [];
  }

  const findExistingItem = objCollection.findIndex(({name}) => name === title);

  const storeResultItem = () => {
    const resultObj = objCollection.concat({
      type,
      name: title,
      cid: newCid,
      cat: newCat,
      techId: newTechId,
      ...otherProps,
    });

    localStorage.setItem(nameCollection, JSON.stringify(resultObj));
  };

  if (findExistingItem === -1) {
    if (objCollection.length >= historyLimit) {
      objCollection.shift();
    }

    // The item is not already stored on localStorage.

    storeResultItem();
  } else {
    objCollection.splice(findExistingItem, 1);

    storeResultItem();
  }
};

export const setNewViewedCategory = nameCategory => {
  const storeName = 'categoriesCached';
  let objCollection = JSON.parse(localStorage.getItem(storeName));

  if (!objCollection) {
    objCollection = [];
  }

  const findExistingItem = objCollection.findIndex(
    ({name}) => name === nameCategory,
  );

  const storeCategory = () => {
    const resultObj = objCollection.concat({
      type: 'category',
      name: nameCategory,
    });

    localStorage.setItem(storeName, JSON.stringify(resultObj));
  };

  if (findExistingItem === -1) {
    if (objCollection.length >= historyLimit) {
      objCollection.shift();
    }

    // The item is not already stored on localStorage.

    storeCategory();
  } else {
    objCollection.splice(findExistingItem, 1);

    storeCategory();
  }
};
