import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  justify-content: ${({justifyContent}) => justifyContent ?? 'space-between'};
  padding: 15px 0;
  margin-bottom: 30px;

  width: 100%;

  @media ${device.mobile} {
    flex-direction: column;
    width: 98%;
  }

  @media ${customDevice(1024)} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .activeSection {
    opacity: 1;
  }
`;

const HeaderSection = styled.div`
  align-items: center;
  width: ${({widthSpec}) => widthSpec ?? '50%'};
  //background-color: ${({mode}) =>
    mode === 'regular' ? '#2CA3FF' : '#2E358B'};
  border-radius: 30px;

  display: flex;
  justify-content: space-between;
  padding: 10px 2%;

  @media ${device.laptop} {
    padding: 10px 5%;
    width: ${({widthSpec}) => '80%'};
  }

  @media ${device.tablet} {
    width: ${({widthSpec}) => '80%'};
    padding: 10px 4%;
  }

  @media ${customDevice(620)} {
    width: 100%;
    padding: 0;
  }

  @media ${customDevice(640)} {
    width: 100%;
  }

  &:first-child {
    @media ${customDevice(1024)} {
      margin-bottom: 10px;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  color: ${({mode}) => (mode === 'regular' ? '#fff' : 'black')};
  background-color: ${({mode}) =>
    mode === 'regular' ? '#1236ff' : 'lightgray'};
  padding: 4.8px 9.6px;
  border-radius: 1000px;
  cursor: pointer;
  opacity: 0.6;
  font-size: 10px;
  letter-spacing: 1.3px;
  font-weight: 600;
  margin: 0 0.5rem;

  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));

  transition-duration: 200ms;

  &:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  @media ${device.laptop} {
    font-size: 10px;
  }

  @media ${device.tablet} {
    font-size: 10px;
  }

  @media ${device.mobileL} {
    font-size: 10px;
  }
`;

export {Base, HeaderSection, StyledNavLink};
