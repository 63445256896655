export const getHorizontalScrollPercentage = element =>
  Math.floor(
    (element.scrollLeft / (element.scrollWidth - element.clientWidth)) * 100,
  );

export const getVerticalScrollPercentage = element =>
  Math.floor(
    (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100,
  );

export const isOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}) => scrollHeight > clientHeight || scrollWidth > clientWidth;

export const deleteHTMLFromString = str => str.replace(/<[^>]*>?/gm, ''); // DONT use this function for security validations.

export const reloadPage = () => window.location.reload();
