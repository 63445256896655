import React from 'react';

import Loading from 'Components/UI/Loading';
import ErrorDisplay from 'Components/UI/ErrorDisplay';

import {AlertContainer} from './styles';

const ServerContent = ({loading, error, children, className}) => {
  let content = null;

  const errorResult = (
    <AlertContainer>
      <ErrorDisplay />
    </AlertContainer>
  );

  if (loading) {
    content = (
      <AlertContainer>
        <Loading />
      </AlertContainer>
    );
  } else if (error) {
    content = errorResult;
  } else {
    content = children ? children : errorResult;
  }

  return <div className={className}>{content}</div>;
};

export default ServerContent;
