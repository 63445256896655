import React from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {convertQueryParamsToObject} from 'Helpers/api';
import Home from 'Components/MemberHomepage/Home';
import EntityListing from 'Components/MemberHomepage/EntityListing';
import AssesmentView from 'Components/MemberHomepage/AssesmentView';
import DefinitionsHeader from 'Components/MemberHomepage/Definitions/SectionsHeader';
import CategoryView from 'Components/MemberHomepage/CategoryView';
import Definitions from 'Components/MemberHomepage/Definitions';
import UserManagement from 'Components/MemberHomepage/UserManagement';
import Onboarding from 'Components/MemberHomepage/Onboarding';
import HomeScheme from 'hoc/HomeScheme';
import {useGetDBName} from '../../hoc/HomeScheme/bl';
import UIService from '../../Services/UIService';
import Footer from '../../Components/Homepage/Footer';
import CreateContent from '../../Components/MemberHomepage/CreateContent';
import ModeSelectorV2 from '../../Components/MemberHomepage/Definitions/ModeSelectorV2';
import ModeSelector from '../../Components/MemberHomepage/Definitions/ModeSelector';
import GigaMessageRoom from '../Containers/GigaMessageRoom';
import NotificationList from '../../Components/MemberHomepage/NotificationList';
import ManageSubscriptionList from '../../Components/MemberHomepage/ManageSubscriptionList';
import CreateSubscription from '../../Components/MemberHomepage/ManageSubscriptionList/CreateSubscription';
import Training from '../../Pages/Training';
import GuestReport from '../../Components/MemberHomepage/GuestReport';
import ManageCategoryList from '../../Components/MemberHomepage/ManageCategoryList';
import CreateCategory from '../../Components/MemberHomepage/ManageCategoryList/CreateCategory';
import ManageKeyFactors from '../../Components/MemberHomepage/ManageKeyFactors';
import CreateKeyFactor from '../../Components/MemberHomepage/ManageKeyFactors/CreateKeyFactor';
import CreateClassification from '../../Components/MemberHomepage/ManageClassifications/CreateClassification';
import ManageRatings from '../../Components/MemberHomepage/ManageRatings';
import CreateRating from '../../Components/MemberHomepage/ManageRatings/CreateRating';
import LinkKeyFactors from '../../Components/MemberHomepage/LinkKeyFactors';
import Constants from '../../Constants/Constants';
import {getUserToken} from '../../utils/HelperService';
import CreateSubject from '../../Components/MemberHomepage/ManageSubjectList/CreateSubject';
import ManageClassifications from '../../Components/MemberHomepage/ManageClassifications';
import ImportCategoryCSV from '../../Components/MemberHomepage/ManageCategoryList/ImportCategoryCSV';
import CategoryNotificationList from "../../Components/MemberHomepage/NotificationList/CategoryNotificationList";

const MemberHomepage = ({
  match: {path},
  location,
  creationModes,
  creationCategories,
}) => {
  const dbName = useGetDBName();

  const searchQuery = new URLSearchParams(location.search);

  const modeSelectorRequired =
    searchQuery.get(Constants.SQ_HEADER_MODES) !== 'false' &&
    getUserToken() != null;

  // const [leftContainerData, setLeftContainerData] = useState([])
  // const [rightContainerData, setRightContainerData] = useState([])
  // const [categoriesData, setCategoriesData] = useState([])

  // useEffect(() => {
  //   const cacheLeftContainer = JSON.parse(
  //     localStorage.getItem('entitiesCached'),
  //   )
  //   const cacheRightContainer = JSON.parse(
  //     localStorage.getItem('assesmentsCached'),
  //   )
  //   const cacheCategoriesData = JSON.parse(
  //     localStorage.getItem('categoriesCached'),
  //   )
  //
  //   setLeftContainerData(cacheLeftContainer ?? [])
  //   setRightContainerData(cacheRightContainer ?? [])
  //   setCategoriesData(cacheCategoriesData ?? [])
  // }, [setLeftContainerData, setRightContainerData])

  // let header = UIService.tabsHeaderRequired(dbName) && (
  //   <TabsHeader
  //     categories={categoriesData}
  //     leftData={leftContainerData}
  //     rightData={rightContainerData}
  //   />
  // )

  let header;

  const headerRequired =
    location.pathname.includes('/home/create') &&
    UIService.tabsHeaderRequired(dbName);

  if (headerRequired) {
    header = <DefinitionsHeader />;
  }

  header = null;

  const queryParams = convertQueryParamsToObject(location.search);

  return (
    // <HomeScheme showHeaderSection={!location.pathname.includes('/home/create') || headerRequired}>
    <HomeScheme showHeaderSection={true}>
      {header}
      {/*<UserText id='user-info'>...</UserText>*/}
      {/*{location.pathname.includes('/home/message') && (*/}
      {/*  <ModeSelector*/}
      {/*    setModes={() => {}}*/}
      {/*    modes={creationModes}*/}
      {/*    categories={creationCategories}*/}
      {/*    setCategories={() => {}}*/}
      {/*  />*/}
      {/*)}*/}
      <Route exact path={`${path}/`} component={Home} />
      <Route
        exact
        path={`${path}/demo/:category_id/:group_category_id`}
        component={Home}
      />
      <Route
        exact
        path={`${path}/notifications`}
        component={NotificationList}
      />
        <Route
            exact
            path={`${path}/notifications/category`}
            component={CategoryNotificationList}
        />
      <Route exact path={`${path}/message`} component={GigaMessageRoom} />
      <Route
        exact
        path={`${path}/create`}
        render={() => <Redirect to={`${path}/create/post`} />}
      />
      <Route
        exact
        path={`${path}/create/:createSection`}
        component={Definitions}
      />

      <Route exact path={`${path}/user`} component={UserManagement} />
      <Route
        exact
        path={`${path}/subscription-list`}
        component={ManageSubscriptionList}
      />
      <Route
        exact
        path={`${path}/manage-categories`}
        component={ManageCategoryList}
      />
      <Route
        exact
        path={`${path}/import-categories/:parentCategoryId/`}
        component={ImportCategoryCSV}
      />
      <Route
        exact
        path={`${path}/manage-key-factors`}
        component={ManageKeyFactors}
      />
      <Route
        exact
        path={`${path}/link-key-factors`}
        component={LinkKeyFactors}
      />
      <Route exact path={`${path}/manage-ratings`} component={ManageRatings} />
      <Route
        exact
        path={`${path}/key-factors/:keyFactorId`}
        component={CreateKeyFactor}
      />
      <Route
        exact
        path={`${path}/ratings/:ratingId`}
        component={CreateRating}
      />
      <Route
        exact
        path={`${path}/subscription/:subscriptionId`}
        component={CreateSubscription}
      />
      <Route
        exact
        path={`${path}/categories/:categoryId`}
        component={CreateCategory}
      />
      <Route
        exact
        path={`${path}/subjects/:subjectId/:categoryId/:parentCategoryId/`}
        component={CreateSubject}
      />
      <Route
        exact
        path={`${path}/manage-classifications`}
        component={ManageClassifications}
      />
      <Route
        exact
        path={`${path}/classifications/:classificationId`}
        component={CreateClassification}
      />

      <Route exact path={`${path}/create-content`} component={CreateContent} />

      <Route
        exact
        path={`${path}/entity/:cat/:cid/:techId`}
        component={EntityListing}
      />
      <Route
        exact
        path={`${path}/guest-report/:cat/:cid/:techId`}
        component={GuestReport}
      />

      <Route
        exact
        path={`${path}/entity/:catEntity/:cidEntity/:techIdEntity/assesment/:catAssesment/:cidAssesment/:techIdAssesment/:assessorID`}
        component={AssesmentView}
      />
      <Route
        exact
        path={`${path}/category/:nameCategory`}
        component={CategoryView}
      />

      <Onboarding path={path} />

      {dbName !== 'initial' && (
        <Footer to={'/home'} poweredByNeeded={true} variant={dbName} />
        // <Footer title={null} variant={dbName} />
      )}
    </HomeScheme>
  );
};

const mapStateToProps = ({user: {sessionId}, ...rest}) => ({
  sessionId,
  creationModes: rest.UserData.creationModes,
  creationCategories: rest.UserData.creationCategories,
});

export default connect(mapStateToProps, null)(MemberHomepage);
