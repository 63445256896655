import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: auto;
  margin-left: 1.5rem;
  font-weight: 600;
  color: #989898;
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
    text-align: center;

    margin-bottom: 20px;
  }

  @media ${customDevice(1240)} {
    margin-left: 0;
  }
`;

export {Base};
