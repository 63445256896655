import {BsInfoCircle} from 'react-icons/bs';
import {
  MdModelTraining,
  MdOutlineAccountCircle,
  MdOutlineSubscriptions,
  MdVerticalDistribute,
  MdOutlinePrivacyTip,
  MdOutlineNotificationsNone,
  MdOutlineCategory, MdCardMembership,
} from 'react-icons/md';
import {BiLogOut} from 'react-icons/bi';
import {AiOutlineHome} from 'react-icons/ai';
import {FaTachometerAlt} from "react-icons/fa";

export const drawerItems = (isGuest, cateVisible, subsVisible, distVisible) => [
  {
    id: 'startPage',
    label: 'Main',
    Icon: FaTachometerAlt,
    visible: isGuest,
  },
    {
    id: 'home',
    label: 'Home',
    Icon: AiOutlineHome,
    visible: true,
  },
  {
    id: 'account',
    label: 'My Account',
    Icon: MdOutlineAccountCircle,
    visible: !isGuest,
    "children": [
      {
        id: '“Profile”',
        label: '“Profile”',
        Icon: AiOutlineHome,
        visible: true,
      },
      {
        id: '“Settings”',
        label: '“Settings”',
        Icon: AiOutlineHome,
        visible: true,
      },
      {
        id: '“Preferences”',
        label: '“Preferences”',
        Icon: AiOutlineHome,
        visible: true,
      },
      {
        id: '“Membership”',
        label: '“Membership”',
        Icon: AiOutlineHome,
        visible: true,
      },
    ],
  },
  {
    id: 'notification',
    label: 'Notification',
    Icon: MdOutlineNotificationsNone,
    visible: !isGuest,
  },
  {
    id: 'category_list',
    label: 'Categories',
    Icon: MdOutlineCategory,
    visible: !isGuest && cateVisible,
  },
  {
    id: 'subscription_list',
    label: 'Subscription List',
    Icon: MdOutlineSubscriptions,
    visible: !isGuest && subsVisible,
  },
  {
    id: 'distribution_list',
    label: 'Distribution List',
    Icon: MdVerticalDistribute,
    visible: !isGuest && distVisible,
  },
  {
    id: 'about',
    label: 'About',
    Icon: BsInfoCircle,
    visible: true,
  },
  {
    id: 'privacy',
    label: 'Privacy Policy',
    Icon: MdOutlinePrivacyTip,
    visible: true,
  },
  {
    id: 'training',
    label: 'Training & Certification',
    Icon: MdModelTraining,
    visible: true,
  },
  {
    id: 'membership',
    label: 'Membership',
    Icon: MdCardMembership,
    visible: true,
  },
  // {
  //   id: 'faq',
  //   label: 'Faq',
  //   Icon: FaQuestion,
  // },
  {
    id: 'logout',
    label: 'Log Out',
    Icon: BiLogOut,
    visible: !isGuest,
  },
];
