import React from 'react';

import {Base} from './styles';

import Logo from 'Components/UI/Logo';
import FooterItems from './FooterItems';
import {connect} from 'react-redux';

const Footer = ({to, poweredByNeeded = true, style, variant, channel}) => {
  const color = channel?.mainColor ?? '#2CA3FF';
  return (
    <Base isCenter style={style}>
      <div className={'mb-4 lg:mb-0 hidden md:block'}>
        <Logo
          size="30"
          text
          title={'giga.'}
          titleStyles={{color: color ?? '#2CA3FF'}}
          to={to}
          noNeedIcon={false}
          variant={variant}
        />
      </div>
      <FooterItems variant={variant} />
      <div
        className={
          'flex flex-row items-center self-center mt-4 lg:mt-0 mb-0 sm:mb-0'
        }>
        {poweredByNeeded && (
          <span className={`text-lg font-semibold mr-2`} style={{color}}>
            Powered by
          </span>
        )}
        <div>
          <Logo
            size="30"
            text
            title={'giga.'}
            titleStyles={{color: color ?? '#2CA3FF'}}
            to={to}
            noNeedIcon={false}
            variant={variant}
            poweredByNeeded={poweredByNeeded}
          />
        </div>
      </div>
    </Base>
  );
};

const mapStateToProps = ({UserData: {channel}}) => ({
  channel,
});

export default connect(mapStateToProps, null)(Footer);
