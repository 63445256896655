import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  padding-top: ${({topPadding}) => topPadding ?? '50px'};
  //display: flex;
  //height: 100vh;

  //@media ${device.mobile} {
  //  padding-top: 20px;
  //  padding-bottom: 0;
  //}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  min-height: calc(100vh - 90px);
`;

export {ContentContainer, Base};
