import styled from 'styled-components';

const Base = styled.div`
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.main};
  padding: 7px 8px;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  opacity: ${({selected}) => (selected ? '0.6' : '1')};

  &:hover {
    transform: scale(1.1) translate(4px, 4px);
  }
`;

const TitleLabel = styled.div`
  padding: 5px 12px;
  background-color: ${({theme}) => theme.colors.main};
  border-radius: 8px;
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  transition: all 0.3s;

  position: absolute;
  left: 50%;
  top: -75%;
  white-space: nowrap;

  ${({show}) =>
    show
      ? `
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1.1);
  `
      : `
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1);
  `}
`;

export {Base, TitleLabel};
