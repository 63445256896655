import React from 'react';

import {Base} from './styles';

import {displayTitle} from './bl';

// Accepted Modes: 'dark', 'light'

const HeaderTab = ({
  content,
  mode,
  shortContent,
  clicked,
  opacity,
  ...props
}) => (
  <Base
    mode={mode}
    shortContent={shortContent}
    {...props}
    onClick={clicked}
    opacity={opacity}>
    {displayTitle(content)}
  </Base>
);

export default HeaderTab;
