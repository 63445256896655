import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const BlockTitle = styled.h4`
  font-size: 48px;
  color: ${({color}) => color};
  margin: 0;
  margin-bottom: 35px;

  @media ${customDevice(1380)} {
    font-size: 40px;
  }

  @media ${device.laptop} {
    font-size: 35px;
  }

  @media ${customDevice(840)} {
    font-size: 30px;
  }
`;

const BlockDesc = styled.div`
  color: #7c7c7c;
  font-weight: 600;
  font-size: 16px;
  margin: 30px 0;
  text-align: justify;
`;

export {BlockTitle, BlockDesc};
