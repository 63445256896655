import React, {useEffect, useState} from 'react';

import CommentaryHeader from './CommentaryHeader';
import CommentaryForm from './CommentaryForm';
import CommentaryListing from './CommentaryListing';

import {Base} from './styles';

const Commentary = ({
  commentaryTopics,
  prevComments,
  onSaved,
  prevCurrentSectionId,
}) => {
  const [currentSectionID, setCurrentSectionID] = useState(
    prevCurrentSectionId ??
      (commentaryTopics != null && commentaryTopics.length > 0
        ? commentaryTopics[0]?.id
        : null),
  );
  const [changingTopic, setChangingTopic] = useState(false);

  const onSavedComment = () => {
    onSaved(currentSectionID);
  };

  useEffect(() => {
    setChangingTopic(true);

    setTimeout(() => {
      setChangingTopic(false);
    }, 10);
  }, [currentSectionID]);

  let currentTopic = null;

  if (commentaryTopics != null && commentaryTopics.length > 0) {
    currentTopic =
      commentaryTopics[
        commentaryTopics.findIndex(topic => topic.id === currentSectionID)
      ];
  }

  return (
    <Base>
      <CommentaryHeader
        commentaryTopics={commentaryTopics}
        currentTopicName={currentTopic?.assessment_topic ?? ''}
        currentSectionID={currentSectionID}
        setCurrentSectionID={setCurrentSectionID}
      />

      {!changingTopic && (
        <>
          <CommentaryListing
            prevComments={prevComments}
            currentTopicName={currentTopic?.assessment_topic ?? ''}
            ratingOptions={currentTopic?.assessment_rating_topic_options ?? ''}
          />

          <CommentaryForm
            currentTopic={currentTopic}
            currentTopicName={currentTopic?.assessment_topic ?? ''}
            changingTopic={changingTopic}
            onSaved={() => onSavedComment()}
          />
        </>
      )}
    </Base>
  );
};

export default Commentary;
