import React from 'react';

import {Base, ContentBoxText, HeaderDesc, TextHeader, Title} from '../styles';
import {ContentWrapper, PortraitImg, TextBox} from './styles';

import Button from 'Components/DesignSystem/button/button';
import ProgressCircles from 'Components/UI/ProgressCircles';

import CategoryExampleImg from 'assets/img/ui/categoryExample.png';

const OnboardingWelcome = ({history}) => {
  return (
    <Base>
      <TextHeader>
        <Title>Categories and Subjects</Title>

        <HeaderDesc>
          Let&apos;s learn about the difference between a subject and a
          category.
        </HeaderDesc>
      </TextHeader>

      <ContentWrapper>
        <PortraitImg
          src={CategoryExampleImg}
          alt="Onboarding Category Example"
        />

        <TextBox>
          <ContentBoxText>
            We&apos;ve created a system to break things into categories, key
            factors and subjects. Categories are baskets for subjects.
            <br />
            <br />
            It gets deeper than this, but this is a great start! Please review
            our example of a category of soccer and subject of Soccer Player
            Cristiano Ronaldo. Another example shows how Meteorology is a
            category for weather forecasts in Portland.
          </ContentBoxText>

          <Button
            onClick={() => history.push('/home/onboarding/assessments')}
            style={{marginTop: '30px'}}>
            Continue
          </Button>

          <ProgressCircles
            circlesLength={3}
            selectedCircle={2}
            style={{marginTop: '20px'}}
          />
        </TextBox>
      </ContentWrapper>
    </Base>
  );
};

export default OnboardingWelcome;
