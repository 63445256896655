import React from 'react';
import {Base, NarrativeContainer, ProfilePicContainer} from './styles';
import Profile from './Profile';
import PreviewRatings from './PreviewRatings';
import ShowHTMLString from 'hoc/ShowHTMLString';
import GigaMenu from '../../../../UI/GigaMenu';
import {assessmentModeButtons} from '../../../info';
import './styles.scss';

const AssesmentPreview = ({
  userName,
  assessorPerspective,
  narrative,
  currentAssessment,
  futureAssessment,
  clicked,
  assessorPic,
}) => {
  return (
    <div
      className={`flex flex-row w-full mt-2 rounded-lg pb-6 xl:pb-16 relative ${
        assessorPerspective === 'Sponsor' ? 'bg-pink-100' : 'bg-giga-graylight'
      }`}>
      <Base>
        <ProfilePicContainer>
          <img
            src={assessorPic}
            alt={`${userName} Profile Pic`}
            className={'object-cover'}
          />
        </ProfilePicContainer>

        <Profile userName={userName} perspective={assessorPerspective} />

        <PreviewRatings
          className={'assessment-preview-preview-ratings1'}
          currentRating={currentAssessment}
          futureRating={futureAssessment}
        />
      </Base>

      <PreviewRatings
        className={'assessment-preview-preview-ratings2'}
        currentRating={currentAssessment}
        futureRating={futureAssessment}
      />

      <div className="Icon cursor-pointer absolute top-2 right-1 text-giga-graymain">
        <GigaMenu
          options={assessmentModeButtons.map(m => ({
            label: m.label,
            action: () => {
              clicked(m.mode);
            },
          }))}
        />
      </div>

      <NarrativeContainer>
        <ShowHTMLString htmlString={narrative} />
      </NarrativeContainer>

      {/*<DisplayButtons userName={userName} handler={clicked} />*/}
    </div>
  );
};

export default AssesmentPreview;
