import styled from 'styled-components';

import ServerContent from 'hoc/ServerContent';

import device from 'mediaQueries';

const Base = styled(ServerContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 72%;

  margin-top: 1rem;

  @media ${device.laptop} {
    width: 75%;
  }

  @media ${device.tablet} {
    width: 80%;
  }

  @media ${device.mobile} {
    margin-top: 3rem;
    width: 96%;
  }
`;

export {Base};
