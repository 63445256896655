import styled from 'styled-components';

import groupGradient from 'assets/img/landing/dana-barros-landing.png';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 500px;
  max-height: 1000px;
  background-image: url(${groupGradient});
  background-color: rgba(0, 0, 0, 0.8);
  background-position: center;
  padding: 120px 170px;
  background-size: contain;

  display: flex;
  align-items: flex-end;

  @media ${customDevice(1200)} {
    padding: 120px 90px;
  }

  @media ${customDevice(840)} {
    padding: 90px 60px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    align-items: center;
  }
`;

const HeroText = styled.div`
  color: #fff;
  font-size: ${({size}) => `${size}px`};
  font-weight: 600;
  margin-bottom: 20px;

  ${({css = ''}) => css}
`;

export {Base, TextContainer, HeroText};
