import React from 'react';

import {Base, TitleContainer} from './styles';

const EntityHeader = ({title, clickedTitle}) => (
  <Base>
    <TitleContainer
      className={title?.length >= 60 ? 'ellipsis' : ''}
      title={title}
      onClick={clickedTitle}>
      {title}
    </TitleContainer>

    {/*<IconButton size="small">*/}
    {/*  <MoreIcon className="Icon" size="small" />*/}
    {/*</IconButton>*/}
  </Base>
);

export default EntityHeader;
