import React from 'react';
import './styles.scss';
import LandingButton from '../../../../../../Homepage/UI/LandingButton';
import Target from '../../../../../../../assets/img/landing/target.png';

export default function PostSuccessModal(props) {
  const {handleCloseModal} = props;

  return (
    <div className={'post-success-modal-container'}>
      <span className={'text-white text-xl sm:text-2xl font-semibold mt-10'}>
        Your post is now in your feed!
      </span>
      <img alt={'target'} src={Target} className={'w-80 h-80'} />
      <div
        className={
          'flex sm:flex-row sm:space-x-6 my-8 space-y-4 sm:space-y-0 flex-col'
        }>
        <LandingButton
          Backgroundcolor="transparent"
          onClick={() => {
            handleCloseModal();
          }}>
          <h3 style={{padding: '0', margin: '0'}}>Go Home</h3>
        </LandingButton>
        <LandingButton
          Backgroundcolor="rgba(0,0,0, 0.5)"
          onClick={() => {
            handleCloseModal();
          }}>
          <h3 style={{padding: '0', margin: '0'}}>See Post</h3>
        </LandingButton>
      </div>
    </div>
  );
}
