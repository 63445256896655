import React from 'react';

import Routes from './Routes';
import {Theme} from './Components/DesignSystem/theme';

import PubNub from 'pubnub';
import {PubNubProvider} from 'pubnub-react';

const pubnub = new PubNub({
  publishKey: 'pub-c-78264b28-9a98-4b3a-8cad-2e67ed2aa992',
  subscribeKey: 'sub-c-fa94c58c-66c1-45bb-bf54-8132dc2da7f1',
  uuid: '1234',
});

const App = () => {
  return (
    <PubNubProvider client={pubnub}>
      <Theme>
        <Routes />
      </Theme>
    </PubNubProvider>
  );
};

export default App;
