import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;

  .Loading {
    color: ${({theme}) => theme.colors.main};
  }
`;

export {Base};
