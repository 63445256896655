import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 60%;
  min-width: 400px;

  @media ${device.mobileL} {
    width: 95%;
    min-width: 240px;
  }
`;

export {Base};
