import React, {useCallback, useEffect, useState} from 'react';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {Base} from '../../../Components/MemberHomepage/Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import GigaMessage from '../GigaMessage';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import Constants from '../../../Constants/Constants';
import {getItem} from '../../../utils/HelperService';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './styles.css';
import {Editor} from 'react-draft-wysiwyg';
import Button from '../../../Components/DesignSystem/button/button';
import AttachInputs from '../../../Components/Create/AttachInputs';
import {filterRepeatedLinks} from '../../../Components/Create/bl';
import Attachments from '../../../Components/Create/Attachments';

let attachmentId = 0;

export default function GigaMessageRoom(props) {
  const history = useHistory();
  const [chatMessageCount, setChatMessageCount] = useState(0);
  const [topic, setTopic] = useState(getItem(Constants.AS_MESSAGE_ROOM_TOPIC));
  const [productDescription, setProductDescription] = useState(null);

  const [editorState, setEditorState] = useState();

  useEffect(() => {
    let state = EditorState.createEmpty();

    if (productDescription) {
      const {contentBlocks, entityMap} = htmlToDraft(
        productDescription.replace(/(<\/?)figure((?:\s+.*?)?>)/g, ''),
      );
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      state = EditorState.createWithContent(contentState);
    }

    setEditorState(state);
  }, []);

  useEffect(() => {
    if (editorState) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());

      setProductDescription(draftToHtml(rawContentState));
    }
  }, [editorState]);

  const [historyList, setHistoryList] = useState([]);
  const [categories, loadingCategories, errorCategories, callCategories] =
    useFetchContent(
      [],
      MemberHomepageService.getCategoriesAndEntities,
      useCallback(({data}) => {
        return data.categories;
      }, []),
    );

  useEffect(() => {
    callCategories();
  }, []);

  useEffect(() => {
    const msgModeList = categories.filter(c => c.category === 'Messages');
    if (msgModeList.length !== 0) {
      setHistoryList(msgModeList[0].entities);
    } else {
      setHistoryList([]);
    }
  }, [categories]);

  console.log('GigaMessageRoom categories', categories, errorCategories);

  const [attachments, setAttachments] = useState([]);
  const [errorFileAttachment, setErrorFileAttachment] = useState('');

  const addNewAttachment = useCallback(
    (type, src, fileName, fileToUpload) => {
      if (attachments.length <= 8 && type !== 'image' && type !== 'video') {
        attachmentId++;

        setAttachments(prevAttachments =>
          filterRepeatedLinks(
            prevAttachments.concat({
              id: attachmentId,
              type,
              src,
              fileName,
            }),
          ),
        );
      } else {
        attachmentId++;

        setAttachments(prevAttachments =>
          prevAttachments.concat({
            id: attachmentId,
            type,
            src,
            fileName,
            fileToUpload,
          }),
        );
      }
    },
    [attachments],
  );

  const removeAttachment = useCallback((id, type, src) => {
    setAttachments(prevAttachments =>
      prevAttachments.filter(attachment => attachment.id !== id),
    );

    if (type === 'video') {
      URL.revokeObjectURL(src);
    }
  }, []);

  return (
    <Base loading={loadingCategories} error={errorCategories}>
      <div className={'flex flex-col items-center'}>
        <div
          className={
            'w-4/5 h-full grid grid-cols-2 grid-rows-2 grid-flow-col gap-3'
          }>
          <GigaMessage
            historyList={historyList}
            topic={topic}
            setTopic={setTopic}
            chatMessageCount={chatMessageCount}
            attachments={attachments}
            message={productDescription}
            onSent={() => {
              setEditorState(EditorState.createEmpty());
            }}
          />
          <div className={'flex flex-col items-end'}>
            <div className={'space-y-2'}>
              <Editor
                editorState={editorState}
                editorClassName="product-editor"
                wrapperClassName="product-editor-wrapper"
                onEditorStateChange={setEditorState}
              />
              <AttachInputs
                hidden={false}
                addNewAttachment={addNewAttachment}
                errorFile={errorFileAttachment}
                setErrorFile={setErrorFileAttachment}
                noNeedSubmitBtn={true}
              />
              <Attachments
                attachments={attachments}
                removeAttachment={removeAttachment}
              />
              <Button
                onClick={() => setChatMessageCount(chatMessageCount + 1)}
                bottomGap>
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
}
