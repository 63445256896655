import {all, call, put, takeLatest} from 'redux-saga/effects';
import * as actions from './constants';
import InitDisplayService from '../../../Services/initDisplay';

function* handleFetchUserData(action) {
  console.log('action.groupCategoryId', action.groupCategoryId);
  try {
    const modesResponse = yield call(InitDisplayService.getDisplayModes);
    const categoriesResponse = yield call(
      InitDisplayService.getDisplayCategories,
      action.groupCategoryId,
    );
    yield put({
      type: actions.UPDATE_USER_DATA,
      displayModes: modesResponse.data.modes,
      displayCategories: categoriesResponse.data.categories,
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_ERROR,
      error,
    });
  }
}

export default all([takeLatest(actions.FETCH_USER_DATA, handleFetchUserData)]);
