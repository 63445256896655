import React, {memo, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {drawerItems} from './info';

import {resetUser} from 'Store/actions';
import {
  getItem,
  getUserToken,
  removeUserToken,
} from '../../../utils/HelperService';
import {useGetDBName} from '../../../hoc/HomeScheme/bl';
import Constants from '../../../Constants/Constants';
import HistoryService from '../../../utils/HistoryService';
import MemberHomepageService from "../../../Services/memberHomepage";
import {areThereSavedAttachments, formatSavedAttachments, getAttachments} from "../Helpers/attachments";
import {useLocation} from "react-router-dom";

const DrawerComp = ({
  isDrawerOpen,
  handleDrawerClose,
  history: {push},
  onResetUser,
}) => {
  const dbName = useGetDBName();
  const [cateVisible, setCateVisible] = useState(false);
  const [subsVisible, setSubsVisible] = useState(false);
  const [distVisible, setDistVisible] = useState(false);

  useEffect(() => {
    MemberHomepageService.getAccountProfile().then(res => {
      setCateVisible(res?.data?.edit_categories === "Yes" ?? false);
      setSubsVisible(res?.data?.edit_subscription_lists === "Yes" ?? false);
      setDistVisible(res?.data?.edit_distribution_lists === "Yes" ?? false);
    });
  }, [])

  const handlers = {
    account: () => HistoryService.push('/home/user'),
    notification: () => HistoryService.push('/home/notifications'),
    logout: () => {
      removeUserToken();
      const channelInfo = getItem(Constants.AS_CHANNEL_INFO);
      if (channelInfo == null) {
        HistoryService.push('/');
      } else {
        HistoryService.push(channelInfo.routePath);
      }
      onResetUser();
    },
    startPage: () => {
      const channelInfo = getItem(Constants.AS_CHANNEL_INFO);
      if (channelInfo == null) {
        HistoryService.push('/');
      } else {
        HistoryService.push(channelInfo.routePath);
      }
    },
    home: () => {
      HistoryService.push('/home');
    },
    category_list: () => {
      HistoryService.push('/home/manage-categories');
    },
    subscription_list: () => {
      HistoryService.push({
        pathname: '/home/subscription-list',
        state: {
          master_entity_type: 'Subscription',
        },
      });
    },
    distribution_list: () => {
      HistoryService.push({
        pathname: '/home/subscription-list',
        state: {
          master_entity_type: 'Distribution',
        },
      });
    },
    about: () => {
      HistoryService.push('/about');
    },
    privacy: () => {
      HistoryService.push('/privacy');
    },
    training: () => {
      HistoryService.push('/training');
    },
    membership: () => {
      HistoryService.push('/membership');
    },
    faq: () => {
      HistoryService.push('/faq');
    },
  };

  const handleClickHandler = handlerMethod => {
    handlerMethod();
    handleDrawerClose();
  };

  const items = drawerItems(getUserToken() == null, cateVisible, subsVisible, distVisible)
    .filter(v => v.visible)
    .map(({id, label, Icon}) => (
      <ListItem
        button
        key={id}
        onClick={handleClickHandler.bind(null, handlers[id])}>
        <ListItemIcon>
          <Icon className={'w-5 h-5 ml-2'} />
        </ListItemIcon>

        <ListItemText primary={label} />
      </ListItem>
    ));

  return (
    <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
      <div style={{width: '250px'}}>{items}</div>
    </Drawer>
  );
};

const mapDispatchToProps = dispatch => ({
  onResetUser: () => dispatch(resetUser()),
});

export default connect(null, mapDispatchToProps)(memo(withRouter(DrawerComp)));
