import React, {useCallback, useEffect, useState} from 'react';

import {Base} from './styles';

import EntityHeader from './EntityHeader';
import Assesments from './Assesments';

import {
  useEntityTitle,
  useGetEntities,
  useGetEntityIdentifiers,
  useRedirectOnInvalidEntity,
  useRedirectToPerformAssessment,
} from './bl';
import {getUserMode} from '../../../utils/HelperService';
import HomeSteps from './EntityHeader/HomeSteps';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {setFormattedSections} from '../Definitions/AnalysisFlow/Topics/bl';
import {useGetDBName} from '../../../hoc/HomeScheme/bl';
// import PodcastPlayer from '../Definitions/AnalysisFlow/Topics/PodcastPlayer'

const EntityListing = ({history: {push}}) => {
  const {cat, cid, techId} = useGetEntityIdentifiers();
  const {entity, loadingEntity, errorEntity} = useGetEntities();
  const [sections, setSections] = useState([]);
  useEntityTitle(entity);
  useRedirectOnInvalidEntity(entity, loadingEntity, push);

  const handlePerformClick = useRedirectToPerformAssessment(push);

  const {mode_name: userMode} = getUserMode();
  const isAssessmentMode = userMode === 'assessment';
  // const playerHidden = userMode !== 'podcast'

  console.log('entity', entity);

  const [sectionsInfo, loadingSections, errorSections, callSections] =
    useFetchContent(
      [],
      MemberHomepageService.getCategoryKeyFactors,
      useCallback(({data: {kf_tabs}}) => kf_tabs, []),
    );

  useEffect(() => {
    callSections(cid, techId);
  }, [callSections, cid, techId]);

  useEffect(() => {
    setFormattedSections(sectionsInfo, setSections);
  }, [sectionsInfo]);

  // const [topicsInfo, loadingTopics, errorTopics, callTopic] = useFetchContent(
  //   {},
  //   MemberHomepageService.postAssessmentFlowForm,
  //   useCallback(({data}) => data, []),
  // )
  //
  // useEffect(() => {
  //   callTopic(cid, techId)
  // }, [callTopic, cid, techId])

  const dbName = useGetDBName();

  return (
    <Base loading={loadingEntity || dbName === 'initial'} error={errorEntity}>
      <HomeSteps />
      <div
        className={
          'flex flex-col lg:flex-row w-full md:space-x-12 xl:space-x-20'
        }>
        <EntityHeader
          name={entity.assessment?.assessment_name}
          image={entity.assessment?.assessment_image}
          description={entity.assessment?.assessment_description}
          activePerform={entity.perform_assessment_link}
          performMessage={entity.perform_assessment_message}
          handlePerformClick={handlePerformClick}
          isAssessmentMode={isAssessmentMode}
          sections={sections}
          dbName={dbName}
        />
        {/*{!playerHidden && (*/}
        {/*  <div className={'mt-10'}>*/}
        {/*    <PodcastPlayer />*/}
        {/*  </div>*/}
        {/*)}*/}
        {isAssessmentMode && (
          <Assesments
            arrAssesments={entity.assessors}
            catEntity={cat}
            cidEntity={cid}
            techIdEntity={techId}
          />
        )}
      </div>
    </Base>
  );
};

export default EntityListing;
