import styled from 'styled-components';

import {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({css}) => css}

  @media ${customDevice(450)} {
    flex-direction: column;
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  width: ${({width}) => width};

  ${({css}) => css} @media ${customDevice(450)} {
    width: 95%;
    text-align: center;
  }
`;

const PortraitContainer = styled.div`
  width: ${({width}) => width};
  height: auto;

  ${({css}) => css} @media ${customDevice(450)} {
    ${({direction}) =>
      direction === 'right' ? 'margin-bottom: 30px;' : 'margin-top: 30px;'}
  }
`;

const PortraitImage = styled.img`
  width: 100%;
  height: 100%;
`;

export {Base, TextContainer, PortraitContainer, PortraitImage};
