import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #919191;
  font-size: 1.5625rem;
  font-weight: bold;
  letter-spacing: 1.25px;
  margin-bottom: 15px;
  margin-top: 1rem;
`;

const EntitiesContainer = styled.div`
  //flex-wrap: wrap;
  //justify-content: space-between;
  display: grid;
  gap: 1.25rem;

  width: 100%;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  
  // grid-template-columns: repeat(5, minmax(0, 1fr));
  //
  // @media ${customDevice(1740)} {
  //   grid-template-columns: repeat(4, minmax(0, 1fr));
  // }
  //
  // @media ${customDevice(1440)} {
  //   grid-template-columns: repeat(3, minmax(0, 1fr));
  // }
  //
  // @media ${customDevice(1080)} {
  //   grid-template-columns: repeat(2, minmax(0, 1fr));
  // }
  //
  // @media ${device.tablet} {
  //   grid-template-columns: repeat(1, minmax(0, 1fr));
  // }
  //
  // @media ${device.mobileL} {
  //   justify-content: center;
  // }
  //
  // @media ${device.mobileS} {
  //   width: 100%;
  // }
`;

const SeeMoreText = styled.span`
  display: inline-block;
  margin-bottom: 25px;
  color: ${({theme}) => theme.colors.main};
  font-size: 11px;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`;

export {Base, EntitiesContainer, Title, SeeMoreText};
