import React, {useCallback, useState} from 'react';
import {Route} from 'react-router-dom';

import PageContainer from 'hoc/PageContainer';

import AuthForm from 'Components/ResetPassword/AuthForm';
import Constants from '../../Constants/Constants';

const ResetPassword = ({match: {path}, history, ...rest}) => {
  const isDana = rest?.location?.state?.isDana ?? false;
  const variant = rest?.location?.state?.variant ?? Constants.GIGA_INTELLIGENCE;
  const [authEmail, setAuthEmail] = useState('');

  const setInputAuthEmail = useCallback(
    ({target: {value}}) => setAuthEmail(value),
    [],
  );

  return (
    <PageContainer>
      <Route
        exact
        path={`${path}/`}
        render={() => (
          <AuthForm
            email={authEmail}
            setEmail={setInputAuthEmail}
            history={history}
            isDana={isDana}
            variant={variant}
          />
        )}
      />
    </PageContainer>
  );
};

export default ResetPassword;
