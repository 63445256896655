const isLetter = char => char.toLowerCase() !== char.toUpperCase();

export const checkLowerCase = str =>
  str
    .trim()
    .split('')
    .some(letter => letter === letter.toLowerCase() && isLetter(letter));

export const checkUpperCase = str =>
  str
    .trim()
    .split('')
    .some(letter => letter === letter.toUpperCase() && isLetter(letter));

export const checkSpecialChar = str => {
  // eslint-disable-next-line no-useless-escape
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  return format.test(str.trim());
};

export const checkLengthStr = length => str => str.trim().length >= length;

const arrayMethods = [
  checkLowerCase,
  checkUpperCase,
  checkSpecialChar,
  checkLengthStr(12),
];

export default arrayMethods;
