import React, {useCallback, useEffect} from 'react';
import {withRouter} from 'react-router';

import BaseContainer from 'hoc/BaseFormContainer';

import HeaderForm from 'Components/UI/HeaderForm';
import Form from './Form';

import passwordValidators from 'Helpers/passwordValidators';

import useTitle from 'hooks/useTitle';
import useFetchContent from 'hooks/useFetchContent';

import {convertQueryParamsToObject} from 'Helpers/api';

import MemberHomepageService from 'Services/memberHomepage';
import ChannelService from '../../../utils/ChannelService';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {connect} from 'react-redux';

const CreateUser = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  privacy,
  setPrivacy,
  history,
  exampleEmail,
  setExampleEmail,
  actions,
}) => {
  useTitle('Create User', true);

  const [
    submissionReponse,
    submissionLoading,
    isThereSubmissionError,
    callSubmission,
  ] = useFetchContent(
    {},
    MemberHomepageService.postVerifySignup,
    useCallback(({data}) => data, []),
    false,
  );

  useEffect(() => {
    if (submissionReponse.verification_and_registration === 'ok') {
      if (submissionReponse.website_url) {
        fetchChannelInfo(
          submissionReponse.website_url.replace(
            'https://www.gigaintelligence.com/',
            '',
          ),
        );
      }
    }
  }, [history, submissionReponse]);

  const fetchChannelInfo = async pathName => {
    await ChannelService.fetchChannelInfo(pathName, actions.updateChannel);
    history.push('/signin?onboarding=true');
  };

  useEffect(() => {
    const unm = convertQueryParamsToObject(window.location.search).unm;

    if (unm) {
      const [email] = unm.split('|');

      if (email) {
        setExampleEmail(email);
      } else {
        history.push('/signup');
      }
    } else {
      history.push('/signup');
    }
  }, [history, setExampleEmail]);

  const statusPassword = passwordValidators.every(validator =>
    validator(password),
  );
  const statusConfirmPassword =
    confirmPassword === password && password.trim().length > 0;

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      if (statusPassword && statusConfirmPassword && privacy) {
        // Submission
        callSubmission(
          password,
          convertQueryParamsToObject(window.location.search).unm,
        );
      }
    },
    [statusPassword, statusConfirmPassword, privacy, callSubmission, password],
  );

  return (
    <BaseContainer>
      <HeaderForm title="Thanks for verifying your email." />
      <Form
        onSubmit={onSubmit}
        password={password}
        changePassword={setPassword}
        confirmPassword={confirmPassword}
        changeConfirmPassword={setConfirmPassword}
        statusPassword={statusPassword}
        statusConfirmPassword={statusConfirmPassword}
        privacy={privacy}
        setPrivacy={setPrivacy}
        emailExample={exampleEmail}
        loading={submissionLoading}
        currentError={
          isThereSubmissionError ||
          (submissionReponse.verification_and_registration &&
            submissionReponse.verification_and_registration !== 'ok')
        }
        errorMessage="There was an error, please reload the page and try again."
      />
    </BaseContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: {
    updateChannel: ch => {
      dispatch(userActions.updateChannel(ch));
    },
  },
});

export default connect(null, mapDispatchToProps)(withRouter(CreateUser));
