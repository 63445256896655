import React from 'react';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

import VideoPreview from './VideoPreview';
import FilePreview from './FilePreview';
import LinkPreview from './LinkPreview';

const AttachmentsLightbox = ({
  attachments,
  photoIndex,
  setPhotoIndex,
  setIsOpen,
  isOpen,
}) => {
  let content = null;

  const formattedAttachments = attachments?.map((attachment, index) => {
    const {type, fileName, src} = attachment;

    if (type === 'video') {
      return {
        type,
        fileName,
        src: <VideoPreview src={src} photoIndex={photoIndex} index={index} />,
      };
    }

    if (type === 'file' || type === 'audio') {
      return {
        type,
        fileName,
        src: <FilePreview type={type} file={src} fileName={fileName} />,
      };
    }

    if (type === 'link') {
      return {
        type,
        fileName,
        src: <LinkPreview src={src} />,
      };
    }

    return attachment;
  });

  if (isOpen) {
    content = (
      <Lightbox
        mainSrc={formattedAttachments[photoIndex].src}
        nextSrc={
          formattedAttachments[(photoIndex + 1) % formattedAttachments.length]
            .src
        }
        prevSrc={
          formattedAttachments[
            (photoIndex + formattedAttachments.length - 1) %
              formattedAttachments.length
          ].src
        }
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() =>
          setPhotoIndex(
            (photoIndex + formattedAttachments.length - 1) %
              formattedAttachments.length,
          )
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % formattedAttachments.length)
        }
      />
    );
  }

  return content;
};

export default AttachmentsLightbox;
