import React, {useCallback, useEffect, useState} from 'react'
import useFetchContent from '../../hooks/useFetchContent';
import CommonService from '../../Services/CommonService';
import './styles.scss';
import ServerContent from '../../hoc/ServerContent';
import Button from '@mui/material/Button'
import {AppBar, Divider, FormControl, FormLabel, Slide, Toolbar, Typography} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import PaypalButtonWrapper from '../../Components/UI/Button/PaypalButtonWrapper'
import {getUserToken} from '../../utils/HelperService'
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Footer from "../../Components/Homepage/Footer";
import header from "../../Components/Create/Header";
import HomeScheme from "../../hoc/HomeScheme";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Membership() {
  const [
    membershipData,
    loadingMembershipData,
    errorMembershipData,
    callLoadMembershipData,
  ] = useFetchContent(
    {},
    CommonService.getMembershipData,
    useCallback(({data}) => data, []),
  );

  useEffect(() => {
    callLoadMembershipData();
  }, []);

  const headerSpan1 = 'col-span-3 p-1';
  const headerSpan2 = 'col-span-2 p-1';
  const headerSpan3 = 'col-span-7';

  const [open, setOpen] = React.useState(false);
  const [selMember, setSelMember] = React.useState('');
  const [paypalPlanId, setPaypalPlanId] = useState('');
  const [subWay, setSubWay] = React.useState('Month');
  const handleClickOpen = (name, id, mFee, yFee, paypalPlanId) => {
    setSelMember(name);
    setPaypalPlanId(paypalPlanId);
    const isAuth = getUserToken()?.isAuth;
    let feeNull = false
    if (subWay === 'Month') {
      if (mFee !== null && mFee !== '') {
        feeNull = true
      }
    } else if (subWay === 'Year') {
      if (yFee !== null && yFee !== '') {
        feeNull = true
      }
    }
    isAuth && feeNull ? setOpen(true) : setOpen(false)

  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSubWay(event.target.value);
  };

  const checkEmpty = (variable) => {
    if (variable === '' || variable === null) {
      return "";
    } else {
      return variable;
    }
  }

  // handle after registering user's subscription details on backend and get response ok
  const [doneSub, setDoneSub] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const handleDoneSub = (value) => {
    setDoneSub(value);
    setOpen(false);  // close dialog
    setOpenAlert(true)  // show success alert
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <ServerContent
      className={'flex flex-col items-center mx-8 pb-12 membership-max-width'}
      loading={loadingMembershipData}
      error={errorMembershipData}>
      {membershipData.title != null && (
        <>
          <HomeScheme showHeaderSection={true}>
            {header}
          <span className={'text-3xl font-semibold mt-16'}>
            {membershipData.title[0].title}
          </span>

          <span className={'text-xl font-semibold mt-4 mb-12'}>
            {membershipData.title[0].subtitle}
          </span>

          <span className={'text-xl font-semibold mt-4 mb-12'}>
            <FormControl>
              <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={subWay}
                  onChange={handleChange}
              >
                <FormControlLabel value="Month" control={<Radio />} label="Month" />
                <FormControlLabel value="Year" control={<Radio />} label="Year" />
                {/*<FormControlLabel value="Special" control={<Radio />} label="Special" />*/}
              </RadioGroup>
            </FormControl>
          </span>

          <div className={'shadow rounded-xl mx-4'}>
            <div className={'space-y-2 py-6 bg-gray-100 membership-header'}>
              <div className={'grid grid-cols-12 gap-2'}>
                <div className={'col-span-3 p-1 h-16 flex items-center'}>
                  <span className={'text-sm font-medium text-center'}>
                    Membership
                  </span>
                </div>
                <div className={'col-span-2 p-1 h-16 flex items-center'}>
                  <span className={'text-sm font-medium text-center'}>
                    Subscription Fee
                  </span>
                </div>
                <div className={'col-span-7 h-16 flex items-center'}>
                  <div className={'grid grid-cols-8 gap-2'}>
                    {membershipData.column_headers
                      .filter(h => h !== 'Name' && h !== 'Fee')
                      .map(h => (
                        <div
                          key={h}
                          className={
                            'col-span-1 flex items-center justify-center'
                          }>
                          <span className={'text-sm font-medium text-center'}>
                            {h}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            {membershipData.memberships.map((membership, index) => (
              <div key={`${index}`} className={'mt-4 mb-4 divide-y'}>
                <div className={'w-full flex justify-center mb-4'}>
                  <span className={'font-semibold text-base p-1'}>
                    {membership.category}
                  </span>
                </div>
                {membership.members.map(member => (
                  <div
                    key={member.id}
                    className={'grid grid-cols-12 gap-2 py-2'}>
                    <div className={headerSpan1}>
                      <span className={'text-base font-medium'}>
                        {member.Name}
                      </span>
                    </div>
                    <div className={headerSpan2}>
                      <span className={'text-base font-medium'}>
                        <Button variant="outlined"  size="medium" onClick={()=> handleClickOpen(member.Name, member.id, member['Fee'], member['Yearly Fee'], member['PayPal Membership Plan ID'])}>{
                          subWay === 'Month' ? checkEmpty(member['Fee']) : subWay === 'Year' ? checkEmpty(member['Yearly Fee']) : checkEmpty(member['Special Offer'])
                        }</Button>
                      </span>
                    </div>
                    <div className={headerSpan3}>
                      <div className={'grid grid-cols-8 gap-2'}>
                        {membershipData.column_headers
                          .filter(h => h !== 'Name' && h !== 'Fee')
                          .map(h => {
                            const status = member[h] ?? 'no';
                            return (
                              <div
                                key={h}
                                className={
                                  'col-span-1 flex items-center justify-center'
                                }>
                                <span
                                  className={`text-sm font-medium text-center ${
                                    status === 'yes'
                                      ? 'text-giga-bluelight'
                                      : status === 'no'
                                      ? 'text-red-300'
                                      : ''
                                  }`}>
                                  {status}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ flex: 1, textAlign: 'center' }} variant="h6" component="div">
                  Subscribe to {selMember}
                </Typography>

              </Toolbar>
            </AppBar>
            <div className={'h-full flex justify-center items-center'}>
              <PaypalButtonWrapper type="subscription" plan_id={paypalPlanId}  handleDoneSub={handleDoneSub} />
              {/*<PaypalButtonWrapper type="subscription" plan_id='P-7BS130047K6743315MTKXVIY'  handleDoneSub={handleDoneSub} />*/}
            </div>
          </Dialog>
            <Snackbar open={openAlert} autoHideDuration={null} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                Congratulations on your subscription to Giga! Please check your email for subscription and payment details. Enjoy using Giga!
              </Alert>
            </Snackbar>
          <Footer/>
        </HomeScheme>
        </>
      )}
    </ServerContent>
  );
}
