import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  height: 85%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  color: #fff;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 7px;

  @media ${device.tablet} {
    font-size: 20px;
  }

  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

const Subtitle = styled.div`
  font-size: 25px;
  font-weight: 600;

  @media ${device.tablet} {
    font-size: 20px;
  }

  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  position: relative;
  bottom: 0;

  button {
    width: 40%;

    @media ${device.laptop} {
      width: 60%;
    }
  }

  button:not(:last-child) {
    margin-right: 50px;

    @media ${device.laptop} {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    align-items: center;
    bottom: 5%;
  }
`;

const Portrait = styled.img`
  margin: 20px 0;
  flex: 1;
  height: auto;
  width: auto;

  @media ${device.tablet} {
    width: 80%;
    flex: initial;
    margin: auto 0;
  }

  @media ${device.mobileS} {
    margin: 40px 0;
  }
`;

export {Base, Title, Subtitle, ButtonsContainer, TitleContainer, Portrait};
