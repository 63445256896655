import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  align-items: center;
  margin: 15px auto;
  cursor: pointer;
  padding: 7px 15px;
  transition: all 0.3s;
  border-radius: 5px;

  &:hover {
    background-color: #eee;
  }
`;

const LabelButton = styled.div`
  font-weight: 600;
  color: #a4a4a4;
  margin-left: 10px;
  font-size: 17px;
`;

export {Base, LabelButton};
