export const putSavedValues = (objSaved, changeInputProp) => {
  Object.keys(objSaved).forEach(inputName => {
    if (inputName === 'portrait_file') {
      changeInputProp('src', inputName, objSaved.url_file);
      return;
    }

    if (inputName === 'password') {
      changeInputProp('value', 'password', objSaved.password);
      changeInputProp('value', 'confirm_password', objSaved.password);
      return;
    }

    changeInputProp('value', inputName, objSaved[inputName]);
  });
};
