export const events = [
  {
    id: 1,
    title: 'Getting Started',
    description:
      'Giga uses a system we designed to help you thoroughly share your intelligence. We have created 11 topics to help you get your thoughts into an assessment. The 11 topics are: Context, Perspective, Importance, Wisdom, Insight, Knowledge, News, Current, Future, Risk and Opportunity ',
    category: 'Getting Started',
  },

  {
    id: 2,
    title: 'Creating Categories',
    description: 'Details of Creating Categories',
    category: 'Creating Category',
  },

  {
    id: 3,
    title: 'Creating a Subject',
    description: 'Details of Creating a Subject',
    category: 'Creating a Subject',
  },

  {
    id: 4,
    title: 'Creating an Assessment',
    description: 'Details of Creating an Assessment',
    category: 'Creating an Assessment',
  },

  {
    id: 5,
    title: 'Filter Points of View',
    description: 'Details of Filtering Points of View',
    category: 'Filter Points of View',
  },

  {
    id: 6,
    title: 'Assessment Topics',
    description:
      'Giga uses a system we designed to help you thoroughly share your intelligence. We have created 11 topics to help you get your thoughts into an assessment. The 11 topics are: Context, Perspective, Importance, Wisdom, Insight, Knowledge, News, Current, Future, Risk and Opportunity ',
    category: 'Assessment Topics',
  },
];
