import * as actionTypes from '../../types';

export const initSignupSaga = (email, token, variant) => ({
  type: actionTypes.SIGNUP_INIT_SAGA,
  email,
  token,
  variant,
});

export const initSignupStart = () => ({
  type: actionTypes.SIGNUP_INIT_START,
});

export const initSignupSuccess = () => ({
  type: actionTypes.SIGNUP_INIT_SUCCESS,
});

export const initSignupFail = errorMessage => ({
  type: actionTypes.SIGNUP_INIT_FAIL,
  errorMessage,
});

export const initSignupFailReq = () => ({
  type: actionTypes.SIGNUP_INIT_FAIL_REQ,
});
