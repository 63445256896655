import {useCallback, useEffect} from 'react';
import useFetchContent from 'hooks/useFetchContent';
import useTitle from 'hooks/useTitle';
import {useParams} from 'react-router-dom';

import MemberHomepageService from 'Services/memberHomepage';

import {setNewViewedCategory, setNewViewedItem} from 'Helpers/localStorage';
import {convertQueryParamsToObject} from 'Helpers/api';

import {assessmentModes} from '../info';
import {assessmentModeAPIEndpoints} from './info';

export const getAssessmentMode = () => {
  const {mode} = convertQueryParamsToObject(window.location.search);
  return mode;
};

const formatTopic = topic => {
  const {
    topic_name,
    subtopic_name,
    subtopic_rating,
    subtopic_narrative,
    ...otherTopicInfo
  } = topic;

  return {
    topic: subtopic_name,
    rating: subtopic_rating,
    narrative: subtopic_narrative,
    ...otherTopicInfo,
  };
};

const formatAssessmentSummary = assessmentSummary =>
  assessmentSummary?.reduce((acc, topic) => {
    const formattedTopic = formatTopic(topic);

    return acc.concat(formattedTopic);
  }, []);

const formatAssessmentDetail = assessmentSummary =>
  assessmentSummary.reduce((acc, topic) => {
    const formattedTopic = formatTopic(topic);
    const {topic_name} = topic;

    const topicIndex = acc.findIndex(({topic}) => topic === topic_name);

    if (topicIndex !== -1) {
      const newTopicInfo = {
        ...acc[topicIndex],
        subtopics: [...acc[topicIndex].subtopics, formattedTopic],
      };

      return Object.assign([], acc, {[topicIndex]: newTopicInfo});
    } else {
      return acc.concat({
        topic: topic_name,
        subtopics: [formattedTopic],
      });
    }
  }, []);

const formatDetailOutput = response => {
  const assessmentSummary = [...response.assessment];
  const newAssessmentSummary = formatAssessmentDetail(assessmentSummary);

  return {...response, assessment: newAssessmentSummary};
};

const formatSummaryOutput = response => {
  const assessmentSummary = [...response.assessment];
  const newAssessmentSummary = formatAssessmentSummary(assessmentSummary);

  return {...response, assessment: newAssessmentSummary};
};

const formatKeyFactorSummaryOutput = response => {
  const keyFactors = [...response.assessment];
  const newKeyFactors = keyFactors.map(
    ({assessment_tab_name, assessment_tab_detail}) => ({
      kfName: assessment_tab_name,
      topics: formatAssessmentSummary(assessment_tab_detail),
    }),
  );

  return {...response, assessment: newKeyFactors};
};

const formatKeyFactorDetailOutput = response => {
  const keyFactors = [...response.assessment];
  const newKeyFactors = keyFactors.map(
    ({assessment_tab_name, assessment_tab_detail}) => ({
      kfName: assessment_tab_name,
      topics: formatAssessmentDetail(assessment_tab_detail),
    }),
  );

  return {...response, assessment: newKeyFactors};
};

export const formatAssessmentResult = response => {
  const mode = getAssessmentMode();

  switch (mode) {
    case 'detail':
      return formatDetailOutput(response);

    case 'kf_summary':
      return formatKeyFactorSummaryOutput(response);

    case 'kf_detail':
      return formatKeyFactorDetailOutput(response);

    default:
      // This will be changed to Summary once kf_summary and kf_detail are completed
      return formatSummaryOutput(response);
  }
};

const useGetAssessmentURL = () => {
  const {
    catEntity,
    cidEntity,
    techIdEntity,
    catAssesment,
    cidAssesment,
    techIdAssesment,
    assessorID,
  } = useParams();

  return `/home/entity/${catEntity}/${cidEntity}/${techIdEntity}/assesment/${catAssesment}/${cidAssesment}/${techIdAssesment}/${assessorID}`;
};

const useGetModeEndpoint = history => {
  const mode = getAssessmentMode();
  const isValidMode = assessmentModes.some(
    assessmentMode => assessmentMode === mode,
  );
  const assessmentURL = useGetAssessmentURL();

  if (!isValidMode) {
    history.push(`${assessmentURL}?mode=summary`);
  }

  return assessmentModeAPIEndpoints[mode];
};

export const useGetAssessment = history => {
  const assessmentEndpoint = useGetModeEndpoint(history);
console.log('ronaldo_endpoint', assessmentEndpoint);
  const [assessment, loadingAssessment, errorAssessment, callAssessment] =
    useFetchContent(
      {},
      MemberHomepageService.getAssesment,
      useCallback(({data}) => formatAssessmentResult(data), []),
    );

  const {catAssesment, cidAssesment, techIdAssesment, assessorID} = useParams();

  useEffect(
    () =>
      callAssessment(
        assessmentEndpoint,
        catAssesment,
        cidAssesment,
        techIdAssesment,
        assessorID,
      ),
    [
      assessmentEndpoint,
      catAssesment,
      cidAssesment,
      techIdAssesment,
      callAssessment,
      assessorID,
    ],
  );

  return {assessmentResult: assessment, loadingAssessment, errorAssessment};
};

export const useAssessmentTitle = assessment => {
  useTitle(
    `${assessment.assessorProfile?.assessor_name ?? '...'} Assessment on ${
      assessment.subject_info?.subject_name ?? '...'
    }`,
    true,
    [assessment],
  );
};

export const useStoreLocalStorageAssessment = assessment => {
  const {
    catEntity,
    cidEntity,
    techIdEntity,
    catAssesment,
    cidAssesment,
    techIdAssesment,
  } = useParams();

  useEffect(() => {
    const assesorName = assessment.assessorProfile?.assessor_name;
    const assesmentName = assessment.assessmentSummary?.assessmentName;
    const categoryName = assessment.category;

    if (assesorName && assesmentName) {
      const assesmentTitle = `${assesorName} Assesment on ${assesmentName}`;

      setNewViewedItem(
        'assesment',
        assesmentTitle,
        cidAssesment,
        catAssesment,
        techIdAssesment,
        {catEntity, cidEntity, techIdEntity},
      );
    }

    if (categoryName) {
      setNewViewedCategory(categoryName);
    }
  }, [
    assessment,
    cidAssesment,
    catAssesment,
    techIdAssesment,
    catEntity,
    cidEntity,
    techIdEntity,
  ]);
};

export const useRedirectToSubject = push => {
  const {catEntity, cidEntity, techIdEntity} = useParams();

  return () => {
    push(`/home/entity/${catEntity}/${cidEntity}/${techIdEntity}`);
  };
};

export const useGetCommentary = (reloading, setReloading) => {
  const [commentary, loadingCommentary, errorCommentary, callCommentary] =
    useFetchContent(
      {},
      MemberHomepageService.postCommentaryInputs,
      useCallback(({data}) => data, []),
    );

  const {cidAssesment, techIdAssesment, assessorID} = useParams();

  useEffect(() => {
    callCommentary(cidAssesment, techIdAssesment, assessorID);
    setReloading(false);
  }, [callCommentary, cidAssesment, techIdAssesment, assessorID, reloading]);

  return {commentary, loadingCommentary, errorCommentary};
};
