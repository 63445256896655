import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleSection = styled.div`
  font-size: 1.875rem;
  font-weight: 600;
  color: ${({color}) => color ?? '#2ca3ff'};
  padding: 0 20px;
`;

const CardsContainer = styled.div`
  padding: 0 10px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export {Base, TitleSection, CardsContainer};
