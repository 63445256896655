import React from 'react';

import {Base, Portrait, Text} from './styles';

import ErrorPortrait from 'assets/img/ui/error-portrait.png';

const ErrorDisplay = () => (
  <Base>
    <Portrait src={ErrorPortrait} alt="Error Image" />

    <Text>Something went wrong... Please reload the page.</Text>
  </Base>
);

export default ErrorDisplay;
