import React from 'react';

import {Base, TopicName} from './styles';

import AssessmentDetailTopics from '../AssessmentDetailTopics';

const AssessmentKfDetailTopics = ({assesmentTopics}) => {
  const assessmentDetailComponents = assesmentTopics.map(({kfName, topics}) => {
    if (!topics.some(t => t.subtopics.some(s => s.narrative))) {
      return null;
    }

    const subtopicsSection = (
      <AssessmentDetailTopics assesmentTopics={topics} />
    );

    return (
      <Base key={kfName}>
        <TopicName>{kfName}</TopicName>
        {subtopicsSection}
      </Base>
    );
  });

  return assessmentDetailComponents;
};

export default AssessmentKfDetailTopics;
