import styled from 'styled-components';

const Base = styled.div`
  display: flex;
`;

const ProgressCircle = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  opacity: ${({selected}) => (selected ? '0.9' : '0.4')};

  &:not(:last-of-type) {
    margin-right: 15px;
  }
`;

export {Base, ProgressCircle};
