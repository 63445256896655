import React, {useState} from 'react';

import {Base} from './styles';

import Button from 'Components/DesignSystem/button/button';
import Alert from 'Components/UI/Alert';
import SubjectProfile from './SubjectProfile';
import EntityItems from './EntityItems';
import RelatedItems from './RelatedItems';
import Constants from '../../../../Constants/Constants';

const EntityHeader = ({
  isAssessmentMode,
  name,
  image,
  description,
  handlePerformClick,
  activePerform,
  performMessage,
  sections,
  dbName,
}) => {
  const [activatePerformAlert, setActivatePerformAlert] = useState(false);

  const activateAlert = () => {
    setActivatePerformAlert(true);
  };

  const canPerform =
    activePerform === 'yes' || dbName === Constants.GIGA_INTELLIGENCE;

  return (
    <Base>
      <SubjectProfile name={name} image={image} description={description} />
      {/*{canPerform && <EntityItems />}*/}

      {isAssessmentMode && canPerform && (
        <Button
          onClick={() =>
            activePerform === 'yes' ? handlePerformClick() : activateAlert()
          }
          style={{marginTop: '20px'}}>
          Perform Summary Assessment
        </Button>
      )}

      {sections.length > 0 && canPerform && (
        <RelatedItems
          sections={sections}
          onClickItem={section =>
            activePerform === 'yes'
              ? handlePerformClick(section)
              : activateAlert()
          }
        />
      )}

      <Alert
        severity="info"
        status={activatePerformAlert}
        errorMessage={performMessage}
        onClose={() => setActivatePerformAlert(false)}
      />
    </Base>
  );
};

export default EntityHeader;
