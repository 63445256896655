import React, {useState} from 'react';

import {Base, TitleLabel} from './styles';

const Tab = ({Icon, title, hoverLabel, clicked, selected}) => {
  const [showLabel, setShowLabel] = useState(false);

  return (
    <Base
      onMouseEnter={() => setShowLabel(true)}
      onMouseLeave={() => setShowLabel(false)}
      onClick={clicked}
      selected={selected}>
      <TitleLabel show={showLabel}>{hoverLabel}</TitleLabel>
      {Icon && <Icon style={{fill: '#fff', margin: '0 3px'}} />}
      {title && (
        <span
          role="img"
          style={{
            fontSize: '1rem',
            lineHeight: 2,
            verticalAlign: 'middle',
            padding: '0 5px',
            color: 'white'
          }}>
          C
        </span>
      )}
    </Base>
  );
};

export default Tab;
