import React from 'react';

import {Base, CardsContainer, TitleSection} from './styles';
import {withRouter} from 'react-router-dom';

import SectionCard from './SectionCard';

const LandingSection = ({title, cardData, cardHeight, history, color}) => {
  const cards = cardData.map(({label, portraitURL}) => (
    <SectionCard
      key={label}
      cardHeight={cardHeight}
      title={label}
      src={portraitURL}
      color={color}
    />
  ));

  return (
    <Base>
      <TitleSection title={title} color={color}>
        {title}
      </TitleSection>

      <CardsContainer>{cards}</CardsContainer>
    </Base>
  );
};

export default withRouter(LandingSection);
