import React, {memo, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Base} from './styles';
import MemberHomepageService from '../../../Services/memberHomepage';
import Button from '../../DesignSystem/button/button';
import GigaAlert from '../../UI/GigaAlert';
import HistoryService from '../../../utils/HistoryService';
import {useStyles} from '../Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import ManageCategorySteps from '../ManageCategoryList/ManageCategorySteps';
import {
  getMCLSteps,
  popMCLSteps,
  setMCLSteps,
} from '../../../utils/HelperService';
import HomeSteps from '../Home/HomeSteps';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {connect} from 'react-redux';

function CreateRating(props) {
  const classes = useStyles();

  let {ratingId} = useParams();
  const isEditMode = ratingId !== '-1';

  const endpointURL =
    props.location?.state?.endpointURL ??
    'react_gi_v1_manage_category_ratings_display.php';
  const objectKey = props.location?.state?.objectKey ?? 'category';

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [definition, setDefinition] = useState('');

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [alertInfo, setAlertInfo] = React.useState({open: false});

  useEffect(() => {
    let stepLength = 4;
    if (objectKey === 'category') {
      stepLength = 3;
    }
    const steps = getMCLSteps();
    if (steps.length > stepLength) {
      setMCLSteps(steps.slice(0, stepLength));
    }

    if (isEditMode) {
      MemberHomepageService.getRatingsForManagement(endpointURL, ratingId).then(
        res => {
          const editionData = res.data[`${objectKey}_ratings`];
          if (editionData) {
            setName(editionData[0][`${objectKey}_rating_name`]);
            setDescription(editionData[0][`${objectKey}_rating_description`]);
            setDefinition(editionData[0][`${objectKey}_rating_definition`]);
          }
        },
      );
    }
  }, []);

  const handleSubmit = async () => {
    let message = '';
    if (name === '') {
      message = 'The name field is required';
    }

    if (description === '') {
      message = 'The description field is required';
    }

    if (definition === '') {
      message = 'The definition field is required';
    }

    if (message !== '') {
      setAlertInfo({
        open: true,
        message,
        severity: 'warning',
      });
      return;
    }

    const body = {
      external_id: ratingId,
      name: name,
      description,
      definition,
    };

    console.log('CreateRatingPage - body', body);

    MemberHomepageService.createRating(objectKey, body)
      .then(res => {
        console.log(res);
        setAlertInfo({
          open: true,
          message: `${isEditMode ? 'Updated' : 'Created'} successfully!`,
        });
        setTimeout(() => {
          popMCLSteps();
          HistoryService.goBack();
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          {isEditMode ? 'Edit' : 'Create'}{' '}
          {objectKey === 'category' ? 'Category' : 'Key Factor'} Rating
        </span>
      </Base>

      <Base className={'flex flex-row px-4 md:px-0'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={props.manageSteps}
          handleClickStep={() => {}}
        />
      </Base>

      <Base className={'flex flex-row px-4 md:px-0'}>
        <ManageCategorySteps />
      </Base>

      <Base loading={false} error={false} className={'px-4 md:px-0'}>
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="definition"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Definition
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="classification"
                  value={definition}
                  onChange={e => setDefinition(e.target.value)}
                  id="classification"
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md border"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Rating Options
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="classification"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  id="classification"
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md border"
                />
                <div className={'flex flex-col mt-1'}>
                  <span
                    className={'font-medium text-xs text-giga-graymedium pt-1'}>
                    Separate options with a comma. Do not leave a space before
                    the start of the next option
                  </span>
                  <span
                    className={'font-medium text-xs text-giga-graymedium pt-1'}>
                    For example: High,Moderate,Low
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'flex justify-center'}>
          <div className={'mkf-create-new mt-12 mb-12'}>
            <Button
              loading={loadingSubmit}
              onClick={() => handleSubmit()}
              bottomGap>
              Submit
            </Button>
          </div>
        </div>
        <GigaAlert
          alertInfo={alertInfo}
          handleClose={() => setAlertInfo({open: false})}
        />
      </Base>
    </>
  );
}

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  manageSteps: state.UserData.manageSteps,
  manageCurrentCategory: state.UserData.manageCurrentCategory,
  searchString: state.UserData.searchString,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateDisplayCategories: categories => {
      dispatch(userActions.updateDisplayCategories(categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
    onResetUser: () => dispatch(resetUser()),
    updateManageCurrentCategory: c =>
      dispatch(userActions.updateManageCurrentCategory(c)),
    updateManageSteps: steps => dispatch(userActions.updateManageSteps(steps)),

    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(CreateRating));
