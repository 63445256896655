import * as actionTypes from '../types';

const initialState = {
  isAuth: false,
  userId: '',
  sessionId: '',
};

const changeUser = (state, {isAuth, userId, sessionId}) => ({
  ...state,
  isAuth,
  userId,
  sessionId,
});

const resetUser = state => ({
  ...state,
  isAuth: false,
  userId: '',
  sessionId: '',
});

const reducer = (state = initialState, action) => {
  const {type} = action;

  switch (type) {
    case actionTypes.CHANGE_USER:
      return changeUser(state, action);

    case actionTypes.RESET_USER:
      return resetUser(state);

    default:
      return state;
  }
};

export default reducer;
