import React, {useState} from 'react';
import './styles.scss';

export default function RelatedItems({sections, onClickItem}) {
  const seeMoreVisible = sections.length > 6;
  const [seeMore, setSeeMore] = useState(false);

  return (
    <div className={'flex flex-col my-6'}>
      <span className={'text-base text-giga-main font-medium ml-3'}>
        Related Assessments
      </span>
      <div
        className={
          'flex flex-row w-full px-2 py-6 mt-4 rounded-t-lg rounded-b-2xl shadow-lg flex-wrap'
        }>
        {sections.slice(0, seeMore ? sections.length : 6).map((item, index) => (
          <span
            key={`${index}`}
            onClick={() => onClickItem(item)}
            className={
              'bg-giga-primary-blue_700 text-white px-4 py-1.5 rounded-full font-medium text-sm mt-2 ml-2 cursor-pointer'
            }>
            {item.label}
          </span>
        ))}
      </div>
      {seeMoreVisible && (
        <button
          className={'text-sm text-giga-main font-medium ml-auto mt-6'}
          onClick={() => setSeeMore(!seeMore)}>
          {seeMore ? 'See less' : 'See more'}
        </button>
      )}
    </div>
  );
}
