import styled from 'styled-components';

import {ReactComponent as VerificationIcon} from 'assets/svg/verification.svg';

import device from 'mediaQueries';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Phrase = styled.div`
  font-size: 22px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  width: 60%;

  @media ${device.mobileL} {
    width: 80%;
  }

  @media ${device.mobileS} {
    width: 90%;
  }
`;

const MainImg = styled(VerificationIcon)`
  margin-bottom: 25px;
`;

export {Phrase, MainImg, Container};
