import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';

import {BackdropLoading} from './button-base';

const themePalette = {
  normal: {
    bgCol: '#41C1FF',
    border: 'none',
    iconBg: 'rgba(255,255,255,.15)',
    backdropFilter: '',
  },
  white: {
    bgCol: 'transparent',
    border: '2px solid #fff',
    iconBg: 'rgba(255,255,255,.6)',
    backdropFilter: '',
  },
  blur: {
    bgCol: '#2CA3FF',
    border: 'none',
    iconBg: 'rgba(255,255,255,.25)',
    backdropFilter: 'blur(30px)',
  },
};

const useStyles = makeStyles({
  root: {
    backgroundColor: ({theme}) => themePalette[theme].bgCol,
    color: 'white',
    width: '100%',
    borderRadius: '8px',
    border: ({theme}) => themePalette[theme].border,
    minHeight: '3.9375rem',
    textAlign: 'center',
    '&[disabled]': {
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: '#36B2FF',
    },
    overflow: 'hidden',
    position: 'relative',
    backdropFilter: ({theme}) => themePalette[theme].backdropFilter,
    WebkitBackdropFilter: ({theme}) => themePalette[theme].backdropFilter,
    marginBottom: ({bottomGap}) => (bottomGap ? '10px' : '0'),

    display: ({displayStatus}) => {
      return displayStatus
        ? displayStatus === 'block'
          ? 'none'
          : 'block'
        : 'block';
    },

    '@media (max-width:1024px)': {
      display: ({displayStatus}) => displayStatus ?? 'block',
    },
  },
  icon: {
    marginLeft: ({direction}) => (direction === 'right' ? 'auto' : '0'),
    marginRight: ({direction}) => (direction === 'left' ? 'auto' : '0'),
    padding: '12px',
    borderRadius: '8px',
    backgroundColor: ({theme}) => themePalette[theme].iconBg,
    transform: ({direction}) => (direction === 'left' ? 'rotate(180deg)' : ''),
  },
  label: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    paddingLeft: ({direction}) => (direction === 'right' ? '2.5rem' : '0'),
    paddingRight: ({direction}) => (direction === 'left' ? '2.5rem' : '0'),
    '@media (max-width:320px)': {
      paddingLeft: ({direction}) => (direction === 'right' ? '0.625rem' : '0'),
      paddingRight: ({direction}) => (direction === 'left' ? '0.625rem' : '0'),
    },
    display: 'flex',
    flexDirection: ({direction}) =>
      direction === 'right' ? 'row' : 'row-reverse',
  },
  rootLoading: {
    position: 'absolute',
    left: '43%',
    color: '#28b5fa',
  },
});

const propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

const AuthButton = ({
  children,
  disabled,
  onClick,
  type,
  style = {},
  loading,
  theme = 'normal',
  direction = 'right',
  bottomGap,
  displayStatus,
}) => {
  const classes = useStyles({theme, direction, bottomGap, displayStatus});
  return (
    <Button
      style={style}
      endIcon={<ArrowForwardIcon />}
      className={classes.root}
      classes={{
        endIcon: classes.icon,
        label: classes.label,
      }}
      onClick={onClick}
      type={type}
      disabled={disabled}>
      {loading && (
        <CircularProgress
          className={classes.rootLoading}
          disableShrink
          thickness={5}
        />
      )}
      <BackdropLoading state={loading} />
      {children}
    </Button>
  );
};

AuthButton.propTypes = propTypes;
AuthButton.defaultProps = {
  disabled: false,
};

export default memo(AuthButton);
