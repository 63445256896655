import React from 'react';

import {BlockDesc, BlockTitle} from '../styles';

import LandingTextBlock from '../../UI/LandingTextBlock';
import SignUpButton from '../../UI/SignUpButton';

import PortraitImg from 'assets/img/landing/learnMoreBlocks/portrait-2.png';

const PrivacyBlock = ({isDana, variant}) => (
  <LandingTextBlock
    portraitSrc={PortraitImg}
    directionText="left"
    imageWidth="50%"
    textWidth="40%">
    <BlockTitle color="#2ca3ff">A place where privacy comes first.</BlockTitle>
    <BlockDesc>
      You are human. You don’t want to be controlled and tracked like a robot.
      Control and privacy are paramount to you. With Giga, you are building
      trusted relationships in privacy. Understanding and being understood,
      enjoying, and possibly profiting.
    </BlockDesc>
    <SignUpButton isDana={isDana} variant={variant} />
  </LandingTextBlock>
);

export default PrivacyBlock;
