import styled from 'styled-components';

const Base = styled.div`
  width: 70%;
  padding: 0.625rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: #ccc;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #a3a3a3;
  }
`;

const SrcContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.75rem;
  margin-bottom: 2px;
`;

const TypeContainer = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
`;

export {Base, SrcContainer, TypeContainer};
