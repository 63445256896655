import React from 'react';
import {Base, HeroText, TextContainer} from './styles';
import device, {customDevice} from 'mediaQueries';
import Constants from '../../../../Constants/Constants';

const mainTextCSS = `
  @media ${device.laptop} {
    font-size: 44px
  }

  @media ${customDevice(840)} {
    font-size: 39px;
  }

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 30px;
  }

  @media ${customDevice(450)} {
    font-size: 30px;
  }
`;

const Hero = ({variant}) => (
  <Base>
    <TextContainer>
      <HeroText css={mainTextCSS} size={54}>
        {variant === Constants.DANA_BARROS_BASKETBALL
          ? 'NBA Moves'
          : 'DB Podcasts'}{' '}
        by Dana Barros Basketball
      </HeroText>
    </TextContainer>
  </Base>
);

export default Hero;
