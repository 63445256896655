import {ApiClient} from './base';
import {getUserToken} from '../utils/HelperService';

export default class CommonService {
  static getTrainingData() {
    return ApiClient({
      method: 'get',
      url:
        getUserToken() == null
          ? '/gi_v1_training_and_certification.php'
          : '/react_gi_v1_training_and_certification.php',
      // url: '/gi_v1_training_and_certification.php',
    });
  }

  static getAboutData() {
    return ApiClient({
      method: 'get',
      url: '/gi_v1_about.php',
    });
  }

  static getGuestData() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v2a_summary_guest_report.php',
    });
  }

  static getPrivacyPolicyData() {
    return ApiClient({
      method: 'get',
      url: '/gi_v1_privacy_policy.php',
    });
  }

  static getCertificationPolicyData() {
    return ApiClient({
      method: 'get',
      url: '/gi_v1_training_and_certification.php',
    });
  }

  static getInquiryPolicyData() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_giga_info_request_display.php',
    });
  }

  static getMembershipData() {
    return ApiClient({
      method: 'get',
      url: '/gi_v1_membership_lp.php',
    });
  }
}
