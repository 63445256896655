import styled from 'styled-components';
import device from '../../../mediaQueries';

const Main = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  margin-bottom: 25px;

  @media ${device.tablet} {
    padding-bottom: 80%;
  }
`;

const Base = styled.div`
  border-radius: 1rem;
  //width: 250px;
  //height: 250px;
  //padding: 10px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  background-color: white;

  transition: all 0.3s;

  &:hover {
    background-color: #eee;
  }
`;

const PortraitEntity = styled.div`
  border-radius: 10px;
  overflow: hidden;
  height: 70%;
  transition: all 0.3s;
  cursor: pointer;
  background-color: rgb(241 245 249);

  &:hover {
    filter: brightness(80%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
  }
`;

export {Main, Base, PortraitEntity};
