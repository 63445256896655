import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const RoutePublic = ({
  component: Component,
  authCheck = false,
  to = '/home',
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      authCheck ? <Redirect to={to} /> : <Component {...props} />
    }
  />
);

export default RoutePublic;
