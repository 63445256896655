import React from 'react';
import {connect} from 'react-redux';

import FormContainer from 'hoc/FormContainer';

import Inputs from './Inputs';
import Indicators from './Indicators';
import TransparentBtn from 'Components/UI/TransparentBtn';
import HistoryService from '../../../../utils/HistoryService';

const Form = ({
  setEmail,
  setPw,
  statusEmail,
  statusPw,
  onSubmit,
  loading,
  requestError,
  inputError,
  errorMessage,
  isDana,
  variant,
}) => {
  let currentError = null;

  if (requestError) {
    currentError = 'There was a problem... Please, try again.';
  } else if (inputError) {
    currentError = errorMessage;
  }

  return (
    <FormContainer
      onSubmit={onSubmit}
      disabledSubmit={!statusEmail || !statusPw}
      currentError={currentError}
      loading={loading}
      isDana={isDana}>
      <Inputs
        changeEmail={setEmail}
        changePw={setPw}
        statusEmail={statusEmail}
        statusPw={statusPw}
      />

      <Indicators statusEmail={statusEmail} statusPw={statusPw} />

      <TransparentBtn
        style={{marginBottom: '15px'}}
        onClick={() => {
          HistoryService.push({
            pathname: '/reset-password',
            state: {
              isDana,
              variant,
            },
          });
        }}>
        Reset my password
      </TransparentBtn>
    </FormContainer>
  );
};

const mapStateToProps = ({
  signinAuthForm: {
    isThereRequestError,
    isThereInputError,
    errorMessage,
    loading,
  },
}) => ({
  requestError: isThereRequestError,
  inputError: isThereInputError,
  errorMessage,
  loading,
});

export default connect(mapStateToProps)(Form);
