import {convertQueryParamsToObject} from '../Helpers/api';
import {
  getUserToken,
  removeItem,
  storeEntityParams,
  storeItem,
  storeUserMode,
} from './HelperService';
import Constants from '../Constants/Constants';
import HistoryService from './HistoryService';

const handleClickEntity = (
  entityLink,
  entityAuthorID,
  actions,
  displayCategories,
) => {
  const history = HistoryService;
  const {
    cat,
    cid,
    tech_id,
    mode_id,
    mode_name,
    category_id,
    parent_id,
    parent_type,
  } = convertQueryParamsToObject(entityLink);

  storeEntityParams({
    mode_id,
    mode_name,
    category_id,
    tech_id,
    parent_id,
    parent_type,
  });
  storeUserMode({mode_name: mode_name, mode_id});
  actions.updateCreationModes(
    [{mode_name}],
    displayCategories.filter(c => c.category_id === category_id),
  );

  if (getUserToken() == null) {
    history.push(`/home/guest-report/${cat}/${cid}/${tech_id}`);
    return;
  }

  if (mode_name === 'assessment') {
    console.log('entityLink - assessment', entityLink);
    history.push(`/home/entity/${cat}/${cid}/${tech_id}`);
    return;
  }

  if (mode_name === 'message') {
    storeItem({channel_id: tech_id}, Constants.AS_MESSAGE_ROOM_TOPIC);
    // history.push('/home/message')
    removeItem(Constants.AS_CURRENT_SECTION);
    localStorage.setItem(Constants.AS_POSTED_STATUS, 'no');
    localStorage.setItem(Constants.AS_PREVIOUS_LINK, window.location.pathname);
    history.push(`/home/create/analysis?tech_id=${tech_id}&cid=${cid}`);
  } else if (
    entityLink.includes('react_gi_v1_edit_entity_summary_display.php')
  ) {
    removeItem(Constants.AS_CURRENT_SECTION);
    localStorage.setItem(Constants.AS_POSTED_STATUS, 'no');
    localStorage.setItem(Constants.AS_PREVIOUS_LINK, window.location.pathname);
    history.push(`/home/create/analysis?tech_id=${tech_id}&cid=${cid}`);
  } else {
    // MemberHomepageService.getAssesmentsOfEntity(cat, cid, tech_id)
    //   .then(result => {
    //     console.log('getAssesmentsOfEntity: ', result);
    //     if (result.data.assessors.length !== 0) {
    //       const assessor = result.data.assessors[0];
    //
    //     }
    //   });
    console.log('entityLink', entityLink);
    history.push(
      `/home/entity/${cat}/${cid}/${tech_id}/assesment/${cat}/${cid}/${tech_id}/${entityAuthorID}?mode=summary`,
    );
    // history.push(`/home/entity/${cat}/${cid}/${tech_id}`);
  }
};

export default {
  handleClickEntity,
};
