import React from 'react';

import {Base} from './styles';

import AssesmentProfile from './AssesmentProfile';
import AssesmentTopics from './AssesmentTopics';
import AssessmentDetailTopics from './AssessmentDetailTopics';
import AssessmentKfSummaryTopics from './AssessmentKfSummaryTopics';
import AssessmentKfDetailTopics from './AssessmentKfDetailTopics';
import {getUserMode} from '../../../../utils/HelperService';

const AssesmentContent = ({
  assesorName,
  assesorProfilePic,
  assesmentTopics,
  mode,
}) => {
  let assessmentOutput = null;

  const {mode_name: userMode} = getUserMode();

  const isProfileNeeded = userMode === 'assessment';

  console.log('selected_mode', mode);

  switch (mode) {
    case 'summary':
      assessmentOutput = <AssesmentTopics assesmentTopics={assesmentTopics} />;
      break;

    case 'detail':
      assessmentOutput = (
        <AssessmentDetailTopics assesmentTopics={assesmentTopics} />
      );
      break;

    case 'kf_summary':
      assessmentOutput = (
        <AssessmentKfSummaryTopics assesmentTopics={assesmentTopics} />
      );
      break;

    case 'kf_detail':
      assessmentOutput = (
        <AssessmentKfDetailTopics assesmentTopics={assesmentTopics} />
      );
      break;

    default:
      assessmentOutput = <AssesmentTopics assesmentTopics={assesmentTopics} />;
  }

  return (
    <Base>
      {isProfileNeeded && (
        <AssesmentProfile
          userName={assesorName}
          profilePic={assesorProfilePic}
        />
      )}
      {assessmentOutput}
    </Base>
  );
};

export default AssesmentContent;
