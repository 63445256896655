import React from 'react';

import Input from 'Components/DesignSystem/inputs/base-input';
import ImageInput from 'Components/Create/Inputs/ImageInput';

const InputList = ({inputsArr, changeInputProp}) =>
  inputsArr.map(
    ({type, label, value, name, validation, status, src, isRequired}) => {
      if (type !== 'password' && type !== 'file') {
        const newStatus = validation(value) ? 'success' : 'error';

        if (status !== newStatus) {
          changeInputProp('status', name, newStatus);
        }

        return (
          <Input
            key={name}
            label={label}
            type={type}
            statusError={newStatus}
            onChange={({target: {value}}) =>
              changeInputProp('value', name, value)
            }
            value={value}
            dark
            labelRequired={isRequired}
            optional={!isRequired}
          />
        );
      }

      if (type === 'password' && name !== 'confirm_password') {
        const newStatus = validation(value) ? 'success' : 'error';

        if (status !== newStatus) {
          changeInputProp('status', name, newStatus);
        }

        return (
          <Input
            key={name}
            label={label}
            type={isPassword => (isPassword ? 'password' : 'text')}
            statusError={newStatus}
            onChange={({target: {value}}) =>
              changeInputProp('value', name, value)
            }
            password
            value={value}
            dark
            labelRequired={isRequired}
          />
        );
      }

      if (type === 'password' && name === 'confirm_password') {
        const newStatus = validation(
          inputsArr.find(({name}) => name === 'password').value,
          value,
        )
          ? 'success'
          : 'error';

        if (status !== newStatus) {
          changeInputProp('status', name, newStatus);
        }

        return (
          <Input
            key={name}
            label={label}
            type={isPassword => (isPassword ? 'password' : 'text')}
            statusError={newStatus}
            onChange={({target: {value}}) =>
              changeInputProp('value', name, value)
            }
            password
            dark
            labelRequired={isRequired}
            value={value}
          />
        );
      }

      if (type === 'file') {
        const newStatus = value ? 'success' : 'error';

        if (status !== newStatus) {
          changeInputProp('status', name, newStatus);
        }

        return (
          <ImageInput
            key={name}
            forId="profile-pic"
            previewImage
            setImageFile={file => changeInputProp('value', name, file)}
            setImageUrl={url => changeInputProp('src', name, url)}
            imageUrl={src}
            bottomGap
            styleLabel={{fontSize: '18px', fontWeight: 'bold'}}
            labelRequired={isRequired}>
            Upload Photo
          </ImageInput>
        );
      }

      return <p key={label}>Not supported Input</p>;
    },
  );

export default InputList;
