import React, {memo, useEffect, useState} from 'react';

import Create from 'Components/Create';
import TopicForm from './TopicForm';
import RatingsBlock from './RatingsBlock';

import {Base, FormContainer, TitleForm} from './styles';
import {
  areThereSavedAttachments,
  formatSavedAttachments,
  getAttachments,
} from 'Components/MemberHomepage/Helpers/attachments';
import {displayModesNames} from '../../../../../../../Constants/Constants';
import {MdKeyboardArrowDown, MdKeyboardArrowRight} from 'react-icons/md';
import ExtraRatingsBlock from './ExtraRatingsBlock';

const TopicDefinition = ({
                           topics,
                           setNewHTMLContent,
                           setNewAssetsBlockData,
                           setNewSelectedRating,
                           mode,
                           showClassification = true,
                           chatMessageCount,
                           extraRatings,
                           setExtraRatings,
                           changePrompt,
                           assetsValue
                         }) => {
  const [forms, setForms] = useState([]);
  const [selectedFormIndex, setSelectedFormIndex] = useState(topics.length - 1);

    console.log('assetsValue', assetsValue)

  useEffect(async () => {
    let newForms = [];
    for (let index = 0; index < topics.length; index++) {
      const topic = topics[index];
      let {
        assessment_narrative_topic_html_element_name,
        assessment_topic,
        assessment_rating_topic_options,
        assessment_rating_topic_selection,
        assessment_narrative,
      } = topic;

      if (!showClassification) {
        assessment_narrative = '';
      }

      /**
       * message_posting_contributor_full_name: "Randy Forman"
       message_posting_contributor_picture: "https://api.gigaintelligence.com/user_profile_pictures/mark_tompkins.jpg"
       message_posting_count: 0
       message_posting_files: {file_attachment_0: {…}}
       message_posting_log_id: "24983"
       message_posting_narrative: "<p>Ok</p>\n"
       message_posting_timestamp: "2022-01-07 20:49:13"
       message_posting_urls: {url_attachment_0: {…}}
       message_thread_id: "10"
       */

      const specificTitle =
          mode !== 'assessment' ? displayModesNames(mode) : null;

      newForms.push(
          <FormContainer key={assessment_narrative_topic_html_element_name}>
            {!specificTitle && (
                <TitleForm>
                  <div
                      className={'flex flex-row items-center cursor-pointer'}
                      onClick={() => setSelectedFormIndex(index)}>
                    <span>{assessment_topic}</span>
                    {index === selectedFormIndex ? (
                        <MdKeyboardArrowDown className={'ml-auto w-6 h-6'} />
                    ) : (
                        <MdKeyboardArrowRight className={'ml-auto w-6 h-6'} />
                    )}
                  </div>
                </TitleForm>
            )}
            {specificTitle && <div className={'mt-4'} />}
                <div hidden={selectedFormIndex !== index}>
                  <RatingsBlock
                      ratingOptions={assessment_rating_topic_options.split(',')}
                      selectedRating={assessment_rating_topic_selection}
                      setNewSelectedRating={setNewSelectedRating.bind(
                          null,
                          assessment_topic,
                      )}
                  />
                </div>
                  <div hidden={selectedFormIndex !== index}>
                      <Create
                          currentAttachments={assetsValue[index].assetsBlockData}
                          setNewHTMLContent={setNewHTMLContent.bind(
                              null,
                              assessment_topic,
                          )}
                          htmlContent={assessment_narrative}
                          setAssetsBlockData={setNewAssetsBlockData.bind(
                              null,
                              assessment_topic,
                          )}
                          changePrompt = {changePrompt}
                      />
                  </div>

          </FormContainer>
      );
    }
    setForms(newForms);
  }, [chatMessageCount, selectedFormIndex, topics]);

  return <Base>{forms}</Base>;
};

export default memo(TopicDefinition);
