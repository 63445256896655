import {createFileFromURL, getFileType} from 'Helpers/files';

export const getAttachments = currentTopic =>
  Object.keys(currentTopic).reduce((acc, infoPropName) => {
    if (infoPropName.includes('url_attachment_')) {
      return acc.concat({
        ...currentTopic[infoPropName],
        type: 'link',
      });
    }

    if (infoPropName.includes('file_attachment_')) {
      return acc.concat({
        ...currentTopic[infoPropName],
        type: getFileType(currentTopic[infoPropName].file_attachment_mime_type),
      });
    }

    return acc;
  }, []);

export const areThereSavedAttachments = (attachmentsArr, cb) =>
  attachmentsArr.length > 0;

export const formatSavedAttachments = async (attachmentsArr, cb) => {
  const result = Promise.all(
    attachmentsArr.map(async (attachment, index) => {
      const attachmentId = index + 1;
      const {type} = attachment;

      if (type === 'link') {
        const {url_attachment_url} = attachment;

        return {
          id: attachmentId,
          type: 'link',
          src: url_attachment_url,
          fileName: url_attachment_url,
        };
      } else {
        const {file_attachment_url, file_attachment_value} = attachment;

        const file = await createFileFromURL(
          file_attachment_url,
          file_attachment_value,
        );

        return {
          id: attachmentId,
          type,
          src: file_attachment_url,
          fileName: file_attachment_value,
          fileToUpload: file,
        };
      }
    }),
  );

  if (cb == null) {
    return result;
  } else {
    result.then(arr => cb(arr));
  }
};
