import React from 'react';

import {Base, TopicName} from './styles';

import AssessmentTopics from '../AssesmentTopics';

const AssessmentKfSummaryTopics = ({assesmentTopics}) => {
  const assessmentDetailComponents = assesmentTopics.map(({kfName, topics}) => {
    if (!topics.some(s => s.narrative)) {
      return null;
    }

    const subtopicsSection = <AssessmentTopics assesmentTopics={topics} />;

    return (
      <Base key={kfName}>
        <TopicName>{kfName}</TopicName>
        {subtopicsSection}
      </Base>
    );
  });

  return assessmentDetailComponents;
};

export default AssessmentKfSummaryTopics;
