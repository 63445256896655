import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {useEffect} from 'react'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 120,
    },
  },
};

function getStyles(option, ratingName, theme) {
  return {
    fontWeight:
      ratingName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function RatingDropDown({ options,
                                         selectedRating,
                                         setNewSelectedRating,
                                         onlyView = false,
                                       }) {
  const theme = useTheme();

  const [ratingName, setRatingName] = React.useState(selectedRating);

    useEffect(() => {
        setNewSelectedRating(selectedRating)
    }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRatingName(
      value
    );
    setNewSelectedRating(value);
  };

  return (
    <div className={'mr-4'}>
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <FormControl sx={{ m: 1, width: 250 }} >
        {/*<InputLabel id="demo-multiple-chip-label">Topic Rating / Classification</InputLabel>*/}
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          value={ratingName}
          onChange={handleChange}
          // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <Chip key={selected} label={selected} sx={{backgroundColor: '#2CA3FF', color: 'white'}}/>
            </Box>
          )}
          MenuProps={MenuProps}
          inputProps={{ readOnly: onlyView }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              style={getStyles(option, ratingName, theme)}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
