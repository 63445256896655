import React from 'react';
import Hero from '../MainPage/Hero';
import {HeroText} from '../MainPage/Hero/styles';
import Constants from '../../../Constants/Constants';
import '../GigaCommon/styles.scss';
import groupGradient2 from '../../../assets/img/landing/groupGradient2.png';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {connect} from 'react-redux';

const GigaRaisingVoice = ({
  channel,
  history,
  variant = Constants.GIGA_INTELLIGENCE,
}) => {
  return (
    <>
      <Hero
        type={1}
        history={history}
        isDana={false}
        variant={variant}
        renderTitle={() => (
          <HeroText size={3.875} style={{textAlign: 'left'}}>
            <span>{channel.private_channel_title ?? ''}</span>
          </HeroText>
        )}
        description={channel.private_channel_subtitle ?? ''}
        descriptionSize={2.75}
        bgImage={channel.bgImage}
        bodyLogo={channel.private_channel_body_logo_image_url}
      />
    </>
  );
};

const mapStateToProps = ({UserData: {channel}}) => ({
  channel,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateChannel: ch => {
      dispatch(userActions.updateChannel(ch));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GigaRaisingVoice);
