import styled from 'styled-components';

const Base = styled.div`
  display: ${({hidden}) => (hidden ? 'none' : 'block')};

  label {
    margin-right: 1.25rem;
  }
`;

export {Base};
