import React, {useEffect, useState} from 'react';
import './styles.scss';
import {InputBase, ListItem, MenuItem, Select} from '@material-ui/core';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import SelectIcon from './SelectIcon';
import NestedList from '../../../../../NestedList';
import ShareService from '../../../../../../../Services/ShareService';
import GigaSwitch from '../../../../../../UI/GigaSwitch';
import Button from '../../../../../../DesignSystem/button/button';
import {
  getEntityParams, getHiddenItems,
  getItem,
  removeItem,
  storeItem,
} from '../../../../../../../utils/HelperService'
import Constants from '../../../../../../../Constants/Constants';
import MemberHomepageService from '../../../../../../../Services/memberHomepage';
import GigaAlert from '../../../../../../UI/GigaAlert';
import HistoryService from '../../../../../../../utils/HistoryService';
import CreateNewMessage from '../../../../../../../Routes/Containers/GigaMessage/CreateNewMessage';
import GGModal from '../../../../../../Modal';
import SelectedCalendars from './SelectedCalendars';

const useStyles = makeStyles({
  root: {
    fontSize: '0.9rem',
    fontWeight: 'semibold',
    color: 'white',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  inputRoot: {
    height: '2.5rem',
  },
  menuList: {
    paddingLeft: '0.5rem',
    fontSize: '0.8rem',
  },
  listItem: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    minHeight: '3rem',
    marginTop: '1.5rem',
    color: 'white',
  },
});

export default function ShareBlock({cid}) {
  const classNames = {
    container: 'flex flex-row justify-between items-center',
    label: 'text-white font-bold mx-2',
  };

  const classes = useStyles();

  const [options, setOptions] = useState({
    isPrivate: true,
    draft: false,
    postErase: 0,
    shareTime: 'Now',
  });
  const [subscribers, setSubscribers] = useState([]);
  const [distributionList, setDistributionList] = useState([]);
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);
  const [selectedDistributionList, setSelectedDistributionList] = useState([]);

  const [shareDisplayInfo, setShareDisplayInfo] = useState();

  const [alertInfo, setAlertInfo] = React.useState({open: false});
  const [selectedMemberCounts, setSelMemberCounts] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalCalendarVisible, setModalCalendarVisible] = useState(false);

  const {mode_id, mode_name, category_id, tech_id, parent_id, parent_type} =
    getEntityParams();

  useEffect(() => {
    const newContentId = getItem(Constants.AS_NEW_CREATION_CONTENT_ID);
    console.log('newContentId', newContentId);
    ShareService.getShareDisplay(newContentId?.channel_id).then(res => {
      console.log('getShareDisplay', res);
      if (res.data != null) {
        const sharing = res.data.sharing;
        if (sharing) {
          let displayInfo = {
            isPrivate: true,
            draft: false,
            postErase: 0,
            shareTime: 'Now',
          };
          sharing.forEach(s => {
            if (s.sharing_type_name === 'Private') {
              displayInfo = {
                ...displayInfo,
                isPrivate: s.sharing_value === 'true',
                // isPrivate: true,
              };
            }
            if (s.sharing_type_name === 'Draft') {
              displayInfo = {
                ...displayInfo,
                draft: s.sharing_value === 'true',
              };
            }
            if (s.sharing_type_name === 'Post and Erase') {
              displayInfo = {
                ...displayInfo,
                postErase:
                  s.sharing_value === "Don't erase" || s.sharing_value == null
                    ? 0
                    : Number(s.sharing_value.replace(' hours', '')),
              };
            }
          });
          setOptions(displayInfo);
        }
        setShareDisplayInfo(res.data);
      }
    });

    ShareService.getSubscriptionList().then(res => {
      console.log('getSubscriptionList', res);
      if (res.data != null) {
        setSubscribers(
          res.data.subscription_list.map(v => ({
            ...v,
            id: v.list_id,
            name: v.list_name,
          })),
        );
        setSelectedSubscribers(
          res.data.subscription_list
            .filter(v => v.sharing_value === 'true')
            .map(v => ({
              ...v,
              id: v.list_id,
              name: v.list_name,
            })),
        );
      }
    });

    ShareService.getDistributionList().then(res => {
      console.log('getDistributionList', res);
      if (res.data != null) {
        setDistributionList(
          res.data.distribution_list.map(v => ({
            ...v,
            id: v.list_id,
            name: v.list_name,
          })),
        );
        setSelectedDistributionList(
          res.data.distribution_list
            .filter(v => v.sharing_value === 'true')
            .map(v => ({
              ...v,
              id: v.list_id,
              name: v.list_name,
            })),
        );
      }
    });

    // InitDisplayService.getSubscribers().then(res => {
    //   if (res.data != null) {
    //
    //   }
    // });
    // InitDisplayService.getDistributionList().then(res => {
    //   if (res.data != null) {
    //
    //   }
    // });
  }, []);

  console.log('hidden variables', shareDisplayInfo?.hidden_variables);
  console.log('category_id', category_id);
  const handleShare = () => {
    const newContentId = getItem(Constants.AS_NEW_CREATION_CONTENT_ID);
    console.log('hh',newContentId);

    // shareDisplayInfo.hidden_variables.external_id = newContentId.channel_id;  //  channel_id value same as external_id is '', its value same as tech_id
    let requestBody = {
      ...shareDisplayInfo.hidden_variables,
      'composite_id' : cid
    };

    shareDisplayInfo.sharing.forEach(s => {
      let sharing_value;
      if (s.sharing_type_name === 'Private') {
        sharing_value = options.isPrivate;
      } else if (s.sharing_type_name === 'Public') {
        sharing_value = !options.isPrivate;
      } else if (s.sharing_type_name === 'Draft') {
        sharing_value = options.draft;
      } else if (s.sharing_type_name === 'Post and Erase') {
        sharing_value =
          options.postErase === 0
            ? `Don't erase`
            : `${options.postErase} hours`;
      } else if (s.sharing_type_name === 'Distribution') {
        sharing_value = selectedDistributionList.length > 0;
      } else if (s.sharing_type_name === 'Subscription') {
        sharing_value = selectedSubscribers.length > 0;
      }
      requestBody = {
        ...requestBody,
        // [`$_POST['${s.sharing_html_name}']`]: sharing_value,
        [`${s.sharing_html_name}`]: sharing_value,
        // [`$_POST['${s.sharing_composite_id_html_name}']`]:
        //   s.sharing_composite_id_value,
        [`${s.sharing_composite_id_html_name}`]: s.sharing_composite_id_value,
      };
    });

    distributionList.forEach(d => {
      requestBody = {
        ...requestBody,
        // [`$_POST['${d.sharing_html_name}']`]: selectedDistributionList.some(
        //   v => v.id === d.id,
        // ),
        // [`$_POST['${d.sharing_composite_id_html_name}']`]:
        //   d.sharing_composite_id_value,
        [`${d.sharing_html_name}`]: selectedDistributionList.some(
          v => v.id === d.id,
        ),
        [`${d.sharing_composite_id_html_name}`]: d.sharing_composite_id_value,
      };
    });

    subscribers.forEach(d => {
      requestBody = {
        ...requestBody,
        // [`$_POST['${d.sharing_html_name}']`]: selectedSubscribers.some(
        //   v => v.id === d.id,
        // ),
        // [`$_POST['${d.sharing_composite_id_html_name}']`]:
        //   d.sharing_composite_id_value,
        [`${d.sharing_html_name}`]: selectedSubscribers.some(
          v => v.id === d.id,
        ),
        [`${d.sharing_composite_id_html_name}`]: d.sharing_composite_id_value,
      };
    });

    console.log('requestBody', requestBody);

    MemberHomepageService.postShare(requestBody)
      .then(res => {
        if (res.data.status === 'ok') {
          setAlertInfo({open: true, message: 'Shared successfully!'});
          setTimeout(() => {
            // HistoryService.push('/home');
            // HistoryService.push(
            //   localStorage.getItem(Constants.AS_PREVIOUS_LINK) ?? '/home',
            // );

            storeItem(
              {channel_id: shareDisplayInfo.hidden_variables.external_id},
              Constants.AS_MESSAGE_ROOM_TOPIC,
            );
            // history.push('/home/message')
            removeItem(Constants.AS_CURRENT_SECTION);
            localStorage.setItem(Constants.AS_POSTED_STATUS, 'no');
            localStorage.setItem(
              Constants.AS_PREVIOUS_LINK,
              window.location.pathname,
            );
            // HistoryService.replace(
            //   `/home/create/analysis?tech_id=${shareDisplayInfo.hidden_variables.external_id}&cid=CMPmessages.${category_id}`,
            // );
            HistoryService.goBack();
          }, 1500);
        } else {
          setAlertInfo({
            open: true,
            message: 'Please try again later',
            severity: 'warning',
          });
        }
      })
      .catch(error => {
        console.log(error);
        setAlertInfo({
          open: true,
          message: 'Please try again later',
          severity: 'warning',
        });
      });
  };

  const onAddMembers = async selectedMembers => {
    console.log('heyhey')
    setModalVisible(false);
    setSelMemberCounts(selectedMembers?.length ?? 0);

    setAlertInfo({
      open: true,
      message: 'Added members successfully!',
    });

    // const newContentId = getItem(Constants.AS_NEW_CREATION_CONTENT_ID);
    const msg_thread_id = getHiddenItems('external_id');   // from hidden value local storage

    await MemberHomepageService.postRecipients(
      shareDisplayInfo.hidden_variables,
      // newContentId.channel_id,
      msg_thread_id,
      selectedMembers,
    );
  };

  const onAddCalendars = async selectedCalendars => {
    setModalCalendarVisible(false);
    console.log(selectedCalendars);
    onAddMembers(selectedCalendars);
  };

  const hasItemOption = name => {
    return shareDisplayInfo.sharing.some(s => s.sharing_type_name === name);
  };

  const hasCalendarOption = () => {
    return mode_name === 'event';
  };

  if (shareDisplayInfo == null) {
    return null;
  }

  return (
    <div>
      <div
        className={'flex flex-col bg-giga-main rounded-lg px-8 py-10'}
        style={{order: 1000}}>
        <div
          className={
            'flex flex-1 flex-col sm:flex-row justify-between items-center'
          }>
          <span className={'text-lg text-white'}>Share Settings</span>
          <span className={'text-sm text-white'}>
            You are sharing a {!options.isPrivate ? 'Public' : 'Private'}{' '}
            {mode_name}.
          </span>
        </div>
        {hasItemOption('Private') && (
          <div className={clsx(classNames.container, 'mt-10 mb-3')}>
            <div>
              <span
                className={`text-white font-bold ${
                  options.isPrivate ? 'opacity-50' : ''
                }`}>
                Public
              </span>
              <span className={'text-white font-bold mx-1'}>/</span>
              <span
                className={`text-white font-bold ml-2 ${
                  options.isPrivate ? '' : 'opacity-50'
                }`}>
                Private
              </span>
            </div>
            <GigaSwitch
              checked={options.isPrivate}
              onChange={e =>
                setOptions({...options, isPrivate: e.target.checked})
              }
            />
          </div>
        )}

        {hasItemOption('Subscription') && (
          <NestedList
            disabled={!options.isPrivate}
            masterEntityType={'Subscription'}
            title={'Share with my subscribers'}
            items={subscribers}
            selectedItems={selectedSubscribers}
            handleChangeItem={item => {
              if (selectedSubscribers.some(v => v.id === item.id)) {
                setSelectedSubscribers(
                  selectedSubscribers.filter(v => v.id !== item.id),
                );
              } else {
                setSelectedSubscribers([...selectedSubscribers, item]);
              }
            }}
          />
        )}

        {hasItemOption('Distribution') && (
          <NestedList
            disabled={!options.isPrivate}
            masterEntityType={'Distribution'}
            title={'Share distribution'}
            items={distributionList}
            selectedItems={selectedDistributionList}
            handleChangeItem={item => {
              if (selectedDistributionList.some(v => v.id === item.id)) {
                setSelectedDistributionList(
                  selectedDistributionList.filter(v => v.id !== item.id),
                );
              } else {
                setSelectedDistributionList([
                  ...selectedDistributionList,
                  item,
                ]);
              }
            }}
          />
        )}

        {hasItemOption('Share my post') && (
          <div className={clsx(classNames.container, 'mt-6')}>
            <span className={classNames.label}>Share my post</span>
            <Select
              id="post-select"
              classes={{root: classes.root}}
              input={<InputBase classes={{root: classes.inputRoot}} />}
              IconComponent={props => <SelectIcon />}
              value={options.shareTime}
              onChange={e =>
                setOptions({...options, shareTime: e.target.value})
              }>
              {['Now', 'Later'].map((item, index) => (
                <MenuItem
                  key={index}
                  classes={{gutters: classes.menuList}}
                  value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        <ListItem
            disabled={!options.isPrivate}
          button
          className={clsx(classNames.label, 'mt-6 cursor-pointer')}
          classes={{root: classes.listItem}}
          onClick={() => {
            if (hasCalendarOption()) {
              setModalCalendarVisible(true);
            } else {
              setModalVisible(true);
            }
          }}>
          Share with{' '}
          {hasCalendarOption()
            ? 'Calendar'
            : `Selected Members (${selectedMemberCounts})`}
        </ListItem>
        {hasItemOption('Post and Erase') && (
          <div
            className={clsx(
              classNames.container,
              `mt-6 ${
                options.isPrivate ? '' : 'opacity-50 pointer-events-none'
              }`,
            )}>
            <span className={classNames.label}>Post and Erase</span>
            <Select
              labelId="post-erase-select-label"
              id="post-erase-select"
              label="post-erase"
              classes={{root: classes.root}}
              input={<InputBase classes={{root: classes.inputRoot}} />}
              IconComponent={props => <SelectIcon />}
              value={options.postErase}
              onChange={e =>
                setOptions({...options, postErase: e.target.value})
              }>
              {[0, 24, 48, 72].map((item, index) => (
                <MenuItem
                  key={index}
                  classes={{gutters: classes.menuList}}
                  value={item}>
                  {item === 0 ? `Don't erase` : `${item} hours`}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {hasItemOption('Draft') && (
          <div className={clsx(classNames.container, 'mt-6')}>
            <span
              className={`${classNames.label} ${
                !options.draft ? 'opacity-50' : ''
              }`}>
              Save as a draft
            </span>
            <GigaSwitch
              checked={options.draft}
              onChange={e => setOptions({...options, draft: e.target.checked})}
            />
          </div>
        )}

        <div className={'mt-10'}>
          <Button onClick={() => handleShare()} bottomGap>
            Share
          </Button>
        </div>
        <GigaAlert
          alertInfo={alertInfo}
          handleClose={() => setAlertInfo({open: false})}
        />
        <GGModal
          modalVisible={modalVisible}
          handleCloseModal={() => setModalVisible(false)}
          renderContent={() => (
            <CreateNewMessage
              handleCloseModal={() => setModalVisible(false)}
              handleContinue={selectedMembers => onAddMembers(selectedMembers)}
            />
          )}
        />
        <GGModal
          modalVisible={modalCalendarVisible}
          handleCloseModal={() => setModalCalendarVisible(false)}
          renderContent={() => (
            <SelectedCalendars
              handleCloseModal={() => setModalCalendarVisible(false)}
              handleContinue={selectedCalendars =>
                onAddCalendars(selectedCalendars)
              }
            />
          )}
        />
      </div>
    </div>
  );
}
