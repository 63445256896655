import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  //align-items: center;
  //justify-content: ${({isCenter}) => (isCenter ? 'center' : 'space-between')};
  //height: 7rem;
  //min-height: 50px;
  width: 100vw;
  background: linear-gradient(#fff 0%, #f4f4f4 100%);
  filter: drop-shadow(-15px -15px 15px rgba(0, 0, 0, 0.05));
  padding: 32px;
  margin-top: auto;

  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  @media ${device.tablet} {
    // flex-direction: ${({mainFooter}) => (mainFooter ? 'row' : 'column')};
    //justify-content: center;
    //height: 10vh;
  }

  @media ${device.mobile} {
    //flex-direction: ${({mainFooter}) => (mainFooter ? 'row' : 'column')};
    //justify-content: center;
    //height: auto;
    //margin-bottom: ${({mainFooter}) => (mainFooter ? '56px' : '0')};
    padding: 5px;
  }
`;

export {Base};
