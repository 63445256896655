export const trendingNowCards = [
  {
    label: 'Coronavirus COVID-19 - Delta Strain',
    portraitURL:
      'https://media.npr.org/assets/img/2021/07/14/gettyimages-1328449688_wide-77131067b69b2b2b9cdd8fb4310e5423d527bceb-s800-c85.webp',
  },
  {
    label: 'Climate Change - Global',
    portraitURL:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Change_in_Average_Temperature.svg/300px-Change_in_Average_Temperature.svg.png',
  },
  {
    label: 'NBA Moves - Dana Barros Behind the back dribble',
    portraitURL:
      'https://celticswire.usatoday.com/wp-content/uploads/sites/39/2020/05/barros-kerr-celtics-bulls-1024x683-1.jpg',
  },
  {
    label: 'Equities (Stocks)',
    portraitURL: 'https://api.gigaintelligence.com/pictures/stock_equities.jpg',
  },
];

export const trendingNowMediaCards = [
  {
    label: 'Covid-19',
    portraitURL:
      'https://im-media.voltron.voanews.com/Drupal/01live-166/styles/892x501/s3/2021-06/Greek%20Letters%20for%20COVID-19%20variants.png',
  },
  {
    label: 'Climate Change',
    portraitURL:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Change_in_Average_Temperature.svg/300px-Change_in_Average_Temperature.svg.png',
  },
  {
    label: 'Basketball',
    portraitURL:
      'https://celticswire.usatoday.com/wp-content/uploads/sites/39/2020/05/barros-kerr-celtics-bulls-1024x683-1.jpg',
  },
  {
    label: 'US Economy',
    portraitURL:
      'https://www.pharmaceutical-technology.com/wp-content/uploads/sites/10/2020/09/Economic-Influencers-14-September.jpg',
  },
];

export const recentCards = [
  {
    label: 'James Taylor Tribute Band Cape Cod - "SteamRoller Blues"',
    portraitURL:
      'https://api.gigaintelligence.com/images/JamesTaylorTributeBandCapeCodePerf.jpg',
  },
  {
    label: 'Student Care Management Software',
    portraitURL:
      'https://api.gigaintelligence.com/images/EmeraldComplianceLogo.png',
  },
  {
    label: 'Grant Williams',
    portraitURL:
      'https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/1040x760/1629684.png',
  },
  {
    label: 'Methadone Clinics',
    portraitURL:
      'https://psmag.com/.image/t_share/MTYzNDU5MTg5MDA2NDExMTA2/clinic.jpg',
  },
];

export const recentMediaCards = [
  {
    label: 'Railroads',
    portraitURL:
      'https://api.gigaintelligence.com/images/JamesTaylorTributeBandCapeCodePerf.jpg',
  },
  {
    label: 'Flower fields',
    portraitURL:
      'https://api.gigaintelligence.com/images/EmeraldComplianceLogo.png',
  },
  {
    label: 'Hawaii',
    portraitURL:
      'https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/1040x760/1629684.png',
  },
  {
    label: 'Outdoors',
    portraitURL:
      'https://psmag.com/.image/t_share/MTYzNDU5MTg5MDA2NDExMTA2/clinic.jpg',
  },
];

export const gigaMediaGuideCards = [
  {
    label: 'Create assessments',
    portraitURL:
      'https://api.gigaintelligence.com/images/JamesTaylorTributeBandCapeCodePerf.jpg',
  },
  {
    label: 'Becoming a Pro User',
    portraitURL:
      'https://api.gigaintelligence.com/images/EmeraldComplianceLogo.png',
  },
  {
    label: 'Understanding Subjects',
    portraitURL:
      'https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/1040x760/1629684.png',
  },
  {
    label: 'Create a Category',
    portraitURL:
      'https://psmag.com/.image/t_share/MTYzNDU5MTg5MDA2NDExMTA2/clinic.jpg',
  },
];

export const trendingNowRaisingCards = [
  {
    label: 'Stargazing',
    portraitURL:
      'https://im-media.voltron.voanews.com/Drupal/01live-166/styles/892x501/s3/2021-06/Greek%20Letters%20for%20COVID-19%20variants.png',
  },
  {
    label: 'Nascar',
    portraitURL:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Change_in_Average_Temperature.svg/300px-Change_in_Average_Temperature.svg.png',
  },
  {
    label: 'College Basketball',
    portraitURL:
      'https://celticswire.usatoday.com/wp-content/uploads/sites/39/2020/05/barros-kerr-celtics-bulls-1024x683-1.jpg',
  },
  {
    label: 'Hawaii',
    portraitURL:
      'https://www.pharmaceutical-technology.com/wp-content/uploads/sites/10/2020/09/Economic-Influencers-14-September.jpg',
  },
];
