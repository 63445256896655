import React, {memo} from 'react';

import RTE from 'Components/Create/RTE';

const NarrativeForm = ({setNarrativeValue}) => (
  <div>
    <RTE
      placeholder="Enter your Narrative here"
      setHTMLValue={value => value && setNarrativeValue(value)}
    />
  </div>
);

export default memo(NarrativeForm);
