import React from 'react';

import {Base} from './styles';

import CommentaryView from './CommentaryView';
import ShowMoreCommentsButton from './ShowMoreCommentsButton';

import useSplitContent from 'hooks/useSplitContent';

const CommentaryListing = ({prevComments, currentTopicName, ratingOptions}) => {
  console.log(
    'comments',
    prevComments.filter(
      ({comment_topic}) => comment_topic === currentTopicName,
    ),
  );

  const allComments = prevComments
    .filter(({comment_topic}) => comment_topic === currentTopicName)
    .map(prevComment => (
      <CommentaryView
        key={prevComments.id}
        picURL={prevComment.commentator_pic}
        userName={prevComment.commentator_full_name}
        narrative={prevComment.comment_narrative}
        timestamp={prevComment.comment_timestamp}
        ratingOptions={ratingOptions}
        selectedRating={prevComment.comment_rating_topic_selection}
      />
    ));

  const [showedComments, maxComments, showNewComments] = useSplitContent(
    allComments,
    3,
  );

  return (
    <Base>
      {showedComments}
      {allComments.length > 3 && !maxComments && (
        <ShowMoreCommentsButton handleClick={showNewComments} />
      )}
    </Base>
  );
};

export default CommentaryListing;
