import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(21% + 30px);
  min-width: 230px;
  cursor: pointer;
  padding-right: 30px;
  margin: 0 auto;
  margin-top: 30px;
`;

const PortraitContainer = styled.div`
  height: ${({height}) => `${height / 16}rem`};
  width: 100%;
  margin-bottom: 30px;
`;

const Portrait = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;

  &:hover {
    opacity: 0.7;
  }
`;

const CardTitle = styled.div`
  color: ${({color}) => color ?? '#2ca3ff'};
  font-weight: 600;

  &:hover {
    opacity: 0.7;
  }
`;

export {Base, PortraitContainer, Portrait, CardTitle};
