import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import {Provider} from 'react-redux';

import store from 'Store/store';
import App from './App';

import './index.css';
import {BrowserRouter} from 'react-router-dom'
import {PayPalScriptProvider} from '@paypal/react-paypal-js'
import Constants from "./Constants/Constants";

WebFont.load({
  google: {
    families: ['Poppins:400,500,600,700,800,900'],
  },
});

const app = (
  <PayPalScriptProvider
    options={{
      // clientId: "ATgyGT8OQ7QmuGsfH8n7Ur2a8-omE8gclaWKPfEiC1DiU3s4dw9Wf4Dwzm9PkHKB480Imxdg0r_8lLIq",   // mine
      clientId: Constants.PAYPAL_CLIENT_ID,    // richard
      components: "buttons",
      intent: "subscription",
      vault: true,
    }}
  >
  <Provider store={store}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </Provider>
  </PayPalScriptProvider>
);

ReactDOM.render(app, document.getElementById('root'));
