import React from 'react';

import {Base, PortraitContainer} from './styles';
import {DescriptionContainer} from '../../../../UI/SubjectProfile/styles';
import ShowHTMLString from '../../../../../hoc/ShowHTMLString';

const SubjectProfile = ({name, image, description}) => (
  <Base>
    <span className={'text-giga-graymedium text-2xl font-semibold mt-4 mb-6'}>
      {name}
    </span>

    <PortraitContainer imgSize={'18.75rem'}>
      <img src={image} alt={`${name} Portrait`} />
    </PortraitContainer>

    <DescriptionContainer>
      <ShowHTMLString htmlString={description} />
    </DescriptionContainer>
  </Base>
);

export default SubjectProfile;
