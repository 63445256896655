import {
  checkLengthStr,
  checkLowerCase,
  checkSpecialChar,
  checkUpperCase,
} from 'Helpers/passwordValidators';

export const indicators = [
  {
    label: 'One lowercase letter',
    validator: checkLowerCase,
  },
  {
    label: 'One uppercase letter',
    validator: checkUpperCase,
  },
  {
    label: 'One special character',
    validator: checkSpecialChar,
  },
  {
    label: '12 character minimum',
    validator: checkLengthStr(12),
  },
];
