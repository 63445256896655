import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 40vw;
  height: 100vh;
  overflow: auto;
  padding-bottom: 80px;

  @media ${device.desktopL} {
    width: 25vw;
  }

  @media ${device.laptop} {
    width: 50vw;
  }

  @media ${device.tablet} {
    width: 65vw;
  }

  @media ${device.mobileL} {
    width: 90vw;
    padding-bottom: 180px;
  }

  @media ${device.mobileS} {
    width: 95vw;
  }
`;

export default Base;
