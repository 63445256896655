import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    width: 48%;

    @media ${device.laptop} {
      width: 100%;
      margin-bottom: 30px;
      max-width: 600px;
      align-self: center;
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export {Base};
