import styled from 'styled-components';

import ServerContent from 'hoc/ServerContent';

import device from 'mediaQueries';

const Base = styled(ServerContent)`
  // width: 70%;
  //
  // @media ${device.tablet} {
  //   width: 75%;
  // }
  //
  // @media ${device.mobile} {
  //   margin-top: 3rem;
  // }
  //
  // @media ${device.mobileM} {
  //   width: 85%;
  // }
  //
  // @media ${device.mobileS} {
  //   width: 95%;
  // }
  width: 100%;
  max-width: 1400px;

  @media (min-width: 640px) {
    width: 96%;
  }
`;

export {Base};
