import styled from 'styled-components';

const BackdropLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #34afeb;
  transition: all 0.3s;
  opacity: ${({state}) => (state ? '.4' : '0')};
  z-index: 10;
`;

export {BackdropLoading};
