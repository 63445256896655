import React, {useEffect, useState} from 'react';
import './styles.css';
import Target from '../../../../assets/img/landing/target.png';
import LandingButton from '../../UI/LandingButton';
import HistoryService from '../../../../utils/HistoryService';
import device from '../../../../mediaQueries';

const buttonCSS = `
  &:not(:last-of-type) {
    margin-right: 1.875rem;

    @media ${device.tablet} {
      margin-right: 1.25rem;
    }
  }
`;

export default function GGDemoModal(props) {
  const {handleCloseModal, handleClickSignUp} = props;

  return (
    <div className={'flex flex-col relative demo-modal-container rounded-lg'}>
      <img
        alt={'target'}
        src={Target}
        className={'w-80 h-80 self-center -mt-20'}
      />
      <span className={'font-medium text-sm text-white mx-12'}>
        You are viewing a read-only version of Giga. Sign up now in order to use
        features like Posting, messaging, analysis, and content creation.
      </span>
      <div
        className={'flex flex-row items-center mt-8 my-16 mx-8 justify-center'}>
        <LandingButton
          bgCol="rgba(0,0,0, 0.8)"
          onClick={() => {
            handleCloseModal();
            HistoryService.push('/home');
          }}
          css={buttonCSS}>
          View Giga Demo
        </LandingButton>
        <LandingButton
          bgCol="rgba(0,0,0, 0.4)"
          onClick={() => handleClickSignUp()}
          css={buttonCSS}>
          Sign Up
        </LandingButton>
      </div>
    </div>
  );
}
