import * as actionTypes from '../types';

export const changeUser = (isAuth, userId, sessionId) => ({
  type: actionTypes.CHANGE_USER,
  isAuth,
  userId,
  sessionId,
});

export const resetUser = () => ({
  type: actionTypes.RESET_USER,
});
