import React, {memo, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Base} from './styles';
import MemberHomepageService from '../../../Services/memberHomepage';
import Button from '../../DesignSystem/button/button';
import useFetchContent from '../../../hooks/useFetchContent';
import ManageMasterEntities from '../../../Services/ManageMasterEntities';
import GigaAlert from '../../UI/GigaAlert';
import HistoryService from '../../../utils/HistoryService';
import {DropzoneArea} from 'material-ui-dropzone';
import {useStyles} from '../Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import {convertQueryParamsToObject} from '../../../Helpers/api';
import {InputBase, makeStyles, MenuItem, Select} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {InputAdornment, ListSubheader, TextField} from '@mui/material';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ManageCategorySteps from '../ManageCategoryList/ManageCategorySteps';
import {
  addMCLSteps,
  getMCLSteps,
  popMCLSteps,
  setMCLSteps,
} from '../../../utils/HelperService';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {connect} from 'react-redux';
import HomeSteps from '../Home/HomeSteps';

function CreateClassification(props) {
  const classes = useStyles();

  let {classificationId} = useParams();
  const isEditMode = classificationId !== '-1';

  const originalClassification = props.location?.state?.classification;

  const [ratings, setRatings] = useState([]);

  const [name, setName] = useState('');
  const [classification, setClassification] = useState('');
  const [rating, setRating] = useState();
  const [searchText, setSearchText] = useState('');

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [alertInfo, setAlertInfo] = React.useState({open: false});

  useEffect(() => {
    const steps = getMCLSteps();
    if (steps.length > 4) {
      setMCLSteps(steps.slice(0, 4));
    }

    // if (isEditMode) {
    //   MemberHomepageService.getClassificationsForManagement(classificationId).then(
    //     res => {
    //       const editionData = res.data.key_factors[0];
    //       if (editionData) {
    //         setName(editionData.key_factor_name);
    //         setClassification(editionData.key_factor_classification);
    //         setRating(editionData.key_factor_rating_id);
    //       }
    //     },
    //   );
    // }

    // MemberHomepageService.getClassificationsRatings(-1).then(res => {
    //   console.log('CreateClassificationPage - ratings', res);
    //   setRatings(res.data.key_factor_ratings);
    // });
  }, []);

  const handleSubmit = async () => {
    let message = '';
    if (name === '') {
      message = 'The name field is required';
    }

    if (classification === '') {
      message = 'The classification field is required';
    }

    if (message !== '') {
      setAlertInfo({
        open: true,
        message,
        severity: 'warning',
      });
      return;
    }

    const body = {
      external_id: classificationId,
      name: name,
      classification: classification,
      mode_id: 61,
      assessment_rating_id: rating,
    };

    console.log('CreateClassificationPage - body', body);

    MemberHomepageService.createClassification(body)
      .then(res => {
        console.log(res);
        setAlertInfo({
          open: true,
          message: `${isEditMode ? 'Updated' : 'Created'} successfully!`,
        });
        setTimeout(() => {
          popMCLSteps();
          HistoryService.goBack();
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          {isEditMode ? 'Edit' : 'Create'} Classification
        </span>
      </Base>

      <Base className={'flex flex-row'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={props.manageSteps}
          handleClickStep={() => {}}
        />
      </Base>

      <Base className={'flex flex-row'}>
        <ManageCategorySteps />
      </Base>

      <Base loading={false} error={false}>
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="classification"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Classification
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="classification"
                  value={classification}
                  onChange={e => setClassification(e.target.value)}
                  id="classification"
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md border"
                />
              </div>
            </div>

            {
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="select-rating"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Ratings
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className={'border rounded-lg p-1 mx-0.5 bg-white'}>
                    <Select
                      displayEmpty
                      classes={{root: classes.root}}
                      input={<InputBase classes={{root: classes.inputRoot}} />}
                      labelId="select-rating"
                      id="select-rating"
                      value={rating ?? ''}
                      IconComponent={ExpandMoreIcon}
                      onClose={() => setSearchText('')}
                      onChange={event => setRating(event.target.value)}>
                      <ListSubheader style={{width: '100%'}}>
                        <TextField
                          size="small"
                          autoFocus
                          placeholder="Type to search..."
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={e => setSearchText(e.target.value)}
                          onKeyDown={e => {
                            if (e.key !== 'Escape') {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {ratings
                        .filter(v =>
                          (v.key_factor_rating_name ?? '')
                            .toLowerCase()
                            .includes(searchText.toLowerCase()),
                        )
                        .map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.key_factor_rating_id}
                            classes={{gutters: classes.menuList}}>
                            {item.key_factor_rating_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className={'flex flex-col justify-center mt-12 mb-12'}>
          <div className={'mc-create-new'}>
            <Button
              loading={loadingSubmit}
              onClick={() => handleSubmit()}
              bottomGap>
              Submit
            </Button>
          </div>
        </div>
        <GigaAlert
          alertInfo={alertInfo}
          handleClose={() => setAlertInfo({open: false})}
        />
      </Base>
    </>
  );
}

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  manageSteps: state.UserData.manageSteps,
  manageCurrentCategory: state.UserData.manageCurrentCategory,
  searchString: state.UserData.searchString,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateDisplayCategories: categories => {
      dispatch(userActions.updateDisplayCategories(categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
    onResetUser: () => dispatch(resetUser()),
    updateManageCurrentCategory: c =>
      dispatch(userActions.updateManageCurrentCategory(c)),
    updateManageSteps: steps => dispatch(userActions.updateManageSteps(steps)),

    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(CreateClassification));
