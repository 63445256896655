const mainBlue = '#2CA3FF';
const theme = {
  colors: {
    main: mainBlue,
    primary: {
      blue_300: '#41C1FF',
      blue_500: mainBlue,
      blue_700: '#2E358B',
      grey_300: '#989898',
      grey_500: '#646464',
      grey_700: '#242134',
    },
    secondary: {
      orange_300: '#F47738',
      orange_500: '#D18661',
      grey_500: '#589FD6',
      grey_700: '#7A8FA6',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
};

export default theme;
