function getCategoryName(category) {
  if (category.length < 12) {
    return category;
  }

  return category.slice(0, 9) + '...';
}

export default {
  getCategoryName,
};
