import React, {useEffect, useRef} from 'react';

import {BaseInput, InputLabel, PreviewImage, Wrapper} from './styles';

import {getFileType, uploadFileReader} from 'Helpers/files/index';
import {MdOutlineAccountCircle} from 'react-icons/md'

const ImageInput = ({
  forId,
  children,
  bottomGap,
  width,
  previewImage,
  imageUrl,
  styleLabel = {},
  labelRequired,
  setImageUrl = () => null,
  setImageFile = () => null,
  setIsThereImage = () => null,
  ...inputProps
}) => {
  const fileInput = useRef(null);

  useEffect(() => {
    fileInput.current.addEventListener('change', () => {
      const imageFile = fileInput.current.files[0];
      const isImage =
        imageFile?.type && getFileType(imageFile.type) === 'image';

      if (isImage) {
        setImageFile(imageFile);

        uploadFileReader(imageFile, result => {
          setImageUrl(result);
        });
      }
    });
  }, [fileInput, setImageUrl, setImageFile]);

  return (
    <Wrapper>
      <div className={'flex flex-col items-center mb-4'}>
        <InputLabel
          labelRequired={labelRequired}
          style={styleLabel}
          htmlFor={forId}>
          {previewImage && imageUrl && imageUrl !== 'https://api.gigaintelligence.com/user_profile_pictures/' ? (
            <PreviewImage src={imageUrl} alt="Portrait Pic" />
          ) : <MdOutlineAccountCircle  style={{color: '#E8F0FE', fontSize: '250px'}}/>}
          {children}
        </InputLabel>
        <BaseInput
          width={width}
          bottomGap={bottomGap}
          type="file"
          id={forId}
          ref={fileInput}
          {...inputProps}
        />
      </div>
    </Wrapper>
  );
};

export default ImageInput;
