import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckboxContainer = styled(FormControlLabel)`
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
`;

export {CheckboxContainer};
