export const setErrorMessage = ({state, message}) => {
  switch (state) {
    case 'error':
      return message;
    case 'success':
      return 'Excellent!';
    default:
      return 'Insert your email';
  }
};
