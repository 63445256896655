// Common
export const UPDATE_ERROR = 'UPDATE_ERROR';

// Fetch User Data
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_CREATION_MODES = 'UPDATE_CREATION_MODES';

export const UPDATE_CATEGORY_DATA = 'UPDATE_CATEGORY_DATA';
// export const UPDATE_MODE_DATA = 'UPDATE_MODE_DATA';
export const UPDATE_MESSAGE_MODE_SELECTED = 'UPDATE_MESSAGE_MODE_SELECTED';

export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';

export const UPDATE_HOME_STEPS = 'UPDATE_HOME_STEPS';
export const UPDATE_HOME_CURRENT_CATEGORY = 'UPDATE_HOME_CURRENT_CATEGORY';
export const UPDATE_HOME_SEARCH_STRING = 'UPDATE_HOME_SEARCH_STRING';
export const UPDATE_HOME_SEARCH_FLAG = 'UPDATE_HOME_SEARCH_FLAG';

export const UPDATE_MANAGE_STEPS = 'UPDATE_MANAGE_STEPS';
export const UPDATE_MANAGE_CURRENT_CATEGORY = 'UPDATE_MANAGE_CURRENT_CATEGORY';
