import styled from 'styled-components';

const TitleTopicName = styled.div`
  color: #868686;
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 15px;
`;

export {TitleTopicName};
