import React, {useContext, useRef, useState} from 'react'
import Button from '@mui/material/Button'
import SendIcon from '@material-ui/icons/Send'
import {MdContentCopy} from 'react-icons/md'
import {TextField} from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@material-ui/lab/Alert'
import {topicContext} from 'Contexts/TopicContext';
import {white} from 'tailwindcss/colors'

const ComposerButtonGroup = ({openAiContent, getNewPrompt}) => {

  const contextValue = useContext(topicContext);
  const prompt = contextValue.prompt;

  const [open, setOpen] = React.useState(false);
  const promptRef = useRef('');
  const copyToClip = () => {
    openAiContent? navigator.clipboard.writeText(openAiContent.toString().replace( /(<([^>]+)>)/ig, '')) : console.log();
    setOpen(true);
  }

  const updatePrompt = () => {
    let val = promptRef.current.value;
    if (val) {
      getNewPrompt(val);
    } else {
      console.log('empty')
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className={'grid grid-cols-1 gap-4 mt-4'}>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Copied to Clipboard!
          </Alert>
        </Snackbar>
        <TextField label="" variant="outlined" inputRef={promptRef} defaultValue={prompt} InputProps={{
          inputProps: {
            style: { textAlign: "center", color:'white' },
          }
        }}
                   multiline={true}
                   className={'bg-red-800'}
                   autoFocus={true}
        />
      </div>

      <div className={'flex justify-around mt-4'}>
        <Button  style={{backgroundColor: "#2CA3FF"}} variant="contained" endIcon={<SendIcon />} onClick={updatePrompt}>
          Update
        </Button>
        <Button style={{backgroundColor: "#2CA3FF"}} variant="contained" endIcon={<MdContentCopy />} onClick={copyToClip}>
          Copy
        </Button>
      </div>
    </>
  );

};

export default ComposerButtonGroup;
