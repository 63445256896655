import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  //&:not(:last-of-type) {
  //  border-bottom: 2px solid #f4f4f4;
  //}
`;

const TitleForm = styled.div`
  font-weight: 500;
  margin: 15px 0;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 4px;
`;

export {Base, FormContainer, TitleForm};
