export const filterRepeatedLinks = attachments =>
  attachments.filter(({type, src}, index) => {
    if (type === 'link') {
      const result = attachments.findIndex(
        attachment => attachment.type === 'link' && attachment.src === src,
      );

      return result === index;
    }

    return true;
  });

export const searchIndex  = (searchTarget, name, company, version) =>  {
  return searchTarget.filter(product => {
    // Perform comparison based on the provided parameters
    const nameMatch = product.ai_engine_name.toLowerCase().includes(name.toLowerCase());
    const companyMatch = product.ai_engine_company.toLowerCase().includes(company.toLowerCase());
    const versionMatch = product.ai_engine_version.toLowerCase().includes(version.toLowerCase());

    // Return true if all conditions are met
    return nameMatch && companyMatch && versionMatch;
  });
}
