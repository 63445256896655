import React from 'react';

import GigaDefinition from './GigaDefinition';
import SectionWrapper from '../UI/SectionWrapper';
import SmallTalkBlock from './SmallTalkBlock';
import PrivacyBlock from './PrivacyBlock';
import ClearOpinionBlock from './ClearOpinionBlock';
import MindsBlock from './MindsBlock';

import useTitle from 'hooks/useTitle';
import Constants from '../../../Constants/Constants';
import Header from '../Header';
import Footer from '../Footer';
import {Wrapper} from '../../../Routes/Homepage/styles';

const LearnMorePage = props => {
  useTitle('Learn More', true);
  const isDana = props?.location?.state?.isDana ?? false;
  const variant =
    props?.location?.state?.variant ?? Constants.GIGA_INTELLIGENCE;

  return (
    <Wrapper>
      <Header isDana={isDana} variant={variant} />
      <SectionWrapper>
        <SmallTalkBlock isDana={isDana} variant={variant} />
        <GigaDefinition isDana={isDana} variant={variant} />
        <PrivacyBlock isDana={isDana} variant={variant} />
        <ClearOpinionBlock isDana={isDana} variant={variant} />
        <MindsBlock isDana={isDana} variant={variant} />
      </SectionWrapper>
      <Footer title={null} variant={variant} />
    </Wrapper>
  );
};

export default LearnMorePage;
