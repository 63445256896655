import React, {useState} from 'react';
import './styles.css';
import {makeStyles} from '@material-ui/core/styles';
import {Button, InputBase, MenuItem, Select} from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 700,
    paddingLeft: '4rem',
    paddingRight: '4rem',
    height: '2.5rem',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    backgroundColor: '#41C1FF',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: '#41C1FF',
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.grey['500'],
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.palette.grey['400'],
    },
    display: 'flex',
    marginBottom: '0.5rem',
    ['@media (min-width:640px)']: {
      marginBottom: 0,
    },
  },
  select: {
    width: '100%',
    paddingRight: 0,
    fontSize: '0.7rem',
    fontWeight: 'bold',
  },
  inputRoot: {
    width: '100%',
    height: '2.5rem',
  },
  menuList: {
    paddingLeft: '0.5rem',
    fontSize: '0.625rem',
  },
}));

function ChooseMC(props) {
  const {handleCloseModal, handleGo} = props;
  const classes = useStyles();

  const [category, setCategory] = useState('');
  const [mode, setMode] = useState('');

  return (
    <div className={'flex flex-col bg-white choose-mc-modal-container'}>
      <div className={'flex flex-col flex-1 mt-8 space-y-4 items-center px-4'}>
        <p className={'text-lg font-semibold text-center'}>Create Content</p>
        {/*<p className={"text-sm text-center"}>Choose mode and category</p>*/}
        <div
          className={
            'text-sm w-full rounded border border-po-graymedium mt-1 bg-white px-2'
          }>
          <Select
            displayEmpty
            classes={{root: classes.select}}
            input={<InputBase classes={{root: classes.inputRoot}} />}
            labelId="select-mode"
            id="select-mode"
            value={mode}
            IconComponent={() => <ExpandMoreIcon color={'primary'} />}
            onChange={event => {
              const selMode = event.target.value;
              setMode(selMode);
              if (selMode.mode_name === 'posts') {
                setCategory(props.displayCategories[21]);
              } else if (selMode.mode_name === 'message') {
                setCategory(props.displayCategories[28]);
              } else if (selMode.mode_name === 'videos') {
                setCategory(props.displayCategories[22]);
              }
            }}>
            <MenuItem disabled value={''} classes={{gutters: classes.menuList}}>
              <em>Select mode</em>
            </MenuItem>
            {props.displayModes
              .filter(d => d.mode_id !== -1)
              .map((item, index) => (
                <MenuItem
                  // disabled={
                  //   item.mode_name !== 'posts' && item.mode_name !== 'videos'
                  // }
                  key={index}
                  value={item}
                  classes={{gutters: classes.menuList}}>
                  {item.mode_label}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div
          className={
            'text-sm w-full rounded border border-po-graymedium mt-1 bg-white px-2'
          }>
          <Select
            displayEmpty
            classes={{root: classes.select}}
            input={<InputBase classes={{root: classes.inputRoot}} />}
            labelId="select"
            id="select"
            value={category}
            IconComponent={() => <ExpandMoreIcon color={'primary'} />}
            onChange={event => setCategory(event.target.value)}>
            <MenuItem disabled value={''} classes={{gutters: classes.menuList}}>
              <em>Select category</em>
            </MenuItem>
            {props.displayCategories.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                classes={{gutters: classes.menuList}}>
                {item.category}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div
        className={
          'mx-4 my-4 sm:my-8 flex flex-col sm:flex-row justify-between'
        }>
        <Button
          variant="contained"
          color="secondary"
          className={clsx(classes.root, classes.cancelButton)}
          onClick={() => handleCloseModal()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.root}
          onClick={() => handleGo(mode, category)}>
          Continue
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
});

export default connect(mapStateToProps)(ChooseMC);
