import React, {useCallback} from 'react';

import InputContainer from './InputContainer';
import ButtonsContainer from './ButtonsContainer';

import {Base, FormContainer} from './styles';

const OnlyInputPhase = ({
  advanceIndexPhase,
  namePhase,
  titleForm,
  inputLabel,
  setInputValue,
  submitText,
  placeholder,
  validation,
  inputValue,
  buttons,
  errorStatus,
  inputComponent,
  headerHeight,
}) => {
  const handleSubmit = useCallback(() => {
    if (validation(inputValue)) {
      advanceIndexPhase();
    }
  }, [advanceIndexPhase, inputValue, validation]);

  return (
    <Base>
      <FormContainer>
        <InputContainer
          namePhase={namePhase}
          titleForm={titleForm}
          inputLabel={inputLabel}
          setInputValue={setInputValue}
          placeholder={placeholder}
          inputValue={inputValue}
          errorStatus={errorStatus}
          inputComponent={inputComponent}
          headerHeight={headerHeight}
        />
      </FormContainer>

      <ButtonsContainer
        buttons={buttons}
        advanceIndexPhase={advanceIndexPhase}
        handleSubmit={handleSubmit}
        submitText={submitText}
      />
    </Base>
  );
};

export default OnlyInputPhase;
