import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Base} from './styles';
import MemberHomepageService from '../../../Services/memberHomepage';
import Button from '../../DesignSystem/button/button';
import useFetchContent from '../../../hooks/useFetchContent';
import ManageMasterEntities from '../../../Services/ManageMasterEntities';
import GigaAlert from '../../UI/GigaAlert';
import HistoryService from '../../../utils/HistoryService';

export default function CreateSubscription(props) {
  const masterEntityType =
    props.location?.state?.masterEntityType ?? 'Subscription';
  const entity = props.location?.state?.entity;
  let {subscriptionId} = useParams();
  const isEditMode = subscriptionId !== '-1';

  const [name, setName] = useState(entity?.entity_name ?? '');
  const [description, setDescription] = useState(
    entity?.entity_description ?? '',
  );
  const [users, setUsers] = useState([]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [alertInfo, setAlertInfo] = React.useState({open: false});

  const [
    subscription,
    loadingSubscription,
    errorSubscription,
    callLoadSubscription,
  ] = useFetchContent(
    [],
    MemberHomepageService.fetchManagements,
    useCallback(({data}) => data, []),
  );

  useEffect(() => {
    callLoadSubscription(masterEntityType, subscriptionId);
  }, []);

  useEffect(() => {
    console.log(subscription);
    if (subscription?.members != null) {
      setUsers(subscription.members);
    }
  }, [subscription]);

  const handleSubmit = async () => {
    let message = '';
    if (name === '') {
      message = 'The name field is required';
    }

    if (description === '') {
      message = 'The description field is required';
    }

    if (message !== '') {
      setAlertInfo({
        open: true,
        message,
        severity: 'warning',
      });
      return;
    }

    setLoadingSubmit(true);
    const result = await ManageMasterEntities.postEntity(
      masterEntityType,
      subscriptionId,
      name,
      description,
      users.filter(u => u.selected === 'Yes').map(u => u.member_id),
    );
    setLoadingSubmit(false);

    setAlertInfo({open: true, message: 'Submitted successfully!'});
    HistoryService.goBack();
  };

  return (
    <Base loading={loadingSubscription} error={errorSubscription}>
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5 mx-4 sm:mx-0">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {isEditMode ? 'Edit' : 'Create'} {masterEntityType} List
          </h3>
        </div>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
                className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Description
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                id="description"
                className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md border"
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="members"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Members
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 overflow-y-scroll max-h-96">
              {users.map((user, index) => {
                return (
                  <div
                    key={`${index}`}
                    onClick={() => {
                      const selected = user.selected === 'Yes' ? 'No' : 'Yes';
                      setUsers(
                        users.map(u =>
                          user.member_id === u.member_id
                            ? {...user, selected}
                            : u,
                        ),
                      );
                    }}
                    className={
                      'flex flex-row items-center space-x-3 my-4 cursor-pointer'
                    }>
                    <span
                      className={`w-4 h-4 rounded-full ${
                        user.selected === 'Yes' ? 'bg-gray-500' : 'bg-gray-200'
                      }`}
                    />
                    <img
                      src={user.member_pic}
                      alt={'avatar'}
                      className={'w-8 h-8 rounded-full object-cover'}
                    />
                    <span className={'font-medium text-sm text-gray-500'}>
                      {user.member_name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          'flex flex-col lg:flex-row justify-between lg:justify-center mt-12 mb-12 lg:space-x-12'
        }>
        <div className={'mkf-create-new'}>
          <Button
            loading={false}
            onClick={() => HistoryService.goBack()}
            bottomGap>
            Cancel
          </Button>
        </div>
        <div className={'mkf-create-new'}>
          <Button
            loading={loadingSubmit}
            onClick={() => handleSubmit()}
            bottomGap>
            Submit
          </Button>
        </div>
      </div>
      <GigaAlert
        alertInfo={alertInfo}
        handleClose={() => setAlertInfo({open: false})}
      />
    </Base>
  );
}
