import React from 'react';
import {defaultStyles, FileIcon} from 'react-file-icon';

import MovieIcon from '@material-ui/icons/Movie';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import LinkIcon from '@material-ui/icons/Link';

import {Base} from './styles';

import {getFileExtension} from 'Helpers/files/index';

const Portrait = ({id, type, src, fileName}) => {
  let thumbnail = <img src={src} alt={`${id} - ${type}`} />;

  if (type === 'video') {
    thumbnail = <MovieIcon style={{fill: '#eee'}} />;
  }

  if (type === 'audio') {
    thumbnail = <AudiotrackIcon style={{fill: '#eee'}} />;
  }

  if (type === 'file') {
    const fileExtension = getFileExtension(fileName);

    thumbnail = (
      <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} />
    );
  }

  if (type === 'link') {
    thumbnail = <LinkIcon style={{fill: '#eee'}} />;
  }

  return <Base>{thumbnail}</Base>;
};

export default Portrait;
