import React, {useEffect} from 'react';
import './styles.scss';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import DateTimeUtil from '../../../../../../../utils/DateTimeUtil';
import {BiRightArrowAlt} from 'react-icons/bi';
import Target from '../../../../../../../assets/img/landing/target.png';

const useStyles = makeStyles({
  root: {
    fontSize: '0.9rem',
    fontWeight: 'semibold',
    color: 'white',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  inputRoot: {
    height: '2.5rem',
  },
  menuList: {
    paddingLeft: '0.5rem',
    fontSize: '0.8rem',
  },
  listItem: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    minHeight: '3rem',
    marginTop: '1.5rem',
    color: 'white',
  },
});

export default function EventList({selectedCV, eventsList, handleClickInfo}) {
  const classes = useStyles();

  const events = eventsList.filter(e =>
    DateTimeUtil.isSameDate(selectedCV, e.event_start_datetime),
  );

  return (
    <div
      className={'flex flex-col bg-giga-main rounded px-8 py-10 flex-1'}
      style={{order: 1000}}>
      <div
        className={
          'flex flex-1 flex-col sm:flex-row justify-between items-center'
        }>
        <span className={'text-lg text-white'}>
          {moment(selectedCV).format('MMM DD, YYYY')}
        </span>
      </div>
      {events.map((event, index) => {
        return (
          <div key={`${index}`} className={'flex flex-col'}>
            <div className={'flex flex-row my-6'}>
              <div className={'flex flex-row items-center'}>
                <span
                  className={
                    'w-2 h-2 rounded-full mr-4 bg-giga-primary-blue_300'
                  }
                />
                <div className={'flex flex-col'}>
                  <span className={'font-semibold text-white'}>
                    {event.event_name}
                  </span>
                  <span className={'font-extralight text-sm text-white mt-2'}>
                    {event.event_description}
                  </span>
                </div>
              </div>
              <div className={'ml-auto'}>
                <div className={'flex flex-row'}>
                  <span
                    className={
                      'text-sm text-white p-2 rounded bg-giga-primary-blue_300'
                    }>
                    {moment(event.event_start_datetime).format('HH:mm')}
                  </span>
                  <span
                    onClick={() => handleClickInfo(event)}
                    className={
                      'text-white px-4 py-2 rounded bg-giga-primary-blue_300 ml-2 cursor-pointer'
                    }>
                    <BiRightArrowAlt className={'w-4 h-4'} />
                  </span>
                </div>
              </div>
            </div>
            <div className={'flex flex-col'}>
              <img
                alt={'photo'}
                src={event.event_image}
                className={'w-20 h-20 ml-10 mt-2 object-cover'}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
