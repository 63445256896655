import React from 'react';
import {ThemeProvider} from 'styled-components';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/styles';

import theme from './theme';
import {createTheme} from '@material-ui/core/styles';

const muiTheme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  overrides: {
    MuiDropzoneArea: {
      textContainer: {
        width: '100% !important',
      },
    },
    MuiDropzonePreviewList: {
      image: {
        height: '100% !important',
        width: '100% !important',
        objectFit: 'cover',
      },
      removeButton: {
        top: '0.25rem',
        right: '0.25rem',
        width: '36px',
        height: '36px',
        minHeight: 0,
      },
    },
  }
});

const Theme = ({children}) => (
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </MuiThemeProvider>
);

export {Theme};
