import HistoryService from '../../../../utils/HistoryService';

export const privateFooterItems = [
  {label: 'About', action: () => HistoryService.push('/about')},
  {label: 'Privacy', action: () => HistoryService.push('/privacy')},
];

export const footerItems = [
  {label: 'Inquiry', action: () => HistoryService.push('/inquiry')},
  {label: 'About', action: () => HistoryService.push('/about')},
  {label: 'Privacy', action: () => HistoryService.push('/privacy')},
  {
    label: 'Video',
    action: () => window.open('https://spark.adobe.com/video/urPA2KFK0xKq2'),
  },
  // {label: 'Wiki', action: () => HistoryService.push('/wiki')},
  {label: 'Demo', action: () => HistoryService.push('/home')},

  // {
  //   label: 'Membership',
  //   action: () => HistoryService.push('/membership'),
  // },
  {label: 'Certification', action: () => HistoryService.push('/training')},
  // {label: 'Support', action: () => HistoryService.push('/about')},
  // {label: 'FAQ', action: () => HistoryService.push('/faq')},
  // {label: 'Pricing', action: () => HistoryService.push('/about')},
];
