import React from 'react';

import ContentBox from '../../UI/LandingContentBox';
import SignUpButton from '../../UI/SignUpButton';

import PortraitImg from 'assets/img/landing/learnMoreBlocks/portrait-3.png';

import {BlockTitle} from '../styles';

import device from 'mediaQueries';

const boxImgCSS = `
  width: 70%;

  @media ${device.tablet} {
    width: 80%;
  }
`;

const ClearOpinionBlock = ({isDana, variant}) => (
  <ContentBox
    height={505}
    bgCol="linear-gradient(#fff 0%, #f8f8f8 49.33%, #ebebeb 100%)"
    srcImage={PortraitImg}
    directionImg="left"
    boxImgCSS={boxImgCSS}>
    <BlockTitle color="#2ca3ff">
      Make your opinion as clear as possible.
    </BlockTitle>
    <SignUpButton isDana={isDana} variant={variant} />
  </ContentBox>
);

export default ClearOpinionBlock;
