import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 140px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #fff;
`;

const SubTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: #fff;
`;

export {Container, Title, SubTitle};
