import React from 'react';

import {Base, Main, PortraitEntity} from './styles';

import Profile from './Profile';
import EntityHeader from './EntityHeader';
import LazyImg from 'Components/utils/LazyImg';
import {AiOutlinePlusCircle} from 'react-icons/ai';

const Entity = ({
  title,
  portraitUrl,
  redirectToEntity,
  userName,
  content = null,
  userImage,
}) => (
  <Main>
    <Base>
      <PortraitEntity onClick={redirectToEntity}>
        {content ? (
          content
        ) : (
          <LazyImg height="100%" width="100%" src={portraitUrl} alt={title} />
        )}
      </PortraitEntity>
      {userName != null ? (
        <>
          <EntityHeader title={title} clickedTitle={redirectToEntity} />
          <Profile profilePic={userImage} userName={userName} />
        </>
      ) : (
        <div className={'flex flex-col self-center items-center mb-2'}>
          <span className={'font-semibold text-base'}>New Entity</span>
          <AiOutlinePlusCircle className={'w-8 h-8'} />
        </div>
      )}
    </Base>
  </Main>
);

export default Entity;
