import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';

// import {} from './styles';
import BaseContainer from 'hoc/BaseFormContainer';

import HeaderForm from 'Components/UI/HeaderForm';
import Form from './Form';

import {validateEmail} from 'Helpers/validators';
import passwordValidators from 'Helpers/passwordValidators';
import {convertQueryParamsToObject} from 'Helpers/api';

import {authSignInSaga} from 'Store/actions';

import useTitle from 'hooks/useTitle';
import InitFormService from '../../../Services/initForm';

// import InitFormService from 'Services/initForm';

const AuthForm = ({
  email,
  setEmail,
  pw,
  setPw,
  onInitSaga,
  success,
  isAuth,
  history,
  sessionId,
  isDana,
  variant,
  channel,
}) => {
  useTitle('Sign In', true);

  const [token, setToken] = useState('');
  const gVariant = channel?.name ?? variant;

  useEffect(() => {
    InitFormService.getChannelToken(gVariant)
      .then(res => {
        console.log('getChannelToken', res);
        setToken(res.data.channel_token);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const queryParams = convertQueryParamsToObject(window.location.search);

    if (success && isAuth) {
      history.push(
        queryParams.onboarding === 'true'
          ? '/home/onboarding/welcome'
          : '/home',
      );
    }
  }, [success, isAuth, history, sessionId]);

  const statusEmail = validateEmail(email);
  const statusPw = passwordValidators.every(validator => validator(pw));

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      console.log('token', token);

      if (statusEmail && statusPw) {
        onInitSaga(email, pw, token);
      }
    },
    [statusEmail, statusPw, onInitSaga, email, pw, token],
  );

  if (token === '') {
    return null;
  }

  return (
    <BaseContainer>
      <HeaderForm title="Enter your email and password" isDana={isDana} />
      <Form
        onSubmit={onSubmit}
        setEmail={setEmail}
        setPw={setPw}
        statusEmail={statusEmail}
        statusPw={statusPw}
        isDana={isDana}
        variant={gVariant}
      />
    </BaseContainer>
  );
};

const mapStateToProps = ({
  signinAuthForm: {success},
  user: {isAuth, sessionId},
  UserData: {channel},
}) => ({
  success,
  isAuth,
  sessionId,
  channel,
});

const mapDispatchToProps = dispatch => ({
  onInitSaga: (email, password, token) =>
    dispatch(authSignInSaga(email, password, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
