import styled from 'styled-components';
import device from '../../../../../../../../../../mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
`;

const Base = styled.div`
  width: 0.625rem;
  height: 2.5rem;
  background-color: ${({isSelected, theme}) =>
    isSelected ? theme.colors.main : '#c4c4c4'};
  transform: scale(${({isSelected}) => (isSelected ? '1.1' : '1')});
  margin-right: 0.4375rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const TextContainer = styled.div`
  font-weight: 600;
  position: absolute;
  bottom: -15px;
  font-size: 0.625rem;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
  white-space: nowrap;

  @media ${device.mobileL} {
    font-size: 0.75rem;
  }
`;

export {Base, Wrapper, TextContainer};
