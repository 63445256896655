import React, {useCallback, useEffect} from 'react'
import AlertContainer from '../../../MemberHomepage/Definitions/AlertContainer'
import xss from 'xss'
import config from '../../../../hoc/ShowHTMLString/config'
import ComposerButtonGroup from '../ComposerButtonGroup'

const ComposerOpenAiContentView = ({openAiContent}) => {
  let content = null;

  return (
    openAiContent ?
      <>
      <div dangerouslySetInnerHTML={{__html: xss(openAiContent, config)}} className={'flex-col border-2 border-giga-main rounded-xl p-2'}></div>
      </>
      : <div></div>
  );

};

export default ComposerOpenAiContentView;
