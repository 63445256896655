import React from 'react';

import {Base, HeaderSection, StyledNavLink} from './styles';

import {proPages, regularPages} from '../info';

const SectionsHeader = () => {
  const setPages = pageArr =>
    pageArr.map(({path, label}) => (
      <StyledNavLink
        key={label}
        exact
        to={`/home/create/${path}`}
        activeClassName="activeSection">
        {label}
      </StyledNavLink>
    ));

  return (
    <Base>
      <HeaderSection mode="regular">{setPages(regularPages)}</HeaderSection>

      <HeaderSection mode="pro">{setPages(proPages)}</HeaderSection>
    </Base>
  );
};

export default SectionsHeader;
