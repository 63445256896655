import * as actionTypes from '../../types';

const initialState = {
  currentPhase: 'init',
};

const changeSignupPhase = (state, {value}) => ({
  ...state,
  currentPhase: value,
});

const reducer = (state = initialState, action) => {
  const {type} = action;

  switch (type) {
    case actionTypes.CHANGE_SIGNUP_PHASE:
      return changeSignupPhase(state, action);

    default:
      return state;
  }
};

export default reducer;
