import styled from 'styled-components';

import {Link} from 'react-router-dom';

const Error = styled.div`
  color: #fff;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
`;

const LinkPrivacy = styled(Link)`
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);

  cursor: pointer;
  text-decoration: initial;
  color: #fff;
  font-size: 12px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export {Error, LinkPrivacy};
