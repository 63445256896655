import styled from 'styled-components';

import device from 'mediaQueries';

export default styled.div`
  color: ${({dark}) => (dark ? '#000' : '#fff')};
  font-size: 14px;
  margin-left: 2px;
  font-weight: bold;
  margin-bottom: 8px;

  ${({labelRequired}) =>
    labelRequired &&
    `
  &::after {
    content: '*';
    color: red;
    margin-left: 10px;
  }
`};
  ${({optional}) =>
    optional &&
    `
 &::after {
      content: 'Optional';
      color: gray;
      margin-left: 8px;
      font-size: 10px;
    }


`} @media ${device.desktopL} {
    font-size: 26px;
    margin-bottom: 12px;
  }
`;
