import React, {useEffect, useState} from 'react';
import {Base, StyledNavLink} from './styles';
import {connect} from 'react-redux';
import MemberHomepageService from '../../../../Services/memberHomepage';
import {
  getUserMode,
  getUserToken,
  removeItem,
  storeCategoryMode,
  storeEntityParams,
  storeUserMode,
} from '../../../../utils/HelperService';
import * as userActions from '../../../../Store/sagas/UserSaga/actions';
import Constants from '../../../../Constants/Constants';
import HSContainer from '../../../UI/HSContainer';
import {HeaderSection} from '../ModeSelector/styles';

const baseContent = `
  padding: 0.4rem 0;
  width: 100%;
`;

const ModeSelectorV2 = ({
  modes,
  setModes,
  displayModes,
  displayCategories,
  actions,
  push,
  dbName = 'initial',
}) => {
  const [accountProfile, setAccountProfile] = useState();
  const [canPerform, setCanPerform] = useState(false);

  useEffect(() => {
    if (displayModes) {
      setModes([displayModes[0]]);
    }
  }, [displayModes]);

  useEffect(() => {
    if (getUserToken() != null) {
      actions.fetchUserData();
    }
    MemberHomepageService.getAccountProfile().then(res => {
      console.log('accountProfile', res);
      setAccountProfile(res?.data ?? {});
    });
  }, []);

  useEffect(() => {
    setCanPerform(
      accountProfile?.perform_assessment_authority === 'Yes' ||
        dbName === Constants.GIGA_INTELLIGENCE,
    );
  }, [accountProfile, dbName]);

  console.log('displayModes', displayModes);
  console.log('displayCategories', displayCategories);

  const handleGo = (e, mode) => {
    e.preventDefault();
    console.log('handleGo with creationModes', mode);

    if (push == null) {
      return;
    }

    const selCategories = displayCategories.filter(
      c => c.category === mode.mode_label,
    );

    if (selCategories.length === 0) {
      return;
    }

    const selCategory = selCategories[0];

    console.log('handleGo with creationModes', selCategory);

    const tech_id = -1;
    storeEntityParams({
      mode_id: mode.mode_id,
      mode_name: mode.mode_name,
      category_id: selCategory.category_id,
      tech_id,
    });
    storeUserMode(mode);
    storeCategoryMode(selCategory);
    actions.updateCreationModes([mode], [selCategory]);
    removeItem(Constants.AS_CURRENT_SECTION);
    localStorage.setItem(Constants.AS_POSTED_STATUS, 'no');
    localStorage.setItem(Constants.AS_PREVIOUS_LINK, window.location.pathname);
    push(`/home/create/analysis?tech_id=${tech_id}&cid=${selCategory.cid}`);
  };

  const setModesPages = pageArr =>
    pageArr
      .filter(d => d.mode_id !== -1 && d.mode_id !== '3')
      .map(mode => (
        <StyledNavLink
          mode="regular"
          key={mode.id}
          exact
          to={`/`}
          onClick={e => handleGo(e, mode)}
          style={{
            opacity: modes.some(m => m?.mode_name === mode?.mode_name)
              ? 1
              : 0.5,
          }}>
          {mode.mode_label}
        </StyledNavLink>
      ));

  if (accountProfile?.url_file == null || dbName === 'initial') {
    return null;
  }

  return (
    <Base>
      <div className={'flex flex-row space-x-3 mb-2'}>
        {accountProfile?.url_file && (
          <img
            src={accountProfile?.url_file}
            alt={'avatar'}
            className="w-10 h-10 object-cover p-0.5 rounded-full"
          />
        )}
        <span className={'text-base font-semibold text-gray-600 mt-3'}>
          Hello, {accountProfile?.user_full_name}.{' '}
          {canPerform ? (
            <>
              <br /> What would you like to create today?
            </>
          ) : (
            ''
          )}
        </span>
      </div>
      <div className={'flex mx-0 sm:mx-6'}>
        {displayModes && canPerform && (
          <HeaderSection
            widthSpec={'100%'}
            mode="regular"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              width: '100%'
              // paddingLeft: 20,
              // paddingRight: 20,
            }}>
            <HSContainer
              vertical
              verticalBreakpoint={100}
              tabsInfo={displayModes}
              width="100"
              bgColor={{left: 'rgba(242,242,242,0)', right: '#f2f2f2'}}
              emptyMessage="Loading..."
              baseStyles={baseContent}>
              {displayModes && setModesPages(displayModes)}
            </HSContainer>
          </HeaderSection>
        )}
      </div>
    </Base>
  );
};

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateCreationModes: (modes, categories) => {
      dispatch(userActions.updateCreationModes(modes, categories));
    },
    fetchUserData: () => {
      dispatch(userActions.fetchUserData());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModeSelectorV2);
