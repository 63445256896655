import styled from 'styled-components';

import {ReactComponent as VerificationIcon} from 'assets/svg/verification.svg';

import device from 'mediaQueries';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media ${device.tablet} {
    width: 80%;
  }

  @media ${device.mobileL} {
    width: 95%;
  }
`;

const Phrase = styled.div`
  font-size: 22px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  width: 75%;
  margin-bottom: 30px;

  @media ${device.laptop} {
    width: 90%;
  }

  @media ${device.mobileL} {
    font-size: 19px;
  }

  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

const MainImg = styled(VerificationIcon)`
  margin-bottom: 25px;
`;

const SkipText = styled.div`
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  margin-top: 30px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  width: 60%;

  @media ${device.laptopL} {
    width: 70%;
  }

  @media ${device.laptop} {
    width: 75%;
  }

  @media ${device.mobileL} {
    width: 95%;
  }

  @media ${device.mobileS} {
    width: 99%;
  }
`;

export {Phrase, MainImg, Container, SkipText, ButtonContainer};
