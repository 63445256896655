import React, {useCallback, useEffect} from 'react';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {ReactComponent as NotificationSVG} from '../../../assets/svg/notification1.svg';
import './styles.scss';
import styled from 'styled-components';
import ServerContent from '../../../hoc/ServerContent';
import device, {customDevice} from '../../../mediaQueries';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import EntityService from '../../../utils/EntityService';
import ListItem from "@mui/material/ListItem";
import {Avatar, Badge, ListItemAvatar, ListItemButton, Typography} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {People} from "@material-ui/icons";
import {NotificationImportant} from "@material-ui/icons";

function NotificationItem(props) {
    const {category, key, goCategory} = props
    return (
        <ListItem alignItems="flex-start" key={key} disablePadding>
            <ListItemAvatar>
                <Avatar alt="" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>

            <ListItemText
                primary= {category.category}
                secondary={
                    <React.Fragment>
                        <ListItemButton  onClick={() => {goCategory(category.category_id, category.mode_id, 'private')}}>
                                Private &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <ListItemIcon sx={{ color: 'inherit' }}>
                                <Badge badgeContent={category.private_entity_count} color="primary">
                                    <NotificationImportant color="action" />
                                </Badge>
                            </ListItemIcon>
                        </ListItemButton>
                        <ListItemButton  onClick={() => {goCategory(category.category_id, category.mode_id, 'public')}}>
                                Public &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Badge badgeContent={category.public_entity_count} color="primary">
                                <NotificationImportant color="action" />
                            </Badge>
                        </ListItemButton>
                        <ListItemButton alignItems={"flex-start"} onClick={() => {goCategory(category.category_id, category.mode_id, 'total')}}>
                                Total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Badge badgeContent={category.total_entity_count} color="primary">
                                <NotificationImportant color="action" />
                            </Badge>
                        </ListItemButton>
                    </React.Fragment>
                }
            />
        </ListItem>

    );
}

export default NotificationItem;