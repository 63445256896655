import styled from 'styled-components';

import device from 'mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  label {
    margin-right: 1.25rem;
  }
`;

const BaseInput = styled.input`
  margin-bottom: ${({bottomGap}) => (bottomGap ? '1.25rem' : '0')};
  width: ${({width}) => `${width}%`};
  display: none;

  @media ${device.mobileM} {
    width: 100%;
  }
`;

const InputLabel = styled.label`
  text-align: center;
  ${({labelRequired}) =>
    labelRequired
      ? `
    &::after {
      content: '*';
      color: red;
      margin-left: 0.675rem;
    }
  `
      : `
 &::after {
      content: '-';
      color: red;
      margin-left: 0.675rem;
    }
`}
`;

const PreviewImage = styled.img`
  width: 18.75rem;
  height: auto;
  margin-bottom: 1.25rem;
  padding: 0.675rem;
  border: 3px solid ${({theme}) => theme.colors.main};
  border-radius: 0.675rem;
  cursor: pointer;
`;

export {Wrapper, BaseInput, PreviewImage, InputLabel};
