import InitDisplayService from '../Services/initDisplay';
import {removeItem, storeItem} from './HelperService';
import Constants from '../Constants/Constants';

function isPrivateChannel(channel) {
  if (channel?.routePath == null) {
    return false;
  }
  return !channel?.routePath?.includes('giga') ?? true;
}

async function fetchChannelInfo(pathName, updateChannel) {
  await InitDisplayService.getDisplayChannel(pathName)
    .then(res => {
      console.log(res);
      const definition = res.data?.landing_page_definition;
      if (definition != null) {
        let newChannel = {};
        let contents = {};
        let cardData = [];
        let hasTrending = false;
        for (let i = 0; i < definition.length; i++) {
          const key = Object.keys(definition[i])[0];
          const value = definition[i][key];
          if (key === 'subTitle') {
            contents = {...contents, [key]: value};
          } else if (key.includes('trending_label')) {
            if (value != null) {
              hasTrending = true;
            }
            cardData.push({label: value});
          } else if (key.includes('trending_portrait')) {
            let newCard = cardData.pop();
            newCard = {...newCard, portraitURL: value};
            cardData.push(newCard);
          } else {
            newChannel = {...newChannel, [key]: value};
          }
        }
        newChannel = {
          ...newChannel,
          contents: {
            ...contents,
            blocks: hasTrending
              ? [
                  {
                    type: 0,
                    title: 'Trending Now',
                    cardHeight: 450,
                    cardData: cardData,
                  },
                ]
              : [],
          },
        };
        console.log('newChannel', newChannel);
        updateChannel(newChannel);
        storeItem(newChannel, Constants.AS_CHANNEL_INFO);
        storeItem(
          {collage_id: res.data?.collage_id, image_id: res.data?.collage_id},
          Constants.AS_CHANNEL_ID,
        );
      } else {
        updateChannel({});
        removeItem(Constants.AS_CHANNEL_INFO);
      }
    })
    .catch(error => {
      console.log(error);
      updateChannel({});
      removeItem(Constants.AS_CHANNEL_INFO);
    });
}

export default {
  isPrivateChannel,
  fetchChannelInfo,
};
