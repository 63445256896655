import React, {useEffect, useState} from 'react';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const AlertComp = ({
  status = false,
  errorMessage = '',
  severity,
  onClose = () => null,
}) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(status);
  }, [status]);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsShown(false);
  };

  return (
    <Snackbar open={isShown} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={() => {
          setIsShown(false);
          onClose();
        }}
        severity={severity}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertComp;
