export const convertQueryParamsToObject = url => {
  if (url !== '') {
    const indexQuery = url.split('').findIndex(char => char === '?');
    const queryParams = url.substring(indexQuery + 1);

    if (indexQuery !== -1) {
      return JSON.parse(
        '{"' +
          decodeURI(queryParams)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}',
      );
    } else {
      return {};
    }
  }

  return {};
};

export const isValidHttpURL = str => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const generateFormOnObject = obj => {
  const form = new FormData();
  const names = Object.keys(obj);

  names.forEach(name => {
    form.append(name, obj[name]);
  });

  return form;
};

export const formatHiddenProps = hiddenProps =>
  hiddenProps
    .map(({name, value}) => ({
      [name]: value,
    }))
    .reduce((acc, prop) => ({...acc, ...prop}), {});
