import React from 'react';

import {Base, Container, MainImg, Phrase} from './styles';

import Logo from 'Components/UI/Logo';

const VerifyResetPassword = () => {
  return (
    <Base>
      <Container>
        <Logo size="150" fixedTopLeft="true" specialSize={'150'} />
        <MainImg />
        <Phrase>
          Check your email to verify your identity, and set up your password
        </Phrase>
      </Container>
    </Base>
  );
};

export default VerifyResetPassword;
