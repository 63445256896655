import styled from 'styled-components';

const BlockTitle = styled.h4`
  font-size: 2.5rem;
  color: ${({color}) => color};
  margin: 0;
  margin-bottom: 35px;
`;

export {BlockTitle};
