import React from 'react';
import './styles.css';
import {IoMdClose} from 'react-icons/io';
import TopicExample from '../TopicExample';
import {
  Card,
  ContentContainer,
  ExplanationTopic,
} from '../../../../DescPage/ExampleView/styles';
import ShowHTMLString from '../../../../../../../hoc/ShowHTMLString';
import {deleteHTMLFromString} from '../../../../../../../Helpers/dom';

export default function MessageDetailModal(props) {
  const {
    handleCloseModal,
    topicInfo,
    topicContent,
    assessmentTitle,
    assessmentImage,
    assessmentDes,
  } = props;

  const CardItem = ({explanation, children, htmlContent}) => (
    <Card pd={10} bottomGap>
      <ExplanationTopic>{'Message'}</ExplanationTopic>
      <Card pd={20}>
        <ContentContainer>
          <ShowHTMLString
            htmlString={
              htmlContent && deleteHTMLFromString(htmlContent)
                ? htmlContent
                : '(No content...)'
            }
          />
        </ContentContainer>
      </Card>
      {children}
    </Card>
  );

  return (
    <div
      className={
        'flex flex-col relative detail-message-modal-container rounded-lg'
      }>
      <IoMdClose
        className={'w-6 h-6 absolute top-4 right-4 text-white cursor-pointer'}
        color={'black'}
        onClick={() => handleCloseModal()}
      />
      <div className={'mt-12 mx-8 mb-8'}>
        <TopicExample
          topics={topicInfo.assessment_summary}
          topicContent={topicContent}
          CardItem={CardItem}
          subjectInfo={topicInfo.assessment}
          assessment_name={assessmentTitle}
          assessment_description={assessmentDes}
          newImage={assessmentImage}
        />
      </div>
    </div>
  );
}
