import React, {useCallback, useState} from 'react';

import {Wrapper} from './styles';

import Attachment from './Attachment';
import OverflowContainer from 'Components/UI/OverflowContainer';
import Lightbox from './Lightbox';

const Attachments = ({
  attachments,
  removeAttachment = () => null,
  hideRemove,
  variant = 1,
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

  const handleAttachmentClick = useCallback(id => {
    setPhotoIndex(id);
    setIsLightBoxOpen(true);
  }, []);

  const attachmentsItems = attachments?.map(
    ({type, src, fileName, id}, index) => {
      return (
        <Attachment
          clickedContent={handleAttachmentClick.bind(null, index)}
          handleRemove={removeAttachment.bind(null, id, type, src)}
          hideRemove={hideRemove}
          key={id}
          id={id}
          type={type}
          fileName={fileName}
          src={src}
          marginRight={variant === 1 ? '1.25rem' : '0'}
        />
      );
    },
  );

  return (
    <Wrapper>
      {variant === 1 ? (
        <OverflowContainer width="100" tabsInfo={attachments} emptyMessage="">
          {attachmentsItems}
        </OverflowContainer>
      ) : (
        <div className={'space-y-2'}>{attachmentsItems}</div>
      )}

      <Lightbox
        attachments={attachments}
        photoIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
        isOpen={isLightBoxOpen}
        setIsOpen={setIsLightBoxOpen}
      />
    </Wrapper>
  );
};

export default Attachments;
