import React from 'react';

import {Base, PortraitEntity} from './styles';

import EntityHeader from './EntityHeader';

const EntityPreview = ({title, portraitUrl, description}) => (
  <>
    <Base>
      <EntityHeader title={title} description={description} />
      <PortraitEntity>
        <img src={portraitUrl} alt={title} style={{objectFit: 'contain'}} />
      </PortraitEntity>
      {/*<EntityFooter />*/}
      {/*<SocialOptions />*/}
    </Base>
  </>
);

export default EntityPreview;
