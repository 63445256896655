import styled from 'styled-components';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/SearchOutlined';

import device from 'mediaQueries';

const Base = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  background-color: ${({bgColor}) => bgColor ?? '#2ca3ff'};
  padding: 10px 30px;
  border-radius: 0 0 30px 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  @media ${device.mobileL} {
    padding: 10px 20px;
  }

  @media ${device.mobileM} {
    padding: 10px;
  }
`;

const BurgerIcon = styled(MenuIcon)`
  color: #fff;
  cursor: pointer;
`;

const SearchButton = styled(SearchIcon)`
  color: #fff;
  cursor: pointer;
`;

export {Base, BurgerIcon, SearchButton};
