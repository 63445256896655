import styled from 'styled-components';

import ServerContent from 'hoc/ServerContent';

const Base = styled(ServerContent)`
  display: flex;
  flex-direction: column;
  //height: 70vh;
  min-height: 600px;
`;

export {Base};
