import styled from 'styled-components';

const Base = styled.div`
  overflow-wrap: break-word;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export {Base};
