import {call, put, takeLatest} from 'redux-saga/effects';
import {storeUserToken} from '../../../utils/HelperService';
import * as actionTypes from '../../types';

import {
  authSignInFail,
  authSignInFailReq,
  authSignInStart,
  authSignInSuccess,
  changeUser,
} from 'Store/actions';

import SigninService from 'Services/signin';

function* postAuthForm(action) {
  const {email, password, token} = action;

  yield put(authSignInStart());

  try {
    const bodyRequest = new FormData();
    bodyRequest.append('email', email);
    bodyRequest.append('password', password);
    bodyRequest.append('token', token);

    console.log(email, password, token);

    const res = yield call(SigninService.getAuth, bodyRequest);
    const {data} = res;

    console.log(res, data);

    if (data) {
      const {giga_login_status, giga_user_id, giga_session_id} = data;

      if (giga_login_status === 'ok') {
        storeUserToken({isAuth: true, giga_user_id, giga_session_id});
        yield put(changeUser(true, giga_user_id, giga_session_id));
        yield put(authSignInSuccess());
      } else {
        yield put(authSignInFail('Email or password is not correct'));
      }
    } else {
      yield put(authSignInFail('User not found'));
    }
  } catch (e) {
    console.error(e);

    yield put(authSignInFailReq());
  }
}

export default function* watchPostAuthForm() {
  yield takeLatest(actionTypes.SIGNIN_AUTH_SAGA, postAuthForm);
}
