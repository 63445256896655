import React, {useState} from 'react';
import {withRouter} from 'react-router';

import BaseContainer from 'hoc/BaseFormContainer';

import HeaderForm from 'Components/UI/HeaderForm';
import Form from './Form';
import {Base} from './styles';

import useTitle from 'hooks/useTitle';
import passwordValidators from '../../../Helpers/passwordValidators';
import {convertQueryParamsToObject} from '../../../Helpers/api';
import SigninService from '../../../Services/signin';
import HistoryService from '../../../utils/HistoryService';

const CreatePassword = () => {
  useTitle('Reset Password', true);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submissionLoading, setSubmissionLoading] = useState(false);

  const statusPassword = passwordValidators.every(validator =>
    validator(newPassword),
  );
  const statusConfirmPassword =
    confirmPassword === newPassword && newPassword.trim().length > 0;

  const onSubmit = e => {
    e.preventDefault();
    if (statusPassword && statusConfirmPassword) {
      console.log('submit');
      const unm = convertQueryParamsToObject(window.location.search).unm;
      const token = convertQueryParamsToObject(window.location.search).token;
      setSubmissionLoading(true);
      SigninService.postResetPasswordProcess(unm, token, newPassword)
        .then(res => {
          setSubmissionLoading(false);
          console.log(res);
          HistoryService.push('/signin');
        })
        .catch(error => {
          setSubmissionLoading(false);
          console.log(error);
        });
    }
  };

  return (
    <Base>
      <BaseContainer>
        <HeaderForm title="Reset Your Password" />
        <Form
          onSubmit={onSubmit}
          password={newPassword}
          changePassword={e => setNewPassword(e.target.value)}
          confirmPassword={confirmPassword}
          changeConfirmPassword={e => setConfirmPassword(e.target.value)}
          statusPassword={statusPassword}
          statusConfirmPassword={statusConfirmPassword}
          loading={submissionLoading}
          currentError={false}
          errorMessage="There was an error, please reload the page and try again."
        />
      </BaseContainer>
    </Base>
  );
};

export default withRouter(CreatePassword);
