import React, {useEffect, useState} from 'react';
import Button from 'Components/DesignSystem/button/button';
import {connect} from 'react-redux';
import {BsSearch} from 'react-icons/bs';
import {IoMdClose} from 'react-icons/io';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ReactComponent as MessageSVG} from '../../../../../../../assets/svg/member/msg.svg';
import MemberHomepageService from '../../../../../../../Services/memberHomepage';
import Entity from '../../../../../../UI/Entity';
import {BsCheckSquareFill} from 'react-icons/bs';

function SelectedCalendars(props) {
  const {handleCloseModal, handleContinue} = props;

  const [calendars, setCalendars] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    MemberHomepageService.fetchAllCalendars().then(res => {
      if (res?.data?.calendars != null) {
        setCalendars(res.data.calendars);
      }
    });
  }, []);

  const filterBy = item => {
    const search = searchString.toLowerCase();
    return (
      item.calendar_name.toLowerCase().includes(search) ||
      item.owner_full_name.toLowerCase().includes(search)
    );
  };

  return (
    <div
      className={
        'flex flex-col relative select-calendars-modal-container rounded-lg'
      }>
      <div className={'flex flex-col flex-1 mt-8 space-y-4 items-center px-4'}>
        <div className={'flex flex-row space-x-2 items-center'}>
          {/*<div*/}
          {/*  className={'pl-1.5 pr-2.5 py-2 rounded-full'}*/}
          {/*  style={{backgroundColor: 'rgb(83,155,212)'}}>*/}
          {/*  <GrSend className={'w-5 h-5'} />*/}
          {/*</div>*/}
          <MessageSVG style={{width: 36, height: 36}} />
          <p className={'text-lg font-semibold text-center text-white'}>
            Select calendars
          </p>
        </div>
      </div>
      <IoMdClose
        className={'w-6 h-6 absolute top-4 right-4 text-white cursor-pointer'}
        onClick={() => handleCloseModal()}
      />
      <div
        className={
          'flex flex-row w-80 bg-white rounded-full items-center px-4 self-center mt-6 py-2 space-x-2'
        }>
        <BsSearch className={'w-4 h-4'} />
        <input
          id="search"
          name="search"
          className="focus:outline-none text-sm flex-1"
          type="search"
          placeholder={'Search'}
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
      </div>
      <div
        className={`select-calendars-list-container ${
          calendars.length === 0 ? 'items-center justify-center' : 'items-start'
        }`}>
        {calendars.length === 0 ? (
          <CircularProgress thickness={5} className="Loading" disableShrink />
        ) : (
          <div
            className={
              'grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 flex-1 gap-4'
            }>
            {calendars
              .filter(c => filterBy(c))
              .map((c, index) => {
                return (
                  <div key={c.calendar_id} className={'relative'}>
                    <Entity
                      title={c.calendar_name}
                      portraitUrl={c.calendar_image_url}
                      redirectToEntity={() => {
                        if (selectedCalendars.includes(c.calendar_id)) {
                          setSelectedCalendars(
                            selectedCalendars.filter(s => s !== c.calendar_id),
                          );
                        } else {
                          setSelectedCalendars([
                            ...selectedCalendars,
                            c.calendar_id,
                          ]);
                        }
                      }}
                      userName={c.owner_full_name}
                      userImage={c.owner_image_url}
                    />
                    {selectedCalendars.includes(c.calendar_id) && (
                      <BsCheckSquareFill
                        className={
                          'w-6 h-6 absolute top-4 right-4 text-giga-bluelight'
                        }
                      />
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <span className={'font-medium text-white text-sm self-center mt-4'}>
        {selectedCalendars.length} Calendar
        {selectedCalendars.length > 1 ? 's' : ''} selected
      </span>
      <div className={'flex flex-row justify-center mb-8 mt-12'}>
        <Button
          onClick={() => handleContinue(selectedCalendars)}
          style={{width: '24rem'}}>
          Submit
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
});

export default connect(mapStateToProps)(SelectedCalendars);
