import React, {useCallback, useContext, useEffect, useState} from 'react'
import useFetchContent from '../../../../hooks/useFetchContent'
import MemberHomepageService from '../../../../Services/memberHomepage'
import {formatAssessmentResult} from '../../../MemberHomepage/AssesmentView/bl'
import {useParams, useLocation} from 'react-router-dom'
import AssesmentTopic from '../../../MemberHomepage/AssesmentView/AssesmentContent/AssesmentTopics/AssesmentTopic'
import {getAttachments} from '../../../MemberHomepage/Helpers/attachments'
import {AlertContainer} from '../../../MemberHomepage/AssesmentView/AssesmentContent/AssesmentTopics/styles'
import ComposerOpenAiContentView from '../ComposerOpenAiContentView'
import ComposerButtonGroup from '../ComposerButtonGroup'
import xss from 'xss'
import config from '../../../../hoc/ShowHTMLString/config'
import {topicContext} from '../../../../Contexts/TopicContext'
import {Skeleton} from '@mui/lab'

const ComposerOpenAiContent = ({prompt_count, ai_engine_index, changePrompt}) => {

  const contextValue = useContext(topicContext);

  const [prompt, setPrompt] = useState(contextValue.prompt);
  const ff_freeform_id = contextValue.ff_freeform_id;
  const ff_topic_id = contextValue.ff_topic_id;
  const updatePrompt = (newPrompt) => {
    changePrompt(newPrompt);
    setPrompt(newPrompt);
  }

  const assessmentEndpoint = 'react_gi_v2a_composer_prompt_retrieval.php';

  const [assessmentResult, loadingAssessment, errorAssessment, callAssessment] =
    useFetchContent(
      {},
      MemberHomepageService.getAssesmentTemp,
      useCallback(({data}) => data, []),
    );

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const catAssesment = queryParameters.get("cat");
  const cidAssesment = queryParameters.get("cid");
  const techIdAssesment = queryParameters.get("tech_id");
  const assessorID = "885";  // chatgpt assessorID
  // const {catAssesment, cidAssesment, techIdAssesment, assessorID} = {
  //     "catEntity": "Boston Celtics Players",
  //     "cidEntity": "gispbbnbaecatbcpl.574",
  //     "techIdEntity": "16",
  //     "catAssesment": "Boston Celtics Players",
  //     "cidAssesment": "gispbbnbaecatbcpl.574",
  //     "techIdAssesment": "16",
  //     "assessorID": "885"
  // };

  useEffect(
    () =>
      callAssessment(
        assessmentEndpoint,
        catAssesment,
        cidAssesment,
        techIdAssesment,
        assessorID,
        prompt,
        ai_engine_index,
        prompt_count,
        ff_freeform_id,
        ff_topic_id
      ),
    [
      assessmentEndpoint,
      catAssesment,
      cidAssesment,
      techIdAssesment,
      callAssessment,
      assessorID,
      prompt,
      ai_engine_index,
      prompt_count,
      ff_freeform_id,
      ff_topic_id
    ],
  );

  const {assessment, assessorProfile, subject_info} = assessmentResult;
  let chatGptRes = null;
  chatGptRes = assessment?.subtopic_narrative;

  return (
    <>
      {loadingAssessment ? (
        <Skeleton variant="wave" height={260} />
      ) : (
        <>
          <ComposerOpenAiContentView openAiContent={chatGptRes} />
          <ComposerButtonGroup openAiContent={chatGptRes} getNewPrompt={updatePrompt} />
        </>
      )}
    </>
  );
};

export default ComposerOpenAiContent;
