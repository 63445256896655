import React, {useEffect, useState} from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import {TextField} from '@material-ui/core';
import {DateTimePicker, LocalizationProvider} from '@mui/lab';
import {makeStyles} from '@material-ui/core/styles';

const INPUT_FORMAT = 'MM/dd/yyyy hh:mm';

const useStyle = makeStyles(theme => ({
  textField: {
    background: '#fff',
    width: '100%',
    color: '#23232D',
    marginBottom: '1rem',
  },
}));

export default function GGDateTimePicker({
  label,
  topicValue,
  onChange,
  isEditable,
}) {
  const textInputClass = useStyle();
  const [value, setValue] = useState(
    topicValue ? moment(topicValue).toDate() : null,
  );

  useEffect(() => {
    onChange(moment(value).format('YYYY-MM-DD HH:MM'));
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isEditable ? (
        <DateTimePicker
          inputFormat={INPUT_FORMAT}
          type="date"
          label={label}
          value={value}
          onChange={setValue}
          renderInput={params => (
            <TextField {...params} className={textInputClass.textField} />
          )}
        />
      ) : (
        <div className={'mb-4'}>
          <span className={'font-semibold'}>{label}: </span>
          <span>{moment(topicValue).format('YYYY-MM-DD HH:MM')}</span>
        </div>
      )}
    </LocalizationProvider>
  );
}
