import React, {useEffect, useState} from 'react';
import {Base, HeaderSection, StyledNavLink} from './styles';
import HSContainer from '../../../UI/HSContainer';
import {connect} from 'react-redux';
import CategoriesUtil from '../../../../utils/CategoriesUtil';
import * as userActions from '../../../../Store/sagas/UserSaga/actions';
import MemberHomepageService from '../../../../Services/memberHomepage';
import Constants from '../../../../Constants/Constants';

const baseContent = `
  padding: 0.4rem 0;
  width: 100%;
`;

const ModeSelector = ({
  modes,
  setModes,
  categories,
  setCategories,
  displayModes,
  displayCategories,
  actions,
  dbName = 'initial',
  groupCategoryId = '-1',
}) => {
  const [accountProfile, setAccountProfile] = useState();
  const [canPerform, setCanPerform] = useState(false);

  useEffect(() => {
    if (displayModes && (modes.length === 0 || modes[0] == null)) {
      setModes([displayModes[0]]);
    }
  }, [displayModes]);

  console.log('groupCategoryId', groupCategoryId);

  useEffect(() => {
    actions.fetchUserData(groupCategoryId);
    MemberHomepageService.getAccountProfile().then(res => {
      console.log('accountProfile', res);
      setAccountProfile(res?.data ?? {});
    });
  }, []);

  useEffect(() => {
    setCanPerform(
      accountProfile?.perform_assessment_authority === 'Yes' ||
        dbName === Constants.GIGA_INTELLIGENCE ||
        dbName === Constants.GIGA_GUEST,
    );
  }, [accountProfile, dbName]);

  const onChangeMode = (e, selectedMode) => {
    e.preventDefault();
    if (modes.some(mode => mode.id === selectedMode.id)) {
      if (selectedMode.mode_name === 'all') {
        setModes([]);
      } else {
        const newModes = modes.filter(
          mode => mode.mode_name !== selectedMode.mode_name,
        );
        if (newModes.length === 0) {
          setModes([displayModes[0]]);
        } else {
          setModes(newModes);
        }
      }
    } else {
      if (selectedMode.mode_name === 'all') {
        setModes([selectedMode]);
      } else {
        setModes([
          ...modes.filter(mode => mode.mode_name !== 'all'),
          selectedMode,
        ]);
      }
    }
  };

  const onChangeCategory = (e, selectedCategory) => {
    e.preventDefault();
    if (categories.some(c => c.id === selectedCategory.id)) {
      setCategories(categories.filter(c => c.id !== selectedCategory.id));
    } else {
      setCategories([...categories, selectedCategory]);
    }
  };

  const setModesPages = pageArr =>
    pageArr.map(mode => (
      <StyledNavLink
        mode="regular"
        key={mode.id}
        exact
        to={`/`}
        onClick={e => onChangeMode(e, mode)}
        style={{
          opacity: modes.some(m => m?.mode_name === mode?.mode_name) ? 1 : 0.5,
        }}>
        {mode.mode_label}
      </StyledNavLink>
    ));

  const setCategoriesPages = pageArr =>
    pageArr.map(category => (
      <StyledNavLink
        title={category.category}
        key={category.id}
        exact
        to={`/`}
        onClick={e => onChangeCategory(e, category)}
        style={{opacity: categories.some(c => c.id === category.id) ? 1 : 0.5}}>
        {CategoriesUtil.getCategoryName(category.category)}
      </StyledNavLink>
    ));

  if (accountProfile == null || dbName === 'initial') {
    return null;
  }

  return (
    <Base justifyContent={canPerform ? 'space-between' : 'center'}>
      {canPerform && (
        <HeaderSection
          mode="regular"
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            // paddingLeft: 20,
            // paddingRight: 20,
          }}>
          <HSContainer
            vertical
            verticalBreakpoint={100}
            tabsInfo={displayModes}
            width="100"
            emptyMessage="Loading..."
            baseStyles={baseContent}>
            {displayModes && setModesPages(displayModes)}
          </HSContainer>
        </HeaderSection>
      )}
      {displayCategories?.length > 0 && (
        <HeaderSection
          widthSpec={canPerform ? '45%' : '80%'}
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            // paddingLeft: 20,
            // paddingRight: 20,
          }}>
          <HSContainer
            vertical
            verticalBreakpoint={100}
            tabsInfo={displayCategories}
            width="100"
            emptyMessage="Loading..."
            baseStyles={baseContent}>
            {setCategoriesPages(displayCategories)}
          </HSContainer>
        </HeaderSection>
      )}
    </Base>
  );
};

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    fetchUserData: groupCategoryId => {
      dispatch(userActions.fetchUserData(groupCategoryId));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModeSelector);
