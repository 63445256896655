import styled from 'styled-components';

const TextErrorContainer = styled.div`
  font-size: 13px;
  color: ${({color}) => color};
  margin: 10px 0;
  font-weight: 500;
`;

export default TextErrorContainer;
