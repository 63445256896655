import React, {memo} from 'react';

import Input from 'Components/DesignSystem/inputs/base-input';

const Inputs = ({changeEmail, statusEmail}) => (
  <>
    <Input
      name="Email"
      label="Email"
      placeholder="Your email"
      type="email"
      onChange={changeEmail}
      statusError={statusEmail ? 'success' : 'error'}
    />
  </>
);

export default memo(Inputs);
