import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  position: relative;
  bottom: 10%;

  button {
    width: ${({hasButtons}) => (hasButtons ? '30%' : '40%')};

    @media ${device.laptopL} {
      width: ${({hasButtons}) => (hasButtons ? '40%' : '50%')};
    }

    @media ${device.laptop} {
      width: ${({hasButtons}) => (hasButtons ? '40%' : '60%')};
    }

    @media ${device.tablet} {
      width: 60%;
    }

    @media ${customDevice(620)} {
      width: 70%;
    }
  }

  button:not(:last-child) {
    margin-right: 50px;

    @media ${device.tablet} {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media ${device.laptop} {
    bottom: 5%;
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    bottom: 0;
  }
`;

export {ButtonsContainer};
