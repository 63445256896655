import React, {memo, useCallback, useEffect, useState} from 'react';

import {Base, TabContainer} from './styles';
import {filterRepeatedLinks, searchIndex} from './bl'

import Header from './Header';
import Attachments from './Attachments';
import AttachInputs from './AttachInputs';
import AttachHyperlink from './AttachHyperlink';
import ComposerDialog from './Composer/ComposerDialog'
import ComposerOpenAiContent from './Composer/ComposerOpenAiContent'
import TopicForm from "../MemberHomepage/Definitions/AnalysisFlow/Topics/TopicSubmission/TopicDefinition/TopicForm";

let attachmentId = 0;

const Create = ({
                  TextTab = TopicForm,
                    // setNewHTMLContent = (v) => null,
                    setNewHTMLContent ,
                    htmlContent,
                  FilesTab = AttachInputs,
                  HyperLinkTab = AttachHyperlink,
                  ComposerTab = ComposerDialog,
                  // setAssetsBlockData = () => null,
                  setAssetsBlockData ,
                  currentAttachments = [],
                  changePrompt
                }) => {
  const [currentTab, setCurrentTab] = useState('text');

  const [attachments, setAttachments] = useState([]);

  const [errorFileAttachment, setErrorFileAttachment] = useState('');

  const [linkAttachment, setLinkAttachment] = useState('');
  const [errorLinkAttachment, setErrorLinkAttachment] = useState('');

  useEffect(() => {
    if (currentAttachments.length >= 0) {
      setAttachments(currentAttachments);
    }
  }, [currentAttachments]);

  useEffect(() => {
    attachmentId = 0;
  }, []);

  useEffect(() => {
    setAssetsBlockData(attachments);
  }, [attachments]);

  const addNewAttachment = useCallback(
    (type, src, fileName, fileToUpload) => {
      if (attachments.length <= 8 && type !== 'image' && type !== 'video') {
        attachmentId++;

        setAttachments(prevAttachments =>
          filterRepeatedLinks(
            prevAttachments.concat({
              id: attachmentId,
              type,
              src,
              fileName,
            }),
          ),
        );
      } else {
        attachmentId++;

        setAttachments(prevAttachments =>
          prevAttachments.concat({
            id: attachmentId,
            type,
            src,
            fileName,
            fileToUpload,
          }),
        );
      }
    },
    [attachments],
  );

  const removeAttachment = useCallback((id, type, src) => {
    setAttachments(prevAttachments =>
      prevAttachments.filter(attachment => attachment.id !== id),
    );

    if (type === 'video') {
      URL.revokeObjectURL(src);
    }
  }, []);

  // ComposerDialog settings
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  // skeleton settings
  const [isOpenAi, setIsOpenAi] = React.useState(false);
  const [aiIndex, setAiIndex] = React.useState();
  const [aiCount, setAiCount] = React.useState();

  // when change currentTab handling component dialog
  useEffect(() => {
    if (currentTab === 'composer') {
      setOpen(true)
    } else {
      setOpen(false);
      setIsOpenAi(false);
    }
  }, [currentTab])

  const handleClose = (newValue, n, cnt) => {
    setOpen(false);
    if (newValue) {
      setValue(newValue);
      if (newValue.includes('ChatGPT')) {
        setIsOpenAi(true);
        setAiIndex(n);
        setAiCount(cnt + 1);
      } else {
        setIsOpenAi(false);
      }
    }
  };

  return (
    <Base>
      <Header currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <TabContainer>
        <TextTab
        hidden={currentTab !== 'text'}
        // setNewHTMLContent={(v) => setNewHTMLContent(v)}
        setNewHTMLContent={setNewHTMLContent}
        htmlContent={htmlContent}
        />
        <FilesTab
          hidden={currentTab !== 'files'}
          addNewAttachment={addNewAttachment}
          errorFile={errorFileAttachment}
          setErrorFile={setErrorFileAttachment}
        />
        <HyperLinkTab
          hidden={currentTab !== 'hyperlink'}
          errorLink={errorLinkAttachment}
          setErrorLink={setErrorLinkAttachment}
          setLink={setLinkAttachment}
          link={linkAttachment}
          addNewAttachment={addNewAttachment}
        />
        <ComposerTab
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
        />

        { isOpenAi? (
          <>
            <ComposerOpenAiContent prompt_count = {aiCount} ai_engine_index = {aiIndex} changePrompt = {changePrompt}/>
            {/*<h1></h1>*/}
          </>
        ) : (
          <></>
        )
        }

      </TabContainer>
      <Attachments
        attachments={attachments}
        removeAttachment={removeAttachment}
      />
    </Base>
  );
};

export default memo(Create);
