import AnalysisFlow from './AnalysisFlow';

// export const definitionPages = {
//   post: () => null,
//   analysis: AnalysisFlow,
//   messages: () => null,
//   subject: EntityFlow,
//   category: CategoryFlow,
//   model: () => null,
// };

export const definitionPages = {
  post: () => null,
  analysis: AnalysisFlow,
  messages: () => null,
  subject: () => null,
  category: () => null,
  model: () => null,
};

export const regularPages = [
  {path: 'messages', label: 'Messages'},
  {path: 'post', label: 'Posts'},
  {path: 'analysis', label: 'Analysis'},
];

export const proPages = [
  {path: 'subject', label: 'Subject'},
  {path: 'category', label: 'Category'},
  {path: 'model', label: 'Model'},
];
