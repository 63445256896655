import styled from 'styled-components';

const VideoPlayer = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: auto;
`;

export {VideoPlayer};
