import React, {useState} from 'react';

import {footerItems, privateFooterItems} from './info';
import GGDemoModal from '../../Header/GGDemoModal';
import GGModal from '../../../Modal';
import HistoryService from '../../../../utils/HistoryService';
import Constants from '../../../../Constants/Constants';
import {getItem, getUserToken} from '../../../../utils/HelperService';
import ChannelService from '../../../../utils/ChannelService';
import {useLocation} from 'react-router-dom';

const FooterItems = ({variant}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const channelInfo = getItem(Constants.AS_CHANNEL_INFO);

  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);

  // const footItemsRequired =
  //   searchQuery.get(Constants.SQ_FOOTER_LINKS) !== 'false' &&
  //   getUserToken() != null;
  let footItemsRequired = true;

  if (location.pathname === '/home' && getUserToken() == null) {
    footItemsRequired = false;
  }

  const handleClickSignUp = () =>
    HistoryService.push({
      pathname: '/signup',
      state: {
        isDana:
          variant === Constants.DANA_BARROS_BASKETBALL ||
          variant === Constants.DANA_BARROS_BASKETBALL_PODCASTS,
        variant,
      },
    });

  const footerComponents = (
    ChannelService.isPrivateChannel(channelInfo)
      ? privateFooterItems
      : footerItems
  ).map(({label, action}) => (
    <div key={label} className={'flex flex-1 items-center justify-center'}>
      <span
        className={
          'font-semibold text-sm mx-10 cursor-pointer transform transition duration-300 ease-out hover:scale-105 text-center'
        }
        onClick={() => {
          if (label === 'Demo') {
            setModalVisible(true);
          } else {
            action();
          }
        }}>
        {label}
      </span>
    </div>
  ));

  return (
    <div className={'flex flex-1 justify-center items-center'}>
      {footItemsRequired && (
        <div
          className={
            ChannelService.isPrivateChannel(channelInfo)
              ? 'flex flex-row'
              : 'grid grid-rows-2 sm:grid-rows-2 grid-flow-col gap-1'
          }>
          {footerComponents}
        </div>
      )}
      <GGModal
        modalVisible={modalVisible}
        handleCloseModal={() => setModalVisible(false)}
        renderContent={() => (
          <GGDemoModal
            handleClickSignUp={() => handleClickSignUp()}
            handleCloseModal={() => setModalVisible(false)}
          />
        )}
      />
    </div>
  );
};

export default FooterItems;
