import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  margin-bottom: 30px;

  width: 85%;

  @media ${device.mobileS} {
    flex-direction: column;
  }

  @media ${customDevice(620)} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .activeSection {
    opacity: 1;
  }
`;

const HeaderSection = styled.div`
  width: 45%;
  background-color: ${({mode}) => (mode === 'regular' ? '#2CA3FF' : '#2E358B')};
  border-radius: 30px;

  display: flex;
  justify-content: space-between;
  padding: 10px 8%;

  @media ${device.laptop} {
    padding: 10px 5%;
    width: 47%;
  }

  @media ${device.tablet} {
    width: 48%;
    padding: 10px 4%;
  }

  @media ${customDevice(620)} {
    width: 80%;
    padding: 10px 8%;
  }

  @media ${device.mobileL} {
    width: 100%;
  }

  &:first-child {
    @media ${customDevice(620)} {
      margin-bottom: 10px;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  opacity: 0.6;
  font-size: 18px;
  letter-spacing: 1.3px;
  font-weight: 600;

  @media ${device.laptop} {
    font-size: 16px;
  }

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 13px;
  }
`;

export {Base, HeaderSection, StyledNavLink};
