import React, {memo, useEffect, useRef, useState} from 'react';

import {Arrow, Base, ErrorContainer, Fade, Wrapper} from './styles';

import {
  getHorizontalScrollPercentage,
  getVerticalScrollPercentage,
  isOverflown,
} from 'Helpers/dom';

const Container = ({
  children,
  width,
  tabsInfo,
  emptyMessage,
  vertical,
  verticalBreakpoint = '0',
  baseStyles = '',
}) => {
  const [scrollNumber, setScrollNumber] = useState(0);
  const [gotToScrollLimit, setGotToScrollLimit] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  const scrollerRef = useRef(null);

  const verticalEndpoint = window.matchMedia(
    `(max-width: ${verticalBreakpoint}px)`,
  );

  useEffect(() => {
    if (vertical && verticalEndpoint.matches) {
      scrollerRef.current.scrollTop = scrollNumber;
    } else {
      scrollerRef.current.scrollLeft = scrollNumber;
    }

    setGotToScrollLimit(
      vertical && verticalEndpoint.matches
        ? getVerticalScrollPercentage(scrollerRef.current) >= 99
        : getHorizontalScrollPercentage(scrollerRef.current) >= 99,
    );
  }, [
    scrollNumber,
    setGotToScrollLimit,
    setHasOverflow,
    vertical,
    verticalEndpoint.matches,
  ]);

  useEffect(() => {
    setHasOverflow(isOverflown(scrollerRef.current));
  }, [tabsInfo]);

  const clickLeftArrow = () => {
    setScrollNumber(prevScrollNumber => prevScrollNumber - 120);
  };

  const clickRightArrow = () => {
    setScrollNumber(prevScrollNumber => prevScrollNumber + 120);
  };

  const hasInfo = tabsInfo?.length > 0;

  return (
    <Wrapper width={width} verticalBreakpoint={verticalBreakpoint}>
      <Fade
        directionHorizontal="left"
        directionVertical="up"
        vertical={vertical}
        verticalBreakpoint={verticalBreakpoint}
        hide={!hasInfo || scrollNumber === 0 || !hasOverflow}
      />
      <Arrow
        hide={!hasInfo || scrollNumber === 0 || !hasOverflow}
        directionHorizontal="left"
        directionVertical="up"
        vertical={vertical}
        verticalBreakpoint={verticalBreakpoint}
        onClick={clickLeftArrow}
      />
      <Base
        ref={scrollerRef}
        baseStyles={baseStyles}
        hasOverflow={hasOverflow}
        verticalBreakpoint={verticalBreakpoint}>
        {hasInfo ? children : <ErrorContainer>{emptyMessage}</ErrorContainer>}
      </Base>
      <Arrow
        hide={!hasInfo || gotToScrollLimit || !hasOverflow}
        directionHorizontal="right"
        directionVertical="down"
        vertical={vertical}
        verticalBreakpoint={verticalBreakpoint}
        onClick={clickRightArrow}
      />
      <Fade
        directionHorizontal="left"
        directionVertical="down"
        vertical={vertical}
        verticalBreakpoint={verticalBreakpoint}
        hide={gotToScrollLimit || !hasOverflow}
      />
    </Wrapper>
  );
};

export default memo(Container);
