const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobile: '640px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const device = {
  mobileS: `only screen and (max-width: ${size.mobileS})`,
  mobileM: `only screen and (max-width: ${size.mobileM})`,
  mobileL: `only screen and (max-width: ${size.mobileL})`,
  mobile: `only screen and (max-width: ${size.mobile})`,
  tablet: `only screen and (max-width: ${size.tablet})`,
  laptop: `only screen and (max-width: ${size.laptop})`,
  laptopL: `only screen and (max-width: ${size.laptopL})`,
  desktop: `only screen and (max-width: ${size.desktop})`,
  desktopL: `only screen and (min-width: ${size.desktop})`,
};

export const customDevice = breakpoint =>
  `only screen and (max-width: ${breakpoint}px)`;

export default device;
