import React from 'react';

import {Base, LabelButton} from './styles';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const ShowMoreCommentsButton = ({leftReplies, handleClick}) => (
  <Base onClick={handleClick}>
    <IconButton>
      <AddIcon />
    </IconButton>

    <LabelButton>View {leftReplies} replies</LabelButton>
  </Base>
);

export default ShowMoreCommentsButton;
