import moment from 'moment';

function getLocalDate(date) {
  const format = 'dddd, MMMM Do YYYY';
  const dateStr = date.format(format);
  if (dateStr === moment(new Date()).format(format)) {
    return 'Today';
  }
  if (dateStr === moment(new Date() - 86400000).format(format)) {
    return 'Yesterday';
  }

  return dateStr;
}

function getChatTime(dateString) {
  return moment.utc(dateString).local().format('LT');
}

function getChatDate(dateString) {
  const gmtDate = moment.utc(dateString).local();
  return getLocalDate(gmtDate);
}

function isToday(date) {
  const format = 'dddd, MMMM Do YYYY';
  const dateStr = moment(date).format(format);
  return dateStr === moment(new Date()).format(format);
}

function isSameDate(date1, date2) {
  const format = 'dddd, MMMM Do YYYY';
  return moment(date1).format(format) === moment(date2).format(format);
}

export default {
  getChatTime,
  getChatDate,
  isToday,
  isSameDate,
};
