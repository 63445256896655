import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  .RemoveButton {
    width: 1.6875rem;
    height: 1.6875rem;
    background-color: #232324;
    border-radius: 50%;
    display: none;

    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    right: -0.625rem;
    cursor: pointer;

    transition: all 0.3s;
    z-index: 10;

    svg {
      fill: #fff;
      width: 1.25rem;
      height: auto;
    }

    &:hover {
      background-color: #2d2d2e;
    }

    &:active {
      background-color: #2f2f30;
    }
  }

  &:hover .RemoveButton {
    display: flex;
  }
`;

const Base = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 0.625rem;
  margin-right: ${({marginRight}) => marginRight};
  min-width: 15.625rem;
  max-width: 15.625rem;
  height: 4.5rem;
`;

export {Wrapper, Base};
