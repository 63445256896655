import {ApiClient} from './base';
import Constants from '../Constants/Constants';
import {getItem} from '../utils/HelperService';

const dbs = {
  [Constants.GIGA_INTELLIGENCE]: {collage_id: 1, image_id: 2},
  [Constants.DANA_BARROS_BASKETBALL]: {collage_id: 5, image_id: 3},
  [Constants.DANA_BARROS_BASKETBALL_PODCASTS]: {collage_id: 6, image_id: 3},
};

class InitFormService {
  static postEmail(body, variant = Constants.GIGA_INTELLIGENCE) {
    let dbInfo = dbs[variant];

    if (dbInfo == null) {
      dbInfo = getItem(Constants.AS_CHANNEL_ID);
    }

    body.append('collage_id', dbInfo.collage_id);
    body.append('image_id', dbInfo.image_id);

    return ApiClient({
      method: 'post',
      url: '/react_gi_v1_signup.php',
      data: body,
    });
  }

  static getChannelToken(variant) {
    const bodyRequest = new FormData();
    bodyRequest.append('channel_name', variant ?? Constants.GIGA_INTELLIGENCE);
    return ApiClient({
      method: 'post',
      url: '/react_gi_v1_get_channel_token.php',
      data: bodyRequest,
    });
  }
}

export default InitFormService;
