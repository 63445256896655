import React from 'react';

import {Base, PhaseContainer, TitleFormContainer} from './styles';

import Input from 'Components/DesignSystem/inputs/base-input';

const InputContainer = ({
  namePhase,
  titleForm,
  inputLabel,
  setInputValue,
  placeholder,
  inputValue,
  errorStatus,
  inputComponent,
  headerHeight,
}) => (
  <Base headerHeight={headerHeight}>
    <PhaseContainer>{namePhase}</PhaseContainer>
    <TitleFormContainer>{titleForm}</TitleFormContainer>
    {inputComponent ? (
      inputComponent
    ) : (
      <Input
        statusError={errorStatus}
        value={inputValue}
        onChange={setInputValue}
        label={inputLabel}
        placeholder={placeholder}
      />
    )}
  </Base>
);

export default InputContainer;
