import React, {memo, useEffect, useRef, useState} from 'react';
import {Base} from './styles';
import MemberHomepageService from '../../../Services/memberHomepage';
import Button from '../../DesignSystem/button/button';

import GigaAlert from '../../UI/GigaAlert';
import {useStyles} from '../Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import {InputBase, MenuItem, Select} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {InputAdornment, ListSubheader, TextField} from '@mui/material';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ManageCategorySteps from './ManageCategorySteps';
import {
  getMCLSteps,
  popMCLSteps,
  setMCLSteps,
} from '../../../utils/HelperService';
import HomeSteps from '../Home/HomeSteps';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {connect} from 'react-redux';
import HistoryService from '../../../utils/HistoryService';
import {useParams} from 'react-router-dom';
import {json2csv} from 'json-2-csv';
import {AiOutlineEdit, AiOutlineCheck} from 'react-icons/ai';

function ImportCategoryCSV(props) {
  const classes = useStyles();
  let inputElement = useRef(null);
  let {parentCategoryId} = useParams();

  const importKey = props.location?.state?.importKey;
  const isCategory = importKey === 'category';

  const [ratings, setRatings] = useState([]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [alertInfo, setAlertInfo] = React.useState({open: false});

  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [file, setFile] = useState();
  const fileReader = new FileReader();

  useEffect(() => {
    const steps = getMCLSteps();
    if (steps.length > 1) {
      setMCLSteps(steps.slice(0, 1));
    }
    if (isCategory) {
      fetchRatings();
    }
  }, []);

  useEffect(() => {
    console.log(file);
    if (file != null) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  }, [file]);

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split('|');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map(i => {
      const values = i.split('|');
      const obj = csvHeader.reduce((object, header, index) => {
        object[
          header.replace(/( )/g, '').replace(/(")/g, '').replace(/(\r)/g, '')
        ] = values[index];
        return object;
      }, {});
      return obj;
    });

    let newItems;

    if (isCategory) {
      newItems = array
        .filter(v => v['CategoryName'] != null && v['CategoryName'] !== '')
        .map((v, i) => {
          return {
            id: i,
            name: v['CategoryName'].replace(/(")/g, ''),
            description: v['CategoryDescription'].replace(/(")/g, ''),
            image_url: v['URLofCategoryImage'].replace(/(")/g, ''),
            rating: (v['CategoryRatingID'] ?? '')
              .replace('\r', '')
              .replace(/(")/g, ''),
            isEditing: false,
          };
        });
    } else {
      newItems = array
        .filter(v => v['SubjectName'] != null && v['SubjectName'] !== '')
        .map((v, i) => {
          return {
            id: i,
            name: v['SubjectName'].replace(/(")/g, ''),
            description: v['SubjectDescription'].replace(/(")/g, ''),
            image_url: v['URLofSubjectImage'].replace(/(")/g, ''),
            isEditing: false,
          };
        });
    }
    setItems(newItems);
    if (newItems.length === 0) {
      setAlertInfo({
        open: true,
        message: `Upload a proper CSV, we can't import ${
          isCategory ? 'categories' : 'subjects'
        } from ${file.name}`,
        severity: 'warning',
      });
    }
  };

  const fetchRatings = (rId = -1) => {
    MemberHomepageService.getCategoryRatings(-1).then(res => {
      setRatings(res.data.category_ratings);
    });
  };

  const handleSubmit = () => {
    if (items.length === 0) {
      return;
    }

    json2csv(
      items.map(v => {
        const {id, ...other} = v;
        return other;
      }),
      (err, data) => {
        console.log(err, data);
        if (err == null) {
          let aFile = new File([data], '1.csv', {
            type: 'text/csv',
          });

          MemberHomepageService.createCategoriesWithCSV(
            parentCategoryId,
            aFile,
            isCategory
              ? '/react_gi_v1_category_csv_fileupload.php'
              : '/react_gi_v1_subject_csv_fileupload.php',
          )
            .then(res => {
              console.log(res);
              setAlertInfo({
                open: true,
                message: `Submitted successfully!`,
              });
              setTimeout(() => {
                popMCLSteps();
                HistoryService.goBack();
              }, 1000);
            })
            .catch(error => {
              console.log(error);
            });
        }
      },
      {delimiter: {field: '|'}},
    );
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          {items.length === 0 ? 'Import' : 'Imported'} New{' '}
          {isCategory ? 'Categories' : 'Subjects'} from CSV
        </span>
      </Base>
      <Base className={'flex flex-row px-4 md:px-0'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={props.manageSteps}
          handleClickStep={() => {}}
        />
      </Base>
      <Base className={'flex flex-row px-4 md:px-0'}>
        <ManageCategorySteps />
      </Base>
      <Base className={'flex flex-row justify-end px-4 md:px-0'}>
        <input
          type={'file'}
          accept={'.csv'}
          className={'hidden'}
          ref={input => (inputElement = input)}
          onChange={e => {
            setFile(e.target.files[0]);
          }}
        />
        <a
          href={isCategory ? '/category.csv' : '/subject.csv'}
          download
          // onClick={e => {
          //   e.preventDefault();
          // }}
          className="bg-giga-secondary-orange_200 hover:opacity-70 text-white py-2 px-4 rounded">
          Download sample CSV
        </a>
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            inputElement.click();
          }}
          className="bg-giga-primary-blue_300 hover:opacity-70 text-white py-2 px-4 rounded ml-4">
          Upload CSV
        </a>
      </Base>
      <Base loading={false} error={false}>
        {items.length === 0 ? (
          <div className={'flex flex-col my-8'}>
            <span className={'self-center text-base font-medium text-center px-4'}>
              You can download the sample csv to check our csv style and then
              upload a proper csv to import{' '}
              {isCategory ? 'categories' : 'subjects'}.
            </span>
          </div>
        ) : (
          <div className="mt-1 mb-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table
                  className="w-full divide-y divide-gray-200"
                  style={{minWidth: 1044}}>
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Image
                      </th>
                      {isCategory && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Rating
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      const categoryName = item.name ?? '';
                      const categoryDescription = item.description ?? '';
                      const categoryImage = item.image_url ?? '';
                      const categoryRating = item.rating ?? '';
                      return (
                        <tr
                          key={`${index}`}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500`}>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={categoryName}
                                onChange={e =>
                                  setItems(
                                    items.map(v =>
                                      v.id === item.id
                                        ? {...item, name: e.target.value}
                                        : v,
                                    ),
                                  )
                                }
                                className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            <input
                              type="text"
                              name="description"
                              id="description"
                              value={categoryDescription}
                              onChange={e =>
                                setItems(
                                  items.map(v =>
                                    v.id === item.id
                                      ? {...item, description: e.target.value}
                                      : v,
                                  ),
                                )
                              }
                              className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                            />
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {/*<a*/}
                            {/*  href={categoryImage}*/}
                            {/*  target="_blank"*/}
                            {/*  rel="noopener noreferrer"*/}
                            {/*  className="text-blue-600 underline">*/}
                            {/*  {categoryImage}*/}
                            {/*</a>*/}
                            {item.isEditing ? (
                              <div className={'flex flex-row items-center'}>
                                <input
                                  type="text"
                                  name="item-image"
                                  id="item-image"
                                  value={categoryImage}
                                  onChange={e =>
                                    setItems(
                                      items.map(v =>
                                        v.id === item.id
                                          ? {...item, image_url: e.target.value}
                                          : v,
                                      ),
                                    )
                                  }
                                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                                />
                                <AiOutlineCheck
                                  className={'ml-1 w-6 h-6 text-giga-main'}
                                  onClick={() =>
                                    setItems(
                                      items.map(v =>
                                        v.id === item.id
                                          ? {
                                              ...item,
                                              isEditing: !item.isEditing,
                                            }
                                          : v,
                                      ),
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <div className={'flex flex-row items-center'}>
                                <img
                                  alt={'img'}
                                  src={categoryImage}
                                  className={'w-20 h-20 object-contain'}
                                />
                                <AiOutlineEdit
                                  className={'ml-1 w-6 h-6 text-giga-main'}
                                  onClick={() =>
                                    setItems(
                                      items.map(v =>
                                        v.id === item.id
                                          ? {
                                              ...item,
                                              isEditing: !item.isEditing,
                                            }
                                          : v,
                                      ),
                                    )
                                  }
                                />
                              </div>
                            )}
                          </td>
                          {isCategory && (
                            <td className="px-6 py-4 text-sm text-gray-500">
                              <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col">
                                <div
                                  className={
                                    'border rounded-lg p-1 mx-0.5 bg-white'
                                  }>
                                  <Select
                                    displayEmpty
                                    MenuProps={{autoFocus: false}}
                                    classes={{root: classes.root}}
                                    input={
                                      <InputBase
                                        classes={{root: classes.inputRoot}}
                                      />
                                    }
                                    labelId="select-rating"
                                    id="select-rating"
                                    value={categoryRating ?? ''}
                                    onChange={e =>
                                      setItems(
                                        items.map(v =>
                                          v.id === item.id
                                            ? {...item, rating: e.target.value}
                                            : v,
                                        ),
                                      )
                                    }
                                    defaultValue={categoryRating ?? ''}
                                    IconComponent={ExpandMoreIcon}
                                    onClose={() => setSearchText('')}>
                                    <ListSubheader style={{width: '100%'}}>
                                      <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Type to search..."
                                        fullWidth
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <SearchIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={e =>
                                          setSearchText(e.target.value)
                                        }
                                        onKeyDown={e => {
                                          if (e.key !== 'Escape') {
                                            // Prevents autoselecting item while typing (default Select behaviour)
                                            e.stopPropagation();
                                          }
                                        }}
                                      />
                                    </ListSubheader>
                                    {ratings
                                      .filter(
                                        v =>
                                          (v.category_rating_definition ?? '')
                                            .toLowerCase()
                                            .includes(
                                              searchText.toLowerCase(),
                                            ) ||
                                          (v.category_rating_description ?? '')
                                            .toLowerCase()
                                            .includes(searchText.toLowerCase()),
                                      )
                                      .map((item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.category_rating_id}
                                          classes={{gutters: classes.menuList}}>
                                          {item.category_rating_definition}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div
          className={
            'flex flex-col lg:flex-row justify-center mt-12 mb-12 lg:space-x-12'
          }>
          <div className={'mcl-create-new'}>
            <Button
              loading={loadingSubmit}
              onClick={() => handleSubmit()}
              bottomGap>
              Submit
            </Button>
          </div>
        </div>
        <GigaAlert
          alertInfo={alertInfo}
          handleClose={() => setAlertInfo({open: false})}
        />
      </Base>
    </>
  );
}

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  manageSteps: state.UserData.manageSteps,
  manageCurrentCategory: state.UserData.manageCurrentCategory,
  searchString: state.UserData.searchString,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateDisplayCategories: categories => {
      dispatch(userActions.updateDisplayCategories(categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
    onResetUser: () => dispatch(resetUser()),
    updateManageCurrentCategory: c =>
      dispatch(userActions.updateManageCurrentCategory(c)),
    updateManageSteps: steps => dispatch(userActions.updateManageSteps(steps)),

    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(ImportCategoryCSV));
