import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {getComponentBasedOnFlow} from './bl';

const Flow = ({nameFlow, arrFlow, index = 0}) => {
  const [indexFlow, setIndexFlow] = useState(0);

  useEffect(() => {
    setIndexFlow(index);
  }, [index]);

  const advanceIndexPhase = useCallback(() => {
    if (indexFlow + 1 !== arrFlow.length) {
      setIndexFlow(prevIndex => prevIndex + 1);
    }
  }, [arrFlow, indexFlow]);

  const convertedFlow = useMemo(
    () =>
      arrFlow.map(phaseFlow =>
        getComponentBasedOnFlow(nameFlow, advanceIndexPhase, phaseFlow),
      ),
    [arrFlow, nameFlow, advanceIndexPhase],
  );

  return convertedFlow[indexFlow];
};

export default Flow;
