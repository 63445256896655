import React from 'react';
import {withRouter} from 'react-router-dom';

import LandingButton from '../LandingButton';

const SignUpButton = ({history, isDana, variant}) => (
  <LandingButton
    onClick={() =>
      history.push({
        pathname: '/signup',
        state: {
          isDana,
          variant,
        },
      })
    }
    bgCol="rgba(0,0,0, 0.3)">
    Sign Up
  </LandingButton>
);

export default withRouter(SignUpButton);
