import styled from 'styled-components';

import device from 'mediaQueries';

import ServerContent from 'hoc/ServerContent';

const Base = styled(ServerContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;

  @media ${device.desktopL} {
    width: 85%;
  }

  @media ${device.laptop} {
    width: 75%;
  }

  @media ${device.mobileM} {
    width: 85%;
  }

  @media ${device.mobileS} {
    width: 95%;
  }
`;

export {Base};
