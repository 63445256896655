import React, {useEffect, useState} from 'react';

import {AnswerContainer, Base, RatingBlock, TitleContainer} from './styles';

import {
  areThereSavedAttachments,
  formatSavedAttachments,
} from 'Components/MemberHomepage/Helpers/attachments';

import ShowHTMLString from 'hoc/ShowHTMLString';

import Separator from '../separator';

import Attachments from 'Components/Create/Attachments';
import {getUserMode} from '../../../../../../utils/HelperService';

const AssesmentTopic = ({
  hasSeparator,
  topic,
  rating,
  narrative,
  attachments,
}) => {
  const [topicAttachments, setTopicAttachments] = useState([]);

  useEffect(() => {
    if (areThereSavedAttachments(attachments)) {
      formatSavedAttachments(attachments, result =>
        setTopicAttachments(result),
      );
    }
  }, [attachments]);

  console.log('AssessmentTopic-narrative', narrative);

  if (
    !narrative ||
    narrative === '<p></p>\n' ||
    narrative === '<p><br></p>\n'
  ) {
    return null;
  }

  const {mode_name: mode} = getUserMode();

  const isPrefNeeded = mode === 'assessment';

  console.log('AssessmentTopic-rating', rating);

  return (
    <Base>
      <TitleContainer>
        {isPrefNeeded ? topic : ''}{' '}
        {rating !== '' && (
          <>
            {isPrefNeeded ? '- ' : ''}
            <RatingBlock>{rating}</RatingBlock>
          </>
        )}
      </TitleContainer>

      <AnswerContainer>
        {narrative ? (
          <ShowHTMLString htmlString={narrative} />
        ) : (
          // <ErrorText>No answer provided.</ErrorText>
          <div />
        )}
      </AnswerContainer>

      {areThereSavedAttachments(topicAttachments) &&
        topicAttachments.length > 0 && (
          <Attachments attachments={topicAttachments} hideRemove />
        )}

      {hasSeparator && <Separator />}
    </Base>
  );
};

export default AssesmentTopic;
