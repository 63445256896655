import React from 'react';

import {Base} from './styles';

import EntityPreview from './EntityPreview';

const AssesmentSummary = ({
  assesmentName,
  assesmentImage,
  assessmentDescription,
}) => (
  <Base>
    <EntityPreview
      title={assesmentName}
      portraitUrl={assesmentImage}
      description={assessmentDescription}
    />
  </Base>
);

export default AssesmentSummary;
