import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 28.75rem;
  align-self: center;

  @media (min-width: 1024px) {
    align-self: flex-start;
  }

  //align-items: center;
`;
export {Base};
