import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  align-items: center;

  padding: 0 1rem 0.5rem 1rem;
`;

const PicContainer = styled.div`
  width: 25px;
  height: 25px;
  background-color: ##989898;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 7px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NameContainer = styled.div`
  color: #989898;
  font-weight: bold;
  font-size: 11px;
  margin-right: 17px;
  position: relative;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #2ba2fe;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
  }
`;

const DateContainer = styled.div`
  font-size: 9px;
  color: #989898;
  margin-top: 3px;
`;

export {Base, PicContainer, NameContainer, DateContainer};
