import styled from 'styled-components';

import groupGradient from 'assets/img/landing/groupGradient.png';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 500px;
  max-height: 1000px;
  background-image: url(${({bgImage}) => bgImage ?? groupGradient});
  background-color: rgba(0, 0, 0, 0.8);
  background-position: top;
  background-repeat: repeat-x;
  padding: 120px 80px;
  
  display: flex;
  align-items: flex-end;

  @media ${customDevice(1200)} {
    padding: 120px 90px;
  }

  @media ${customDevice(840)} {
    padding: 90px 60px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    align-items: center;
  }
`;

const HeroText = styled.div`
  color: #fff;
  font-size: ${({size}) => `${size}rem`};
  font-weight: 600;
  margin-bottom: 20px;

  @media ${device.mobile} {
    font-size: ${({size}) => `${Number(size) * 0.6}rem`};
  }

  ${({css = ''}) => css}
`;

export {Base, TextContainer, HeroText};
