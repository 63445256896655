import React from 'react';

import Hero from '../MainPage/Hero';
import {HeroText} from '../MainPage/Hero/styles';
import LandingSection from '../UI/LandingSection';
import GetStartedBlock from '../MainPage/GetStartedBlock';
import SectionWrapper from '../UI/SectionWrapper';
import Constants from '../../../Constants/Constants';
import {connect} from 'react-redux';
import './styles.scss';

const GigaCommon = ({
  history,
  variant = Constants.GIGA_INTELLIGENCE,
  channel,
}) => {
  return (
    <>
      <Hero
        bgImage={channel.bgImage}
        history={history}
        isDana={false}
        variant={variant}
        renderTitle={() => (
          <HeroText size={2.375} style={{textAlign: 'left'}}>
            <span>{'Why join Giga'}</span>
            <span
              className={'gradient-common-title'}
              style={{
                backgroundColor: channel.mainColor,
                backgroundImage: `linear-gradient(180deg, #ffffff, ${channel.mainColor})`,
              }}>
              {' '}
              {channel.contents.subTitle}
            </span>
          </HeroText>
        )}
        description={
          'Giga is a place where all humans can learn & understand in privacy.'
        }
      />
      {channel.contents.blocks.length > 0 && (
        <SectionWrapper>
          {channel.contents.blocks.map((b, index) => {
            const {type} = b;
            if (type === 0) {
              return (
                <LandingSection
                  key={`${index}`}
                  color={channel.mainColor}
                  title={b.title}
                  cardHeight={b.cardHeight}
                  cardData={b.cardData}
                />
              );
            } else {
              return (
                <GetStartedBlock
                  key={`${index}`}
                  title={b.title}
                  srcImage={b.srcImage}
                  bgCol={`linear-gradient(${channel.mainColor} 0%, #424dd1 100%)`}
                  isDana={false}
                  variant={variant}
                />
              );
            }
          })}

          {/*<LandingSection*/}
          {/*  color={'#BC4A27'}*/}
          {/*  title="Giga guides"*/}
          {/*  cardHeight={220}*/}
          {/*  cardData={gigaMediaGuideCards}*/}
          {/*/>*/}
          {/*<LandingSection*/}
          {/*  title="Recently Made"*/}
          {/*  cardHeight={220}*/}
          {/*  cardData={recentMediaCards}*/}
          {/*  color={'#BC4A27'}*/}
          {/*/>*/}
          {/*<LearnMoreBlock history={history} color={'#BC4A27'} description={true} />*/}
        </SectionWrapper>
      )}
    </>
  );
};

const mapStateToProps = ({UserData: {channel}}) => ({
  channel,
});

export default connect(mapStateToProps, null)(GigaCommon);
