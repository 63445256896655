import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  //width: 100%;

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .Icon {
    fill: #7a8fa6;
  }
`;

const TitleContainer = styled.span`
  font-size: 15px;
  font-weight: 600;
  transition: all 0.3s;

  @media ${device.laptopL} {
    font-size: 11px;
  }
`;

const TitleDescription = styled.span`
  font-size: 13px;
  font-weight: 500;
  transition: all 0.3s;
  width: 100%;
  text-align: center;

  @media ${device.laptopL} {
    font-size: 10px;
  }
`;

export {Base, TitleContainer, TitleDescription};
