import styled from 'styled-components';
import img from 'assets/general/landing-button-blur.png';
import device from '../../../../mediaQueries';

const Button = styled.button`
  width: 12vw;
  min-width: 11.875rem;
  height: 2.5rem;
  backdrop-filter: blur(5px);
  background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  background-color: ${({bgCol}) => bgCol};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 1s ease;

  @media ${device.mobile} {
    min-width: 8rem;
    font-size: 0.5rem;
    height: 1.6rem;
  }

  &:hover {
    background-color: #3393dd;

    cursor: pointer;
  }

  ${({css = ''}) => css}
`;

export default Button;
