import {styled} from '@material-ui/styles';
import {Switch} from '@material-ui/core';
import point from '../../../assets/img/ui/point.png';

const GigaSwitch = styled(Switch)(({theme}) => ({
  width: 62,
  height: 32,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(24px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url(${point})`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,255,255,0.71)'
            : 'rgba(255,255,255,0.71)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
    width: 30,
    height: 30,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${point})`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.71)'
        : 'rgba(255,255,255,0.71)',
    borderRadius: 20 / 2,
  },
}));

export default GigaSwitch;
