import {ApiClient} from './base'

import {formatHiddenProps, generateFormOnObject} from 'Helpers/api'
import {
    getCategoryMode,
    getEntityParams, getHiddenItems,
    getUserMode,
    getUserToken,
} from '../utils/HelperService'
import {useParams} from 'react-router-dom'
import moment from 'moment'

class PaymentService {
    static getSubscriptionInfo(subscription_id) {
        return ApiClient({
            method: 'get',
            url: `/react_gi_v1_process_member_subscription.php?subscription_id=${subscription_id}`,
        })
    }
}

export default PaymentService