import {supportedMimeTypes} from './info';

export const getFileExtension = url =>
  url.split(/[#?]/)[0].split('.').pop().trim();

export const getFileType = mimeType => {
  const result = supportedMimeTypes.find(
    supportedMimeType => supportedMimeType === mimeType,
  );
  const mimeCategory = result?.split('/')[0];
  const type =
    mimeCategory === 'application' || mimeCategory === 'text'
      ? 'file'
      : mimeCategory;

  return result ? type : null;
};

export const isValidHTTPSUrl = strUrl => {
  let url;

  try {
    url = new URL(strUrl);
  } catch (_) {
    return false;
  }

  return url.protocol === 'https:';
};

export const createBlobURL = file => {
  const blob = new Blob([file], {type: 'audio/mp3'});

  return window.URL.createObjectURL(blob);
};

export const uploadFileReader = (fileToUpload, cb) => {
  const reader = new FileReader();

  reader.onloadend = function () {
    cb(reader.result);
  };

  reader.readAsDataURL(fileToUpload);
};

export const createFileFromURL = async (url, fileName) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], fileName, {
    type: blob.type,
  });

  return file;
};
