import React from 'react';

import {Base} from './styles';

import CommentaryViewHeader from './CommentaryViewHeader';
import CommentaryViewNarrative from './CommentaryViewNarrative';

const CommentaryView = ({
  picURL,
  userName,
  ratingOptions,
  selectedRating,
  narrative = '',
  timestamp,
}) => (
  <Base>
    <CommentaryViewHeader
      picURL={picURL}
      userName={userName}
      ratingOptions={ratingOptions}
      selectedRating={selectedRating}
      timestamp={timestamp}
    />

    <CommentaryViewNarrative narrative={narrative} />
  </Base>
);

export default CommentaryView;
