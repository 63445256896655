import React from 'react';

import Logo from 'Components/UI/Logo';

import {CTA, Title} from './styles';

const HeaderForm = ({title, isDana = false}) => (
  <>
    {!isDana && <Logo center="true" size="120" specialSize={'120'} />}
    {isDana && <Title>Dana Barros</Title>}
    <CTA>{title}</CTA>
  </>
);

export default HeaderForm;
