export const retrieveCategoryInfo = (name, arrCategories) => {
  const decodedName = decodeURIComponent(name);

  return arrCategories.find(({category}) => category === decodedName);
};

export const formatSubcategories = (objSubCategories, handleClick) =>
  objSubCategories?.map(
    ({subcategoryName, subcategoryImage, subcategoryLink}) => ({
      entityName: subcategoryName,
      entityImage: subcategoryImage,
      entityLink: subcategoryLink,
      handleClick: handleClick.bind(null, subcategoryName),
    }),
  );
