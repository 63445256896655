export default [
  'signin',
  'reset-password',
  'reset-password-process',
  'reset-password-confirm',
  'training',
  'guests',
  'membership',
  'about',
  'privacy',
  'inquiry',
  'messageus',
  'faq',
  'signup',
  'home',
  'learnMore',
  '404',
];
