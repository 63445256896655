import {call, put, takeLatest} from 'redux-saga/effects';

import * as actionTypes from '../../types';

import {
  initSignupFail,
  initSignupFailReq,
  initSignupStart,
  initSignupSuccess,
} from 'Store/actions';

import initFormService from 'Services/initForm';

function* postInitForm(action) {
  const {email, token, variant} = action;

  yield put(initSignupStart());

  try {
    const bodyRequest = new FormData();
    bodyRequest.append('email', email);
    bodyRequest.append('token', token);

    const {
      data: {sign_up_status, status_message},
    } = yield call(initFormService.postEmail, bodyRequest, variant);

    if (sign_up_status === 'ok') {
      yield put(initSignupSuccess());
    } else {
      yield put(initSignupFail(status_message));
    }
  } catch (e) {
    console.error(e);
    yield put(initSignupFailReq());
  }
}

export default function* watchPostInitForm() {
  yield takeLatest(actionTypes.SIGNUP_INIT_SAGA, postInitForm);
}
