import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ProfilePic = styled.img`
  border-radius: 50%;
  margin-right: 15px;
  background-color: #ccc;
  width: 60px;
  height: 60px;
  overflow: hidden;
`;

const ProfileUserName = styled.div`
  color: ${({theme}) => theme.colors.main};
  font-size: 18px;
  font-weight: 600;
`;

const ProfileDate = styled.div`
  font-size: 8px;
  margin-left: 10px;
`;

export {Base, ProfileUserName, ProfilePic, ProfileDate};
