import React, {memo, useEffect, useState} from 'react';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {LabelContainer, Wrapper} from './styles';
import RTEConfig from './config';

const RTE = ({
  label,
  setHTMLValue = () => null,
  htmlValue = '',
  ...editorProps
}) => {
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    let state = EditorState.createEmpty();

    if (htmlValue) {
      const {contentBlocks, entityMap} = htmlToDraft(
        htmlValue.replace(/(<\/?)figure((?:\s+.*?)?>)/g, ''), // Delete <figure> tag that causes DraftJS error
      );
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      state = EditorState.createWithContent(contentState);
    }

    setEditorState(state);
  }, [htmlValue]);

  useEffect(() => {
    if (editorState) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());

      setHTMLValue(draftToHtml(rawContentState));
    }
  }, [editorState]);

  return (
    <Wrapper>
      {label && <LabelContainer>{label}</LabelContainer>}

      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        editorClassName="editorClass"
        toolbar={RTEConfig}
        {...editorProps}
      />
    </Wrapper>
  );
};

export default memo(RTE);
