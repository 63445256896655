import React from 'react';

import {Base, ProgressCircle} from './styles';

const ProgressCircles = ({circlesLength, selectedCircle, style = {}}) => {
  const circles = Array(circlesLength)
    .fill()
    .map((_, index) => (
      <ProgressCircle key={index} selected={index + 1 === selectedCircle} />
    ));

  console.log('circles', circles);

  return <Base style={style}>{circles}</Base>;
};

export default ProgressCircles;
