import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 30px;

  @media ${device.desktop} {
    width: 80%;
  }

  @media ${device.laptop} {
    width: 85%;
  }

  @media ${device.tablet} {
    width: 95%;
  }

  @media ${device.mobileM} {
    width: 100%;
  }
`;

export {Base};
