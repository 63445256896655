import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';

import Header from 'Components/Homepage/Header';
import Footer from 'Components/Homepage/Footer';
import MainPage from 'Components/Homepage/MainPage';
import DanaBarrosPage from 'Components/Homepage/DanaBarrosPage';
import Constants from './../../Constants/Constants';
import {Wrapper} from './styles';
import useTitle from 'hooks/useTitle';
import GigaCommon from '../../Components/Homepage/GigaCommon';
import GigaRaisingVoice from '../../Components/Homepage/GigaRaisingVoice';

const Homepage = ({channel, variant = Constants.GIGA_INTELLIGENCE}) => {
  const gVariant = channel?.name ?? variant;

  useTitle('GigaIntelligence');

  const isDana =
    gVariant === Constants.DANA_BARROS_BASKETBALL ||
    gVariant === Constants.DANA_BARROS_BASKETBALL_PODCASTS;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Header isDana={isDana} variant={gVariant} />

      <Switch>
        {/*<Route path="/learnMore" component={LearnMorePage} />*/}
        <Route
          path="/danabarrosbasketball"
          render={() => <DanaBarrosPage variant={gVariant} />}
        />
        <Route
          path="/danabarrosbasketballpodcasts"
          render={() => <DanaBarrosPage variant={gVariant} />}
        />
        {channel?.routePath && !channel.routePath.includes('giga') && (
          <Route
            path={channel.routePath}
            render={() => <GigaRaisingVoice variant={gVariant} />}
          />
        )}
        {channel?.routePath && channel.routePath.includes('giga') && (
          <Route
            key={channel.name}
            path={channel.routePath}
            render={() => <GigaCommon variant={gVariant} />}
          />
        )}
        <Route path="/" component={() => <MainPage variant={gVariant} />} />
      </Switch>

      <Footer title={null} variant={gVariant} />
    </Wrapper>
  );
};

export default Homepage;
