import React from 'react';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

const TransparentBtn = ({onClick, children, style = {}}) => {
  const classes = useStyles();

  return (
    <Button
      style={style}
      classes={{label: classes.label}}
      className={classes.root}
      onClick={onClick}
      fullWidth>
      {children}
    </Button>
  );
};

export default TransparentBtn;
