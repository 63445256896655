import React from 'react';

import {Base, BoxImg, ContentContainer} from './styles';

const LandingContentBox = ({
  height,
  bgCol,
  children,
  srcImage,
  style = {},
  directionImg,
  boxImgCSS = '',
  contentBaseCSS = '',
  contentContainerCSS = '',
  breakpoint = 768,
}) => (
  <Base
    css={contentBaseCSS}
    height={height}
    bgCol={bgCol}
    style={style}
    direction={directionImg}
    breakpoint={breakpoint}>
    <ContentContainer
      css={contentContainerCSS}
      direction={directionImg}
      breakpoint={breakpoint}>
      {children}
    </ContentContainer>
    {srcImage && (
      <BoxImg
        src={srcImage}
        direction={directionImg}
        alt=""
        css={boxImgCSS}
        breakpoint={breakpoint}
      />
    )}
  </Base>
);

export default LandingContentBox;
