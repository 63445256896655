import * as React from 'react';
import PaypalButtonWrapper from '../../UI/Button/PaypalButtonWrapper'
export default function PriceCard({course, handleDoneSub}) {
  return (

    <div className="flex flex-col p-4 mx-1 mt-8 text-center bg-white border-t-4 border-giga-main rounded shadow w-72 h-126 dark:bg-gray-800">
      <div className="mb-8 text-2xl font-medium text-gray-800 dark:text-white">
        {course.training_course_name}
      </div>
      <div className="mb-10 text-sm font-light leading-loose text-gray-700 dark:text-gray-50">
        <div>
          {course.training_course_description}
        </div>
      </div>
      <div className=" mb-2 text-2xl font-bold text-gray-500 dark:text-gray-200">
            <span>
                {course.course_fee}
            </span>
      </div>
      <div className="flex align-bottom lex-1 px-4 mt-auto">
        {/*<button type="button"*/}
        {/*        className="py-2 px-4  bg-giga-main hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">*/}
        {/*  Start*/}
        {/*</button>*/}
        <PaypalButtonWrapper type="subscription" plan_id='P-09X743303V694634JMTOM4FA'  handleDoneSub={handleDoneSub} />
      </div>
    </div>


  );
}

