import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import RatingsBlock
  from 'Components/MemberHomepage/Definitions/AnalysisFlow/Topics/TopicSubmission/TopicDefinition/RatingsBlock';
import Button from 'Components/DesignSystem/button/button';
import NarrativeForm from './NarrativeForm';
import TextErrorContainer from 'Components/UI/TextErrorContainer';

import {TitleTopicName} from './styles';
import {handleCommentarySubmit} from './bl';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';

const CommentaryForm = ({currentTopicName, currentTopic, onSaved}) => {
  const history = useHistory();

  const [narrativeValue, setNarrativeValue] = useState('');
  const [ratingValue, setRatingValue] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {cidAssesment, techIdAssesment, assessorID} = useParams();

  console.log('assessorID', assessorID);

  useEffect(() => {
    setErrorMessage('');
    setRatingValue('');
    setNarrativeValue('');
  }, [currentTopic]);
  const updateRating = (rate) => {
    setRatingValue(rate);
  }

  const handleSaved = () => {
    console.log('handleSaved', 'hey');
    setErrorMessage('');
    setRatingValue('');
    setNarrativeValue('');
    onSaved();
  };

  if (currentTopic == null) {
    return null;
  }

  return (
    <>
      <TitleTopicName>{currentTopicName}</TitleTopicName>
      <RatingsBlock
        ratingOptions={currentTopic.assessment_rating_topic_options.split(',')}
        selectedRating={''}
        setNewSelectedRating={updateRating}
      />
      <NarrativeForm setNarrativeValue={setNarrativeValue} />
      {errorMessage && <TextErrorContainer>{errorMessage}</TextErrorContainer>}
      <Button
        onClick={handleCommentarySubmit.bind(
          null,
          ratingValue,
          narrativeValue,
          currentTopic.assessment_rating_topic_html_element_name,
          currentTopic.assessment_narrative_topic_html_element_name,
          currentTopic.assessment_topic_id,
          cidAssesment,
          techIdAssesment,
          assessorID,
          setErrorMessage,
          setLoadingSubmit,
          handleSaved,
        )}
        loading={loadingSubmit}>
        Save Commentary Topic
      </Button>
      <Button style={{marginTop: 8}} onClick={() => history.goBack()} loading={false}>
        Cancel
      </Button>
    </>
  );
};

export default CommentaryForm;
