import styled from 'styled-components';

const Base = styled.div`
  width: 100%;
  height: 70vh;
  min-height: 600px;

  background-color: ${({theme, error, loading}) =>
    error || loading ? '#fff' : theme.colors.main};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  border-radius: 15px;

  box-shadow: ${({error, loading}) =>
    error || loading ? 'none' : '0px 3px 6px #00000029'};
`;

export {Base};
