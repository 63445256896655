import React from 'react';

import {BlockDesc, BlockTitle} from '../styles';

import LandingTextBlock from '../../UI/LandingTextBlock';
import SignUpButton from '../../UI/SignUpButton';

import PortraitImg from 'assets/img/landing/learnMoreBlocks/portrait-4.png';

const MindsBlock = ({isDana, variant}) => (
  <LandingTextBlock
    portraitSrc={PortraitImg}
    directionText="right"
    imageWidth="50%"
    textWidth="40%">
    <BlockTitle color="#2ca3ff">A place where all minds connect.</BlockTitle>
    <BlockDesc>
      Giga uniquely features “shared intelligence” assessments, contributed by
      people like you. Presented separate and together, “shared intelligence”
      assessments are your quickest, easiest path to understanding or being
      understood on any subject.
    </BlockDesc>
    <SignUpButton isDana={isDana} variant={variant} />
  </LandingTextBlock>
);

export default MindsBlock;
