import * as actionTypes from '../../types';

const initialState = {
  isThereInputError: false,
  isThereRequestError: false,
  errorMessage: '',
  loading: false,
  success: false,
};

const signupInitStart = state => ({
  ...state,
  loading: true,
});

const signupInitSuccess = state => ({
  ...state,
  success: true,
  loading: false,
  errorMessage: '',
  isThereInputError: false,
  isThereRequestError: false,
});

const signupInitFail = (state, {errorMessage}) => ({
  ...state,
  success: false,
  loading: false,
  isThereInputError: true,
  isThereRequestError: false,
  errorMessage,
});

const signupInitFailReq = state => ({
  ...state,
  success: false,
  loading: false,
  isThereInputError: false,
  isThereRequestError: true,
});

const reducer = (state = initialState, action) => {
  const {type} = action;

  switch (type) {
    case actionTypes.SIGNUP_INIT_START:
      return signupInitStart(state);

    case actionTypes.SIGNUP_INIT_SUCCESS:
      return signupInitSuccess(state);

    case actionTypes.SIGNUP_INIT_FAIL:
      return signupInitFail(state, action);

    case actionTypes.SIGNUP_INIT_FAIL_REQ:
      return signupInitFailReq(state);

    default:
      return state;
  }
};

export default reducer;
