import React from 'react';

import Input from 'Components/DesignSystem/inputs/base-input';

// import {} from './styles';

const Inputs = ({
  changePassword,
  statusPassword,
  statusConfirmPassword,
  changeConfirmPassword,
}) => (
  <>
    <Input
      name="Password"
      label="Password"
      placeholder="Your password"
      password
      type={isPassword => (isPassword ? 'password' : 'text')}
      onChange={changePassword}
      statusError={statusPassword ? 'success' : 'error'}
    />

    <Input
      name="Confirm Password"
      label="Confirm Password"
      placeholder="Your password"
      password
      type={isPassword => (isPassword ? 'password' : 'text')}
      onChange={changeConfirmPassword}
      statusError={statusConfirmPassword ? 'success' : 'error'}
    />
  </>
);

export default Inputs;
