import React from 'react';

import ContentBox from '../../UI/LandingContentBox';
import LandingButton from '../../UI/LandingButton';

import GetStartedPortrait from 'assets/img/3d/complete-definition.png';

import {BlockTitle} from '../styles';

import device from 'mediaQueries';
import HistoryService from '../../../../utils/HistoryService';

const contentBaseCSS = `
  @media ${device.laptop} {
    height: 350px;
  }
`;

const GetStartedBlock = ({title, srcImage, bgCol, isDana, variant}) => (
  <ContentBox
    height={450}
    bgCol={bgCol ?? 'linear-gradient(#2ca3ff 0%, #424dd1 100%)'}
    srcImage={srcImage ?? GetStartedPortrait}
    directionImg="right"
    contentBaseCSS={contentBaseCSS}>
    <BlockTitle color="#fff">
      {title ?? 'Learn about something new and share with your friend group.'}
    </BlockTitle>
    <LandingButton
      bgCol="rgba(0,0,0, 0.3)"
      onClick={() =>
        HistoryService.push({
          pathname: '/signup',
          state: {
            isDana,
            variant,
          },
        })
      }>
      Get Started
    </LandingButton>
  </ContentBox>
);

export default GetStartedBlock;
