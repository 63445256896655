import React from 'react';

import {Base} from './styles';

import CommentaryViewProfile from './CommentaryViewProfile';
import RatingOptions from 'Components/MemberHomepage/Definitions/AnalysisFlow/Topics/TopicSubmission/TopicDefinition/RatingsBlock/RatingOptions';
import RatingDropDown
  from '../../../../../Definitions/AnalysisFlow/Topics/TopicSubmission/TopicDefinition/RatingsBlock/RatingDropDown'

const CommentaryViewHeader = ({
  picURL,
  userName,
  ratingOptions,
  selectedRating,
  timestamp,
}) => (
  <Base>
    <CommentaryViewProfile
      timestamp={timestamp}
      picURL={picURL}
      userName={userName}
    />

    {/*<RatingOptions*/}
    {/*  options={ratingOptions.split(',')}*/}
    {/*  selectedIndex={ratingOptions.split(',').indexOf(selectedRating)}*/}
    {/*  onlyView*/}
    {/*  style={{margin: '0 15px'}}*/}
    {/*/>*/}
    <RatingDropDown
      selectedIndex={ratingOptions.split(',').indexOf(selectedRating)}
      selectedRating={selectedRating}
      options={ratingOptions.split(',')}
      onlyView
    />
  </Base>
);

export default CommentaryViewHeader;
