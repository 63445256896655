import React from 'react';

import {Base} from './styles';

import {tabs} from './info';

import Tab from './Tab';

const Header = ({currentTab, setCurrentTab}) => {
  const headerTabs = tabs.map(({id, title, icon, hoverLabel}) => (
    <Tab
      selected={currentTab === id}
      clicked={setCurrentTab.bind(null, id)}
      key={id}
      Icon={icon}
      title={title}
      hoverLabel={hoverLabel}
    />
  ));

  return <Base>{headerTabs}</Base>;
};

export default Header;
