import styled from 'styled-components';

import {customDevice} from 'mediaQueries';

const Base = styled.div`
  width: 80%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 70px 0;

  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 90px;
  }

  @media ${customDevice(1200)} {
    width: 90%;
  }

  @media ${customDevice(840)} {
    width: 95%;
  }
`;

export default Base;
