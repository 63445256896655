import styled from 'styled-components';
import device from '../../../../mediaQueries';

const DemoButton = styled.button`
  width: 12vw;
  min-width: 11.875rem;
  height: 2.5rem;
  backdrop-filter: blur(5px);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  color: ${({color}) => color ?? '#2CA3FF'};
  font-size: 1rem;
  font-weight: 700;
  border-color: ${({color}) => color ?? '#2CA3FF'};
  border-width: 2px;
  background-color: white;
  transition: background-color 1s ease;

  @media ${device.mobile} {
    min-width: 8rem;
    font-size: 0.5rem;
    height: 1.6rem;
  }

  &:hover {
    cursor: pointer;
  }

  ${({css = ''}) => css}
`;

export default DemoButton;
