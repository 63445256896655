import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 20px;

  @media ${device.mobileL} {
    justify-content: center;
  }

  @media ${device.mobileS} {
    width: 100%;
  }
`;

const NoAssesmentsText = styled.div`
  height: 150px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  opacity: 0.4;
`;

export {Base, NoAssesmentsText};
