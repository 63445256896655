import React, {useEffect, useState} from 'react';
import MemberHomepageService from '../../../../../../../Services/memberHomepage';
import {IoMdClose} from 'react-icons/io';
import {
  getEntityParams,
  getItem,
} from '../../../../../../../utils/HelperService';
import Constants from '../../../../../../../Constants/Constants';
import './styles.css';
import {CircularProgress} from '@material-ui/core';
import {BsSearch} from 'react-icons/bs';

export default function ContributorsDetailModal(props) {
  const {handleCloseModal} = props;

  const [users, setUsers] = useState([]);
  const [searchString, setSearchString] = useState('');

  const topic = getItem(Constants.AS_MESSAGE_ROOM_TOPIC);
  const {category_id} = getEntityParams();

  useEffect(() => {
    MemberHomepageService.fetchAllContributors(
      category_id,
      topic.channel_id,
    ).then(res => {
      if (res?.data?.message_posting_contributors != null) {
        setUsers(res.data.message_posting_contributors);
      }
    });
  }, []);

  return (
    <div
      className={
        'flex flex-col relative detail-contributors-modal-container rounded-lg'
      }>
      <div className={'flex flex-col flex-1 mt-4 space-y-4 items-center px-4'}>
        <div className={'flex flex-row space-x-2 items-center'}>
          <p className={'text-lg font-semibold text-center text-black'}>
            Contributors
          </p>
        </div>
      </div>
      <IoMdClose
        className={'w-6 h-6 absolute top-4 right-4 text-white cursor-pointer'}
        color={'black'}
        onClick={() => handleCloseModal()}
      />
      <div
        className={
          'flex flex-row w-80 bg-white rounded-full items-center px-4 self-center mt-6 py-2 space-x-2 border-black border'
        }>
        <BsSearch className={'w-4 h-4'} />
        <input
          id="search"
          name="search"
          className="focus:outline-none text-sm flex-1"
          type="search"
          placeholder={'Search'}
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
      </div>
      <div
        className={`detail-contributors-list-container ${
          users.length === 0 ? 'items-center justify-center' : 'items-start'
        }`}>
        {users.length === 0 ? (
          <CircularProgress thickness={5} className="Loading" disableShrink />
        ) : (
          <div className={'grid grid-cols-2 flex-1'}>
            {users
              .filter(u =>
                u.message_posting_contributor_full_name
                  .toLowerCase()
                  .includes(searchString.toLowerCase().trim()),
              )
              .map((user, index) => {
                return (
                  <div
                    key={`${index}`}
                    className={'flex flex-row items-center space-x-3 my-4'}>
                    <img
                      src={user.message_posting_contributor_file_url}
                      alt={'avatar'}
                      className={'w-12 h-12 rounded-full object-cover'}
                    />
                    <span className={'font-medium text-black'}>
                      {user.message_posting_contributor_full_name}
                    </span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
