import * as actionTypes from '../../types';

const initialState = {
  isThereInputError: false,
  isThereRequestError: false,
  errorMessage: '',
  loading: false,
  success: false,
};

const signinAuthStart = state => ({
  ...state,
  loading: true,
});

const signinAuthSuccess = state => ({
  ...state,
  success: true,
  loading: false,
  errorMessage: '',
  isThereInputError: false,
  isThereRequestError: false,
});

const signinAuthFail = (state, {errorMessage}) => ({
  ...state,
  success: false,
  loading: false,
  isThereInputError: true,
  isThereRequestError: false,
  errorMessage,
});

const signinAuthFailReq = state => ({
  ...state,
  success: false,
  loading: false,
  isThereInputError: false,
  isThereRequestError: true,
});

const reducer = (state = initialState, action) => {
  const {type} = action;

  switch (type) {
    case actionTypes.SIGNIN_AUTH_START:
      return signinAuthStart(state);

    case actionTypes.SIGNIN_AUTH_SUCCESS:
      return signinAuthSuccess(state);

    case actionTypes.SIGNIN_AUTH_FAIL:
      return signinAuthFail(state, action);

    case actionTypes.SIGNIN_AUTH_FAIL_REQ:
      return signinAuthFailReq(state);

    default:
      return state;
  }
};

export default reducer;
