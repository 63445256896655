import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabContainer = styled.div`
  margin-top: 6px;
`;

export {Base, TabContainer};
