import Constants from '../Constants/Constants';

/**
 * Store userToken
 *
 * @param {object} userToken
 */
export const storeUserToken = userToken => {
  try {
    localStorage.setItem(Constants.AS_USER_TOKEN, JSON.stringify(userToken));
  } catch (error) {
    console.log(error);
  }
};

/**
 * Fetch userToken from LocalStorage
 *
 * @returns {object}
 */
export function getUserToken() {
  const token = localStorage.getItem(Constants.AS_USER_TOKEN);
  if (token == null) {
    return null;
  }
  return JSON.parse(token);
}

/**
 * Remove userToken from LocalStorage
 *
 * @returns {object}
 */
export function removeUserToken() {
  localStorage.removeItem(Constants.AS_USER_TOKEN);
}

export const storeUserMode = mode => {
  try {
    localStorage.setItem(Constants.AS_USER_MODE, JSON.stringify(mode));
  } catch (error) {
    console.log(error);
  }
};

export function getUserMode() {
  const mode = localStorage.getItem(Constants.AS_USER_MODE);
  if (mode == null) {
    return {};
  }
  return JSON.parse(mode);
}

export const storeEntityParams = params => {
  try {
    localStorage.setItem(Constants.AS_ENTITY_PARAMS, JSON.stringify(params));
  } catch (error) {
    console.log(error);
  }
};

export function getEntityParams() {
  const params = localStorage.getItem(Constants.AS_ENTITY_PARAMS);
  if (params == null) {
    return {};
  }
  return JSON.parse(params);
}

export const storeCategoryMode = category => {
  try {
    localStorage.setItem(Constants.AS_USER_CATEGORY, JSON.stringify(category));
  } catch (error) {
    console.log(error);
  }
};

export function getCategoryMode() {
  const category = localStorage.getItem(Constants.AS_USER_CATEGORY);
  if (category == null) {
    return {};
  }
  return JSON.parse(category);
}

export const storeItem = (item, key) => {
  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    console.log(error);
  }
};

export function getItem(key) {
  const item = localStorage.getItem(key);
  if (item == null) {
    return null;
  }
  return JSON.parse(item);
}

export function getHiddenItems(key) {
  const hiddenItems = localStorage.getItem(Constants.AS_CURRENT_HIDDEN);
  if (hiddenItems != null) {
    let itemValue
    itemValue = JSON.parse(hiddenItems).find(item => item.name === key)
    return itemValue.value;
  } else {
    return null
  }
}
export function updateHiddenItems(external_id) {
  const hiddenItems = localStorage.getItem(Constants.AS_CURRENT_HIDDEN);
  if (hiddenItems != null) {
    let parsedHiddenItems = JSON.parse(hiddenItems);
    const foundItemIndex = parsedHiddenItems.findIndex(item => item.name === 'external_id');
    if (foundItemIndex !== -1) {
      parsedHiddenItems[foundItemIndex].value = external_id;
      localStorage.setItem(Constants.AS_CURRENT_HIDDEN, JSON.stringify(parsedHiddenItems));
    }
  } else {
    return null;
  }
}

export function removeItem(key) {
  localStorage.removeItem(key);
}

export function getMCLSteps() {
  const steps = window.sessionStorage.getItem(Constants.SS_MCL_STEPS);
  if (steps == null) {
    return [];
  }
  return JSON.parse(steps);
}

export function setMCLSteps(steps) {
  try {
    window.sessionStorage.setItem(
      Constants.SS_MCL_STEPS,
      JSON.stringify(steps),
    );
  } catch (error) {
    console.log(error);
  }
}

export function addMCLSteps(step) {
  const steps = getMCLSteps();
  try {
    window.sessionStorage.setItem(
      Constants.SS_MCL_STEPS,
      JSON.stringify([...steps, step]),
    );
  } catch (error) {
    console.log(error);
  }
}

export function popMCLSteps() {
  const steps = getMCLSteps();
  if (steps.length === 0) {
    return;
  }
  try {
    window.sessionStorage.setItem(
      Constants.SS_MCL_STEPS,
      JSON.stringify(steps.slice(0, -1)),
    );
  } catch (error) {
    console.log(error);
  }
}
