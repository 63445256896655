import React, {memo, useCallback, useEffect, useState} from 'react';
import './styles.scss';
import {Base} from './styles';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {connect} from 'react-redux';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {useStyles} from '../Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import {Checkbox, InputBase, MenuItem, Select} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HistoryService from '../../../utils/HistoryService';
import clsx from 'clsx';
import SearchBy from '../../SearchBy';
import {InputAdornment, ListSubheader, TextField} from '@mui/material';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ManageCategorySteps from '../ManageCategoryList/ManageCategorySteps';
import {
  addMCLSteps,
  getMCLSteps,
  setMCLSteps,
} from '../../../utils/HelperService';
import HomeSteps from '../Home/HomeSteps';
import GigaSpinner from '../../UI/GigaSpinner';
import Button from '../../DesignSystem/button/button';

function ManageClassifications(props) {
  const [
    data,
    loadingClassifications,
    errorClassifications,
    callClassifications,
  ] = useFetchContent(
    [],
    MemberHomepageService.getClassificationForManagement,
    useCallback(({data}) => {
      setClassifications(data.classifications);
      setKeyFactors(data.key_factors);
      return data;
    }, []),
  );

  const [searchText, setSearchText] = useState('');
  const [classifications, setClassifications] = useState([]);
  const [keyFactors, setKeyFactors] = useState([]);
  const [filterClassification, setFilterClassification] = useState();
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    const steps = getMCLSteps();
    if (steps.length > 3) {
      setMCLSteps(steps.slice(0, 3));
    }
    callClassifications(-1);
  }, []);

  const classes = useStyles();

  const onClickCreateClassification = item => {
    console.log(item);
    const classificationId = item?.id ?? -1;
    const path = {
      pathname: `/home/classifications/${classificationId}`,
      classification: item,
    };

    addMCLSteps({
      ...path,
      title: `${classificationId === -1 ? 'Create' : 'Edit'} Classification`,
    });

    HistoryService.push(path);
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          Manage Classifications
        </span>
      </Base>

      <Base className={'flex flex-row'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={props.manageSteps}
          handleClickStep={() => {}}
        />
      </Base>

      <Base className={'flex flex-row'}>
        <ManageCategorySteps />
      </Base>

      <Base
        className={'flex flex-col justify-center'}
        loading={false}
        error={false}>
        <div className={'grid grid-cols-3 gap-4'}>
          <div className={'col-span-2'}>
            <SearchBy
              className={clsx('flex flex-1')}
              placeholder="Search by Name and Description"
              searchInputProps={{
                value: searchString,
                onChange: e => {
                  setSearchString(e.target.value);
                },
              }}
            />
          </div>
          <div className={'col-span-1'}>
            <div className={'border rounded-lg p-1 mx-0.5 bg-white'}>
              <Select
                displayEmpty
                classes={{root: classes.root}}
                input={<InputBase classes={{root: classes.inputRoot}} />}
                labelId="select-filter"
                id="select-filter"
                value={filterClassification ?? ''}
                IconComponent={ExpandMoreIcon}
                onClose={() => setSearchText('')}
                onChange={event => setFilterClassification(event.target.value)}>
                <ListSubheader style={{width: '100%'}}>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => setSearchText(e.target.value)}
                    onKeyDown={e => {
                      if (e.key !== 'Escape') {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem
                  disabled
                  value={''}
                  classes={{gutters: classes.menuList}}>
                  <em>Select one classification</em>
                </MenuItem>
                {classifications
                  .filter(v =>
                    (v.classification ?? '')
                      .toLowerCase()
                      .includes(searchText.toLowerCase()),
                  )
                  .map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.classification}
                      classes={{gutters: classes.menuList}}>
                      {item.classification}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
        </div>
        {filterClassification == null && searchString === '' ? (
          <div className={'flex flex-col my-8 items-center'}>
            <span className={'text-sm font-medium'}>
              Please input search string or select classification to find
              classifications
            </span>
          </div>
        ) : (
          <div className="mt-1 mb-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Classification
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterClassification == null && searchString === '' ? (
                      <span></span>
                    ) : (
                      keyFactors
                        .filter(
                          v =>
                            (v.key_factor_classification ===
                              filterClassification ||
                              filterClassification == null) &&
                            ((v.key_factor_name ?? '')
                              .toLowerCase()
                              .includes(searchString.toLowerCase()) ||
                              (v.key_factor_description ?? '')
                                .toLowerCase()
                                .includes(searchString.toLowerCase())),
                        )
                        .map((item, index) => {
                          const name = item.key_factor_name;
                          const description = item.key_factor_description;
                          const cla = item.key_factor_classification;
                          const isActive = true;
                          return (
                            <tr
                              key={`${index}`}
                              className={
                                index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              }>
                              <td
                                className={`px-6 py-4 text-sm text-gray-500 ${
                                  !isActive ? '' : 'cursor-pointer underline'
                                }`}
                                onClick={() => {}}>
                                {name}
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-500">
                                {cla}
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-500">
                                {description}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault();
                                    onClickCreateClassification(item);
                                  }}
                                  className="bg-giga-primary-blue_300 hover:opacity-70 text-white py-1 px-3 rounded">
                                  Edit
                                </a>
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        <div className={'mkf-create-new'}>
          <Button
            onClick={() => {
              onClickCreateClassification();
            }}
            bottomGap>
            Add new classification
          </Button>
        </div>
      </Base>
    </>
  );
}

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  manageSteps: state.UserData.manageSteps,
  manageCurrentCategory: state.UserData.manageCurrentCategory,
  searchString: state.UserData.searchString,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateDisplayCategories: categories => {
      dispatch(userActions.updateDisplayCategories(categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
    onResetUser: () => dispatch(resetUser()),
    updateManageCurrentCategory: c =>
      dispatch(userActions.updateManageCurrentCategory(c)),
    updateManageSteps: steps => dispatch(userActions.updateManageSteps(steps)),

    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(ManageClassifications));
