import React from 'react';

import Button from 'Components/UI/Button';

const GoBackSubject = ({handleClick}) => (
  <div style={{width: '100%', marginBottom: '10px'}}>
    <Button onClick={handleClick}>
      &#8592; Return To Listing Of Assessors
    </Button>
  </div>
);

export default GoBackSubject;
