import React, {memo, useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import HeaderForm from './HeaderForm';
import Form from './Form';
import BaseContainer from 'hoc/BaseFormContainer';
import {changeSignupPhase, initSignupSaga} from 'Store/actions';
import useTitle from 'hooks/useTitle';
import InitFormService from '../../../Services/initForm';

const InitialForm = ({
  stateError,
  email,
  checkedEmail,
  setEmail,
  isCaptchaVerified,
  setIsCaptchaVerified,
  onInitSaga,
  history,
  success,
  onChangeSignupPhase,
  currentSignupPhase,
  isDana,
  variant,
}) => {
  useTitle('Sign Up', true);

  const [token, setToken] = useState('');

  useEffect(() => {
    InitFormService.getChannelToken(variant)
      .then(res => {
        console.log(res);
        setToken(res.data.channel_token);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      if (isCaptchaVerified && stateError.state === 'success') {
        onInitSaga(checkedEmail, token, variant);
      }
    },
    [isCaptchaVerified, stateError.state, onInitSaga, checkedEmail, token],
  );

  useEffect(() => {
    if (success) {
      onChangeSignupPhase('verifyEmail');
    }

    if (currentSignupPhase !== 'init') {
      history.push('/signup/verifyEmail');
    }
  }, [success, history, currentSignupPhase, onChangeSignupPhase]);

  console.log('initialForm render', isDana);

  if (token === '') {
    return null;
  }

  return (
    <BaseContainer>
      <HeaderForm history={history} isDana={isDana} variant={variant} />
      <Form
        onSubmit={onSubmit}
        stateError={stateError}
        change={setEmail}
        isCaptchaVerified={isCaptchaVerified}
        setIsCaptchaVerified={setIsCaptchaVerified}
        isDana={isDana}
      />
    </BaseContainer>
  );
};

const mapStateToProps = ({initForm: {success}, signup: {currentPhase}}) => ({
  success,
  currentSignupPhase: currentPhase,
});

const mapDispatchToProps = dispatch => ({
  onInitSaga: (email, token, variant) =>
    dispatch(initSignupSaga(email, token, variant)),
  onChangeSignupPhase: value => dispatch(changeSignupPhase(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(InitialForm));
