import React, {useCallback, useEffect} from 'react';
import {Base} from './styles';
import {useGetDBName} from '../../../hoc/HomeScheme/bl';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {useGetEntityIdentifiers} from '../EntityListing/bl';
import AssesmentSummary from '../AssesmentView/AssesmentSummary';
import AssesmentContent from '../AssesmentView/AssesmentContent';
import {getEntityParams} from '../../../utils/HelperService';
import {formatAssessmentResult} from '../AssesmentView/bl';

const GuestReport = ({history: {push}}) => {
  const dbName = useGetDBName();
  const {cat, cid, techId} = useGetEntityIdentifiers();
  const {mode_name} = getEntityParams();

  console.log('mode_name', mode_name);

  const [entity, loadingEntity, errorEntity, setNewEntity] = useFetchContent(
    {},
    MemberHomepageService.getGuestReportOfEntity,
    useCallback(({data}) => formatAssessmentResult(data), []),
  );

  useEffect(() => {
    setNewEntity(cat, cid, techId);
  }, [cat, cid, techId, setNewEntity]);

  return (
    <Base loading={loadingEntity || dbName === 'initial'} error={errorEntity}>
      <AssesmentSummary
        assesmentName={entity?.subject_info?.subject_name}
        assesmentImage={entity?.subject_info?.subject_image}
        assessmentDescription={entity?.subject_info?.subject_description}
      />
      <AssesmentContent
        assesorName={entity?.assessorProfile?.assessor_name}
        assesorProfilePic={entity?.assessorProfile?.assessor_image}
        assesmentTopics={entity?.assessment}
        mode={mode_name}
      />
    </Base>
  );
};

export default GuestReport;
