import {isEmail} from 'validator';

export const validateEmail = value => isEmail(value);

export const checkStrLength = (str, min, max) =>
  str.trim().length > min && str.trim().length <= max;

export const messageCheckStrLength = (str, strName = 'string', min, max) => {
  if (str.trim().length === min) {
    return min === 0
      ? `The ${strName} can't be empty`
      : `The ${strName} has a minimum length of ${min} characters`;
  }

  if (str.trim().length >= max) {
    return `The ${strName} has a maximum length of ${max} characters`;
  }

  return '';
};

export const isObjEmpty = obj => Object.keys(obj).length === 0;
