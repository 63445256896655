import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 1rem 0 1rem;
  justify-content: center;

  @media ${customDevice(1240)} {
    padding: 1rem 0.5rem 0 0.5rem;
  }

  //&:hover {
  //  background-color: #eee;
  //}

  //@media ${device.tablet} {
  //flex-direction: column;
  //}
`;

const ProfilePicContainer = styled.div`
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  margin-left: 2.5rem;
  border-radius: 2rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  // @media ${device.laptop} {
  //   width: 2.5rem;
  //   height: 2.5rem;
  // }
  //
  @media ${device.tablet} {
    margin-left: 1rem;
  }

  @media ${customDevice(1240)} {
    margin-left: 1rem;
  }
`;

const NarrativeContainer = styled.div`
  flex: 1;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  //align-items: center;

  text-align: left;

  margin: 0 4rem 0 10rem;

  @media ${customDevice(1240)} {
    margin: 0 2rem 0 6.5rem;
  }

  color: #646464;

  img {
    display: none;
    margin: 0 auto;
    width: 150px;
    height: auto;

    @media ${device.laptopL} {
      width: 110px;
    }
  }

  @media ${device.tablet} {
    flex: 0;
    width: 60%;
    margin: 0 auto;
  }
`;

export {Base, ProfilePicContainer, NarrativeContainer};
