import React from 'react';

import {Base, ProfileDate, ProfilePic, ProfileUserName} from './styles';

import moment from 'moment';

const format = 'dddd, MMMM Do';

const CommentaryViewProfile = ({picURL, userName = '...', timestamp}) => {
  return (
    <Base>
      <ProfilePic src={picURL} alt={`${userName} Profile Picture`} />
      <ProfileUserName>{userName}</ProfileUserName>
      <ProfileDate>{moment(timestamp).format(format)}</ProfileDate>
    </Base>
  );
};

export default CommentaryViewProfile;
