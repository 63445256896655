import {useCallback, useState} from 'react';

const useFormGeneratorData = arrInputs => {
  const [contentInputs, setContentInputs] = useState(
    arrInputs.map(input => ({...input, value: '', status: 'error'})),
  );

  const changeInputProp = useCallback((prop, selectedName, newValue) => {
    setContentInputs(prevInputs =>
      prevInputs.map(input => {
        if (selectedName === input.name) {
          return {
            ...input,
            [prop]: newValue,
          };
        }

        return input;
      }),
    );
  }, []);

  const validateForm = useCallback(
    () /*: [isValid, errorMessage] */ => {
      const requiredInputs = contentInputs.filter(({isRequired}) => isRequired);
      const isValid = requiredInputs.every(({status, name, src}) => {
        if (name === 'portrait_file') {
          return !!src;
        }

        return status === 'success';
      });

      if (!isValid) {
        const firstInvalidInputName = requiredInputs.find(
          ({status}) => status === 'error',
        ).label;

        return [false, `${firstInvalidInputName} is not valid`];
      }

      return [true, ''];
    },
    [contentInputs],
  );

  return [contentInputs, changeInputProp, validateForm];
};

export default useFormGeneratorData;
