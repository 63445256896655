import React from 'react';

import LinkIcon from '@material-ui/icons/Link';

import {Base, URLContainer} from './styles';

import Button from 'Components/UI/Button';

const LinkPreview = ({src}) => {
  return (
    <Base>
      <LinkIcon />
      <URLContainer>{src}</URLContainer>
      <Button component="a" target="_blank" href={src}>
        Redirect to Link
      </Button>
    </Base>
  );
};

export default LinkPreview;
