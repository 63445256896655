import React from 'react';

import ContentBox from '../../UI/LandingContentBox';
import LandingButton from '../../UI/LandingButton';

import LearnMorePortrait from 'assets/img/landing/learnMorePortrait.png';

import {BlockTitle} from '../styles';

import device, {customDevice} from 'mediaQueries';
import HistoryService from '../../../../utils/HistoryService';

const boxImgCSS = `
  @media ${customDevice(1690)} {
    width: 50%;
  }
`;

const contentBaseCSS = `
  @media ${customDevice(1690)} {
    height: 475px;
  }

  @media ${device.laptopL} {
    height: 385px;
  }

  @media ${device.laptop} {
    height: 320px;
  }
`;

const contentContainerCSS = `
  @media ${customDevice(1690)} {
    width: 45%;
  }
`;

const LearnMoreBlock = ({
  history,
  color,
  description = false,
  isDana,
  variant,
}) => (
  <ContentBox
    height={590}
    bgCol="linear-gradient(#fff 0%, #f8f8f8 49.33%, #ebebeb 100%)"
    srcImage={LearnMorePortrait}
    style={{paddingRight: '0', paddingLeft: '50px'}}
    directionImg="right"
    boxImgCSS={boxImgCSS}
    contentBaseCSS={contentBaseCSS}
    contentContainerCSS={contentContainerCSS}>
    <BlockTitle color={color ?? '#2ca3ff'}>
      A place where all minds connect.
    </BlockTitle>
    <div className={'flex flex-col items-center'}>
      {description === true && (
        <span className={'text-lg mb-4 lg:mb-8'} style={{color: '#7C7C7C'}}>
          GigaMedia is at the intersection of lore ipsum dolor dfasd dsfa sf
          were asdfasd fd lore impum dolor mite. lore ipsum
        </span>
      )}
      <LandingButton
        bgCol="rgba(0,0,0, 0.3)"
        onClick={() =>
          HistoryService.push({
            pathname: '/learnMore',
            state: {
              isDana,
              variant,
            },
          })
        }>
        Learn More
      </LandingButton>
    </div>
  </ContentBox>
);

export default LearnMoreBlock;
