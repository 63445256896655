import React from 'react';

import {Base, DateContainer, NameContainer, PicContainer} from './styles';

const Profile = ({profilePic, userName}) => (
  <Base>
    <PicContainer>
      <img src={profilePic} alt={userName} />
    </PicContainer>

    <NameContainer>{userName}</NameContainer>

    <DateContainer>1d</DateContainer>
  </Base>
);

export default Profile;
