import {validateEmail} from 'Helpers/validators';

export const validateInitialEmail = email => {
  if (email.trim().length === 0) {
    return [true, 'Email is required'];
  }

  if (!validateEmail(email)) {
    return [true, 'Email is not valid'];
  }

  return [false, ''];
};

export const checkErrorEmail = (
  error,
  errorMessage,
  state,
  setState,
  setCheckedEmail,
  email,
) => {
  if (error) {
    if (state !== 'error') {
      setState({state: 'error', message: errorMessage});
    }
  } else {
    if (state !== 'success') {
      setState({state: 'success', message: ''});
      setCheckedEmail(email);
    }
  }
};
