import React from 'react';
import {BsChevronRight, BsChevronDown} from 'react-icons/bs';
import {CgClose, CgArrowRight} from 'react-icons/cg';
import {IconButton} from '@material-ui/core';
import Target from '../../../../assets/img/landing/target.png';

export default function MajorCategory({
  category,
  setCurrentCategory,
  steps,
  setSteps,
  removeCategory,
}) {
  return (
    <div className={'flex flex-col my-4 shadow rounded-2xl py-4 px-8'}>
      <div className={'flex flex-row items-center'}>
        <img
          alt={'image'}
          src={category.image}
          className={'w-20 h-20 mr-4 rounded object-contain'}
        />
        <span className={'font-semibold text-xl text-giga-graymedium mr-auto'}>
          {category.category_name}
        </span>
        {category.subcategory_link !== '' && (
          <IconButton
            onClick={() => {
              if (category.subcategory_link === '') {
                return;
              }
              setSteps([...steps, category]);
              setCurrentCategory(category.subcategory_link);
            }}>
            <BsChevronRight className={'text-giga-graymedium w-5 h-5'} />
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            setSteps([...steps, category]);
            setCurrentCategory(category.subject_link);
          }}>
          <BsChevronDown className={'text-giga-graymedium w-5 h-5'} />
        </IconButton>
        <IconButton
          onClick={() => {
            removeCategory(category)
          }}>
          <CgClose className={'text-giga-graymedium w-5 h-5'} />
        </IconButton>
      </div>
    </div>
  );
}
