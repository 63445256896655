import React from 'react';

import {Base, ContentBoxText, HeaderDesc, TextHeader, Title} from '../styles';

import ContentBox from 'Components/Homepage/UI/LandingContentBox';
import Button from 'Components/DesignSystem/button/button';
import ProgressCircles from 'Components/UI/ProgressCircles';

import GetStartedPortrait from 'assets/img/3d/complete-definition.png';

const OnboardingWelcome = ({history}) => {
  return (
    <Base>
      <TextHeader>
        <Title>Welcome to Giga.</Title>

        <HeaderDesc>
          We welcome you to our community of sharers and people help changing
          the world.
        </HeaderDesc>
      </TextHeader>

      <ContentBox
        height={450}
        bgCol="linear-gradient(#2ca3ff 0%, #424dd1 100%)"
        srcImage={GetStartedPortrait}
        directionImg="right"
        breakpoint={1095}>
        <ContentBoxText>
          Giga is a new way to share information over the Internet. We
          categorize your ideas, to help you teach, and also learn new ideas.
          <br />
          <br />
          Here we will show you some of our ways to use the system we designed.
        </ContentBoxText>

        <Button
          onClick={() => history.push('/home/onboarding/categories')}
          style={{marginTop: '30px', width: '60%'}}>
          Continue
        </Button>

        <ProgressCircles
          circlesLength={3}
          selectedCircle={1}
          style={{marginTop: '20px'}}
        />
      </ContentBox>
    </Base>
  );
};

export default OnboardingWelcome;
