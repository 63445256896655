import axios from 'axios';

import {convertQueryParamsToObject} from 'Helpers/api';
import {getUserToken} from '../utils/HelperService';

const BASE_URL = 'https://api.gigaintelligence.com/';
// const BASE_URL = 'https://gigainteliigencelinux.azurewebsites.net/';
// const BASE_URL = 'http://localhost/gigaintelligence/'
const ApiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

ApiClient.interceptors.request.use(
  config => {
    const {method, url} = config;

    const gigaSessionID = getUserToken()?.giga_session_id;

    if (method === 'get' && gigaSessionID) {
      const objQuery = convertQueryParamsToObject(url);

      return {
        ...config,
        url:
          Object.keys(objQuery).length > 0
            ? url + `&giga_session_id=${gigaSessionID}`
            : url + `?giga_session_id=${gigaSessionID}`,
      };
    } else if (method === 'post' && gigaSessionID) {
      const {data} = config;
      data.append('giga_session_id', gigaSessionID);
    }

    return config;
  },
  e => Promise.reject(e),
);

ApiClient.interceptors.response.use(
  response => {
    const userInfo = document.getElementById('user-info');
    if (userInfo) {
      const {
        data: {user_full_name, selected_model_name},
      } = response;

      let content = selected_model_name;

      if (user_full_name) {
        if (selected_model_name === 'Giga Intelligence - Intelligence') {
          content = `${
            typeof user_full_name === 'string' ? user_full_name : ''
          } - Giga Intelligence`;
        } else {
          content = `${
            typeof user_full_name === 'string' ? user_full_name : ''
          } - ${
            typeof selected_model_name === 'string' ? selected_model_name : ''
          }`;
        }
      }

      userInfo.textContent = content;
    }
    return response;
  },
  e => Promise.reject(e),
);

export {ApiClient};
