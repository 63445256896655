import {useEffect, useState} from 'react';

import MemberHomepageService from 'Services/memberHomepage';
import Constants from '../../Constants/Constants';
import {getItem, getUserToken} from '../../utils/HelperService';

export const useGetDBName = () => {
  const [dbName, setDbName] = useState('initial');

  useEffect(() => {
    const callDBName = async () => {
      if (getUserToken() == null) {
        const channelInfo = getItem(Constants.AS_CHANNEL_INFO);
        if (channelInfo) {
          setDbName(channelInfo.name);
        } else {
          setDbName(Constants.GIGA_GUEST);
        }
        return;
      }
      try {
        const {
          data: {selected_model_name},
        } = await MemberHomepageService.getDBName();
        console.log('selected_model_name', selected_model_name);
        if (selected_model_name === 'GigaWeather - ') {
          setDbName(Constants.GIGA_WEATHER);
        } else if (selected_model_name === 'Giga Intelligence - Intelligence') {
          setDbName(Constants.GIGA_INTELLIGENCE);
        } else if (selected_model_name === 'GigaMedia - Media') {
          setDbName(Constants.GIGA_MEDIA);
        } else if (selected_model_name === 'GigaAthletics - Sports') {
          setDbName(Constants.GIGA_ATHLETICS);
        } else {
          setDbName(Constants.GIGA_INTELLIGENCE);
        }
      } catch (e) {
        setDbName(Constants.GIGA_GUEST);
      }
    };

    callDBName();
  }, []);

  return dbName;
};
