import React from 'react';
import {Redirect, useParams} from 'react-router-dom';

import {Base} from './styles';

import {definitionPages} from './info';

import useTitle from 'hooks/useTitle';

const Definitions = props => {
  const {createSection} = useParams();
  useTitle(
    `${
      createSection.charAt(0).toUpperCase() + createSection.slice(1)
    } Definition`,
    true,
  );

  const CurrentPage = definitionPages[createSection];

  return (
    <Base>
      {CurrentPage ? <CurrentPage {...props} /> : <Redirect to="/404" />}
    </Base>
  );
};

export default Definitions;
