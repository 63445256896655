import React from 'react';

import {AlertContainer, Base} from './styles';

// import Context from './Context';
import AssesmentTopic from './AssesmentTopic';

import {getAttachments} from 'Components/MemberHomepage/Helpers/attachments';

const AssesmentTopics = ({assesmentTopics}) => {
  let content = null;

  console.log('AssesmentTopics-assesmentTopics', assesmentTopics);

  if (assesmentTopics?.length > 0) {
    const topicElements = assesmentTopics.map((topicInfo, index) => {
      const {topic, rating, narrative} = topicInfo;

      return (
        <AssesmentTopic
          key={topic}
          hasSeparator={index !== assesmentTopics.length - 1}
          topic={topic}
          rating={rating}
          narrative={narrative}
          attachments={getAttachments(topicInfo)}
        />
      );
    });

    content = topicElements;
  } else {
    content = (
      <AlertContainer>
        Something went wrong... Please, reload the page.
      </AlertContainer>
    );
  }

  console.log('AssesmentTopics-content', content);

  return <Base>{content}</Base>;
};

export default AssesmentTopics;
