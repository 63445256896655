import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  font-weight: 600;
  color: #989898;
`;

const ProfileContainer = styled.div`
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NameContainer = styled.div`
  margin-left: 10px;
  font-size: 15px;
`;

const CircleSeparator = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 15px;
  background-color: ${({theme}) => theme.colors.main};
`;

const DateText = styled.div`
  font-size: 13px;
`;

export {Base, ProfileContainer, NameContainer, CircleSeparator, DateText};
