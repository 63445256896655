import styled from 'styled-components';

import {customDevice} from 'mediaQueries';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const Wrapper = styled.div`
  padding: 0 8px;
  width: ${({width}) => `${width}%`};
  position: relative;
  ${({verticalBreakpoint}) => `
    @media ${customDevice(verticalBreakpoint)} {
      width: 100%;
      height: 100%;
    }
  `})
`;

const Fade = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  ${({directionHorizontal}) =>
    directionHorizontal === 'right' ? 'right: 0;' : 'right: 93%;'}
  ${({directionHorizontal}) =>
    directionHorizontal === 'left' && 'transform: rotate(180deg);'}
  pointer-events: none;
  background-image: ${({bgColor}) => `linear-gradient(
    to right,
    ${bgColor?.left ?? 'rgba(255, 255, 255, 0)'},
    ${bgColor?.right ?? 'rgba(255, 255, 255, 1)'} 90%
  )`};
  height: 100%;
  width: 7%;
  ${({hide}) => hide && `background: transparent; background-image: initial;`}

  ${({vertical, verticalBreakpoint, directionVertical}) =>
    vertical &&
    `
    @media ${customDevice(verticalBreakpoint)} {
      right: 0;
      width: 100%;
      height: 9%;
      transform: rotate(0);
      ${directionVertical === 'up' ? 'top: 0;' : 'top: 86%;'}
      background-image: linear-gradient(
        to ${directionVertical === 'up' ? 'top' : 'bottom'},
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 90%
      );
    }
  `}
`;

const Base = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: none;
  transition: all 0.3s;

  &::-webkit-scrollbar {
    height: 3px;
  }

  ${({baseStyles}) => baseStyles}

  ${({verticalBreakpoint, hasOverflow}) => `
    @media ${customDevice(verticalBreakpoint)} {
      flex-direction: column;
      height: 150px;
      overflow: hidden;

      & > div {
        margin-bottom: 10px;
        width: 100%;
        margin-right: 0 !important;
        margin-right: 12px;
      }
    }
  `}
`;

const Arrow = styled(ArrowBackIosIcon)`
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
  width: 14px;
  height: auto;
  z-index: 10;
  cursor: pointer;
  opacity: 0.4;
  transition: all 0.3s;

  &:hover {
    opacity: 0.7;
  }

  ${({hide}) => hide && 'display: none !important;'}
  ${({directionHorizontal}) =>
    directionHorizontal === 'left' ? 'left: 2px;' : 'right: 2px;'}
  ${({directionHorizontal}) =>
    directionHorizontal === 'right' &&
    'transform: rotate(180deg) translateY(50%);'}

  ${({vertical, verticalBreakpoint, directionVertical}) =>
    vertical &&
    `
    @media ${customDevice(verticalBreakpoint)} {
      right: 0;
      left: 50%;
      transform: rotate(${
        directionVertical === 'up' ? '90deg' : '270deg'
      }) translateY(0) translateX(-50%);
      ${directionVertical === 'up' ? 'top: 10%;' : 'top: 70%;'}
    }
  `}
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  font-size: 13px;
  font-weight: 600;
  opacity: 0.4;
  font-style: italic;
  text-align: center;
`;

export {Base, Arrow, Wrapper, ErrorContainer, Fade};
