import React, {useEffect, useState} from 'react';
import MemberHomepageService from '../../../../../../../Services/memberHomepage';
import {IoMdClose} from 'react-icons/io';
import {
  getEntityParams,
  getItem,
} from '../../../../../../../utils/HelperService';
import Constants from '../../../../../../../Constants/Constants';
import './styles.css';
import {CircularProgress} from '@material-ui/core';
import {BsSearch} from 'react-icons/bs';
import EventList from '../EventList';

export default function EventDetailModal(props) {
  const {handleCloseModal, selectedCV, eventsList, handleClickInfo} = props;

  return (
    <div
      className={
        'flex flex-col relative event-detail-modal-container rounded-lg bg-giga-main'
      }>
      <div className={'flex flex-col flex-1 mt-4 space-y-4 items-center px-4'}>
        <div className={'flex flex-row space-x-2 items-center'}>
          <p className={'text-lg font-semibold text-center text-white'}>
            Events
          </p>
        </div>
      </div>
      <IoMdClose
        className={'w-6 h-6 absolute top-4 right-4 text-white cursor-pointer'}
        color={'white'}
        onClick={() => handleCloseModal()}
      />
      <div className={`event-detail-list-container`}>
        <EventList
          selectedCV={selectedCV}
          eventsList={eventsList}
          handleClickInfo={handleClickInfo}
        />
      </div>
    </div>
  );
}
