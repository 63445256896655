import styled from 'styled-components';

import device from 'mediaQueries';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Base = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    display: ${({show}) => (!show ? 'none' : 'flex')};
  }
`;

const ActivateSwitch = styled(FormControlLabel)`
  color: #2ca3ff;
  font-weight: 700;
  margin-bottom: 10px;
`;

const ActivateSwitchContainer = styled.div`
  display: none;

  @media ${device.mobileL} {
    display: contents;
  }
`;

const RequiredIcon = styled.div`
  margin-left: 10px;
  color: red;
  opacity: 1;
  font-weight: 600;
`;

export {Base, RequiredIcon, ActivateSwitch, ActivateSwitchContainer};
