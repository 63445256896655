import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  background-color: #fff;
  box-shadow: -6px -5px 20px #00000033;
  width: 60px;
  height: 70vh;

  position: fixed;
  top: 55%;
  left: 0;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 30px 0;

  .Fab {
  }

  .Icon {
    fill: #cbccce;
    cursor: pointer;

    &:hover {
      fill: #b9babd;
    }
  }

  @media ${customDevice(530)} {
    width: 50px;
  }

  @media ${device.mobile} {
    width: 100vw;
    height: 10px;

    padding: 30px 20px;

    bottom: -5px;
    top: initial;
    transform: translateY(0);
    border-radius: 15px 15px 0 0;

    flex-direction: row;

    z-index: 100;

    .Fab {
      margin-bottom: 30px;
      width: 50px;
      height: 50px;
    }
  }
`;

export {Base};
