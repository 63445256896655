import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import useFetchContent from '../hooks/useFetchContent';
import CommonService from '../Services/CommonService';
import ServerContent from '../hoc/ServerContent';
import {
  Checkbox,
  FormControlLabel,
  InputBase,
  MenuItem,
  Select,
} from '@material-ui/core';
import Button from '../Components/DesignSystem/button/button';
import './Training/styles.scss';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from '../hoc/Loader';
import {
  Center,
  ReCAPTCHAContainer,
} from '../Components/SignUp/InitialForm/Form/styles';

const InquiryText = styled.h6`
  color: white;
  font-size: 0.9rem;
  line-height: 3vh;
  //text-align: center;
  white-space: pre-line;
`;

const useStyles = makeStyles(theme => ({
  select: {
    width: '100%',
    paddingRight: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  inputRoot: {
    width: '100%',
    height: '2.5rem',
  },
  menuList: {
    paddingLeft: '0.5rem',
    fontSize: '1rem',
  },
  root: {
    fontWeight: 700,
    paddingLeft: '4rem',
    paddingRight: '4rem',
    height: '2.5rem',
  },
  cancelButton: {
    backgroundColor: '#F0F0F5',
    '&:hover': {
      opacity: 1,
      backgroundColor: '#F0F0F5',
    },
    display: 'flex',
    marginBottom: '0.5rem',
    ['@media (min-width:640px)']: {
      marginBottom: 0,
    },
  },
}));

export default function InquiryPage() {
  const classes = useStyles();
  const [role, setRole] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [
    inquiryData,
    loadingInquiryData,
    errorInquiryData,
    callLoadInquiryData,
  ] = useFetchContent(
    {},
    CommonService.getInquiryPolicyData,
    useCallback(({data}) => data, []),
  );

  useEffect(() => {
    callLoadInquiryData();
  }, []);

  const onChangeReCAPTCHA = value => {
    setIsCaptchaVerified(!!value);
  };

  return (
    <ServerContent
      loading={loadingInquiryData}
      error={errorInquiryData}
      className={'flex flex-col w-screen h-screen'}>
      <div className={'flex flex-col mx-48 p-8'}>
        <span className={'font-semibold text-3xl self-center mb-6'}>
          {inquiryData?.header?.topic_name}
        </span>
        <span className={'font-semibold text-2xl self-center mb-12'}>
          {inquiryData?.header?.topic_definition}
        </span>

        {inquiryData?.roles && (
          <div className={'flex flex-row items-center my-2'}>
            <span className={'font-semibol w-24'}>I am a </span>
            <div
              className={
                'w-full text-sm rounded border border-po-graymedium mt-1 bg-white px-2'
              }>
              <Select
                displayEmpty
                classes={{root: classes.select}}
                input={<InputBase classes={{root: classes.inputRoot}} />}
                labelId="select-role"
                id="select-role"
                value={role}
                onChange={event => setRole(event.target.value)}
                IconComponent={ExpandMoreIcon}>
                <MenuItem
                  disabled
                  value={''}
                  classes={{gutters: classes.menuList}}>
                  <em>Select Role</em>
                </MenuItem>
                {inquiryData.roles.roles.map(key => {
                  const value = key.role_name;
                  return (
                    <MenuItem
                      key={value}
                      value={value}
                      classes={{gutters: classes.menuList}}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
        )}

        <div className={'mt-6'}>
          <span className={'font-semibol mb-2'}>
            I am interested in the following domains
          </span>

          <div className={'h-96 overflow-y-scroll my-4'}>
            <div className={'grid grid-cols-4'}>
              {inquiryData?.domains &&
                inquiryData?.domains[0]?.map(domain => (
                  <FormControlLabel
                    key={domain.domain_name}
                    control={<Checkbox color={'primary'} />}
                    label={domain.domain_name}
                  />
                ))}
            </div>
          </div>
        </div>

        <InquiryText>
          {inquiryData.section_narrative
            ? inquiryData.section_narrative[0].section_html_narrative.replaceAll(
                '\n<br>',
                '\n',
              )
            : ''}
        </InquiryText>

        <div className={'flex flex-row items-center my-2'}>
          <span className={'font-semibol w-24'}>My email:</span>
          <input
            id="my_email"
            name="my_email"
            className="block w-full py-3 px-4 text-sm font-semibold focus:outline-none border border-po-graymedium rounded-xl"
            placeholder=""
          />
        </div>

        <div className={'my-2'}>
          <FormControlLabel
            control={<Checkbox color={'primary'} />}
            label={inquiryData?.private_channel?.prompt ?? ''}
          />
        </div>

        <div className={'my-2'}>
          <FormControlLabel
            control={<Checkbox color={'primary'} />}
            label={inquiryData?.marketing_and_sales?.prompt ?? ''}
          />
        </div>

        <div className={'my-2'}>
          <FormControlLabel
            control={<Checkbox color={'primary'} />}
            label={inquiryData?.enterprise?.prompt ?? ''}
          />
        </div>

        <Center>
          <Loader>
            <ReCAPTCHAContainer
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onChangeReCAPTCHA}
            />
          </Loader>
        </Center>

        <div className={'giga-training-submit-btn'}>
          <Button loading={false} disabled={!isCaptchaVerified} bottomGap>
            Submit
          </Button>
        </div>
      </div>
    </ServerContent>
  );
}
