import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 25px 15px;
  background-color: #f2f2f2;
  border-radius: 10px;
`;

export {Base};
