import React, {memo, useEffect, useRef, useState} from 'react';

import {Arrow, Base, ErrorContainer, Fade, Wrapper} from './styles';

import {
  getHorizontalScrollPercentage,
  getVerticalScrollPercentage,
  isOverflown,
} from 'Helpers/dom';

const HSContainer = ({
  children,
  width,
  tabsInfo,
  emptyMessage,
  vertical,
  verticalBreakpoint = '0',
  baseStyles = '',
  bgColor,
}) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollNumber, setScrollNumber] = useState(0);
  const [gotToScrollLimit, setGotToScrollLimit] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  const scrollerRef = useRef(null);

  const verticalEndpoint = window.matchMedia(
    `(max-width: ${verticalBreakpoint}px)`,
  );

  useEffect(() => {
    // if (vertical && verticalEndpoint.matches) {
    //   scrollerRef.current.scrollTop = scrollNumber
    // } else {
    //   scrollerRef.current.scrollLeft = scrollNumber
    // }

    setGotToScrollLimit(
      vertical && verticalEndpoint.matches
        ? getVerticalScrollPercentage(scrollerRef.current) >= 99
        : getHorizontalScrollPercentage(scrollerRef.current) >= 99,
    );
  }, [
    scrollNumber,
    scrollLeft,
    setGotToScrollLimit,
    setHasOverflow,
    vertical,
    verticalEndpoint.matches,
  ]);

  useEffect(() => {
    setHasOverflow(isOverflown(scrollerRef.current));
  }, [tabsInfo]);

  const onScroll = () => {
    setScrollLeft(scrollerRef.current.scrollLeft);
    setGotToScrollLimit(
      vertical && verticalEndpoint.matches
        ? getVerticalScrollPercentage(scrollerRef.current) >= 99
        : getHorizontalScrollPercentage(scrollerRef.current) >= 99,
    );
  };

  const clickLeftArrow = () => {
    // setScrollNumber(prevScrollNumber => prevScrollNumber - 120)
    scrollTo(-300);
  };

  const clickRightArrow = () => {
    // setScrollNumber(prevScrollNumber => prevScrollNumber + 120)
    scrollTo(300);
  };

  const scrollTo = (change, duration = 500) => {
    let start = scrollerRef.current.scrollLeft,
      currentTime = 0;

    let animateScroll = function (increment) {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      scrollerRef.current.scrollLeft = val;
      setScrollLeft(val);
      if (currentTime < duration) {
        setTimeout(() => animateScroll(increment + 1), increment);
      }
    };
    animateScroll(10);
  };

  const hasInfo = tabsInfo?.length > 0;

  return (
    <Wrapper width={width} verticalBreakpoint={verticalBreakpoint}>
      <Fade
        bgColor={bgColor}
        directionHorizontal="left"
        directionVertical="up"
        vertical={vertical}
        verticalBreakpoint={verticalBreakpoint}
        hide={!hasInfo || scrollLeft <= 0 || !hasOverflow}
        // style={{backgroundImage: 'none'}}
      />
      <Arrow
        hide={!hasInfo || scrollLeft <= 0 || !hasOverflow}
        directionHorizontal="left"
        directionVertical="up"
        vertical={vertical}
        verticalBreakpoint={verticalBreakpoint}
        onClick={clickLeftArrow}
      />
      <Base
        ref={scrollerRef}
        baseStyles={baseStyles}
        hasOverflow={hasOverflow}
        onScroll={onScroll}
        verticalBreakpoint={verticalBreakpoint}>
        {hasInfo ? children : <ErrorContainer>{emptyMessage}</ErrorContainer>}
      </Base>
      <Arrow
        hide={!hasInfo || gotToScrollLimit || !hasOverflow}
        directionHorizontal="right"
        directionVertical="down"
        vertical={vertical}
        verticalBreakpoint={verticalBreakpoint}
        onClick={clickRightArrow}
      />
      <Fade
        bgColor={bgColor}
        directionHorizontal="right"
        directionVertical="down"
        vertical={vertical}
        verticalBreakpoint={verticalBreakpoint}
        hide={gotToScrollLimit || !hasOverflow}
        // style={{backgroundImage: 'none'}}
      />
    </Wrapper>
  );
};

export default memo(HSContainer);

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
