import * as actionTypes from '../../types';

export const authSignInSaga = (email, password, token) => ({
  type: actionTypes.SIGNIN_AUTH_SAGA,
  email,
  password,
  token,
});

export const authSignInStart = () => ({
  type: actionTypes.SIGNIN_AUTH_START,
});

export const authSignInSuccess = () => ({
  type: actionTypes.SIGNIN_AUTH_SUCCESS,
});

export const authSignInFail = errorMessage => ({
  type: actionTypes.SIGNIN_AUTH_FAIL,
  errorMessage,
});

export const authSignInFailReq = () => ({
  type: actionTypes.SIGNIN_AUTH_FAIL_REQ,
});
