import React from 'react';

import {Base, PortraitContainer, PortraitImage, TextContainer} from './styles';

const LandingTextBlock = ({
  directionText,
  portraitSrc,
  children,
  imageWidth,
  textWidth,
  containerCSS = '',
  portraitCSS = '',
  textCSS = '',
}) => {
  const portrait = (
    <PortraitContainer
      direction={directionText}
      css={portraitCSS}
      width={imageWidth}
      key="portrait">
      <PortraitImage src={portraitSrc} />
    </PortraitContainer>
  );
  const textBlock = (
    <TextContainer css={textCSS} width={textWidth} key="text">
      {children}
    </TextContainer>
  );

  const content = [portrait, textBlock];

  return (
    <Base css={containerCSS}>
      {directionText === 'right' ? content : content.reverse()}
    </Base>
  );
};

export default LandingTextBlock;
