import React, {memo, useCallback, useEffect, useState} from 'react';
import './styles.scss';
import {Base} from './styles';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {connect} from 'react-redux';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {useStyles} from '../Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HistoryService from '../../../utils/HistoryService';
import clsx from 'clsx';
import SearchBy from '../../SearchBy';
import {Checkbox, InputBase, MenuItem, Select, FormControl, InputAdornment, ListSubheader, TextField} from '@mui/material';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ManageCategorySteps from '../ManageCategoryList/ManageCategorySteps';
import {getMCLSteps, setMCLSteps} from '../../../utils/HelperService';
import HomeSteps from '../Home/HomeSteps';
import GigaSpinner from '../../UI/GigaSpinner';
import InputLabel from '@mui/material/InputLabel'

function LinkKeyFactors(props) {
  const externalId = props.location?.state?.externalId ?? -1;
  const [data, loadingKeyFactors, errorKeyFactors, callKeyFactors] =
    useFetchContent(
      [],
      MemberHomepageService.getLinkKeyFactorsForManagement,
      useCallback(({data}) => {
        setClassifications(data.classifications);
        setLinkKeyFactors(data.linked_key_factors);
        setUnLinkedKeyFactors(data.unlinked_key_factors);
        return data;
      }, []),
    );

  const [searchText, setSearchText] = useState('');
  const [classifications, setClassifications] = useState([]);
  const [linkKeyFactors, setLinkKeyFactors] = useState([]);
  const [unLinkedKeyFactors, setUnLinkedKeyFactors] = useState([]);
  const [filterClassification, setFilterClassification] = useState();
  const [searchString, setSearchString] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [selectedLinkItems, setSelectedLinkItems] = useState([]);
  const [selectedUnLinkItems, setSelectedUnLinkItems] = useState([]);

  useEffect(() => {
    const steps = getMCLSteps();
    if (steps.length > 1) {
      setMCLSteps(steps.slice(0, 1));
    }

    callKeyFactors(externalId);
  }, []);

  const handleClickItem = item => {
    const isChecked = item.html_element_value === 'checked';
    if (item.unlinked_key_factor_id) {
      setUnLinkedKeyFactors(
        unLinkedKeyFactors.map(v =>
          v.unlinked_key_factor_id === item.unlinked_key_factor_id
            ? {
                ...item,
                html_element_value: isChecked ? '' : 'checked',
              }
            : v,
        ),
      );
      if (isChecked) {
        setSelectedUnLinkItems(
          selectedUnLinkItems.filter(
            v => v.unlinked_key_factor_id !== item.unlinked_key_factor_id,
          ),
        );
      } else {
        setSelectedUnLinkItems([...selectedUnLinkItems, item]);
      }
    } else {
      setLinkKeyFactors(
        linkKeyFactors.map(v =>
          v.linked_key_factor_id === item.linked_key_factor_id
            ? {
                ...item,
                html_element_value: isChecked ? '' : 'checked',
              }
            : v,
        ),
      );
      if (!isChecked) {
        setSelectedLinkItems(
          selectedLinkItems.filter(
            v => v.linked_key_factor_id !== item.linked_key_factor_id,
          ),
        );
      } else {
        setSelectedLinkItems([...selectedLinkItems, item]);
      }
    }
  };

  const handleApply = itemKey => {
    if (selectedLinkItems.length === 0 && selectedUnLinkItems.length === 0) {
      return;
    }

    let body = {};

    [...linkKeyFactors, ...unLinkedKeyFactors].forEach(v => {
      if (v.html_element_value === 'checked') {
        body = {...body, [v.html_element_name]: '1'};
      }
    });

    setIsLoading(true);
    MemberHomepageService.checkLinkKeyFactor({
      external_id: externalId,
      ...body,
    }).then(async res => {
      await callKeyFactors(externalId);
      setIsLoading(false);
      setSelectedUnLinkItems([]);
      setSelectedLinkItems([]);
      // if (itemKey === 'linked') {
      //   let newLinkItems = linkKeyFactors;
      //   let newUnLinkItems = unLinkedKeyFactors;
      //   selectedLinkItems.forEach(item => {
      //     newLinkItems = linkKeyFactors.filter(
      //       v => v.linked_key_factor_id !== item.linked_key_factor_id,
      //     );
      //
      //     newUnLinkItems = [
      //       ...unLinkedKeyFactors,
      //       {
      //         ...item,
      //         unlinked_key_factor_id: item.linked_key_factor_id,
      //         unlinked_key_factor_count: item.linked_key_factor_count,
      //         unlinked_key_factor_description:
      //           item.linked_key_factor_description,
      //         unlinked_key_factor_name: item.linked_key_factor_name,
      //         unlinked_key_factor_rating_id: item.linked_key_factor_rating_id,
      //         unlinked_key_factor_rating_name:
      //           item.linked_key_factor_rating_name,
      //         html_element_value: '',
      //       },
      //     ];
      //   });
      //   setSelectedLinkItems([]);
      //   setUnLinkedKeyFactors(newUnLinkItems);
      //   setLinkKeyFactors(newLinkItems);
      // } else {
      //   let newLinkItems = linkKeyFactors;
      //   let newUnLinkItems = unLinkedKeyFactors;
      //   selectedUnLinkItems.forEach(item => {
      //     newUnLinkItems = newUnLinkItems.filter(
      //       v => v.unlinked_key_factor_id !== item.unlinked_key_factor_id,
      //     );
      //
      //     newLinkItems = [
      //       ...newLinkItems,
      //       {
      //         ...item,
      //         linked_key_factor_id: item.unlinked_key_factor_id,
      //         linked_key_factor_count: item.unlinked_key_factor_count,
      //         linked_key_factor_description:
      //           item.unlinked_key_factor_description,
      //         linked_key_factor_name: item.unlinked_key_factor_name,
      //         linked_key_factor_rating_id: item.unlinked_key_factor_rating_id,
      //         linked_key_factor_rating_name:
      //           item.unlinked_key_factor_rating_name,
      //         html_element_value: 'checked',
      //       },
      //     ];
      //   });
      //   setUnLinkedKeyFactors(newUnLinkItems);
      //   setLinkKeyFactors(newLinkItems);
      //   setSelectedUnLinkItems([]);
      // }
    });
  };

  const classes = useStyles();

  const renderTable = (title, keyFactors, itemKey) => {
    return (
      <div className="mt-1 mb-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div
              className={
                'pt-4 flex flex-row items-center justify-between px-4'
              }>
              <span className={'font-semibold'}>{title}</span>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  handleApply(itemKey);
                }}
                className="bg-giga-primary-blue_300 hover:opacity-70 text-white py-1.5 px-4 rounded text-sm">
                Apply
              </a>
            </div>
            <table className="min-w-full divide-y divide-gray-200 mt-4">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">CheckBox</span>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Classification
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Rating
                  </th>
                </tr>
              </thead>
              <tbody>
                {keyFactors
                  .filter(
                    v =>
                      (v[`${itemKey}_key_factor_description`] ===
                        filterClassification ||
                        filterClassification == null) &&
                      ((v[`${itemKey}_key_factor_name`] ?? '')
                        .toLowerCase()
                        .includes(searchString.toLowerCase()) ||
                        (v[`${itemKey}_key_factor_rating_name`] ?? '')
                          .toLowerCase()
                          .includes(searchString.toLowerCase())),
                  )
                  .map((item, index) => {
                    const name = item[`${itemKey}_key_factor_name`];
                    const description =
                      item[`${itemKey}_key_factor_description`];
                    const cla = item[`${itemKey}_key_factor_rating_name`];
                    const isActive = true;
                    const isChecked = item['html_element_value'] === 'checked';
                    return (
                      <tr
                        key={`${index}`}
                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <th scope="col" className="relative px-6 py-3">
                          <Checkbox
                            color={'default'}
                            checked={isChecked}
                            onClick={() => handleClickItem(item)}
                          />
                        </th>
                        <td
                          className={`px-6 py-4 text-sm text-gray-500 ${
                            !isActive ? '' : 'cursor-pointer underline'
                          }`}
                          onClick={() => {}}>
                          {name}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {description}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {cla}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          Link Key Factors
        </span>
      </Base>

      <Base className={'flex flex-row px-4 md:px-0'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={props.manageSteps}
          handleClickStep={() => {}}
        />
      </Base>

      <Base className={'flex flex-row px-4 md:px-0'}>
        <ManageCategorySteps />
      </Base>

      <Base
        className={'flex flex-col justify-center px-4 md:px-0'}
        loading={false}
        error={false}>
        <div className={'grid grid-cols-1 md:grid-cols-3 gap-4'}>
          <GigaSpinner isLoading={loadingKeyFactors} />
          <div className={'col-span-2'}>
            <SearchBy
              className={clsx('flex flex-1')}
              placeholder="Search by Name and Rating"
              searchInputProps={{
                value: searchString,
                onChange: e => {
                  setSearchString(e.target.value);
                },
              }}
            />
          </div>
          <div className={'col-span-1'}>
            <div className={'border rounded-lg p-1 mx-0.5 bg-white'}>
              <FormControl fullWidth>
                {/*<InputLabel id="search-select-label">Options</InputLabel>*/}
              <Select
                displayEmpty
                // autoFocus={false}
                MenuProps={{ autoFocus: false }}
                classes={{root: classes.root}}
                input={<InputBase classes={{root: classes.inputRoot}} />}
                labelId="search-select-label"
                id="select-filter"
                value={filterClassification ?? ''}
                IconComponent={ExpandMoreIcon}
                onClose={() => setSearchText('')}
                onChange={event => setFilterClassification(event.target.value)}
                renderValue={() => filterClassification}
                style={{ paddingLeft: '10px' }}
              >
                <ListSubheader style={{width: '100%'}}>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => setSearchText(e.target.value)}
                    onKeyDown={e => {
                      if (e.key !== 'Escape') {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                {classifications
                  .filter(v =>
                    (v.classification ?? '')
                      .toLowerCase()
                      .includes(searchText.toLowerCase()),
                  )
                  .map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.classification}
                      classes={{gutters: classes.menuList}}>
                      {item.classification}
                    </MenuItem>
                  ))}
              </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {renderTable('Linked Key Factors', linkKeyFactors, 'linked')}
        {(filterClassification || searchString !== '') &&
          renderTable('UnLinked Key Factors', unLinkedKeyFactors, 'unlinked')}
        <GigaSpinner isLoading={isLoading} />
      </Base>
    </>
  );
}

  const mapStateToProps = state => ({
    displayCategories: state.UserData.displayCategories,
    displayModes: state.UserData.displayModes,
    messageModeSelected: state.UserData.messageModeSelected,
    manageSteps: state.UserData.manageSteps,
    manageCurrentCategory: state.UserData.manageCurrentCategory,
    searchString: state.UserData.searchString,
  });

  const mapDispatchToProps = dispatch => ({
    actions: {
      updateDisplayCategories: categories => {
        dispatch(userActions.updateDisplayCategories(categories));
      },
      updateMessageModeSelected: flag =>
        dispatch(userActions.updateMessageModeSelected(flag)),
      onResetUser: () => dispatch(resetUser()),
      updateManageCurrentCategory: c =>
        dispatch(userActions.updateManageCurrentCategory(c)),
      updateManageSteps: steps => dispatch(userActions.updateManageSteps(steps)),

      updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
    },
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(LinkKeyFactors));
