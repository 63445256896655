import React from 'react';

import {Base, TitleContainer, TitleDescription} from './styles';

const EntityHeader = ({title, description}) => (
  <Base>
    <div className={'flex flex-col space-y-2 flex-1 items-center'}>
      <TitleContainer
        className={title?.length >= 60 ? 'ellipsis' : ''}
        title={title}>
        {title}
      </TitleContainer>

      <TitleDescription>{description}</TitleDescription>
    </div>

    {/*<IconButton size="small">*/}
    {/*  <MoreIcon className="Icon" size="small" />*/}
    {/*</IconButton>*/}
  </Base>
);

export default EntityHeader;
