import React, {memo} from 'react';
import {connect} from 'react-redux';

import Input from 'Components/DesignSystem/inputs/base-input';
import ErrorIndicator from 'Components/DesignSystem/error/error-indicator';

import FormContainer from 'hoc/FormContainer';
import Loader from 'hoc/Loader';

import {Center, ErrorDisplay, ReCAPTCHAContainer} from './styles';

import {setErrorMessage} from './bl';

const Form = ({
  onSubmit,
  stateError,
  change,
  isCaptchaVerified,
  setIsCaptchaVerified,
  requestError,
  inputError,
  errorMessage,
  loading,
  isDana,
}) => {
  console.log('Form render');

  const onChangeReCAPTCHA = value => {
    setIsCaptchaVerified(!!value);
  };

  let currentError = null;

  if (requestError) {
    currentError = 'There was a problem... Please, try again.';
  } else if (inputError) {
    currentError = errorMessage;
  }

  return (
    <FormContainer
      onSubmit={onSubmit}
      disabledSubmit={!isCaptchaVerified || stateError.state !== 'success'}
      currentError={currentError}
      loading={loading}
      isDana={isDana}>
      <Input
        name="Email"
        label="Email"
        placeholder="Your email"
        type="email"
        onChange={change}
        statusError={stateError.state}
      />

      <ErrorDisplay>
        <ErrorIndicator status={stateError.state} />
        {setErrorMessage(stateError)}
      </ErrorDisplay>

      <Center>
        <Loader>
          <ReCAPTCHAContainer
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={onChangeReCAPTCHA}
          />
        </Loader>
      </Center>
    </FormContainer>
  );
};

const mapStateToProps = ({
  initForm: {isThereRequestError, isThereInputError, errorMessage, loading},
}) => ({
  requestError: isThereRequestError,
  inputError: isThereInputError,
  errorMessage,
  loading,
});

export default connect(mapStateToProps)(memo(Form));
