import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

import InputLabel from './input-label';
import ErrorIndicator from '../error/error-indicator';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import device from 'mediaQueries';

const InputBase = styled.input`
  background-color: ${({dark}) => (dark ? '#E8F0FE' : 'transparent')};
  border: none;
  border-radius: 0.25rem;
  border-bottom: 1px solid white;
  color: ${({disabled, dark}) =>
    disabled ? 'rgba(255, 255, 255, .5)' : dark ? 'rgba(0, 0, 0, .7)' : '#fff'};
  padding: 8px;
  font-weight: bold;
  font-size: 22px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: white;
    font-size: 22px;
    font-weight: bold;
    opacity: 0.5;

    @media ${device.desktopL} {
      font-size: 28px;
    }
  }

  @media ${device.desktopL} {
    font-size: 28px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  position: relative;
`;

const useStyles = makeStyles({
  eyePassword: {
    width: '25px',
    height: '25px',
    cusor: 'pointer',
    position: 'absolute',
    right: 30,
    top: '47%',
    color: ({dark}) => (dark ? '#000' : '#fff'),
  },
});

const Input = ({
  label,
  placeholder = '',
  name,
  type,
  onChange,
  onKeyDown,
  onClear,
  statusError,
  withoutError,
  disabled,
  password,
  dark,
  labelRequired,
  optional,
  ...rest
}) => {
  const [isPassword, setIsPassword] = useState(true);

  const classes = useStyles({dark});

  const togglePassword = () => {
    setIsPassword(prevPassword => !prevPassword);
  };

  const EyeComponent = isPassword
    ? VisibilityOutlinedIcon
    : VisibilityOffOutlinedIcon;

  return (
    <InputContainer>
      <InputLabel dark={dark} labelRequired={labelRequired} optional={optional}>
        {label}
      </InputLabel>
      <InputBase
        placeholder={placeholder}
        type={password ? type(isPassword) : type}
        name={name}
        onChange={onChange}
        onClear={onClear}
        disabled={disabled}
        dark={dark}
        {...rest}
      />
      {password && (
        <EyeComponent
          onClick={togglePassword}
          className={classes.eyePassword}
        />
      )}
      {!withoutError && labelRequired && (
        <ErrorIndicator absolute status={statusError} />
      )}
    </InputContainer>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClear: PropTypes.func,
  hasError: PropTypes.bool,
};

Input.defaultProps = {
  hasError: false,
};

export default Input;
