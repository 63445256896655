import React from 'react';

import CloseIcon from '@material-ui/icons/Close';

import {Base, Wrapper} from './styles';
import {capitalize} from './bl';

import Portrait from './Portrait';
import AttachmentContent from './AttachmentContent';

const Attachment = ({
  id,
  type,
  src,
  fileName,
  clickedContent,
  handleRemove,
  hideRemove,
  marginRight = '1.25rem',
}) => {
  // type: 'image' | 'video' | 'link' | 'file' | 'audio'

  const capType = capitalize(type);

  return (
    <Wrapper>
      <Base marginRight={marginRight} onClick={clickedContent}>
        <Portrait id={id} type={type} src={src} fileName={fileName} />
        <AttachmentContent type={capType} src={src} fileName={fileName} />
      </Base>

      {!hideRemove && (
        <div className="RemoveButton" onClick={handleRemove}>
          <CloseIcon />
        </div>
      )}
    </Wrapper>
  );
};

export default Attachment;
