import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hoverFocus: {
    '&:hover': {
      // color: '#fff',
    },
    '&.Mui-focusVisible': {
      // color: '#fff',
    },
    '&.focused:not(.Mui-focusVisible):not($checked)': {
      // color: '#fff',
    },
  },
}));

export default useStyles;
