import React, {useCallback, useState} from 'react';

import Header from 'Components/MemberHomepage/HeaderPage';
import VerticalNav from 'Components/MemberHomepage/VerticalNav';
import Drawer from 'Components/MemberHomepage/Drawer';

import {Base, ContentContainer} from './styles';
import {useGetDBName} from './bl';
import Constants from '../../Constants/Constants';
import {useLocation} from 'react-router-dom'

const dbDisplayInfo = {
  initial: {
    verticalNav: false,
    search: false,
    subTitle: false,
    loading: true,
  },
  [Constants.GIGA_GUEST]: {
    verticalNav: false,
    search: true,
    subTitle: true,
  },
  [Constants.GIGA_RAISINGYOURINNERVOICE]: {
    verticalNav: false,
    search: true,
    subTitle: true,
  },
  [Constants.GIGA_INTELLIGENCE]: {
    verticalNav: true,
    search: true,
    subTitle: true,
  },
  [Constants.DANA_BARROS_BASKETBALL]: {
    verticalNav: false,
    search: false,
    subTitle: false,
    title: 'Dana Barros basketball',
    noNeedIcon: true,
  },
};

const HomeScheme = ({showHeaderSection, children}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dbName = useGetDBName();

  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);

  const modifyDrawer = useCallback(value => setIsDrawerOpen(value), []);

  const dbInfo =
    dbDisplayInfo[dbName] ?? dbDisplayInfo[Constants.GIGA_INTELLIGENCE];

  return (
    <Base topPadding={showHeaderSection ? '50px' : '0'}>
      <Drawer
        isDrawerOpen={isDrawerOpen}
        handleDrawerClose={modifyDrawer.bind(null, false)}
      />

      {showHeaderSection && dbName !== 'initial' && (
        <Header
          noNeedIcon={dbInfo.noNeedIcon}
          showSearch={dbInfo.search}
          showSubTitle={dbInfo.subTitle}
          title={dbInfo.title}
          loading={dbInfo.loading}
          handleBurgerClick={modifyDrawer.bind(null, true)}
          // bgColor={dbName === Constants.GIGA_MEDIA ? 'white' : '#2CA3FF'}
          bgColor={'white'}
          variant={dbName}
        />
      )}

      <ContentContainer>{children}</ContentContainer>

      {/*{dbInfo.verticalNav !== false && <VerticalNav />}*/}
    </Base>
  );
};

export default HomeScheme;
