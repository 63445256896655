export const getOpacityTab = (indexTopic, index, isCompleted) => {
  let opacity = '0.5';

  if (isCompleted) {
    opacity = '0.8';
  }

  if (indexTopic === index) {
    opacity = '1';
  }

  return opacity;
};
