export const checkAbsolute = absolute => {
  if (absolute) {
    return `
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: 5px;
    `;
  }

  return `
    display: inline-block;
    margin-right: 12px;
  `;
};

export const checkColorStatus = status => {
  switch (status) {
    case 'error':
      return '#EA5F7B';
    case 'success':
      return '#5FEAAF';
    default:
      return '#C4C4C4';
  }
};
