import React from 'react';

import Button from 'Components/DesignSystem/button/button';

import {ButtonsContainer} from './styles';

const ButtonsContainerComp = ({
  buttons,
  advanceIndexPhase,
  handleSubmit,
  submitText,
}) => (
  <ButtonsContainer hasButtons={!!buttons}>
    {buttons ? (
      buttons(advanceIndexPhase)
    ) : (
      <Button onClick={handleSubmit} style={{margin: '0 20px'}}>
        {submitText}
      </Button>
    )}
  </ButtonsContainer>
);

export default ButtonsContainerComp;
