import {convertQueryParamsToObject} from '../../../../../Helpers/api';

export const validateAndStoreAssessmentSummary = (
  topicsInfo,
  setTopicsArr,
  historyPush,
) => {
  if (Object.keys(topicsInfo).length > 0) {
    console.log('topicsInfo', topicsInfo);
    const {assessment_summary, new_content_edit_link} = topicsInfo;

    if (assessment_summary?.length > 0) {
      const result = assessment_summary.map(topic => ({
        ...topic,
        selected: topic.assessment_topic_selection !== 'NULL',
      }));

      // // The required topics are ordered as the first in the array
      // result.sort(
      //   (a, b) =>
      //     (a.assessment_topic_required === '0') -
      //     (b.assessment_topic_required === '0')
      // );

      setTopicsArr(result);
    } else if (new_content_edit_link !== '') {
      const {
        freeform_id,
        topic_id,
        external_id,
        cid,
        mode_id,
        category_id,
        parent_id,
        parent_type,
      } = convertQueryParamsToObject(new_content_edit_link);
      setTopicsArr([
        {
          selected: false,
          assessment_subtopics_url: new_content_edit_link,
          assessment_topic_id: topic_id,
          assessment_freeform_id: freeform_id,
          assessment_cid: cid,
          assessment_parent_id: parent_id,
          assessment_parent_type: parent_type,
          id: 0,
        },
      ]);
    } else {
      historyPush('/404');
    }
  }
};

export const setFormattedSections = (sectionsInfo, setSections) => {
  if (sectionsInfo.length > 0) {
    setSections([
      {
        id: -1,
        isSummary: true,
        label: 'Summary',
        topicsCompleted: [],
      },
      ...sectionsInfo.map(({kf_id, kf_name, cid, ...rest}) => ({
        id: kf_id,
        label: kf_name,
        isSummary: false,
        kf_cid: cid,
        topicsCompleted: [],
        cid,
        kf_id,
        kf_name,
        ...rest,
      })),
    ]);
  }
};
