import React from 'react';
import {withRouter} from 'react-router-dom';

import AssesmentPreview from './AssesmentPreview';

import {Base, NoAssesmentsText} from './styles';

import {generateAssessmentLink} from '../bl';
import HistoryService from '../../../../utils/HistoryService'; // This component is for example purposes. WIll likely change in a near future.

// This component is for example purposes. WIll likely change in a near future.

const Assesments = ({arrAssesments, catEntity, cidEntity, techIdEntity}) => {
  const handleAssesmentClick = generateAssessmentLink(
    catEntity,
    cidEntity,
    techIdEntity,
    HistoryService.push,
  );

  const assesmentItems = arrAssesments
    ? arrAssesments.map(
        ({
          assessorName,
          assessorType,
          assessorID,
          narrative,
          assessment_link,
          currentAssessment,
          futureAssessment,
          assessorPic,
        }) => (
          <AssesmentPreview
            key={assessorName}
            userName={assessorName}
            assessorPerspective={assessorType}
            currentAssessment={currentAssessment}
            futureAssessment={futureAssessment}
            narrative={narrative}
            assessorPic={assessorPic}
            clicked={mode =>
              handleAssesmentClick(assessment_link, assessorID, mode)
            }
          />
        ),
      )
    : null;

  return (
    <Base>
      {assesmentItems ? (
        assesmentItems
      ) : (
        <NoAssesmentsText>
          There are no assesments for this entity.
        </NoAssesmentsText>
      )}
    </Base>
  );
};

export default withRouter(Assesments);
