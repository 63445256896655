import React from 'react';
import RatingsBlock from '../RatingsBlock';
import GGDateTimePicker from './GGDateTimePicker';
import PreviewRatings from '../../../../../../EntityListing/Assesments/AssesmentPreview/PreviewRatings';
import moment from 'moment';

export default function ExtraRatingsBlock({
  extraRatings,
  setExtraRatings,
  isEditable = true,
}) {
  const handleChange = (extraRating, selRating) => {
    setExtraRatings(extraRating, selRating);
  };

  return (
    <div>
      {extraRatings.map((extraRating, index) => (
        <div key={index}>
          {extraRating.ratings_html_type === 'dropdown' &&
            (isEditable ? (
              <RatingsBlock
                ratingTitle={extraRating.ratings_title}
                ratingOptions={extraRating.ratings_options.split(',')}
                selectedRating={extraRating.ratings_selection}
                setNewSelectedRating={selRating =>
                  handleChange(extraRating, selRating)
                }
                isEditable={isEditable}
              />
            ) : (
              <div>
                <div className={'mb-4'}>
                  <span className={'font-semibold'}>
                    {extraRating.ratings_title}:{' '}
                  </span>
                  <span>{extraRating.ratings_selection}</span>
                </div>
              </div>
            ))}
          {extraRating.ratings_html_type === 'datetime' && (
            <GGDateTimePicker
              label={extraRating.ratings_title}
              onChange={selChange => handleChange(extraRating, selChange)}
              topicValue={extraRating.ratings_selection}
              isEditable={isEditable}
            />
          )}
        </div>
      ))}
    </div>
  );
}
