import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import useStyles from './styles';

const IconButtonBase = ({children, ...props}) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.hoverFocus} {...props}>
      {children}
    </IconButton>
  );
};

export default IconButtonBase;
