import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {FormGroup} from '@material-ui/core';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import GigaMenu from '../../UI/GigaMenu';
import HistoryService from '../../../utils/HistoryService';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: '1rem',
  },
  listItem: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  label: {
    color: 'white',
  },
}));

export default function NestedList(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const {
    title,
    items,
    selectedItems = [],
    renderTitle,
    renderContent,
    handleChangeItem,
    disabled,
    masterEntityType,
  } = props;

  const onClickCreate = () => {
    HistoryService.push({
      pathname: `/home/subscription/-1`,
      state: {
        masterEntityType,
      },
    });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const onClick = (editMode, item) => {
    console.log(editMode);
    console.log(item);
    if (editMode === 'Edit') {
      HistoryService.push({
        pathname: `/home/subscription/${item.list_id}`,
        state: {
          masterEntityType,
          entity: {
            entity_name: item.list_name,
            entity_description: item.list_description,
          },
        },
      });
    }
  };

  return (
    <div className={`${disabled ? 'opacity-50 pointer-events-none' : ''}`}>
      <List component="nav">
        <ListItem
          button
          onClick={handleClick}
          classes={{root: classes.listItem}}>
          {renderTitle ? (
            renderTitle()
          ) : (
            <ListItemText
              primaryTypographyProps={{
                style: {
                  color: 'white',
                },
              }}
              primary={title}
            />
          )}
          {open ? (
            <ExpandLess color={'primary'} style={{color: 'white'}} />
          ) : (
            <ExpandMore color={'primary'} style={{color: 'white'}} />
          )}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {renderContent ? (
            renderContent()
          ) : (
            <PerfectScrollbar className={'md:max-h-96 lg:max-h-80'}>
              <FormGroup className={classes.nested}>
                {items.map((item, index) => {
                  const isChecked = selectedItems.some(s => s.id === item.id);
                  return (
                    <div
                      key={`${index}`}
                      className={
                        'flex flex-row items-center mt-1.5 cursor-pointer'
                      }
                      onClick={() => handleChangeItem(item)}>
                      <span
                        className={`ml-2 w-5 h-5 rounded-full ${
                          isChecked ? 'bg-white' : 'bg-gray-200'
                        }`}
                      />
                      <span
                        className={
                          'text-white font-semibold ml-3 transform transition ease-out duration-300 hover:scale-105'
                        }>
                        {item.name}
                      </span>
                      <div className="ml-auto cursor-pointer text-white">
                        <GigaMenu
                          iconClass={'text-white'}
                          options={['Edit', 'Delete'].map(m => ({
                            label: m,
                            action: () => {
                              onClick(m, item);
                            },
                          }))}
                        />
                      </div>
                      {/*<span*/}
                      {/*  className={*/}
                      {/*    'text-white font-medium text-sm ml-auto cursor-pointer'*/}
                      {/*  }>*/}
                      {/*  Delete*/}
                      {/*</span>*/}
                    </div>
                  );
                })}
                <span
                  onClick={e => onClickCreate(e)}
                  className={
                    'text-white font-bold text-sm cursor-pointer mt-4 ml-7 transform transition ease-out duration-300 hover:scale-105 self-start'
                  }>
                  Create new list
                </span>
              </FormGroup>
            </PerfectScrollbar>
          )}
        </Collapse>
      </List>
    </div>
  );
}
