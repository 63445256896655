import React from 'react';

import FormContainer from 'hoc/FormContainer';

import Inputs from './Inputs';
import Indicators from './Indicators';

const Form = ({
  onSubmit,
  changePassword,
  password,
  changeConfirmPassword,
  statusPassword,
  statusConfirmPassword,
  ...formContainerProps
}) => (
  <FormContainer
    onSubmit={onSubmit}
    disabledSubmit={!statusPassword || !statusConfirmPassword}
    {...formContainerProps}>
    <Inputs
      statusPassword={statusPassword}
      changePassword={changePassword}
      statusConfirmPassword={statusConfirmPassword}
      changeConfirmPassword={changeConfirmPassword}
    />

    <Indicators password={password} />
  </FormContainer>
);

export default Form;
