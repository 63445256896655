import React, {createContext, memo, useCallback, useEffect, useMemo, useState} from 'react'
import { topicContext } from "Contexts/TopicContext";
import DescPage from 'Components/MemberHomepage/Definitions/DescPage';
import TopicDefinition from './TopicDefinition';
import TopicExample from './TopicExample';
import Alert from 'Components/UI/Alert';
import Button from 'Components/DesignSystem/button/button';
import './styles.css';
import {Base, PlainInput, useStyles} from './styles';
import {Base as HeaderContainer} from '../../../DescPage/styles';
import {
  callCurrentTopicInfo,
  initTopicContent,
  setNewTopicContentData,
} from './bl';

import useFetchContent from 'hooks/useFetchContent';

import MemberHomepageService from 'Services/memberHomepage';
import {TitleForm} from './TopicDefinition/styles';
import {DropzoneArea} from 'material-ui-dropzone';
import Constants, {
  displayModesNames,
} from '../../../../../../Constants/Constants';
import HSContainer from '../../../../../UI/HSContainer';
import {HeaderSection, StyledNavLink} from '../../../ModeSelector/styles';
import {
  HiOutlineArrowLeft,
  HiOutlineArrowRight,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from 'react-icons/hi';
import {connect} from 'react-redux';
import {
  getHiddenItems,
  getItem,
  getUserToken,
  storeCategoryMode,
  storeEntityParams,
  storeItem, updateHiddenItems,
} from '../../../../../../utils/HelperService'
import * as userActions from '../../../../../../Store/sagas/UserSaga/actions';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import CreateNewMessage from '../../../../../../Routes/Containers/GigaMessage/CreateNewMessage';
import GGModal from '../../../../../Modal';
import GigaMessage from '../../../../../../Routes/Containers/GigaMessage';
import GigaMenu from '../../../../../UI/GigaMenu';
import MessageDetailModal from './MessageDetailModal';
import ContributorsDetailModal from './ContributorsDetailModal';
import PostSuccessModal from './PostSuccessModal';
import ShareBlock from './ShareBlock';
import Calendar from 'react-calendar';
import DateTimeUtil from '../../../../../../utils/DateTimeUtil';
import EventDetailModal from './EventDetailModal';
import {isSameDay} from 'date-fns';
import EventList from './EventList';
import moment from 'moment';
import EventInfoModal from './EventInfoModal';
import GoBackSubject from '../../../../AssesmentView/GoBackSubject';
import {useLocation, useParams} from 'react-router-dom'
import {usePubNub} from 'pubnub-react'
import HistoryService from "../../../../../../utils/HistoryService";

const TopicSubmission = ({
                           currentTopic = {},
                           techId,
                           errorTopicMessage,
                           setErrorTopicMessage,
                           setLoadingTopic,
                           completeTopic,
                           completeAssessment,
                           errorAssessment,
                           currentSection,
                           secondButtonHidden,
                           mode,
                           displayCategories,
                           creationModes,
                           creationCategories,
                           actions,
                         }) => {
  const classes = useStyles();
  const history = useHistory();
  const pubnub = usePubNub();

  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);

  const isLinkActive =
      searchQuery.get(Constants.SQ_ACTIVE) !== 'false' && getUserToken() != null;

  const [contributorsModalVisible, setContributorsModalVisible] =
      useState(false);
  const [eventModalVisible, setEventModalVisible] = useState(false);
  const [eventInfoModalVisible, setEventInfoModalVisible] = useState({
    visible: false,
    eventInfo: {},
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [historyList, setHistoryList] = useState([]);
  const [topic, setTopic] = useState(getItem(Constants.AS_MESSAGE_ROOM_TOPIC));
  const [chatMessageCount, setChatMessageCount] = useState(0);

  const [localCreationCategories, setLocalCreationCategories] =
      useState(creationCategories);

  let [topicInfoTemp, loadingTopic, errorTopic, callTopicInfo] = useFetchContent(
      {},
      MemberHomepageService.postTopicInfo,
      useCallback(({data}) => data, []),
  );

  const [isPosted, setIsPosted] = useState(
      localStorage.getItem(Constants.AS_POSTED_STATUS) === 'yes',
  );

  const [isEditMode, setIsEditMode] = useState(false);
  const [topicContent, setTopicContent] = useState([]);
  const [topicInfo, setTopicInfo] = useState([]);
  const [eventsList, setEventsList] = useState([]);

  const [loadingSaveTopic, setLoadingSaveTopic] = useState(false);
  const [loadingDoneSaveTopic, setLoadingDoneSaveTopic] = useState(false);
  const [messageResultTopic, setMessageResultTopic] = useState();

  const isMessageMode = mode === 'message';
  const showCompleteBtn = mode === 'assessment';
  const specificTitle = mode !== 'assessment' ? displayModesNames(mode) : null;
  let submitBtnTitle = showCompleteBtn
      // ? 'Submit My Topic Assessment'
      ? 'Next'
      : 'Submit';

  const isCalendarMode = mode === 'calendar';
  const isEventMode = mode === 'event';

  let [extraRatings, setExtraRatings] = useState();
  const [selectedCV, setSelectedCV] = useState(new Date());
  const canShowEvents = !DateTimeUtil.isSameDate(selectedCV, new Date());

  if (isMessageMode) {
    submitBtnTitle = isEditMode ? 'Send' : 'Share';
  }

  const [assessmentTitle, setAssessmentTitle] = useState('');
  const [assessmentDes, setAssessmentDes] = useState('');
  const [assessmentImage, setAssessmentImage] = useState(null);

  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([
    {id: 1, name: 'Boston Celtics Players'},
    {id: 2, name: 'Coronavirus COVID-19 - Variants'},
    {id: 3, name: 'Houses'},
    {id: 4, name: 'New car dealers'},
  ]);

  let reactTags = React.useRef();

  const onDelete = i => {
    const newTags = tags.slice(0);
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const onAddition = tag => {
    const newTags = [].concat(tags, tag);
    setTags(newTags);
  };


  useEffect(() => {
    if (isCalendarMode && isEditMode) {
      MemberHomepageService.fetchCalendarPerDay(selectedCV).then(res => {
        if (res.data?.events) {
          setEventsList(res.data.events);
        }
      });
    }
  }, [isCalendarMode, isEditMode]);

  useEffect(() => {
    if (getUserToken() != null) {
      actions.fetchUserData();
    }
  }, []);

  useEffect(() => {
    callCurrentTopicInfo(currentTopic, callTopicInfo, techId, currentSection);
  }, [callTopicInfo, currentTopic, techId, currentSection]);

  const hidden = topicInfoTemp?.hidden ?? {};

  useEffect(() => {
    // initTopicContent(topicInfo, setTopicContent);
    // setTopicContent(await initTopicContent(topicInfo));

    (async () => {
      const topicContentVal = await initTopicContent(topicInfoTemp);
      setTopicContent(topicContentVal);
      setTopicInfo(topicInfoTemp)
      console.log("ggg", topicContentVal);
      console.log('topicInfo', topicInfoTemp);

    })();


    setAssessmentTitle(topicInfo.assessment?.assessment_name);
    setAssessmentDes(topicInfo.assessment?.assessment_description);
    if (topicInfo.assessment_summary) {
      if (topicInfo.assessment_summary[0].extra_ratings) {
        let newExtraRatings = [];
        topicInfo.assessment_summary[0].extra_ratings.forEach(e =>
            newExtraRatings.push(e),
        );
        setExtraRatings(newExtraRatings);
      }
    }

    setIsEditMode(
        !Object.keys(hidden).some(
            hKey =>
                hidden[hKey].name === 'external_id' && hidden[hKey].value === '-1',
        ),
    );

  }, [topicInfoTemp]);

  useEffect(() => {
    setLoadingTopic(loadingTopic);
  }, [loadingTopic, setLoadingTopic]);

  const setNewHTMLContent = useCallback((assessment_topic, newValue) => {
    setTopicContent(prevTopicContent =>
        setNewTopicContentData(
            'htmlContent',
            prevTopicContent,
            assessment_topic,
            newValue,
        ),
    );
  }, []);

  const setNewAssetsBlockData = useCallback((assessment_topic, newValue) => {
    setTopicContent(prevTopicContent =>
      setNewTopicContentData(
        'assetsBlockData',
        prevTopicContent,
        assessment_topic,
        newValue,
      ),
    );
  }, []);

  const setNewSelectedRating = useCallback((assessment_topic, newValue) => {
    setTopicContent(prevTopicContent =>
        setNewTopicContentData(
            'selectedRating',
            prevTopicContent,
            assessment_topic,
            newValue,
        ),
    );
  }, []);


  const onAddMembers = async selectedMembers => {
    setModalVisible(false);

    // await MemberHomepageService.postRecipients(
    //   topicInfo.hidden,
    //   channel_id,
    //   selectedMembers,
    // )

    // history.push('/home')
    continueSaveTopic(selectedMembers);
  };

  const handleSaveTopic = () => {
    setErrorTopicMessage('');

    // if (isMessageMode && !isEditMode) {
    //   setModalVisible(true);
    // } else {
    //   continueSaveTopic();
    // }
    continueSaveTopic();
  };

  const continueSaveTopic = async selectedMembers => {
    console.log('extraRatings', extraRatings);
    setLoadingSaveTopic(true);
    try {
      const resultTopic = await MemberHomepageService.postSaveTopic(
          topicInfo.hidden,
          topicInfo.assessment_summary,
          topicContent,
          currentSection.isSummary,
          assessmentTitle,
          assessmentDes,
          assessmentImage,
          currentSection,
          extraRatings,
          // selectedMembers,
      );

      console.log('resultTopic', resultTopic);
      let channel_id = '';
      let category_id = '';
      if (resultTopic.data.category_id != null) {
        category_id = resultTopic.data.category_id;
      }

      //  message_thread_id is only for message mode, should be new_content_id for other modes like posts, music, short vid
      if (resultTopic.data.message_thread_id != null) {
        channel_id = resultTopic.data.message_thread_id;   // same external_id
      }

      storeItem(topicInfo.hidden, Constants.AS_CURRENT_HIDDEN);

      if (resultTopic.data.external_id != null) {
        channel_id = resultTopic.data.external_id;
        updateHiddenItems(resultTopic.data.external_id);  // update initial topicinfo external_id after send or submit topic when creating new message or post from -1
      }

      if (resultTopic.data.new_content_id != null) {
        channel_id = resultTopic.data.new_content_id;   // same external_id
        updateHiddenItems(resultTopic.data.new_content_id);
      }
      console.log('channel_id', channel_id)
      console.log('category_id', category_id)

      storeItem(
          {channel_id, category_id},
          Constants.AS_NEW_CREATION_CONTENT_ID,
      );

      if (isMessageMode) {
        if (isEditMode) {
          setMessageResultTopic(resultTopic.data);
          await setLoadingSaveTopic(false);
          setChatMessageCount(chatMessageCount + 1);
          return;
        }
        // storeItem(
        //   {},
        //   Constants.AS_MESSAGE_ROOM_TOPIC,
        // )
        // history.push('/home/message')
        // setModalVisible(true)
        // await MemberHomepageService.postRecipients(
        //   topicInfo.hidden,
        //   channel_id,
        //   selectedMembers,
        // );
        // await setLoadingSaveTopic(false);
        // history.push('/home');
        // return;
      }
      await setLoadingSaveTopic(false);
      if (showCompleteBtn) {
        await completeTopic(topicContent, topicInfo.assessment_topic_required);
      } else {
        handlePosted();
      }
    } catch (error) {
      console.error(error);
      setErrorTopicMessage('There was a problem. Please try again later.');
      setLoadingSaveTopic(false);
    }
  };

  const doneSaveTopic = async selectedMembers => {
    setLoadingDoneSaveTopic(true);
    try {
      const resultTopic = await MemberHomepageService.postSaveTopic(
          topicInfo.hidden,
          topicInfo.assessment_summary,
          topicContent,
          currentSection.isSummary,
          assessmentTitle,
          assessmentDes,
          assessmentImage,
          currentSection,
          extraRatings,
          // selectedMembers,
      );

      console.log('resultTopic', resultTopic);
      let channel_id = '';
      let category_id = '';
      if (resultTopic.data.category_id != null) {
        category_id = resultTopic.data.category_id;
      }

      //  message_thread_id is only for message mode, should be new_content_id for other modes like posts, music, short vid
      if (resultTopic.data.message_thread_id != null) {
        channel_id = resultTopic.data.message_thread_id;   // same external_id
      }

      storeItem(topicInfo.hidden, Constants.AS_CURRENT_HIDDEN);

      if (resultTopic.data.external_id != null) {
        channel_id = resultTopic.data.external_id;
        updateHiddenItems(resultTopic.data.external_id);  // update initial topicinfo external_id after send or submit topic when creating new message or post from -1
      }

      if (resultTopic.data.new_content_id != null) {
        channel_id = resultTopic.data.new_content_id;   // same external_id
        updateHiddenItems(resultTopic.data.new_content_id);
      }
      console.log('channel_id', channel_id)
      console.log('category_id', category_id)

      storeItem(
          {channel_id, category_id},
          Constants.AS_NEW_CREATION_CONTENT_ID,
      );

      // if (isMessageMode) {
      //   if (isEditMode) {
      //     setMessageResultTopic(resultTopic.data);
      //     await setLoadingSaveTopic(false);
      //     setChatMessageCount(chatMessageCount + 1);
      //     return;
      //   }
      // }
      await setLoadingDoneSaveTopic(false);
      handlePosted();
    } catch (error) {
      console.error(error);
      setErrorTopicMessage('There was a problem. Please try again later.');
      setLoadingDoneSaveTopic(false);
    }
  };

  const handlePosted = () => {
    localStorage.setItem(Constants.AS_POSTED_STATUS, 'yes');
    // localStorage.setItem(
    //   Constants.AS_CURRENT_HIDDEN,
    //   JSON.stringify(topicInfo.hidden),
    // );
    currentSection.isSummary? setIsPosted(true) : HistoryService.goBack();
  };

  const initialImage = topicInfo?.assessment?.assessment_image ?? '';

  const onChangeCategory = (e, selectedCategory) => {
    e.preventDefault();
    const selCategory = selectedCategory;
    const selMode = creationModes[0];

    const tech_id = -1;
    storeEntityParams({
      mode_id: selMode.mode_id,
      mode_name: selMode.mode_name,
      category_id: selCategory.category_id,
      tech_id,
    });
    storeCategoryMode(selCategory);
    setLocalCreationCategories([selCategory]);
  };

  const setCategoriesPages = pageArr =>
      pageArr.map(category => (
          <StyledNavLink
              key={category.id}
              exact
              to={`/`}
              onClick={e => onChangeCategory(e, category)}
              style={{
                opacity: localCreationCategories.some(c => c.id === category.id)
                    ? 1
                    : 0.5,
              }}>
            {category.category}
          </StyledNavLink>
      ));

  const callBackFunction = value => {
    if (DateTimeUtil.isSameDate(value, new Date())) {
      if (
          eventsList.some(e =>
              DateTimeUtil.isSameDate(
                  value,
                  moment(e.event_start_datetime).toDate(),
              ),
          )
      ) {
        setEventModalVisible(true);
      }
    }
  };

  const renderHeader = hidden => {
    if (isEditMode && isMessageMode) {
      return (
          <div
              className={`flex-row mb-6 space-x-3 mx-1 items-center ${
                  hidden ? 'hidden lg:flex' : 'flex'
              }`}>
            <img
                src={initialImage}
                alt={`${name} Portrait`}
                className={'w-10 h-10 rounded-lg object-cover object-contain'}
            />
            <span className={'font-semibold text-black text-xl'}>
            {assessmentTitle}
          </span>
            <GigaMenu
                options={[
                  {
                    label: 'Details',
                    action: () => setDetailModalVisible(true),
                  },
                  {
                    label: 'Contributors',
                    action: () => setContributorsModalVisible(true),
                  },
                ]}
                // handleClickDetails={() => setDetailModalVisible(true)}
                // handleClickContributors={() => setContributorsModalVisible(true)}
            />
          </div>
      );
    } else {
      return null;
    }
  };

  //  creating context value ---  'prompt and freeform_id, topic_id'from hidden of topic info for composer request
  const queryParameters = new URLSearchParams(location.search)
  const catAssesment = queryParameters.get("cat");  // boston celtic players
  const cid = queryParameters.get('cid'); // gispbbnbaecatbcpl.574
  const promptq = catAssesment + ' ' + assessmentTitle;
  useEffect(() => {
    setPrompt(promptq)
  }, [catAssesment, assessmentTitle])

  const ff_freeform_id = hidden[0]?.value;
  const ff_topic_id = hidden[1]?.value;

  const contextValue = useMemo(() => ({
    prompt: prompt,
    ff_freeform_id: ff_freeform_id,
    ff_topic_id: ff_topic_id}), [prompt, ff_freeform_id, ff_topic_id]);

  const changePrompt = (newValue) => {
    setPrompt(newValue);
  }

  return (

      <>
        {Object.keys(topicInfo).length !== 0 ?  <topicContext.Provider value = {contextValue}>
          <Base loading={loadingTopic} error={errorTopic}>
            <HeaderContainer>
              {renderHeader()}
              {renderHeader(true)}
            </HeaderContainer>
            {localStorage
                .getItem(Constants.AS_PREVIOUS_LINK)
                ?.includes('/entity') && (
                <div className={'mb-4'}>
                  <GoBackSubject
                      handleClick={() =>
                          history.push(localStorage.getItem(Constants.AS_PREVIOUS_LINK))
                      }
                  />
                </div>
            )}

            <DescPage
                orderRequired={!isMessageMode || !isEditMode}
                hideHeaderTitle={isEditMode && isMessageMode}
                type="Assessment Topic"
                custom
                customObj={{
                  definitionComp: (
                      <>
                        {isPosted && <ShareBlock cid = {cid}/>}
                        {canShowEvents && !isPosted && (
                            <div>
                              <EventList
                                  selectedCV={selectedCV}
                                  eventsList={eventsList}
                                  handleClickInfo={event => {
                                    setEventInfoModalVisible({
                                      visible: true,
                                      eventInfo: event,
                                    });
                                  }}
                              />
                            </div>
                        )}
                        <div
                            style={{
                              display: isPosted || canShowEvents ? 'none' : 'block',
                            }}>
                          {specificTitle && !(isEditMode && isMessageMode) && (
                              <TitleForm>{specificTitle}</TitleForm>
                          )}

                          {specificTitle && !(isEditMode && isMessageMode) && (
                              <>
                                {/*<ReactTags*/}
                                {/*  ref={reactTags}*/}
                                {/*  placeholderText={"Add new category"}*/}
                                {/*  tags={tags}*/}
                                {/*  suggestions={suggestions}*/}
                                {/*  onDelete={onDelete.bind(this)}*/}
                                {/*  onAddition={onAddition.bind(this)}*/}
                                {/*/>*/}

                                <PlainInput
                                    id={'title'}
                                    name={'title'}
                                    type={'text'}
                                    required
                                    placeholder={'Enter your Title here'}
                                    value={assessmentTitle}
                                    onChange={e => setAssessmentTitle(e.target.value)}
                                    // disabled={isEditMode ? 'disabled' : ''}
                                />

                                <PlainInput
                                    id={'description'}
                                    name={'description'}
                                    type={'text'}
                                    required
                                    placeholder={'Enter your Description here'}
                                    value={assessmentDes}
                                    onChange={e => setAssessmentDes(e.target.value)}
                                    // disabled={isEditMode ? 'disabled' : ''}
                                />

                                {isEditMode ? (
                                    <div className={'flex justify-center'}>
                                      <img
                                          src={initialImage}
                                          alt={`${name} Portrait`}
                                          style={{
                                            // height: 400,
                                            width: '100%',
                                            borderRadius: 16,
                                            objectPosition: 'center',
                                            objectFit: 'cover',
                                          }}
                                      />
                                    </div>
                                ) : (
                                    <div
                                        className={classes.dropzoneContainer}
                                        style={{pointerEvents: isEditMode ? 'none' : 'auto'}}>
                                      <DropzoneArea
                                          acceptedFiles={['image/*']}
                                          initialFiles={[
                                            initialImage !== ''
                                                ? initialImage
                                                : 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Change_in_Average_Temperature.svg/300px-Change_in_Average_Temperature.svg.png',
                                          ]}
                                          filesLimit={1}
                                          maxFileSize={18000000}
                                          dropzoneText={
                                            'Drag and drop an image here or click'
                                          }
                                          dropzoneClass={classes.dropzone}
                                          dropzoneParagraphClass={classes.dropzoneText}
                                          previewGridClasses={{
                                            container: classes.dropzonePreview,
                                            item: classes.dropzonePreviewItem,
                                          }}
                                          onChange={files => {
                                            setAssessmentImage(files[0]);
                                          }}
                                      />
                                    </div>
                                )}
                              </>
                          )}

                          <TopicDefinition
                              setNewHTMLContent={setNewHTMLContent}
                              setNewAssetsBlockData={setNewAssetsBlockData}
                              setNewSelectedRating={setNewSelectedRating}
                              topics={topicInfo.assessment_summary}
                              assetsValue={topicContent}
                              mode={mode}
                              showClassification={
                                !((isEditMode && isMessageMode) || isEventMode)
                              }
                              chatMessageCount={chatMessageCount}
                              extraRatings={extraRatings}
                              setExtraRatings={(extraRating, selRating) => {
                                setExtraRatings(prevExtraRatings => {
                                  return prevExtraRatings.map(er =>
                                      er.ratings_id === extraRating.ratings_id
                                          ? {
                                            ...er,
                                            ratings_selection: selRating,
                                          }
                                          : er,
                                  );
                                });
                              }}
                              changePrompt = {changePrompt}
                          />

                          <Button
                              // style={{maxWidth: '500px'}}
                              disabled={!isLinkActive}
                              displayStatus={'none'}
                              onClick={handleSaveTopic}
                              loading={loadingSaveTopic}
                              bottomGap>
                            {submitBtnTitle}
                          </Button>
                          <Alert
                              severity="error"
                              status={errorTopicMessage.trim().length > 0}
                              errorMessage={errorTopicMessage}
                          />

                          {!secondButtonHidden && showCompleteBtn && (
                              <Button
                                  displayStatus={'none'}
                                  // onClick={() => completeAssessment(() => handlePosted())}
                                  onClick={() => completeAssessment(() => doneSaveTopic())}
                                  loading={loadingDoneSaveTopic}
                                  bottomGap
                                  // style={{maxWidth: '500px'}}
                              >
                                {/*Submit Complete Assessment*/}
                                Done
                              </Button>
                          )}

                          <Button
                              displayStatus={'none'}
                              onClick={() => history.goBack()}>
                            Cancel
                          </Button>

                          <Alert
                              severity="error"
                              status={errorAssessment.trim().length > 0}
                              errorMessage={errorAssessment}
                          />
                        </div>
                      </>
                  ),
                  exampleFunc: CardItem =>
                      isCalendarMode ? (
                          <div className={'flex justify-center'}>
                            <Calendar
                                prevLabel={
                                  <div className={'flex items-center justify-center'}>
                                    <HiOutlineArrowLeft title={'Prev Month'} />
                                  </div>
                                }
                                prev2Label={
                                  <div className={'flex items-center justify-center'}>
                                    <HiOutlineChevronDoubleLeft title={'Prev Year'} />
                                  </div>
                                }
                                nextLabel={
                                  <div className={'flex items-center justify-center'}>
                                    <HiOutlineArrowRight title={'Next Month'} />
                                  </div>
                                }
                                next2Label={
                                  <div className={'flex items-center justify-center'}>
                                    <HiOutlineChevronDoubleRight title={'Next Year'} />
                                  </div>
                                }
                                tileContent={({date, view}) => {
                                  if (
                                      eventsList.some(e =>
                                          DateTimeUtil.isSameDate(
                                              date,
                                              moment(e.event_start_datetime).toDate(),
                                          ),
                                      )
                                  ) {
                                    return (
                                        <div className={'flex justify-center mt-1'}>
                                          <p
                                              className={
                                                'w-1.5 h-1.5 rounded bg-giga-primary-blue_500'
                                              }
                                          />
                                        </div>
                                    );
                                  } else {
                                    return (
                                        <div className={'flex justify-center mt-1'}>
                                          <p className={'w-1.5 h-1.5 rounded'} />
                                        </div>
                                    );
                                  }
                                }}
                                onChange={newValue => {
                                  setSelectedCV(newValue);
                                  callBackFunction(newValue);
                                }}
                                value={selectedCV}
                            />
                          </div>
                      ) : isEditMode && isMessageMode ? (
                          <div className={'flex'} style={{height: 600}}>
                            <GigaMessage
                                pubnub={pubnub}
                                showHistory={false}
                                messageThreadHeight={600}
                                messageClassName={'flex flex-row flex-1'}
                                historyList={historyList}
                                topic={getItem(Constants.AS_MESSAGE_ROOM_TOPIC)}
                                setTopic={setTopic}
                                chatMessageCount={chatMessageCount}
                                attachments={messageResultTopic}
                                message={
                                  topicContent.length === 0
                                      ? ''
                                      : topicContent[0].htmlContent
                                }
                                onSent={() => {}}
                            />
                          </div>
                      ) : (
                          <TopicExample
                              topics={topicInfo.assessment_summary}
                              topicContent={topicContent}
                              CardItem={CardItem}
                              subjectInfo={topicInfo.assessment}
                              assessment_name={assessmentTitle}
                              assessment_description={assessmentDes}
                              newImage={assessmentImage}
                          />
                      ),
                  title: `${
                      topicInfo.assessment_summary &&
                      topicInfo.assessment_summary.length !== 0
                          ? topicInfo.assessment_summary[
                          topicInfo.assessment_summary.length - 1
                              ].assessment_topic
                          : '...'
                  } Assessment`,
                }}
            />
            {!isPosted && (
                <>
                  <Button
                      // style={{maxWidth: '500px'}}
                      disabled={!isLinkActive}
                      displayStatus={'block'}
                      onClick={handleSaveTopic}
                      loading={loadingSaveTopic}
                      bottomGap>
                    {submitBtnTitle}
                  </Button>
                  {!secondButtonHidden && showCompleteBtn && (
                      <Button
                          displayStatus={'block'}
                          onClick={() => completeAssessment(() => handlePosted())}
                          bottomGap
                          // style={{maxWidth: '500px'}}
                      >
                        {/*Submit Complete Assessment*/}
                        Done
                      </Button>
                  )}
                  <Button
                      displayStatus={'block'}
                      onClick={() => history.goBack()}
                      bottomGap>
                    Cancel
                  </Button>
                </>
            )}
            <GGModal
                modalVisible={modalVisible}
                handleCloseModal={() => setModalVisible(false)}
                renderContent={() => (
                    <CreateNewMessage
                        handleCloseModal={() => setModalVisible(false)}
                        handleContinue={selectedMembers => onAddMembers(selectedMembers)}
                    />
                )}
            />
            <GGModal
                modalVisible={detailModalVisible}
                handleCloseModal={() => setDetailModalVisible(false)}
                renderContent={() => (
                    <MessageDetailModal
                        topicInfo={topicInfo}
                        topicContent={topicContent}
                        assessmentTitle={assessmentTitle}
                        assessmentDes={assessmentDes}
                        assessmentImage={assessmentImage}
                        handleCloseModal={() => setDetailModalVisible(false)}
                    />
                )}
            />
            <GGModal
                modalVisible={successModalVisible}
                handleCloseModal={() => setSuccessModalVisible(false)}
                renderContent={() => (
                    <PostSuccessModal
                        handleCloseModal={() => setSuccessModalVisible(false)}
                    />
                )}
            />
            <GGModal
                modalVisible={contributorsModalVisible}
                handleCloseModal={() => setContributorsModalVisible(false)}
                renderContent={() => (
                    <ContributorsDetailModal
                        handleCloseModal={() => setContributorsModalVisible(false)}
                    />
                )}
            />
            <GGModal
                modalVisible={contributorsModalVisible}
                handleCloseModal={() => setContributorsModalVisible(false)}
                renderContent={() => (
                    <ContributorsDetailModal
                        handleCloseModal={() => setContributorsModalVisible(false)}
                    />
                )}
            />
            <GGModal
                modalVisible={eventModalVisible}
                handleCloseModal={() => setEventModalVisible(false)}
                renderContent={() => (
                    <EventDetailModal
                        selectedCV={selectedCV}
                        eventsList={eventsList}
                        handleCloseModal={() => setEventModalVisible(false)}
                        handleClickInfo={event => {
                          setEventModalVisible(false);
                          setEventInfoModalVisible({visible: true, eventInfo: event});
                        }}
                    />
                )}
            />
            <GGModal
                modalVisible={eventInfoModalVisible.visible}
                handleCloseModal={() =>
                    setEventInfoModalVisible({...eventInfoModalVisible, visible: false})
                }
                renderContent={() => (
                    <EventInfoModal
                        eventInfo={eventInfoModalVisible.eventInfo}
                        handleCloseModal={() =>
                            setEventInfoModalVisible({
                              ...eventInfoModalVisible,
                              visible: false,
                            })
                        }
                    />
                )}
            />
          </Base>
        </topicContext.Provider> : ''}
      </>
      // </TopicContext.Provider>

  );
};

const baseContent = `
  padding: 15px 0;
  width: 100%;
`;

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
  creationModes: state.UserData.creationModes,
  creationCategories: state.UserData.creationCategories,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateCreationModes: (modes, categories) => {
      dispatch(userActions.updateCreationModes(modes, categories));
    },
    fetchUserData: () => {
      dispatch(userActions.fetchUserData());
    },
  },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(memo(TopicSubmission));
