import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .editorClass {
    padding: 0.675rem;
    border-radius: 0.675rem;
    border: 3px solid ${({theme}) => theme.colors.main};
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }
`;

const LabelContainer = styled.div`
  margin-bottom: 0.675rem;
`;

export {Wrapper, LabelContainer};
