import React, {useRef} from 'react';

import RTE from 'Components/Create/RTE';
import ImageInput from 'Components/Create/Inputs/ImageInput';
import Button from 'Components/DesignSystem/button/button';
import TextErrorContainer from 'Components/UI/TextErrorContainer';

import {Base} from './styles';

const CreateDefinition = ({
  fileLabel,
  RTELabel,
  imageUrl,
  setImageUrl,
  setDesc,
  submitLabel,
  handleSubmit,
  error,
  setFileImage,
}) => {
  const imageInput = useRef(null);

  const handleSubmitImage = () => {
    const urlValue = imageInput.current.value;

    if (urlValue.trim().length > 0) {
      setImageUrl(urlValue);
    }
  };

  return (
    <Base>
      <ImageInput
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        previewImage
        width={50}
        bottomGap
        forId="File Label"
        size="30"
        setImageFile={setFileImage}>
        {fileLabel}
      </ImageInput>
      <div style={{display: 'flex', marginBottom: '15px'}}>
        <input placeholder="Put your Image URL" ref={imageInput} />
        <button onClick={handleSubmitImage}>Submit Image</button>
      </div>

      <RTE setHTMLValue={setDesc} label={RTELabel} />
      <Button onClick={handleSubmit}>{submitLabel}</Button>
      <TextErrorContainer color="#000">{error}</TextErrorContainer>
    </Base>
  );
};

export default CreateDefinition;
