import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  width: 100%;
  height: 70vh;
  min-height: 600px;

  background-color: ${({theme}) => theme.colors.main};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  border-radius: 15px;

  box-shadow: 0 3px 6px #00000029;
`;

const FormContainer = styled.div`
  width: 40%;
  height: 75%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;

  @media ${device.laptopL} {
    width: 55%;
  }

  @media ${device.laptop} {
    width: 65%;
  }

  @media ${device.tablet} {
    width: 75%;
    height: 60%;
  }

  @media ${customDevice(620)} {
    width: 85%;
  }
`;

export {Base, FormContainer};
