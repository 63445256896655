import React, {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {Base} from './styles';

import useFetchContent from 'hooks/useFetchContent';
import useTitle from 'hooks/useTitle';

import MemberHomepageService from 'Services/memberHomepage';

import {formatSubcategories, retrieveCategoryInfo} from './bl';

// import SubCategories from './SubCategories';
import Category from 'Components/MemberHomepage/Home/Category';

import {setNewViewedCategory} from 'Helpers/localStorage';

const CategoryView = ({history}) => {
  const {nameCategory} = useParams(); // namecategory needs to be coded with encodeURIComponent

  useTitle(`${nameCategory} Category View`, true, [nameCategory]);

  console.log('CategoryView render');

  const [
    categoryInfo,
    loadingCategoryInfo,
    errorCategoryInfo,
    callCategoryInfo,
  ] = useFetchContent(
    {},
    MemberHomepageService.getCategoryInfo,
    useCallback(
      ({data: {categories}}) => retrieveCategoryInfo(nameCategory, categories),
      [nameCategory],
    ),
  );

  if (!categoryInfo) {
    history.push('/404');
  }

  useEffect(() => {
    callCategoryInfo();
  }, [callCategoryInfo]);

  useEffect(() => {
    if (categoryInfo?.category) {
      setNewViewedCategory(categoryInfo?.category);
    }
  }, [categoryInfo]);

  const handleSubCategoryClick = useCallback(
    subcategoryName => {
      history.push(`/home/category/${encodeURIComponent(subcategoryName)}`);
    },
    [history],
  );

  return (
    <Base loading={loadingCategoryInfo} error={errorCategoryInfo}>
      {categoryInfo?.subcategories ? (
        <Category
          title={categoryInfo?.category}
          entities={formatSubcategories(
            categoryInfo?.subcategories,
            handleSubCategoryClick,
          )}
        />
      ) : (
        <Category
          title={categoryInfo?.category}
          entities={categoryInfo?.entities}
        />
      )}
    </Base>
  );
};

export default CategoryView;
