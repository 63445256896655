import {ApiClient} from './base';
import {getUserToken} from '../utils/HelperService';

class InitDisplayService {
  static getDisplayModes() {
    return ApiClient({
      method: 'get',
      url:
        getUserToken() == null
          ? '/react_gi_v1_guest_mode_nav_bar_display.php'
          : '/react_gi_v1_mode_nav_bar_display.php',
    });
  }

  static getDisplayCategories(groupCategoryId) {
    return ApiClient({
      method: 'get',
      // url: '/react_gi_v1_category_list.php',
      url:
        getUserToken() == null
          ? `/react_gi_v1_guest_category_nav_bar_display.php?group_category_id=${groupCategoryId}`
          : '/react_gi_v1_category_nav_bar_display.php',
    });
  }

  static getDisplayChannel(channelName) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_landing_page.php?channel_name=${channelName}`,
    });
  }

  static getSubscribers() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_subscription_lists.php',
    });
  }

  static getDistributionList() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_distribution_lists.php',
    });
  }
}

export default InitDisplayService;
