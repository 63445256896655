import styled from 'styled-components';

const modes = {
  dark: '46,53,139',
  light: '#727A7E',
};

const Base = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  opacity: ${({opacity}) => opacity};

  font-size: 11px;

  height: 25px;
  padding: 0 ${({shortContent}) => (shortContent ? '30px' : '25px')};
  border-radius: 20px;

  cursor: pointer;
  user-select: none;
  transition: all 0.3s;

  font-weight: 600;

  ${({mode, opacity}) => `
    background-color: ${
      mode === 'dark' ? `rgba(${modes.dark}, ${opacity ?? '1'})` : '#fff'
    };
    color: ${mode === 'dark' ? `rgb(255, 255, 255)` : modes.light};
    ${mode === 'light' && `border: 2px solid ${modes.light};`}
  `}
  &:hover {
    filter: brightness(90%);
  }

  &:not(:last-of-type) {
    margin-right: 30px;
  }
`;

export {Base};
