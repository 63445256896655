import React, {memo, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Base} from './styles';
import MemberHomepageService from '../../../Services/memberHomepage';
import Button from '../../DesignSystem/button/button';

import GigaAlert from '../../UI/GigaAlert';
import HistoryService from '../../../utils/HistoryService';
import {DropzoneArea} from 'material-ui-dropzone';
import {useStyles} from '../Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import {convertQueryParamsToObject} from '../../../Helpers/api';
import {InputBase, makeStyles, MenuItem, Select} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  FormControl,
  InputAdornment,
  ListSubheader,
  TextField,
} from '@mui/material';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ManageCategorySteps from './ManageCategorySteps';
import {
  addMCLSteps,
  getMCLSteps,
  popMCLSteps,
  setMCLSteps,
} from '../../../utils/HelperService';
import HomeSteps from '../Home/HomeSteps';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {connect} from 'react-redux';

function CreateCategory(props) {
  const classes = useStyles();

  const selectedCategoryURL = props.location?.state?.selectedCategoryURL;
  // const parentName = props.location?.state?.parentName;
  console.log('CreateCategoryPage - selectedCategoryURL', selectedCategoryURL);

  let {categoryId} = useParams();
  const isEditMode = categoryId !== '-1';

  const [ratings, setRatings] = useState([]);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [rating, setRating] = useState();
  const [searchText, setSearchText] = useState('');
  const [image, setImage] = useState();
  const [initialImage, setInitialImage] = useState();

  const [isURLMode, setIsURLMode] = useState(false);
  const [imageURL, setImageURL] = useState('');

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [alertInfo, setAlertInfo] = React.useState({open: false});

  useEffect(() => {
    const steps = getMCLSteps();
    if (steps.length > 1) {
      setMCLSteps(steps.slice(0, 1));
    }
    if (!isEditMode) {
      fetchRatings();
    }
    if (isEditMode) {
      MemberHomepageService.callEndpoint(selectedCategoryURL).then(res => {
        console.log('CreateCategoryPage - display', res);
        const resData = res?.data?.category_definition;
        if (resData) {
          setRating(resData.category_assessment_rating_id);
          setDescription(resData.category_description);
          setName(resData.category_name);
          setInitialImage(resData.category_image_url);
          fetchRatings(resData.category_assessment_rating_id);
        }
      });
    }
  }, []);

  const fetchRatings = (rId = -1) => {
    MemberHomepageService.getCategoryRatings(-1).then(res => {
      console.log('CreateCategoryPage - ratings', res);
      setRatings(res.data.category_ratings);
    });
  };

  const handleSubmit = () => {
    let message = '';
    if (name === '') {
      message = 'The name field is required';
    }

    if (description === '') {
      message = 'The description field is required';
    }

    if (message !== '') {
      setAlertInfo({
        open: true,
        message,
        severity: 'warning',
      });
      return;
    }

    const {parent_category_id} =
      convertQueryParamsToObject(selectedCategoryURL);

    const body = {
      external_id: categoryId,
      parent_id: parent_category_id,
      category_name: name,
      category_description: description,
      mode_id: 61,
      category_rating_id: rating,
      category_image_url: imageURL,
    };

    console.log('CreateCategoryPage - body', body);

    MemberHomepageService.createCategory(body, image)
      .then(res => {
        console.log(res);
        setAlertInfo({
          open: true,
          message: `${isEditMode ? 'Updated' : 'Created'} successfully!`,
        });
        setTimeout(() => {
          popMCLSteps();
          HistoryService.goBack();
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onClickManageRatings = () => {
    const path = {
      pathname: `/home/manage-ratings`,
      state: {
        subTitle: 'Category',
        endpointURL: 'react_gi_v1_manage_category_ratings_display.php',
      },
    };
    addMCLSteps({
      ...path,
      title: `Manage Category Ratings`,
    });
    HistoryService.push(path);
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          {isEditMode ? 'Edit' : 'Create'} Category
        </span>
      </Base>
      <Base className={'flex flex-row px-4 md:px-0'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={props.manageSteps}
          handleClickStep={() => {}}
        />
      </Base>
      <Base className={'flex flex-row px-4 md:px-0'}>
        <ManageCategorySteps />
      </Base>
      <Base loading={false} error={false} className={'px-4 md:px-0'}>
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 border rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  id="description"
                  className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md border"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="select-rating"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Ratings
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col">
                <div className={'border rounded-lg p-1 mx-0.5 bg-white'}>
                  <Select
                    displayEmpty
                    MenuProps={{autoFocus: false}}
                    classes={{root: classes.root}}
                    input={<InputBase classes={{root: classes.inputRoot}} />}
                    labelId="select-rating"
                    id="select-rating"
                    value={rating ?? ''}
                    IconComponent={ExpandMoreIcon}
                    onClose={() => setSearchText('')}
                    onChange={event => setRating(event.target.value)}>
                    <ListSubheader style={{width: '100%'}}>
                      <TextField
                        size="small"
                        autoFocus
                        placeholder="Type to search..."
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={e => setSearchText(e.target.value)}
                        onKeyDown={e => {
                          if (e.key !== 'Escape') {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {ratings
                      .filter(
                        v =>
                          (v.category_rating_definition ?? '')
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          (v.category_rating_description ?? '')
                            .toLowerCase()
                            .includes(searchText.toLowerCase()),
                      )
                      .map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.category_rating_id}
                          classes={{gutters: classes.menuList}}>
                          {item.category_rating_definition}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <span
                  className={
                    'font-normal text-sm text-giga-graymedium mx-2 mt-2'
                  }>
                  {ratings.find(v => v.category_rating_id === rating)
                    ?.category_rating_description ?? ''}
                </span>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Image
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col">
                {!isEditMode && (
                  <div className="flex items-center">
                    <input
                      id={'url-mode'}
                      name="url-mode"
                      type="radio"
                      checked={isURLMode}
                      onClick={() => setIsURLMode(true)}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor={'url-mode'}
                      className="ml-3 block text-sm font-medium text-gray-700">
                      URL
                    </label>
                  </div>
                )}
                {!isEditMode && (
                  <div
                    className={`${
                      isURLMode ? '' : 'pointer-events-none opacity-70'
                    } mt-2`}>
                    <input
                      type="text"
                      name="image-url"
                      value={imageURL}
                      onChange={e => setImageURL(e.target.value)}
                      id="image-url"
                      className="px-4 h-12 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md border"
                    />
                  </div>
                )}
                {!isEditMode && (
                  <div className="flex items-center mt-2">
                    <input
                      id={'file-mode'}
                      name="file-mode"
                      type="radio"
                      checked={!isURLMode}
                      onClick={() => setIsURLMode(false)}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor={'file-mode'}
                      className="ml-3 block text-sm font-medium text-gray-700">
                      File
                    </label>
                  </div>
                )}
                <div
                  className={`${
                    isURLMode ? 'opacity-70 pointer-events-none' : ''
                  }`}>
                  {isEditMode ? (
                    <div className={'flex justify-start mt-2'}>
                      {initialImage && (
                        <img
                          src={initialImage}
                          alt={`${name} Portrait`}
                          style={{
                            height: 'auto',
                            width: 'auto',
                            borderRadius: 16,
                            objectFit: 'contain',
                            maxHeight: 400,
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className={classes.categoryDropzoneContainer}
                      style={{
                        pointerEvents:
                          isEditMode || isURLMode ? 'none' : 'auto',
                      }}>
                      <DropzoneArea
                        acceptedFiles={['image/*']}
                        // initialFiles={[
                        //   initialImage !== ''
                        //     ? initialImage
                        //     : 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Change_in_Average_Temperature.svg/300px-Change_in_Average_Temperature.svg.png',
                        // ]}
                        filesLimit={1}
                        maxFileSize={18000000}
                        dropzoneText={'Drag and drop an image here or click'}
                        dropzoneClass={classes.dropzone}
                        dropzoneParagraphClass={classes.dropzoneText}
                        previewGridClasses={{
                          container: classes.dropzonePreview,
                          item: classes.dropzonePreviewItem,
                        }}
                        onChange={files => {
                          setImage(files[0]);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            'flex flex-col lg:flex-row justify-between lg:justify-center mt-12 mb-12 lg:space-x-12'
          }>
          <div className={'mcl-create-new'}>
            <Button
              loading={loadingSubmit}
              onClick={() => onClickManageRatings()}
              bottomGap>
              Manage Ratings
            </Button>
          </div>
          <div className={'mcl-create-new'}>
            <Button
              loading={loadingSubmit}
              onClick={() => handleSubmit()}
              bottomGap>
              Submit
            </Button>
          </div>
        </div>
        <GigaAlert
          alertInfo={alertInfo}
          handleClose={() => setAlertInfo({open: false})}
        />
      </Base>
    </>
  );
}

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  manageSteps: state.UserData.manageSteps,
  manageCurrentCategory: state.UserData.manageCurrentCategory,
  searchString: state.UserData.searchString,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateDisplayCategories: categories => {
      dispatch(userActions.updateDisplayCategories(categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
    onResetUser: () => dispatch(resetUser()),
    updateManageCurrentCategory: c =>
      dispatch(userActions.updateManageCurrentCategory(c)),
    updateManageSteps: steps => dispatch(userActions.updateManageSteps(steps)),

    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(CreateCategory));
