import styled from 'styled-components';
import {Link} from 'react-router-dom';

import device from 'mediaQueries';

const Base = styled(Link)`
  margin: ${({center}) => (center === 'true' ? '0 auto' : '0')};
  text-decoration: none;

  ${({withtext}) =>
    withtext === 'true' &&
    `
    display: flex;
    align-items: center;
  `}
  ${({reversed}) =>
    reversed &&
    `
    flex-direction: row-reverse;
  `}
  ${({fixedtopleft}) =>
    fixedtopleft === 'true' &&
    `
    position: fixed;
    top: 30px;
    left: 40px;
    
    @media ${device.mobileL} {
      left: 20px;
    }
    
    @media ${device.mobileM} {
      top: 20px;
    }
  `}
  .LogoImg {
    width: ${({size}) => `${size}px`};
    height: auto;
    cursor: pointer;
  }
`;

const TextContainer = styled.div`
  display: ${({show}) => (show ? 'flex' : 'none')};
  flex-direction: column;
  margin-left: ${({marginLeft}) => marginLeft ?? '10px'};
  margin-right: 10px;

  @media ${device.mobileS} {
    display: none;
  }
`;

const TitleText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #fff;

  &:active {
    color: #fff;
  }
`;

const SubtitleText = styled.div`
  font-size: 9px;
  font-weight: bold;
  color: ${({subTitleColor}) => subTitleColor ?? '#fff'};

  &:active {
    color: #fff;
  }
`;

export {Base, TextContainer, TitleText, SubtitleText};
