import {useCallback, useState} from 'react';

const useFetchContent = (
  initialContentState,
  fetchMethod,
  responseDestructuration,
  initLoadingState = true,
) => {
  const [content, setContent] = useState(initialContentState);
  const [loading, setLoading] = useState(initLoadingState);
  const [error, setError] = useState(false);

  // fetchMethod retrieves the HTTP response.
  // responseDestructuration returns the data used from the response.

  /*
    IMPORTANT: responseDestructuration needs to be wrapped inside useCallback with an
    empty dependency array to avoid an infinite loop.
  */

  const callContent = useCallback(
    async (...params) => {
      setLoading(true);
      setError(false);

      try {
        const response = await fetchMethod(...params);
        const data = await responseDestructuration(response);
        await setContent(data);
        await setLoading(false);
      } catch (e) {
        console.error(e);
        setError(true);
        setLoading(false);
      }
    },
    [setContent, setLoading, setError, responseDestructuration, fetchMethod],
  );

  return [content, loading, error, callContent];
};

export default useFetchContent;
