import styled from 'styled-components';

import device from 'mediaQueries';

const IndicatorsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3px;
  margin-bottom: 20px;

  @media ${device.mobileS} {
    grid-template-columns: 1fr;
  }
`;

const ErrorDisplay = styled.span`
  position: relative;
  display: block;
  font-size: 14px;
  color: white;
  font-weight: bold;

  @media ${device.mobileL} {
    font-size: 10px;
  }
`;

export {IndicatorsContainer, ErrorDisplay};
