import {validateEmail} from 'Helpers/validators';
import passwordMethods from 'Helpers/passwordValidators';

export const userInputs = [
  {
    isRequired: true,
    label: 'First Name',
    name: 'first_name',
    type: 'text',
    validation: value => value.trim().length > 0,
  },
  {
    isRequired: true,
    label: 'Last Name',
    name: 'last_name',
    type: 'text',
    validation: value => value.trim().length > 0,
  },
  {
    isRequired: true,
    label: 'Email',
    name: 'email',
    type: 'email',
    validation: value => validateEmail(value),
  },
  {
    isRequired: true,
    label: 'Screen Name',
    name: 'screen_name',
    type: 'text',
    validation: value => value.trim().length > 0,
  },
  {
    isRequired: true,
    label: 'Password',
    name: 'password',
    type: 'password',
    validation: value => passwordMethods.every(method => method(value)),
  },
  {
    isRequired: true,
    label: 'Confirm Password',
    name: 'confirm_password',
    type: 'password',
    validation: (pw, confirmPw) => pw === confirmPw,
  },
  {
    isRequired: true,
    label: 'Profile Picture',
    name: 'portrait_file',
    type: 'file',
  },
  {
    label: 'Phone',
    name: 'phone',
    type: 'text',
    validation: value => value.trim().length > 0,
    isRequired: false,
  },
  {
    label: 'Street Address',
    name: 'street_address',
    type: 'text',
    validation: value => value.trim().length > 0,
    isRequired: false,
  },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    validation: value => value.trim().length > 0,
    isRequired: false,
  },
  {
    label: 'State/Province',
    name: 'state',
    type: 'text',
    validation: value => value.trim().length > 0,
    isRequired: false,
  },
  {
    label: 'Zip/Postal Code',
    name: 'zip_code',
    type: 'number',
    validation: value => value.trim().length > 0,
    isRequired: false,
  },
];
