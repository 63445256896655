import styled from 'styled-components';

const Base = styled.div`
  width: 30%;
  overflow: hidden;
  position: relative;
  background-color: #c4c4c4;

  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      filter: brightness(80%);
      transform: scale(1.05);
    }
  }
`;

export {Base};
