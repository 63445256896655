import React from 'react';

import Hero from './Hero';
import SectionWrapper from '../UI/SectionWrapper';
import GetStartedBlock from './GetStartedBlock';
import LearnMoreBlock from './LearnMoreBlock';
import LandingSection from '../UI/LandingSection';

import {recentCards, trendingNowCards} from './info';
import Constants from '../../../Constants/Constants';

const MainPage = ({history, variant}) => {
  const isDana =
    variant === Constants.DANA_BARROS_BASKETBALL ||
    variant === Constants.DANA_BARROS_BASKETBALL_PODCASTS;

  return (
    <>
      <Hero history={history} isDana={isDana} variant={variant} />

      <SectionWrapper>
        <LandingSection
          title="Trending Now"
          cardHeight={450}
          cardData={trendingNowCards}
        />
        <GetStartedBlock isDana={isDana} variant={variant} />
        <LandingSection
          title="Recently Made"
          cardHeight={220}
          cardData={recentCards}
        />
        <LearnMoreBlock history={history} isDana={isDana} variant={variant} />
      </SectionWrapper>
    </>
  );
};

export default MainPage;
