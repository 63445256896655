import React, {memo, useState} from 'react';

import {Base} from './styles';

import Logo from 'Components/UI/Logo';
import IconButton from 'Components/UI/IconButton';

import NotImplemented from 'hoc/NotImplemented';
import {connect} from 'react-redux';

import {VscListSelection, VscSettings} from 'react-icons/vsc';
import clsx from 'clsx';
import SearchBy from '../../SearchBy';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {useLocation} from 'react-router-dom';
import Constants from '../../../Constants/Constants';
import {getUserToken} from '../../../utils/HelperService';

const format = "dddd, mmmm d'th'";

const HeaderPage = ({
  loading,
  handleBurgerClick,
  showSearch,
  showSubTitle,
  title = 'Understand',
  noNeedIcon = false,
  bgColor,
  variant,
  channel,
  searchString,
  searchFlag,
  actions,
}) => {
  // const color =
  //   variant === Constants.GIGA_MEDIA || variant === Constants.GIGA_ATHLETICS
  //     ? '#F6511D'
  //     : '#2CA3FF'

  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);

  const color = channel?.mainColor ?? '#2CA3FF';

  let pathName = window.location.pathname;
  let searchRequired = pathName.includes('/home');

  const [searchStr, setSearchStr] =  useState('')

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // actions.updateSearchBar(searchStr)
      actions.updateSearchFlag(! searchFlag)
    }
  };

  if (searchRequired) {
    pathName = pathName.replace('/home', '');
    if (pathName.length > 0) {
      if (pathName[0] !== '?') {
        if (!pathName.includes('/demo')) {
          searchRequired = false;
        }
      }
    }
  }

  if (searchQuery.get(Constants.SQ_HEADER_SEARCH) === 'false') {
    searchRequired = false;
  }

  if (getUserToken() == null) {
    searchRequired = false;
  }

  return (
    <Base bgColor={bgColor}>
      <IconButton onClick={handleBurgerClick}>
        {/*<BurgerIcon style={{color}} />*/}
        <VscListSelection style={{color}} />
      </IconButton>

      {searchRequired && (
        <SearchBy
          className={clsx('flex flex-1')}
          placeholder="Search Giga"
          searchInputProps={{
            value: searchString,
            onChange: e => {
              actions.updateSearchBar(e.target.value);
              setSearchStr(e.target.value)
              // searchStr === '' ? actions.updateSearchBar(searchStr) : undefined
            },
            onKeyDown: e => {
              setSearchStr(e.target.value)
              handleKeyDown(e)
            },
            // onClear: e => {
            //   // setSearchStr(e.target.value)
            //   alert('hello world')
            //   setSearchStr('')
            //   actions.updateSearchBar(searchStr)
            // }
          }}
        />
      )}

      {/*<NotImplemented show={showSearch}>*/}
      {/*  <IconButton>*/}
      {/*    <VscSettings style={{color}} />*/}
      {/*  </IconButton>*/}
      {/*</NotImplemented>*/}

      {!loading && (
          <Logo
              to="/home"
              size="30"
              text
              title={'giga.'}
              titleStyles={{color: color ?? '#2CA3FF'}}
              // subtitle={showSubTitle ? dateFormat(new Date(), format) : null}
              // subTitleColor={color}
              // reversed={!showSubTitle}
              noNeedIcon={noNeedIcon}
              // isBlue={variant === Constants.GIGA_MEDIA}
              variant={variant}
          />
      )}

      {/*<div className={'w-6 h-6'} />*/}
    </Base>
  );
};

const mapStateToProps = ({UserData: {channel, searchString, searchFlag}}) => ({
  channel,
  searchString,
  searchFlag
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
    updateSearchFlag: flag => dispatch(userActions.updateSearchFlag(flag)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(HeaderPage));
