import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  align-items: center;

  padding: 0 1rem;

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .Icon {
    fill: #7a8fa6;
  }
`;

const TitleContainer = styled.div`
  font-size: 11px;
  font-weight: 600;
  flex: 1;
  margin-right: auto;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({theme}) => theme.colors.main};
  }
`;

export {Base, TitleContainer};
