import React, {useEffect, useRef} from 'react';
import './styles.css';
import IconButton from '../IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import {Menu, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  menuList: {
    paddingLeft: '0.5rem',
    fontSize: '0.8rem',
    width: '10rem',
  },
});

export default function GigaMenu({
  // handleClickDetails,
  // handleClickContributors,
  options,
  iconClass,
}) {
  let container = useRef();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = event => {
    if (container.current && !container.current.contains(event.target)) {
      handleClose();
    }
  };

  const handleClickItem = (e, handleItem) => {
    e.stopPropagation();
    handleClose();
    handleItem();
  };

  return (
    <div className="giga-menu-container" ref={container}>
      {/*<IconButton*/}
      {/*  size="small"*/}
      {/*  onClick={() => {*/}
      {/*    setOpen(true);*/}
      {/*  }}>*/}
      {/*  <MoreIcon className={iconClass ?? 'text-giga-graymain'} size="small" />*/}
      {/*</IconButton>*/}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreIcon className={iconClass ?? 'text-giga-graymain'} size="small" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: '10rem',
          },
        }}>
        {options.map(option => (
          <MenuItem
            key={option.label}
            classes={{gutters: classes.menuList}}
            onClick={e => handleClickItem(e, option.action)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      {/*{open && (*/}
      {/*  <div className="giga-menu-dropdown">*/}
      {/*    <div className={'flex flex-col'}>*/}
      {/*      {options.map((option, index) => (*/}
      {/*        <span*/}
      {/*          key={`${index}`}*/}
      {/*          className={'px-3 py-2 cursor-pointer text-sm'}*/}
      {/*          onClick={() => handleClickItem(option.action)}>*/}
      {/*          {option.label}*/}
      {/*        </span>*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
}
