import React from 'react';

import {
  ActivateSwitch,
  ActivateSwitchContainer,
  Base,
  RequiredIcon,
} from './styles';
import {Title} from '../AnalysisHeaderTitle';

import HeaderTab from 'Components/UI/HeaderTab';
import OverflowContainer from 'Components/UI/OverflowContainer';

import {findAssessmentTopic} from './bl';
import {getOpacityTab} from '../Helpers/tabs';
import {Switch} from '@material-ui/core';

const baseContent = `
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #c5c5c5;
  margin-bottom: 30px;

  width: 100%;
`;

const TopicsHeader = ({
  selectedTopics,
  setIndexTopic,
  indexTopic,
  loadingTopic,
  completedTopics,
  checked,
  setChecked,
}) => {
  const tabs = selectedTopics.map(
    (
      {assessment_topic, assessment_topic_id, assessment_topic_required},
      index,
    ) => {
      const required = assessment_topic_required === '1';

      return (
        <HeaderTab
          title={
            required
              ? `Change Topic to ${assessment_topic} (Required)`
              : `Change Topic to ${assessment_topic}`
          }
          key={assessment_topic_id}
          mode="dark"
          content={
            <>
              {assessment_topic} {required && <RequiredIcon>*</RequiredIcon>}
            </>
          }
          clicked={loadingTopic ? () => null : setIndexTopic.bind(null, index)}
          opacity={getOpacityTab(
            indexTopic,
            index,
            findAssessmentTopic(completedTopics, assessment_topic)?.isCompleted,
          )}
        />
      );
    },
  );

  return (
    <>
      <ActivateSwitchContainer>
        <ActivateSwitch
          value="start"
          control={
            <Switch
              checked={checked}
              onChange={event => {
                setChecked(event.target.checked);
              }}
              color="secondary"
            />
          }
          label={<span style={{fontSize: '0.8rem'}}>Topics</span>}
          labelPlacement="start"
        />
      </ActivateSwitchContainer>

      <Base show={checked}>
        <Title>Assessment Topics</Title>
        <OverflowContainer
          vertical
          verticalBreakpoint={620}
          tabsInfo={tabs}
          width="100"
          emptyMessage="Loading..."
          baseStyles={baseContent}>
          {tabs}
        </OverflowContainer>
      </Base>
    </>
  );
};

export default TopicsHeader;
