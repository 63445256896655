import React from 'react';
import Button from 'Components/DesignSystem/button/button';

import SubjectName from '../SubjectName';

const generateSubjectNamePhase = (
  setSubjectName,
  subjectNameErrorStatus,
  submitSubjectName,
) => ({
  type: 'onlyCustomInput',
  titleForm: 'What subject are you analyzing?',
  headerHeight: 85,
  inputComponent: (
    <SubjectName
      setSubjectName={setSubjectName}
      subjectNameErrorStatus={subjectNameErrorStatus}
    />
  ),
  buttons: advanceIndexPhase => (
    <>
      <Button theme="white">Create Subject</Button>
      <Button onClick={submitSubjectName.bind(null, advanceIndexPhase)}>
        Continue Analysis
      </Button>
    </>
  ),
});

export default generateSubjectNamePhase;
