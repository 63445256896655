import React from 'react';

import Logo from 'Components/UI/Logo';

import {CTA, LinkSignIn, PageLink} from './styles';
import {Title} from '../../../UI/HeaderForm/styles';

const HeaderForm = ({history, isDana, variant}) => {
  return (
    <>
      {!isDana && <Logo center="true" size="120" specialSize={'120'} />}
      {isDana && <Title>Dana Barros</Title>}
      <CTA>
        Privacy is important to us. <br />
        Please verify your email.
      </CTA>
      <br />
      <PageLink
        to="/signin"
        onClick={e => {
          e.preventDefault();
          history.push({
            pathname: '/signin',
            state: {
              isDana,
              variant,
            },
          });
        }}>
        Already have an account?
        <LinkSignIn>Sign In!</LinkSignIn>
      </PageLink>
    </>
  );
};

export default HeaderForm;
