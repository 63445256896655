import React from 'react';

import FormContainer from 'hoc/FormContainer';

import Inputs from './Inputs';
import Indicators from './Indicators';
import CheckPrivacy from './CheckPrivacy';

const Form = ({
  onSubmit,
  changePassword,
  password,
  changeConfirmPassword,
  statusPassword,
  statusConfirmPassword,
  privacy,
  setPrivacy,
  emailExample = 'example@gmail.com',
  ...formContainerProps
}) => (
  <FormContainer
    onSubmit={onSubmit}
    disabledSubmit={!statusPassword || !statusConfirmPassword || !privacy}
    {...formContainerProps}>
    <Inputs
      emailValue={emailExample}
      statusPassword={statusPassword}
      changePassword={changePassword}
      statusConfirmPassword={statusConfirmPassword}
      changeConfirmPassword={changeConfirmPassword}
    />

    <Indicators password={password} />

    <CheckPrivacy checked={privacy} change={setPrivacy} />
  </FormContainer>
);

export default Form;
