import React, {memo} from 'react';

import Input from 'Components/DesignSystem/inputs/base-input';
import ResultsSubject from './ResultsSubject';

import {Base} from './styles';

const SubjectName = ({setSubjectName, subjectNameErrorStatus}) => {
  return (
    <Base>
      <Input
        label="Subject Name"
        placeholder="Insert the name of the Subject..."
        onChange={({target: {value}}) => setSubjectName(value)}
        statusError={subjectNameErrorStatus}
      />
      <ResultsSubject resultArr={['Arab', 'Arabic', 'Long Test Text']} />
    </Base>
  );
};

export default memo(SubjectName);
