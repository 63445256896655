import React from 'react';

import {Base, Label, Result, ResultContainer} from './styles';

const ResultsSubject = ({resultArr}) => {
  const resultElements = resultArr.map(result => (
    <Result key={result}>{result}</Result>
  ));

  return (
    <Base>
      <Label>Do you mean</Label>

      <ResultContainer>{resultElements}</ResultContainer>
    </Base>
  );
};

export default ResultsSubject;
