import styled from 'styled-components';
import {Link} from 'react-router-dom';

import device from 'mediaQueries';

const CTA = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 8px;

  @media ${device.desktopL} {
    font-size: 30px;
  }

  @media ${device.mobile} {
    font-size: 18px;
  }
`;

const PageLink = styled(Link)`
  font-size: 14px;
  color: white;
  text-decoration: none;
  margin-bottom: 30px;
  cursor: inital;

  @media ${device.desktopL} {
    font-size: 20px;
  }
`;

const LinkSignIn = styled.span`
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export {CTA, PageLink, LinkSignIn};
