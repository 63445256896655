import React, {memo, useCallback, useEffect} from 'react';
import {Base} from './styles';
import Category from './Category';
import useTitle from 'hooks/useTitle';
import useFetchContent from 'hooks/useFetchContent';
import MemberHomepageService from 'Services/memberHomepage';
import {useGetDBName} from '../../../hoc/HomeScheme/bl';
import UIService from '../../../Services/UIService';
import ModeSelectorV2 from '../Definitions/ModeSelectorV2';
import ModeSelector from '../Definitions/ModeSelector';
import {connect} from 'react-redux';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {getUserToken, removeUserToken} from '../../../utils/HelperService';
import {resetUser} from '../../../Store/actions';
import Constants from '../../../Constants/Constants';
import HistoryService from '../../../utils/HistoryService';
import {useLocation, useParams} from 'react-router-dom';
import MajorCategory from './MajorCategory';
import HomeSteps from './HomeSteps';

const Home = ({
  history,
  displayModes,
  messageModeSelected,
  searchString,
  searchFlag,
  actions,
  ...props
}) => {
  useTitle('Home', true);

  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);

  const [modes, setModes] = React.useState([]);
  const [selCategories, setSelCategories] = React.useState([]);
  const dbName = useGetDBName();
  const {group_category_id, category_id} = useParams();

  const {homeSteps: steps, homeCurrentCategory: currentCategory} = props;

  const modeSelectorRequired =
    searchQuery.get(Constants.SQ_HEADER_MODES) !== 'false' && getUserToken() != null;

  console.log('homeSteps', steps);
  console.log('homeCurrentCategory', currentCategory);

  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [categoryValues, setCategoryValues] = React.useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (messageModeSelected) {
      actions.updateMessageModeSelected(false);
      const messageMode = displayModes.find(m => m.mode_name === 'message');
      if (messageMode) {
        setModes([messageMode]);
      }
    }
  }, [messageModeSelected]);

  const setCurrentCategory = c => {
    actions.updateHomeCurrentCategory(c);
  };

  const setSteps = s => {
    actions.updateHomeSteps(s);
  };

  const [categories, loadingCategories, errorCategories, callCategories] =
    useFetchContent(
      [],
      MemberHomepageService.getCategoriesAndEntities,
      useCallback(({data}) => {
        if (data.categories == null) {
          console.log('here it is');
          removeUserToken();
          if (dbName === Constants.GIGA_MEDIA) {
            HistoryService.push('/gigamedia');
          } else {
            HistoryService.push('/');
          }
          actions.onResetUser();
        }
        return data.categories;
      }, []),
    );

  useEffect(() => {
    callCategories(group_category_id, category_id, currentCategory, searchString);
  }, [currentCategory, searchFlag]);

  useEffect(() => {
    setCategoryValues(categories)
  }, [categories]);

  useEffect(() => {
    setSelectedCategories(categoryValues?.filter(
        c =>
            (modes.length === 0 ||
                modes.some(m => Number(m?.mode_id) === -1) ||
                modes.some(m => Number(m?.mode_id) === Number(c.category_mode_id))) &&
            (selCategories.length === 0 ||
                selCategories.some(s => s.category_id === c.category_id)) ||
            (c.entities != null &&
                modes.some(m => Number(m?.mode_id) === Number(c.entities.mode_id))),
    ) ?? [])
  }, [categoryValues]);
  console.log("selectedcategories", selectedCategories)
  const handleClickStep = (step, index) => {
    if (step == null) {
      setSteps([]);
      setCurrentCategory(null);
    } else {
      setSteps(steps.slice(0, index + 1));
      setCurrentCategory(step.subcategory_link);
    }
  };

  const removeCategory = (category) => {
    setCategoryValues(categoryValues?.filter(
        c => ( c.category_id !== category.category_id)
    ))
  }

  return (
    <>
      {UIService.tabsHeaderRequired(dbName) && modeSelectorRequired && (
        <>
          {steps.length === 0 && (
            <ModeSelector
              setModes={setModes}
              modes={modes}
              categories={selCategories}
              setCategories={setSelCategories}
              dbName={dbName}
              groupCategoryId={group_category_id}
            />
          )}
          <ModeSelectorV2
            setModes={() => {}}
            modes={[]}
            push={history.push}
            dbName={dbName}
          />
        </>
      )}
      <Base className={'flex flex-row'}>
        <HomeSteps steps={steps} handleClickStep={handleClickStep} />
      </Base>
      <Base loading={loadingCategories} error={errorCategories}>
        {selectedCategories?.map((c, index) => {
          const {mode, category, entities} = c;
          const majorCategory = category == null;
          if (entities != null) {
            return (
              <Category
                key={`${entities.category} - ${index}`}
                title={entities.category}
                // entities={entities.entities.filter(v =>
                //   v.entityName
                //     .toLowerCase()
                //     .includes(searchString.toLowerCase()),
                // )}
                entities={entities.entities}
                mode={entities.mode}
              />
            );
          }
          return (
            <MajorCategory
              key={`${category} - ${index}`}
              category={c}
              setCurrentCategory={setCurrentCategory}
              steps={steps}
              setSteps={setSteps}
              removeCategory={removeCategory}
            />
          );
        })}
      </Base>
    </>
  );
};

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  homeSteps: state.UserData.homeSteps,
  homeCurrentCategory: state.UserData.homeCurrentCategory,
  searchString: state.UserData.searchString,
  searchFlag: state.UserData.searchFlag,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    // updateDisplayModes: modes => {
    //   dispatch(userActions.updateDisplayModes(modes));
    // },
    updateDisplayCategories: categories => {
      dispatch(userActions.updateDisplayCategories(categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
    onResetUser: () => dispatch(resetUser()),
    updateHomeCurrentCategory: c =>
      dispatch(userActions.updateHomeCurrentCategory(c)),
    updateHomeSteps: steps => dispatch(userActions.updateHomeSteps(steps)),

    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
    updateSearchFlag: flag => dispatch(userActions.updateSearchFlag(flag)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Home));
