import React, {memo, useCallback, useEffect, useState} from 'react';
import './styles.scss';
import {Base} from './styles';
import Button from '../../DesignSystem/button/button';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {connect} from 'react-redux';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {useStyles} from '../Definitions/AnalysisFlow/Topics/TopicSubmission/styles';
import HistoryService from '../../../utils/HistoryService';
import clsx from 'clsx';
import SearchBy from '../../SearchBy';
import ManageCategorySteps from '../ManageCategoryList/ManageCategorySteps';
import {
  addMCLSteps,
  getMCLSteps,
  setMCLSteps,
} from '../../../utils/HelperService';
import HomeSteps from '../Home/HomeSteps';

function ManageRatings(props) {
  const subTitle = props.location?.state?.subTitle;
  const endpointURL =
    props.location?.state?.endpointURL ??
    'react_gi_v1_manage_category_ratings_display.php';

  const objectKey = subTitle === 'Key Factor' ? 'key_factor' : 'category';

  const [data, loadingRatings, errorRatings, callRatings] = useFetchContent(
    [],
    MemberHomepageService.getRatingsForManagement,
    useCallback(({data}) => {
      setRatings(data[`${objectKey}_ratings`]);
      return data;
    }, []),
  );

  const [ratings, setRatings] = useState([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    let stepLength = 3;
    if (objectKey === 'category') {
      stepLength = 2;
    }
    const steps = getMCLSteps();
    if (steps.length > stepLength) {
      setMCLSteps(steps.slice(0, stepLength));
    }

    callRatings(endpointURL, -1);
  }, []);

  const classes = useStyles();

  const onClickCreateRating = (ratingId = -1, rating) => {
    const path = {
      pathname: `/home/ratings/${ratingId}`,
      state: {
        rating,
        endpointURL,
        objectKey,
      },
    };

    addMCLSteps({
      ...path,
      title: `${ratingId === -1 ? 'Create' : 'Edit'} ${subTitle} Rating`,
    });
    HistoryService.push(path);
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          Manage {subTitle} Ratings
        </span>
      </Base>

      <Base className={'flex flex-row px-4 md:px-0'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={props.manageSteps}
          handleClickStep={() => {}}
        />
      </Base>

      <Base className={'flex flex-row px-4 md:px-0'}>
        <ManageCategorySteps />
      </Base>

      <Base
        className={'flex flex-col justify-center'}
        loading={false}
        error={false}>
        <div className={'grid grid-cols-2 md:grid-cols-3 gap-4 px-4 md:px-0'}>
          <div className={'col-span-2'}>
            <SearchBy
              className={clsx('flex flex-1')}
              placeholder="Search by Name and Description"
              searchInputProps={{
                value: searchString,
                onChange: e => {
                  setSearchString(e.target.value);
                },
              }}
            />
          </div>
        </div>
        <div className="mt-1 mb-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Definition
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ratings
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ratings
                    .filter(
                      v =>
                        (v[`${objectKey}_rating_name`] ?? '')
                          .toLowerCase()
                          .includes(searchString.toLowerCase()) ||
                        (v[`${objectKey}_rating_description`] ?? '')
                          .toLowerCase()
                          .includes(searchString.toLowerCase()),
                    )
                    .map((item, index) => {
                      const name = item[`${objectKey}_rating_name`];
                      const description =
                        item[`${objectKey}_rating_description`];
                      const cla = item[`${objectKey}_rating_definition`];
                      const isActive = false;
                      return (
                        <tr
                          key={`${index}`}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }>
                          <td
                            className={`px-6 py-4 text-sm text-gray-500 ${
                              !isActive ? '' : 'cursor-pointer underline'
                            }`}
                            onClick={() => {}}>
                            {name}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {cla}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500 max-w-md overflow-x-auto">
                            {description}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                onClickCreateRating(
                                  item[`${objectKey}_rating_id`],
                                  item,
                                );
                              }}
                              className="bg-giga-primary-blue_300 hover:opacity-70 text-white py-1 px-3 rounded">
                              Edit
                            </a>
                            {/*<a*/}
                            {/*  href="#"*/}
                            {/*  className="text-red-600 hover:text-red-900">*/}
                            {/*  Delete*/}
                            {/*</a>*/}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className={'mkf-create-new'}>
          <Button
            onClick={() => {
              onClickCreateRating();
            }}
            bottomGap>
            Add new {subTitle} rating
          </Button>
        </div>
      </Base>
    </>
  );
}

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  manageSteps: state.UserData.manageSteps,
  manageCurrentCategory: state.UserData.manageCurrentCategory,
  searchString: state.UserData.searchString,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateDisplayCategories: categories => {
      dispatch(userActions.updateDisplayCategories(categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
    onResetUser: () => dispatch(resetUser()),
    updateManageCurrentCategory: c =>
      dispatch(userActions.updateManageCurrentCategory(c)),
    updateManageSteps: steps => dispatch(userActions.updateManageSteps(steps)),

    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(ManageRatings));
