import React, {memo, useCallback, useEffect, useState} from 'react';
import './styles.scss';
import {Base} from './styles';
import Button from '../../DesignSystem/button/button';
import HistoryService from '../../../utils/HistoryService';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {resetUser} from '../../../Store/actions';
import {connect} from 'react-redux';
import HomeSteps from '../Home/HomeSteps';
import {addMCLSteps, setMCLSteps} from '../../../utils/HelperService';

function ManageCategoryList(props) {
  // const [title, setTitle] = useState('Manage Category List');

  const {manageSteps: steps, manageCurrentCategory: currentCategory} = props;
  const [newCategoryURL, setNewCategoryURL] = useState('');
  const [categorySubjects, setCategorySubjects] = useState([]);
  const [accountProfile, setAccountProfile] = useState({});

  const [hasSubjects, setHasSubjects] = useState(false);

  const [categories, loadingCategories, errorCategories, callCategories] =
    useFetchContent(
      [],
      MemberHomepageService.getCategoriesForManagement,
      useCallback(({data}) => {
        setNewCategoryURL(data?.buttons?.add_new_category_url);
        setCategorySubjects(data.subjects ?? []);
        if (data.subjects != null) {
          setHasSubjects(true);
        } else {
          setHasSubjects(false);
        }
        return data.subcategories ?? [];
      }, []),
    );

  useEffect(() => {
    callCategories(currentCategory);
  }, [currentCategory]);

  useEffect(() => {
    setMCLSteps([]);
    MemberHomepageService.getAccountProfile().then(res => {
      console.log('accountProfile', res);
      setAccountProfile(res?.data ?? {});
    });
  }, []);

  const setSteps = s => {
    props.actions.updateManageSteps(s);
  };

  const setCurrentCategory = c => {
    props.actions.updateManageCurrentCategory(c);
  };

  const handleClickStep = (step, index) => {
    if (step == null) {
      setSteps([]);
      setCurrentCategory(null);
    } else {
      setSteps(steps.slice(0, index + 1));
      setCurrentCategory(step.next_category_url_string);
    }
  };

  const onClickNewSubject = item => {
    const searchQuery = new URLSearchParams(currentCategory);
    const subjectId = item?.subject_id ?? -1;
    const path = {
      pathname: `/home/subjects/${subjectId}/${
        searchQuery.get('category_id') ?? -1
      }/${searchQuery.get('parent_category_id') ?? -1}`,
    };
    addMCLSteps({
      ...path,
      title: subjectId === -1 ? 'Create Subject' : 'Edit Subject',
    });
    HistoryService.push(path);
  };

  const onClickImportCategory = importKey => {
    const searchQuery = new URLSearchParams(currentCategory);
    console.log(currentCategory);

    const path = {
      pathname: `/home/import-categories/${
        searchQuery.get('category_id') ?? -1
      }`,
      state: {
        importKey,
      },
    };
    addMCLSteps({
      ...path,
      title: `Import New ${
        importKey === 'category' ? 'Categories' : 'Subjects'
      } from CSV`,
    });
    HistoryService.push(path);
  };

  const onClickNewCategory = (
    categoryId = -1,
    selectedCategoryURL = newCategoryURL,
  ) => {
    const path = {
      pathname: `/home/categories/${categoryId}`,
      state: {
        selectedCategoryURL,
        parentName:
          steps.length === 0
            ? 'Root'
            : steps[steps.length - 1].subcategory_name,
      },
    };
    addMCLSteps({
      ...path,
      title: categoryId === -1 ? 'Create Category' : 'Edit Category',
    });
    HistoryService.push(path);
  };

  const onClickLinkKeyFactor = item => {
    const path = {
      pathname: '/home/link-key-factors',
      state: {
        externalId: item.subcategory_id,
      },
    };
    addMCLSteps({
      ...path,
      title: 'Link Key Factors',
    });
    HistoryService.push(path);
  };

  return (
    <>
      <Base className={'flex flex-row justify-center mt-6 mb-12'}>
        <span className={'text-2xl font-semibold self-center'}>
          Manage {hasSubjects ? 'Subjects' : 'Categories'}
        </span>
      </Base>
      <Base className={'flex flex-row px-4 md:px-0'}>
        <HomeSteps
          nameKey={'subcategory_name'}
          steps={steps}
          handleClickStep={handleClickStep}
        />
      </Base>
      <Base
        className={'flex flex-col justify-center'}
        loading={loadingCategories}
        error={errorCategories}>
        {hasSubjects ? (
          // <EntitiesContainer>
          //   <Entity
          //     title={'Add New'}
          //     portraitUrl={
          //       'https://api.gigaintelligence.com/pictures/subject_default_image.png'
          //     }
          //     redirectToEntity={() => {}}
          //     userName={null}
          //     userImage={''}
          //   />
          //   {categorySubjects.map((subjectItem, index) => {
          //     const entityName = subjectItem.subject_name;
          //     const entityImage = subjectItem.subject_image_url;
          //     return (
          //       <Entity
          //         key={entityName}
          //         title={entityName}
          //         portraitUrl={entityImage}
          //         redirectToEntity={() => {}}
          //         userName={`${accountProfile.first_name} ${accountProfile.last_name}`}
          //         userImage={accountProfile.url_file}
          //       />
          //     );
          //   })}
          // </EntitiesContainer>
          <>
            <div className="mt-1 mb-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Image
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <div className={'flex justify-center'}>Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {categorySubjects.map((item, index) => {
                        const subjectName = item.subject_name;
                        const subjectDescription = (
                          item.subject_description ?? ''
                        )
                          .replace('<p>', '')
                          .replace('</p>', '');
                        const subjectImage =
                          item.subject_image_url === ''
                            ? item.subject_image
                            : item.subject_image_url;
                        return (
                          <tr
                            key={`${index}`}
                            className={
                              index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            }>
                            <td className="px-6 py-4 text-sm text-gray-500">
                              <img
                                alt={'img'}
                                src={subjectImage}
                                className={'w-20 h-20 object-contain'}
                              />
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500`}>
                              {subjectName}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                              {subjectDescription}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  onClickNewSubject(item);
                                }}
                                className="bg-giga-primary-blue_300 hover:opacity-70 text-white py-1 px-3 rounded">
                                Edit
                              </a>
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                }}
                                className="bg-red-600 hover:bg-red-900 text-white py-1 px-3 rounded">
                                Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className={'mcl-create-new'}>
              <Button onClick={() => onClickNewSubject()} bottomGap>
                Add new subject
              </Button>
            </div>
            <div className={'mcl-create-new'}>
              <Button
                onClick={() => onClickImportCategory('subject')}
                bottomGap>
                Import from CSV
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="mt-1 mb-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Image
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((item, index) => {
                        const categoryName = item.subcategory_name;
                        const categoryDescription =
                          item.subcategory_description ?? '';
                        return (
                          <tr
                            key={`${index}`}
                            className={
                              index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            }>
                            <td
                              className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500 ${
                                item.next_category_url_string === ''
                                  ? ''
                                  : 'cursor-pointer underline'
                              }`}
                              onClick={() => {
                                if (item.next_category_url_string !== '') {
                                  // setTitle(item.category_name);
                                  setSteps([...steps, item]);
                                  setCurrentCategory(
                                    item.next_category_url_string,
                                  );
                                }
                              }}>
                              {categoryName}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                              {categoryDescription}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                              <img
                                alt={'img'}
                                src={item.subcategory_image_url}
                                className={'w-32 h-32 object-contain'}
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  onClickNewCategory(
                                    item.subcategory_id,
                                    item.edit_category_url,
                                  );
                                }}
                                className="bg-giga-primary-blue_300 hover:opacity-70 text-white py-1 px-3 rounded">
                                Edit
                              </a>
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  onClickLinkKeyFactor(item);
                                }}
                                className="bg-giga-secondary-orange_200 hover:opacity-70 text-white py-1 px-3 rounded">
                                Key factors
                              </a>
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  if (item.subjects_per_category_url !== '') {
                                    // setTitle(item.category_name);
                                    setSteps([...steps, item]);
                                    setCurrentCategory(
                                      item.subjects_per_category_url,
                                    );
                                  }
                                }}
                                className="bg-giga-red hover:opacity-70 text-white py-1 px-3 rounded">
                                Subjects
                              </a>
                              {/*<a*/}
                              {/*  href="#"*/}
                              {/*  className="text-red-600 hover:text-red-900">*/}
                              {/*  Delete*/}
                              {/*</a>*/}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className={'mcl-create-new'}>
              <Button onClick={() => onClickNewCategory()} bottomGap>
                Add new category
              </Button>
            </div>
            <div className={'mcl-create-new'}>
              <Button
                onClick={() => onClickImportCategory('category')}
                bottomGap>
                Import from CSV
              </Button>
            </div>
            <div className={'mcl-create-new'}>
              <Button
                onClick={() => {
                  const path = {
                    pathname: '/home/manage-key-factors',
                  };
                  addMCLSteps({
                    ...path,
                    title: 'Manage Key Factors',
                  });
                  HistoryService.push(path);
                }}
                bottomGap>
                Manage key factors
              </Button>
            </div>
          </>
        )}
      </Base>
    </>
  );
}

const mapStateToProps = state => ({
  displayCategories: state.UserData.displayCategories,
  displayModes: state.UserData.displayModes,
  messageModeSelected: state.UserData.messageModeSelected,
  manageSteps: state.UserData.manageSteps,
  manageCurrentCategory: state.UserData.manageCurrentCategory,
  searchString: state.UserData.searchString,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateDisplayCategories: categories => {
      dispatch(userActions.updateDisplayCategories(categories));
    },
    updateMessageModeSelected: flag =>
      dispatch(userActions.updateMessageModeSelected(flag)),
    onResetUser: () => dispatch(resetUser()),
    updateManageCurrentCategory: c =>
      dispatch(userActions.updateManageCurrentCategory(c)),
    updateManageSteps: steps => dispatch(userActions.updateManageSteps(steps)),

    updateSearchBar: str => dispatch(userActions.updateSearchBar(str)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(ManageCategoryList));
