import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  flex: 1;
  padding: 0 20px;

  font-weight: 500;
`;

const AlertContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  color: #989898;

  width: 100%;
  height: 100%;
`;

export {Base, AlertContainer};
