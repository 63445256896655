import React, {memo} from 'react';
import './styles.css';
import {ReactComponent as StepSVG} from '../../../../../assets/svg/member/home-arrow.svg';
import HistoryService from '../../../../../utils/HistoryService';
import {connect} from 'react-redux';

function HomeSteps({homeSteps}) {
  const renderStep = (name, action = () => {}, active = false) => (
    <span
      onClick={() => action()}
      className={`font-semibold text-base ${
        active
          ? 'text-giga-graymain cursor-pointer transform transition duration-300 ease-out hover:scale-105'
          : 'text-giga-graymedium'
      }`}>
      {name}
    </span>
  );

  const categoryName =
    homeSteps.length === 0
      ? 'Home'
      : homeSteps[homeSteps.length - 1].category_name;

  return (
    <div className={'flex flex-row mt-8 mb-6 items-center'}>
      {renderStep(categoryName, () => HistoryService.push('/home'), true)}
      <div className={'mx-3'}>
        <StepSVG width={14} height={14} />
      </div>
      {renderStep('Assessment')}
    </div>
  );
}

const mapStateToProps = state => ({
  homeCurrentCategory: state.UserData.homeCurrentCategory,
  homeSteps: state.UserData.homeSteps,
});

export default connect(mapStateToProps, null)(HomeSteps);
