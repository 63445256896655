import styled from 'styled-components';

import ServerContent from 'hoc/ServerContent';

import device from 'mediaQueries';

const Base = styled(ServerContent)`
  width: 100%;
  max-width: 1400px;

  @media (min-width: 640px) {
    width: 96%;
  }
`;

export {Base};
