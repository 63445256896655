import React, {useEffect, useRef} from 'react';
import {defaultStyles, FileIcon} from 'react-file-icon';

import {Base, FileName} from './styles';

import {createBlobURL, getFileExtension} from 'Helpers/files/index';

import Button from 'Components/UI/Button';
import axios from 'axios';
import fileDownload from 'js-file-download';

const FilePreview = ({file, fileName, type}) => {
  const downloadButtonRef = useRef(null);

  const fileExtension = getFileExtension(fileName);

  // useEffect(() => {
  //   console.log(file);
  //   const url = createBlobURL(file);
  //   const downloadButton = downloadButtonRef.current;
  //
  //   downloadButton.setAttribute('download', `${fileName}`);
  //   downloadButton.setAttribute('href', url);
  //
  //   return () => {
  //     URL.revokeObjectURL(url);
  //   };
  // }, [file, fileName]);

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then(res => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <Base>
      <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} />
      <FileName>{fileName}</FileName>
      <Button
        component="a"
        ref={downloadButtonRef}
        onClick={() => {
          handleDownload(file, fileName);
        }}>
        Download File
      </Button>
    </Base>
  );
};

export default FilePreview;
