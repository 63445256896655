import {generateFormOnObject} from '../Helpers/api';
import {ApiClient} from './base';

class ManageMasterEntities {
  static postEntity(
    master_entity_type,
    external_id,
    name,
    description,
    follow,
  ) {
    const body = generateFormOnObject({
      master_entity_type,
      external_id,
      name,
      description,
      'follow[]': follow,
    });

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_gi_v1_manage_master_entities_process.php',
    });
  }
}

export default ManageMasterEntities;
