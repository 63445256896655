import React, {useState} from 'react';

import {ButtonsContainer} from './styles';
import LandingButton from '../../UI/LandingButton';

import device from 'mediaQueries';
import DemoButton from '../../UI/DemoButton';
import GGModal from '../../../Modal';
import GGDemoModal from '../GGDemoModal';
import ChannelService from '../../../../utils/ChannelService';

const buttonCSS = `
  &:not(:last-of-type) {
    margin-right: 1.875rem;

    @media ${device.tablet} {
      margin-right: 1.25rem;
    }
  }
`;

const SignButtons = ({history, isDana, variant, channel}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClickSignUp = () =>
    history.push({
      pathname: '/signup',
      state: {
        isDana,
        variant,
      },
    });

  return (
    <ButtonsContainer>
      {!ChannelService.isPrivateChannel(channel) && (
        <DemoButton
          onClick={() => setModalVisible(true)}
          color={channel?.mainColor ?? '#2CA3FF'}
          css={buttonCSS}>
          Giga demo
        </DemoButton>
      )}
      <LandingButton
        bgCol="rgba(0,0,0, 0.8)"
        onClick={handleClickSignUp}
        css={buttonCSS}>
        Sign up
      </LandingButton>
      <LandingButton
        bgCol="rgba(0,0,0, 0.4)"
        onClick={() =>
          history.push({
            pathname: '/signin',
            state: {
              isDana,
              variant,
            },
          })
        }
        css={buttonCSS}>
        Sign in
      </LandingButton>
      <GGModal
        modalVisible={modalVisible}
        handleCloseModal={() => setModalVisible(false)}
        renderContent={() => (
          <GGDemoModal
            handleClickSignUp={() => handleClickSignUp()}
            handleCloseModal={() => setModalVisible(false)}
          />
        )}
      />
    </ButtonsContainer>
  );
};

export default SignButtons;
