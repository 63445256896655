import {useState} from 'react';

import {setNewBlock} from './bl';

const useSplitContent = (arr, blockLength) => {
  const [current, setCurrent] = useState(arr.slice(0, blockLength));
  const [index, setIndex] = useState(blockLength);
  const [max, setMax] = useState(false);

  return [
    current,
    max,
    setNewBlock.bind(
      null,
      arr,
      current,
      setCurrent,
      index,
      setIndex,
      max,
      setMax,
      blockLength,
    ),
  ];
};

export default useSplitContent;
