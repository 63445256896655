import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImg = ({src, height, width, alt, ...otherProps}) => (
  <LazyLoadImage
    effect="blur"
    alt={alt}
    src={src}
    height={height}
    width={width}
    style={{objectFit: 'contain'}}
  />
);

export default LazyImg;
