import React from 'react';

import {
  Base,
  Card,
  ContentContainer,
  ExplanationTopic,
  NoImageText,
  PortraitImg,
  TitleOverview,
} from './styles';

import ShowHTMLString from 'hoc/ShowHTMLString';

import {deleteHTMLFromString} from 'Helpers/dom';
import {getUserMode} from '../../../../../utils/HelperService';
import {displayModesNames} from '../../../../../Constants/Constants';

const ExampleView = ({
  type,
  url,
  subjectDesc,
  custom = false,
  customText,
  hideHeaderTitle = false,
  orderRequired = false,
}) => {
  const {mode_name: mode} = getUserMode();
  const headerTitle = mode === 'assessment' ? null : displayModesNames(mode);

  let result = (
    <Base>
      <TitleOverview>{type} Overview</TitleOverview>
      <Card pd={30}>
        <ExplanationTopic>Explanation of your {type}</ExplanationTopic>
        <Card pd={20}>
          <ContentContainer>
            <ShowHTMLString
              htmlString={subjectDesc ? subjectDesc : '(No content...)'}
            />
          </ContentContainer>
        </Card>
        {url ? (
          <PortraitImg src={url} alt="Portrait Pic" />
        ) : (
          <NoImageText>No Portrait Image</NoImageText>
        )}
      </Card>
    </Base>
  );

  if (custom) {
    const {title, exampleFunc} = customText;

    const CardItem = ({explanation, children, htmlContent}) => (
      <Card pd={10} bottomGap>
        <ExplanationTopic>{headerTitle ?? explanation}</ExplanationTopic>
        <Card pd={20}>
          <ContentContainer>
            <ShowHTMLString
              htmlString={
                htmlContent && deleteHTMLFromString(htmlContent)
                  ? htmlContent
                  : '(No content...)'
              }
            />
          </ContentContainer>
        </Card>
        {children}
      </Card>
    );

    result = (
      <Base orderRequired={orderRequired}>
        {hideHeaderTitle ? (
          <div className={'mt-0'} />
        ) : (
          <TitleOverview>{headerTitle ?? title}</TitleOverview>
        )}
        <Card pd={30}>{exampleFunc(CardItem)}</Card>
      </Base>
    );
  }

  return result;
};

export default ExampleView;
