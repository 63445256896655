import {ApiClient} from './base'

import {formatHiddenProps, generateFormOnObject} from 'Helpers/api'
import {
  getCategoryMode,
  getEntityParams, getHiddenItems,
  getUserMode,
  getUserToken,
} from '../utils/HelperService'
import {useParams} from 'react-router-dom'
import moment from 'moment'

class MemberHomepageService {
  static callEndpoint(endpointURL) {
    return ApiClient({
      method: 'get',
      url: endpointURL,
    })
  }

  static getCategoryRatings(externalId) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_manage_category_ratings_display.php?external_id=${externalId}`,
    })
  }

  static getKeyFactorsRatings(externalId) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_manage_key_factor_ratings_display.php?external_id=${externalId}`,
    })
  }

  static getNotificationsDisplay() {
    return ApiClient({
      method: 'get',
      // url: `/${mode.link}.php?${queryParams}&amp;mode=${mode.mode}`,
      url: '/react_gi_v1_notifications_display.php',
    })
  }

  static getNotificationsSummaryDisplay() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_notifications_summary_display.php',
    })
  }

  static getNotificationsDetailsDisplay(notification_request_type, category_id, mode_id) {
    console.log("wow", notification_request_type, category_id, mode_id)
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_notifications_details_display.php?notification_request_type=${notification_request_type}&category_id=${category_id}&mode_id=${mode_id}`,
    })
  }

  static getCategoriesForManagement(currentCategoryLink) {
    return ApiClient({
      method: 'get',
      url:
        currentCategoryLink ??
        `/react_gi_v1_manage_categories_display.php?category_id=0&parent_category_id=0`,
    })
  }

  static getKeyFactorsForManagement(externalId) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_manage_key_factors_display.php?external_id=${externalId}`,
    })
  }

  static getClassificationForManagement(externalId) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_manage_key_factors_display.php?external_id=${externalId}`,
    })
  }

  static getLinkKeyFactorsForManagement(externalId) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_link_key_factors_display.php?external_id=${externalId}`,
    })
  }

  static checkLinkKeyFactor(body) {
    const request = generateFormOnObject(body)
    return ApiClient({
      method: 'post',
      url: `/react_gi_v1_link_key_factors_process.php`,
      data: request,
    })
  }

  static getRatingsForManagement(endpointURL, externalId) {
    return ApiClient({
      method: 'get',
      url: `/${endpointURL}?external_id=${externalId}`,
    })
  }

  static createCategory(body, image) {
    let request = generateFormOnObject(body)
    if (image) {
      request.append('image', image)
    }
    return ApiClient({
      method: 'post',
      url: `/react_gi_v1_manage_categories_process.php`,
      data: request,
    })
  }

  static createCategoriesWithCSV(external_id, file, endpoint) {
    let request = generateFormOnObject({external_id})
    if (file) {
      request.append('file_name', file)
    }
    return ApiClient({
      method: 'post',
      url: endpoint ?? `/react_gi_v1_category_csv_fileupload.php`,
      data: request,
    })
  }

  static createSubject(body, image) {
    let request = generateFormOnObject(body)
    if (image) {
      request.append('image', image)
    }
    return ApiClient({
      method: 'post',
      url: `/react_gi_v1_manage_subjects_process.php`,
      data: request,
    })
  }

  static createRating(objectKey, body) {
    return ApiClient({
      method: 'post',
      url: `/react_gi_v1_manage_${objectKey}_ratings_process.php`,
      data: generateFormOnObject(body),
    })
  }

  static createKeyFactor(body) {
    return ApiClient({
      method: 'post',
      url: `/react_gi_v1_manage_key_factors_process.php`,
      data: generateFormOnObject(body),
    })
  }

  static getCategoriesAndEntities(
    group_category_id,
    category_id,
    currentCategoryLink,
    searchString
  ) {
    // const queryParams =
    //   'under_development=1&category_id=0&amp;assessor_id=0&amp;external_id=0&amp;question_id=0&amp;freeformsection_id=0&amp;ans_text=0&amp;ans_comments=0&amp;display_subcategories=0&amp;start=0&amp;stop=0&amp;max=8';

    console.log('currentCategory', currentCategoryLink)
    const queryParams =
      `under_development=1&category_id=0&display_subcategories=1&display_majorcategories=0&display_subjects=0`
    const queryParamsSearch =
        `under_development=1&category_id=0&display_subcategories=1&display_majorcategories=0&display_subjects=0&search=${searchString}`
    const user = getUserToken()
    return ApiClient({
      method: 'get',
      // url: `/${mode.link}.php?${queryParams}&amp;mode=${mode.mode}`,
      url:
        currentCategoryLink ??
        (user == null
          ? `/react_gi_v1_display_guest_member_homepage.php?category_id=${
            category_id ?? '6250'
          }&assessor_id=0&external_id=0&question_id=0&freeformsection_id=0&ans_text=0&ans_comments=0&start=0&stop=0&max=8&display_subcategories=0&display_majorcategories=0&display_subjects=1&mode_id=3&group_category_id=${
            group_category_id ?? -1
          }`
          : searchString === '' ? `/react_gi_v1_display_member_homepage.php?${queryParams}` : `/react_gi_v1_display_member_homepage_search.php?${queryParamsSearch}`),
    })
  }

  static getAssesmentsOfEntity(cat, cid, techId) {
    const {mode_id} = getUserMode()
    const {category_id} = getEntityParams()
    const queryParams = `cat=${cat}&cid=${cid}&tech_id=${techId}&mode_id=${mode_id}&external_id=${techId}&cat_id=${category_id}`

    return ApiClient({
      method: 'get',
      url: `/react_gi_v2a_view_entity_listings.php?${queryParams}`,
    })
  }

  static getGuestReportOfEntity(cat, cid, techId) {
    const {mode_id} = getUserMode()
    const queryParams = `cat=${cat}&cid=${cid}&tech_id=${techId}&mode_id=${mode_id}`

    return ApiClient({
      method: 'get',
      url: `/react_gi_v2a_summary_guest_report.php?${queryParams}`,
    })
  }

  static getAssesment(assessmentEndpoint, cat, cid, techId, assessorID) {
    const {mode_id, mode_name, category_id, tech_id, parent_id, parent_type} =
      getEntityParams()
    const body = generateFormOnObject({
      tech_id,
      cid,
      cat,
      assessor: assessorID,
      mode_id,
      mode_name,
      category_id,
      parent_id,
      parent_type,
    })

    if (assessmentEndpoint) {
      return ApiClient({
        method: 'post',
        url: `/${assessmentEndpoint}`,
        data: body,
      })
    }
  }

  static getAssesmentTemp(assessmentEndpoint, cat, cid, techId, assessorID, prompt, ai_engine_index, prompt_count, ff_freeform_id, ff_topic_id) {
    const {mode_id, mode_name, category_id, tech_id, parent_id, parent_type} =
      getEntityParams()
    const body = generateFormOnObject({
      tech_id,
      cid,
      cat,
      assessor: assessorID,
      mode_id,
      mode_name,
      category_id,
      parent_id,
      parent_type,
      prompt,
      ai_engine_index,
      prompt_count,
      ff_freeform_id,
      ff_topic_id
    })

    if (assessmentEndpoint) {
      return ApiClient({
        method: 'post',
        url: `/${assessmentEndpoint}`,
        data: body,
      })
    }
  }

  static getCategoryInfo() {
    const queryParams = `category_id=0&assessor_id=0&external_id=0&question_id=0&freeformsection_id=0&ans_text=0&ans_comments=0&display_subcategories=0&start=0&stop=0&max=8`

    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_display_member_homepage_extended.php?${queryParams}`,
    })
  }

  static getCategories() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_display_member_homepage_extended.php?category_id=-1',
    })
  }

  static checkIfSubjectIsRepeated(categoryID, subjectName) {
    const queryParams = `category_id=${categoryID}&subject_name=${encodeURIComponent(
      subjectName,
    )}`

    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_verify_category_subject_pair.php?${queryParams}`,
    })
  }

  static checkIfCategoryIsRepeated(categoryName) {
    const queryParams = `category_name=${categoryName}`

    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_lookup_category.php?${queryParams}`,
    })
  }

  static postSubject(
    subjectName,
    subjectDesc,
    portraitAttachmentFile,
    pid,
    cid,
    urlPortrait,
    lastSubmittedPortrait,
  ) {
    const body = generateFormOnObject({
      freeform_id: '26',
      freeformsection_id: '5785',
      category_id: pid,
      cid,
      pid,
      tech_name: subjectName,
      tech_desc: subjectDesc,
      binFile: lastSubmittedPortrait === 'file' ? portraitAttachmentFile : null,
      tech_detail: lastSubmittedPortrait === 'url' ? urlPortrait : null,
    })

    return ApiClient({
      method: 'post',
      url: '/react_process_gi_v1_define_table_element.php',
      data: body,
    })
  }

  static getModels() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_retrieve_models.php',
    })
  }

  static getKeyFactors() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_retrieve_key_factors.php',
    })
  }

  static postCategory(
    categoryName,
    modelID,
    selectedKeyFactors,
    categoryDesc,
    categoryImageFile,
    categoryImageURL,
    lastSubmittedPortrait,
  ) {
    const keyFactorsObj = selectedKeyFactors.reduce(
      (acc, keyFactorID) => ({
        ...acc,
        [`kpi_${keyFactorID}`]: `kpi_${keyFactorID}`,
      }),
      {},
    )

    const body = generateFormOnObject({
      freeform_id: '27',
      freeformsection_id: '5786',
      tech_name: categoryName,
      model_id: modelID,
      tech_desc: categoryDesc,
      binFile: lastSubmittedPortrait === 'file' ? categoryImageFile : null,
      tech_detail: lastSubmittedPortrait === 'url' ? categoryImageURL : null,
      ...keyFactorsObj,
    })

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_process_gi_v1_define_entity_types.php',
    })
  }

  static postAssessmentFlowForm(cid, techId) {
    let queryParams = `cid=${cid}&tech_id=${techId}`

    const mode = getUserMode()
    const category = getCategoryMode()

    if (mode.mode_id) {
      queryParams = `${queryParams}&mode_id=${mode.mode_id}`
    }

    if (category.category_id) {
      queryParams = `${queryParams}&category_id=${category.category_id}`
    }

    if (
      mode?.mode_label !== category?.category ||
      mode?.mode_id === '3' ||
      techId !== '-1'
    ) {
      return ApiClient({
        method: 'get',
        url: `/react_gi_v2a_eval_entity_display.php?${queryParams}`,
      })
    } else {
      return ApiClient({
        method: 'get',
        url: `/react_gi_v1_mode_category_query_for_new_content_creation.php?${queryParams}`,
      })
    }
  }

  static postTopicInfo(
    subjectID,
    cid,
    freeformID,
    topicID,
    urlRequest,
    parent_id,
    parent_type,
    otherProps = {},
  ) {
    const mode = getUserMode()
    // const category = getCategoryMode()
    const queryParameters = new URLSearchParams(location.search)
    const catAssesment = queryParameters.get("cat");  // boston celtic players
    const {mode_id, mode_name, category_id, tech_id} = getEntityParams()

    const body = generateFormOnObject({
      external_id: subjectID,
      cid,
      freeform_id: freeformID,
      topic_id: topicID,
      parent_id,
      parent_type,
      ...otherProps,
    })

    if (mode_id) {
      body.append('mode_id', mode_id)
    }

    if (mode_name) {
      body.append('mode_name', mode_name)
    }

    if (category_id) {
      body.append('category_id', category_id)
    }

    if (catAssesment) {
      body.append('category_name', catAssesment)
    }

    return ApiClient({
      method: 'post',
      data: body,
      url: `/${urlRequest}`,
    })
  }

  // get chatgpt rating from assessment button
  static getRatingsFromChatGPT(endpointURL, assessment_name) {
    return ApiClient({
      method: 'get',
      url: `/${endpointURL}?assessment_name=${assessment_name}`,
    })
  }

  // get chatgpt rating from assessment button
  static getListOfAiEngines() {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_ai_engines.php`,
    })
  }

  static getListOfAnalytics() {
    const {mode_id, mode_name, category_id, tech_id} = getEntityParams()
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_analytics.php?category_id=${category_id}`,
    })
  }

  static postSaveTopic(
    hiddenProps,
    assessment_summary,
    topicContent,
    isSummary,
    assessmentTitle,
    assessmentDes,
    assessmentImage,
    additionalParamsForKeyFactors,
    extraRatings,
    members,
  ) {
    const hiddenValues = formatHiddenProps(hiddenProps)
    const ratingsAndNarratives = assessment_summary
      .map(
        ({
           assessment_topic,
           assessment_rating_topic_html_element_name,
           assessment_narrative_topic_html_element_name,
         }) => {
          const {htmlContent, selectedRating} = topicContent.find(
            topic => topic.assessment_topic === assessment_topic,
          )

          return {
            [assessment_rating_topic_html_element_name]: selectedRating,
            [assessment_narrative_topic_html_element_name]: htmlContent,
          }
        },
      )
      .reduce((acc, dataTopic) => ({...acc, ...dataTopic}), {})

    let extraRatingValues = {}
    if (extraRatings) {
      extraRatings.forEach(extraRating => {
        extraRatingValues = {
          ...extraRatingValues,
          [extraRating.ratings_html_name]: extraRating.ratings_selection,
        }
      })
    }

    const {mode_name: mode} = getUserMode()
    const {mode_id, mode_name, category_id, tech_id} = getEntityParams()

    const {freeform_id, external_id, ...newValues} = hiddenValues

    let additionals = {}

    if (!isSummary) {
      const {cid, kf_id, kf_name, kpix, ocid, otech_id, xpid} =
        additionalParamsForKeyFactors
      additionals = {cid, kf_id, kf_name, kpix, ocid, otech_id, xpid}
    }

    const body = generateFormOnObject({
      ...ratingsAndNarratives,
      ...newValues,
      ...extraRatingValues,
      complete: 'submit subtopic assessment',
      mode_id, // Assessments
      mode_name,
      category_id,
      tech_id,
      external_id: external_id,
      ff_freeform_id: freeform_id,
      folder_id: 1, // General
      ...additionals,
    })
    // if (members != null) {
    //   body.append('members', JSON.stringify(members))
    // }

    console.log('postSaveTopic isSummary', isSummary)

    let endpoint = isSummary
      ? '/react_gi_v1_edit_entity_commentary_process.php'
      : '/react_gi_v1_edit_kpi_commentary_process.php'

    console.log('postSaveTopic mode', mode)

    if (mode !== 'assessment') {
      endpoint = '/react_gi_v1_edit_entity_commentary_process.php'
      body.append('assessment_name', assessmentTitle)
      body.append('assessment_description', assessmentDes)
      body.append('image', assessmentImage)
    }

    const attachmentsObj = topicContent.map(
      ({assessment_topic, assetsBlockData}) => {
        const subtopicSectionId = assessment_summary
          .find(subtopic => subtopic.assessment_topic === assessment_topic)
          .assessment_narrative_topic_html_element_name.substring(3)

        return {
          sectionId: subtopicSectionId,
          assets: assetsBlockData,
        }
      },
    )

    attachmentsObj.forEach(({sectionId, assets}) => {
      if (assets.length > 0) {
        assets.forEach(asset => {
          const {type} = asset

          let name = `binFile_${sectionId}[]`
          let assetToUpload = asset.src

          if (type === 'image' || type === 'video') {
            assetToUpload = asset.fileToUpload
          }

          if (type === 'link') {
            name = `urlLink_${sectionId}[]`
          }

          body.append(name, assetToUpload)
        })
      }
    })

    console.log(body)

    return ApiClient({
      method: 'post',
      data: body,
      url: endpoint,
    })
  }

  static postVerifySignup(password, unm) {
    const body = generateFormOnObject({
      password,
      unm,
    })

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_gi_v1_signup_verify_and_register.php',
    })
  }

  static postRecipients(hiddenProps, msg_thread_id, message_recipient_ids) {
    const {mode_id, mode_name, category_id, tech_id} = getEntityParams()

    const {ffid, external_id, ...newValues} = hiddenProps
    const cid = getHiddenItems('cid')
    const body = generateFormOnObject({
      msg_thread_id,
      message_recipient_ids,
      external_id: msg_thread_id,
      ff_freeform_id: ffid,
      folder_id: 1, // General
      cid: cid,
      mode_id, // Assessments
      mode_name,
      category_id,
      tech_id: msg_thread_id,
      parent_id: 0,
      parent_type: 0,
      ...newValues,
    })

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_gi_v1_message_recipients_process.php',
    })
  }

  static getAccountInputValues() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_user_account_management_display.php',
    })
  }

  static getAccountProfile() {
    return ApiClient({
      method: 'get',
      url:
        getUserToken() == null
          ? '/react_gi_v2a_summary_guest_report.php'
          : '/react_gi_v1_user_info_display.php',
    })
  }

  static postAccountUpdates(inputsArr) {
    const contentObj = inputsArr
      .filter(({name}) => name !== 'confirm_password')
      .reduce((acc, {name, value}) => ({...acc, [name]: value}), {})

    const body = generateFormOnObject(contentObj)

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_gi_v1_user_account_management_process.php',
    })
  }

  static getCategoryKeyFactors(cid, techID) {
    const catID = cid.split('.')[1]

    const body = generateFormOnObject({
      cat_id: catID,
      ocid: cid,
      otech_id: techID,
    })

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_gi_v2a_key_factor_tabs_display.php',
    })
  }

  static postCommentaryInputs(cid, techID, assessor) {
    const body = generateFormOnObject({
      freeform_id: '2',
      tech_id: techID,
      cid,
      assessor,
      topic_id: 3315,
    })

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_gi_v1_edit_entity_commentary_display.php',
    })
  }

  static getDBName() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_db_query.php',
    })
  }

  static fetchAllUsers() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_member_list_display.php',
    })
  }

  static fetchAllCalendars() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_calendar_list_display.php',
    })
  }

  static fetchCalendarPerDay(date) {
    const {tech_id} = getEntityParams()
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_events_per_day.php?calendar_id=${tech_id}&date=${moment(
        date,
      ).format('YYYY-MM-DD')}`,
    })
  }

  static fetchManagements(master_entity_type, id) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_manage_master_entities_display.php?master_entity_type=${master_entity_type}${
        id != null ? `&external_id=${id}` : ''
      }`,
    })
  }

  static fetchAllContributors(cat_id, msg_thread_id) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_message_authorized_contributors_display.php?cat_id=${cat_id}&msg_thread_id=${msg_thread_id}`,
    })
  }

  static fetchAllMessageThreads() {
    return ApiClient({
      method: 'get',
      url: '/react_gi_v1_message_threads_display.php',
    })
  }

  static fetchMessageThread(threadId) {
    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_message_postings_display.php?msg_thread_id=${threadId}`,
    })
  }

  static postCommentarySubmit(
    ratingValue,
    narrativeValue,
    ratingTopicName,
    narrativeTopicName,
    cid,
    techID,
    topicID,
    assessorID,
  ) {
    const body = generateFormOnObject({
      freeform_id: '2',
      [narrativeTopicName]: narrativeValue,
      [ratingTopicName]: ratingValue,
      cid,
      external_id: techID,
      topic_id: topicID,
      assessor: assessorID,
    })

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_gi_v1_edit_commentary_process.php',
    })
  }

  static postShare(requests) {
    const body = generateFormOnObject(requests)

    return ApiClient({
      method: 'post',
      data: body,
      url: '/react_gi_v1_sharing_process.php',
    })
  }
}

export default MemberHomepageService
