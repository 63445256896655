import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({bottomGap}) => (bottomGap ? '25px' : '0')};
`;

const PortraitContainer = styled.div`
  width: ${({imgSize}) => imgSize};
  height: auto;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NameContainer = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const DescriptionContainer = styled.div`
  font-size: 12px;
`;

export {Base, NameContainer, DescriptionContainer, PortraitContainer};
