import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {IoMdArrowRoundBack} from 'react-icons/io';
import clsx from 'clsx';
import './styles.scss';
import {Close} from '@material-ui/icons';
import {AiFillHome} from 'react-icons/ai';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {getMCLSteps, setMCLSteps} from '../../../utils/HelperService';

export default function ManageCategorySteps(props) {
  const theme = useTheme();
  const secondaryColor = theme.palette.common.graymedium;
  const {visible = true, nameKey = 'title'} = props;

  const steps = getMCLSteps();

  const atHome = steps.length === 0;

  const history = useHistory();

  const handleClickStep = (step, index) => {
    if (step == null) {
      setMCLSteps([]);
      history.push('/home/manage-categories');
    } else {
      setMCLSteps(steps.slice(0, index + 1));
      history.push(step);
    }
  };

  const homeButton = () => {
    if (atHome) {
      return (
        <div className={'pl-2 pr-3'}>
          <AiFillHome color={secondaryColor} />
        </div>
      );
    }

    return (
      <>
        <div
          className={'pl-3 pr-1 cursor-pointer block md:hidden'}
          onClick={() => {
            const step = steps.length - 2;
            if (step >= 0) {
              handleClickStep(steps[step], step);
            } else {
              handleClickStep(null, -1);
            }
          }}>
          <IoMdArrowRoundBack
            className={'w-6 h-6 text-giga-black hover:text-black'}
          />
        </div>
        <div
          className={
            'pl-3 pr-2 cursor-pointer breadcrumbs-home text-giga-black hidden md:block'
          }
          onClick={() => handleClickStep(null, -1)}>
          <AiFillHome className={'breadcrumbs-icon'} color={'#4A4A4A'} />
        </div>
      </>
    );
  };

  return (
    <div
      className={clsx(
        'home-steps-container flex mt-2 mb-4 w-full flex-wrap',
        !visible && 'hidden',
      )}>
      {!atHome ? (
        <div
          className={
            'bg-giga-secondary-orange_300 flex flex-row h-7 items-center'
          }>
          {homeButton()}
          <div className={'home-steps-divider self-center'} />
        </div>
      ) : null}
      {steps.map((step, index) => {
        const isActive = index === steps.length - 1;
        return (
          <div
            key={index}
            className={`flex-row justify-center bg-giga-secondary-orange_300 h-7 pr-2 ${
              !isActive ? 'hidden md:flex' : 'flex rounded-r'
            }`}>
            <a
              className={clsx(
                'text-sm pl-3 pr-1 self-center',
                isActive
                  ? `font-normal text-giga-black`
                  : 'font-bold text-giga-black hover:text-black cursor-pointer transform transition duration-300 ease-out',
              )}
              onClick={() => (!isActive ? handleClickStep(step, index) : {})}>
              {step[nameKey]}
            </a>
            {!isActive && <div className={'home-steps-divider self-center'} />}
            {isActive && (
              <Close
                style={{fontSize: '1rem'}}
                className={'mr-1 ml-2 cursor-pointer self-center h-4'}
                color={'primary'}
                onClick={() => handleClickStep(steps[index - 1], index - 1)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
