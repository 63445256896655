import React from 'react';

import {ButtonContainer, Container, MainImg, Phrase, SkipText} from './styles';

import Button from 'Components/DesignSystem/button/button';

import Logo from 'Components/UI/Logo';

import useTitle from 'hooks/useTitle';

const Confirmation = ({history}) => {
  useTitle('Sign Up Confirmation', true);

  return (
    <Container>
      <Logo size="150" fixedTopLeft="true" specialSize={'150'} />
      <MainImg />
      <Phrase>
        Thanks for being a member of the Giga community. Would you like to learn
        how to use our platform?
      </Phrase>
      <ButtonContainer>
        <Button>Start Onboarding!</Button>
      </ButtonContainer>
      <SkipText onClick={() => history.push('/home')}>Skip Onboarding</SkipText>
    </Container>
  );
};

export default Confirmation;
