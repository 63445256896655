import React from 'react';

import {Base} from './styles';

const Profile = ({userName, perspective}) => (
  <Base>
    <span>{userName}</span>
    <span className={'w-1.5 h-1.5 rounded-full bg-giga-main mx-2'} />
    <span>{perspective}</span>
  </Base>
);

export default Profile;
