import {ApiClient} from './base';
import {getEntityParams, getHiddenItems} from '../utils/HelperService'
import Constants from '../Constants/Constants';

class ShareService {
  static getShareDisplay(external_id) {
    // const {mode_id, mode_name, category_id, tech_id, parent_id, parent_type} =
    //   getEntityParams();
    const mode_id = getHiddenItems('mode_id')
    const mode_name = getHiddenItems('mode_name')
    const category_id = getHiddenItems('category_id')
    const tech_id = getHiddenItems('external_id')
    const parent_id = getHiddenItems('parent_id')
    const parent_type = getHiddenItems('parent_type')
    let composite_id = getHiddenItems('cid');

    const isEventMode = mode_name === 'event';

    return ApiClient({
      method: 'get',
      url: `/${
        isEventMode
          ? 'react_gi_v1_sharing_calendar_display'
          : 'react_gi_v1_sharing_display'
      }.php?external_id=${
        tech_id
      }&composite_id=${composite_id}&mode_id=${mode_id}&category_id=${category_id}`,
    });
  }

  static getSubscriptionList(master_entity_type = 'Subscription') {
    // const {mode_id, mode_name, category_id, tech_id, parent_id, parent_type} =
    //   getEntityParams();

    const mode_id = getHiddenItems('mode_id')
    const mode_name = getHiddenItems('mode_name')
    const category_id = getHiddenItems('category_id')
    const tech_id = getHiddenItems('external_id')
    const composite_id = getHiddenItems('cid')
    const parent_type = getHiddenItems('parent_type')

    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_subscription_list.php?external_id=${tech_id}&composite_id=${composite_id}&mode_id=${mode_id}&category_id=${category_id}&master_entity_type=${master_entity_type}`,
    });
  }

  static getDistributionList(master_entity_type = 'Distribution') {
    // const {mode_id, mode_name, category_id, tech_id, parent_id, parent_type} =
    //   getEntityParams();

    const mode_id = getHiddenItems('mode_id')
    const mode_name = getHiddenItems('mode_name')
    const category_id = getHiddenItems('category_id')
    const tech_id = getHiddenItems('external_id')
    const composite_id = getHiddenItems('cid')
    const parent_type = getHiddenItems('parent_type')

    return ApiClient({
      method: 'get',
      url: `/react_gi_v1_distribution_list.php?external_id=${tech_id}&composite_id=${composite_id}&mode_id=${mode_id}&category_id=${category_id}&master_entity_type=${master_entity_type}`,
    });
  }
}

export default ShareService;
