import React from 'react';
import OnlyInputPhase from './OnlyInputPhase';

export const getComponentBasedOnFlow = (
  namePhase,
  advanceIndexPhase,
  phaseFlow,
) => {
  const {type} = phaseFlow;

  const setComponent = (Component, props) => {
    return (
      <Component
        advanceIndexPhase={advanceIndexPhase}
        namePhase={namePhase}
        {...props}
      />
    );
  };

  const {titleForm, input, submitText, buttons} = phaseFlow;

  switch (type) {
    case 'onlyInput': {
      const {
        inputLabel,
        setInputValue,
        placeholder,
        validation,
        inputValue,
        errorStatus,
      } = input;

      return setComponent(OnlyInputPhase, {
        titleForm,
        inputLabel,
        setInputValue,
        placeholder,
        validation,
        submitText,
        inputValue,
        errorStatus,
        buttons,
      });
    }

    case 'onlyCustomInput': {
      const {inputComponent, headerHeight} = phaseFlow;

      return setComponent(OnlyInputPhase, {
        titleForm,
        inputComponent,
        submitText,
        buttons,
        headerHeight,
      });
    }

    case 'custom': {
      const {Component, propsComponent} = phaseFlow;

      return (
        <Component advanceIndexPhase={advanceIndexPhase} {...propsComponent} />
      );
    }

    default:
      throw new Error(`getComponentBasedOnFlow type ${type} is invalid`);
  }
};
