import React, {useCallback, useEffect} from 'react';

import {Base} from './styles';

import AnalysisKeyFactorsHeader from './AnalysisKeyFactorsHeader';
import TopicSubmission from './TopicSubmission';
import TopicsHeader from './TopicsHeader';

import MemberHomepageService from 'Services/memberHomepage';

import useFetchContent from 'hooks/useFetchContent';

import {setFormattedSections, validateAndStoreAssessmentSummary} from './bl';
import {getUserMode} from '../../../../../utils/HelperService';

const Topics = ({
  topicsArr,
  setTopicsArr,
  indexTopic,
  setIndexTopic,
  cid,
  techId,
  loadingTopic,
  setLoadingTopic,
  historyPush,
  errorTopic,
  setErrorTopic,
  completeTopic,
  completeAssessment,
  currentTopic,
  completedTopics,
  errorAssessment,
  advanceIndexPhase,
  currentSection,
  setCurrentSection,
  sections,
  setSections,
}) => {
  const [checked, setChecked] = React.useState(false);

  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const [topicsInfo, loadingTopics, errorTopics, callTopic] = useFetchContent(
    {},
    MemberHomepageService.postAssessmentFlowForm,
    useCallback(({data}) => data, []),
  );

  const [sectionsInfo, loadingSections, errorSections, callSections] =
    useFetchContent(
      [],
      MemberHomepageService.getCategoryKeyFactors,
      useCallback(({data: {kf_tabs}}) => kf_tabs, []),
    );

  useEffect(() => {
    callSections(cid, techId);
  }, [callSections, cid, techId]);

  useEffect(() => {
    callTopic(cid, techId);
  }, [callTopic, cid, techId]);

  useEffect(() => {
    validateAndStoreAssessmentSummary(topicsInfo, setTopicsArr, historyPush);
  }, [historyPush, setTopicsArr, topicsInfo]);

  useEffect(() => {
    setFormattedSections(sectionsInfo, setSections);
  }, [sectionsInfo, setSections]);

  const onHandleCompleteTopic = contentObj => {
    const {mode_name: userMode} = getUserMode();
    completeTopic(
      contentObj,
      (!checked && width <= 425) || userMode !== 'assessment'
        ? 'required'
        : null,
    );
  };

  const {mode_name: mode} = getUserMode();
  const topicsHeaderVisible = mode === 'assessment';

  return (
    <Base
      loading={loadingTopics || loadingSections}
      error={errorTopics || errorSections}>
      {topicsHeaderVisible && (
        <AnalysisKeyFactorsHeader
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          sections={sections}
        />
      )}
      {topicsHeaderVisible && (
        <TopicsHeader
          selectedTopics={topicsArr}
          setIndexTopic={setIndexTopic}
          indexTopic={indexTopic}
          loadingTopic={loadingTopic}
          completedTopics={completedTopics}
          checked={checked}
          setChecked={setChecked}
        />
      )}
      <TopicSubmission
        currentSection={currentSection}
        errorTopicMessage={errorTopic}
        setErrorTopicMessage={setErrorTopic}
        currentTopic={currentTopic}
        techId={techId}
        setLoadingTopic={setLoadingTopic}
        completeTopic={onHandleCompleteTopic}
        // completeAssessment={completeAssessment.bind(null, advanceIndexPhase)}
        completeAssessment={completeAssessment}
        errorAssessment={errorAssessment}
        secondButtonHidden={!checked && width <= 5}
        mode={mode}
      />
      {/*{!topicsHeaderVisible && <PodcastPlayer />}*/}
    </Base>
  );
};

export default Topics;
