import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;

  @media ${device.desktopL} {
    width: 85%;
  }

  @media ${device.laptop} {
    width: 75%;
  }

  @media ${device.mobileM} {
    width: 85%;
  }

  @media ${device.mobileS} {
    width: 95%;
  }
`;

const TextHeader = styled.div`
  margin: 0 auto;
  width: 90%;
  margin-bottom: 30px;

  @media ${customDevice(630)} {
    width: 100%;
  }
`;

const Title = styled.div`
  color: #185c9d;
  font-size: 32px;
  font-weight: 600;
  margin: 0 10px;
  margin-bottom: 10px;

  @media ${customDevice(630)} {
    margin: 0;
    text-align: center;
  }

  @media ${device.mobileM} {
    font-size: 27px;
  }
`;

const HeaderDesc = styled.div`
  font-size: 20px;
  color: #7c7c7c;
  font-weight: 600;
  width: 50%;

  @media ${customDevice(950)} {
    width: 75%;
  }

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${customDevice(630)} {
    text-align: center;
  }

  @media ${device.mobileM} {
    font-size: 15px;
  }
`;

const ContentBoxText = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 500;
`;

export {Base, TextHeader, Title, HeaderDesc, ContentBoxText};
