import styled from 'styled-components';

const Base = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 15.625rem;
    height: auto;
    margin-bottom: 1.875rem;
  }
`;

const URLContainer = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
`;

export {Base, URLContainer};
