import React from 'react';

import ContentBox from '../../UI/LandingContentBox';
import LandingButton from '../../UI/LandingButton';

import GetStartedPortrait from 'assets/img/3d/complete-definition.png';

import {BlockTitle} from '../styles';
import HistoryService from '../../../../utils/HistoryService';

const GetStartedBlock = ({isDana, variant}) => (
  <ContentBox
    height={450}
    bgCol="linear-gradient(#2ca3ff 0%, #424dd1 100%)"
    srcImage={GetStartedPortrait}
    directionImg="right">
    <BlockTitle color="#fff">
      Giga is a system to analyze, and learn information in a new way.
    </BlockTitle>
    <LandingButton
      bgCol="rgba(0,0,0, 0.3)"
      onClick={() =>
        HistoryService.push({
          pathname: '/signup',
          state: {
            isDana,
            variant,
          },
        })
      }>
      Get Started
    </LandingButton>
  </ContentBox>
);

export default GetStartedBlock;
