import React, {memo} from 'react';

import RTE from 'Components/Create/RTE';

const TopicForm = ({hidden, setNewHTMLContent, htmlContent, specificTitle}) => (
  <div style={{display: hidden ? 'none' : 'block'}}>
    <RTE
      // placeholder={`Enter your ${specificTitle ? 'Comment' : 'Narrative'} here`}
      placeholder={`Enter your Narrative here`}
      setHTMLValue={setNewHTMLContent}
      htmlValue={htmlContent}
    />
  </div>
);

export default memo(TopicForm);
