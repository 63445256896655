import {combineReducers} from 'redux';

import signUpReducer from './SignUp/signup';
import initFormReducer from './SignUp/initForm';

import signInAuthFormReducer from './SignIn/authForm';

import UserReducer from '../sagas/UserSaga/reducers';

import userReducer from './user';

export default combineReducers({
  signup: signUpReducer,
  initForm: initFormReducer,
  signinAuthForm: signInAuthFormReducer,
  user: userReducer,
  UserData: UserReducer,
});
