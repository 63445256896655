import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;

export {Base};
