import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Portrait = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  width: 40%;
`;

const Text = styled.div`
  font-weight: 600;
  width: 40%;
  text-align: center;
`;

export {Base, Portrait, Text};
