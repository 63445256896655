import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.colors.main};
  width: 100%;
  margin-bottom: 20px;
`;

const Text = styled.div`
  margin: 0 auto;
  font-size: ${({size}) => `${size / 16}rem`};
  margin-bottom: 10px;
  text-align: center;
`;

const RatingContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
  border: 1px solid ${({theme}) => theme.colors.main};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
`;

export {Base, Text, RatingContainer};
