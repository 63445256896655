import React from 'react';

import {
  Base,
  CircleSeparator,
  DateText,
  NameContainer,
  ProfileContainer,
} from './styles';

const AssesmentProfile = ({userName, profilePic}) => (
  <Base>
    <ProfileContainer>
      <img src={profilePic} alt={`${userName} Profile Pic`} />
    </ProfileContainer>

    <NameContainer>{userName}</NameContainer>

    <CircleSeparator />

    <DateText>3h</DateText>
  </Base>
);

export default AssesmentProfile;
