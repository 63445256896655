import * as actions from './constants';
import Constants from '../../../Constants/Constants';
import {storeItem} from '../../../utils/HelperService';
import {UPDATE_HOME_SEARCH_FLAG} from "./constants";

const initialState = {
  displayModes: [],
  displayCategories: [],
  creationModes: [],
  creationCategories: [],
  messageModeSelected: false,
  channel: null,

  homeSteps: [],
  homeCurrentCategory: null,

  manageSteps: [],
  manageCurrentCategory: null,

  searchString: '',
  searchFlag: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
      case actions.UPDATE_HOME_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case actions.UPDATE_HOME_SEARCH_FLAG:
      return {
        ...state,
        searchFlag: action.searchFlag,
      };
    case actions.UPDATE_HOME_STEPS:
      return {
        ...state,
        homeSteps: action.homeSteps,
      };
    case actions.UPDATE_HOME_CURRENT_CATEGORY:
      return {
        ...state,
        homeCurrentCategory: action.homeCurrentCategory,
      };
    case actions.UPDATE_MANAGE_STEPS:
      return {
        ...state,
        manageSteps: action.manageSteps,
      };
    case actions.UPDATE_MANAGE_CURRENT_CATEGORY:
      return {
        ...state,
        manageCurrentCategory: action.manageCurrentCategory,
      };
    case actions.UPDATE_CHANNEL:
      return {
        ...state,
        channel: action.channel,
      };
    case actions.UPDATE_MESSAGE_MODE_SELECTED:
      return {
        ...state,
        messageModeSelected: action.messageModeSelected,
      };
    case actions.UPDATE_USER_DATA:
      return {
        ...state,
        displayModes: action.displayModes,
        displayCategories: action.displayCategories,
      };
    case actions.UPDATE_CATEGORY_DATA:
      return {
        ...state,
        displayCategories: action.displayCategories,
      };
    case actions.UPDATE_CREATION_MODES:
      storeItem(
        {
          creationModes: action.creationModes,
          creationCategories: action.creationCategories,
        },
        Constants.AS_CREATION_MODES,
      );
      return {
        ...state,
        creationModes: action.creationModes,
        creationCategories: action.creationCategories,
      };
    case actions.UPDATE_ERROR:
      return {...state, error: action.error, updateProcessing: false};
    default:
      return {...state};
  }
};
