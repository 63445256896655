import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

import ServerContent from 'hoc/ServerContent';

const Base = styled(ServerContent)`
  display: flex;
  flex-direction: column;
  width: 80%;

  @media ${device.laptopL} {
    width: 90%;
  }

  @media ${customDevice(1240)} {
    width: 80%;
  }

  @media ${device.mobileS} {
    width: 95%;
  }
`;

const PlayerContainer = styled.div``;

export {Base, PlayerContainer};
