import styled from 'styled-components';

import device from 'mediaQueries';

const Base = styled.div`
  flex-direction: row;
  align-items: center;
  color: #2ca3ff;

  //& > div:first-child {
  //  margin-bottom: 10px;
  //}

  //div {
  //  text-align: center;
  //}

  @media ${device.tablet} {
    //width: 100%;
    margin-bottom: 20px;
  }
`;

export {Base};
