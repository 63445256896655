import {ApiClient} from './base';
import {generateFormOnObject} from '../Helpers/api';

class SigninService {
  static getAuth(body) {
    body.append('upload', 'submit');

    return ApiClient({
      /*
        Correct input example:
        joeldomingueztapia@gmail.com
        ReactProgrammer1$
      */

      method: 'post',
      url: '/react_gi_v1_login.php',
      data: body,
    });
  }

  static postResetPassword(email, token) {
    const body = generateFormOnObject({
      to: email,
      token,
    });

    return ApiClient({
      method: 'post',
      url: '/react_gi_v1_password_reset.php',
      data: body,
    });
  }

  static postResetPasswordProcess(email, token, new_password) {
    const body = generateFormOnObject({
      email,
      token,
      new_password,
    });

    return ApiClient({
      method: 'post',
      url: '/react_gi_v1_password_reset_process.php',
      data: body,
    });
  }
}

export default SigninService;
