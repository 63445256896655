import styled from 'styled-components';

import ServerContent from 'hoc/ServerContent';
import {makeStyles} from '@material-ui/core';

const Base = styled(ServerContent)`
  width: 100%;
  //height: 50vh;
  min-height: 600px;
  margin-bottom: 20px;
`;

const PlainInput = styled.input`
  padding: 10px;
  border-radius: 10px;
  border: 3px solid ${({theme}) => theme.colors.main};
  margin-bottom: 20px;
  width: 100%;
  height: auto;
`;

export const useStyles = makeStyles(theme => ({
  dropzoneContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    marginTop: '0.5rem',
    position: 'relative',
  },
  categoryDropzoneContainer: {
    width: '50%',
    height: 0,
    paddingBottom: '30%',
    marginTop: '0.5rem',
    position: 'relative',
    minWidth: '300px',
    minHeight: '200px',
  },

  dropzone: {
    position: 'absolute !important',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100% !important',
    height: '100%',
    minHeight: '100% !important',
    borderRadius: '1.5rem !important',
    display: 'flex',
    fontSize: '1.2rem',
  },
  dropzoneText: {
    marginTop: '20% !important',
    fontSize: '0.8em',
    padding: '0 0.5rem',
    color: theme.palette.common.graymedium,
  },
  dropzonePreview: {
    width: '100%',
    height: '100%',
    margin: '0 !important',
    position: 'absolute',
  },
  dropzonePreviewItem: {
    width: '100%',
    height: '100%',
    maxWidth: '100% !important',
    flexBasis: '100% !important',
    padding: '0 !important',
  },

  root: {
    width: "100%",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    fontSize: "0.9rem",
    fontWeight: "semibold",
  },
  inputRoot: {
    width: "100%",
    height: "2.5rem",
  },
  menuList: {
    paddingLeft: "0.5rem",
    fontSize: "0.8rem",
  },
}));

export {Base, PlainInput};
