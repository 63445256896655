import {uploadFileReader} from 'Helpers/files/index';

const uploadFile = (fileToUpload, addNewAttachment, fileType, fileName) => {
  addNewAttachment(fileType, fileToUpload, fileName);
};

const uploadObjectURL = (
  fileToUpload,
  addNewAttachment,
  fileType,
  fileName,
) => {
  addNewAttachment(
    fileType,
    URL.createObjectURL(fileToUpload),
    fileName,
    fileToUpload,
  );
};

export const uploadAttachmentBasedOnFile = (
  fileToUpload,
  addNewAttachment,
  fileType,
  fileName,
) => {
  switch (fileType) {
    case 'file':
      uploadFile(fileToUpload, addNewAttachment, fileType, fileName);
      break;

    case 'audio':
      uploadFile(fileToUpload, addNewAttachment, fileType, fileName);
      break;

    case 'video':
      uploadObjectURL(fileToUpload, addNewAttachment, fileType, fileName);
      break;

    case 'image':
      uploadFileReader(fileToUpload, result =>
        addNewAttachment(fileType, result, fileName, fileToUpload),
      );
      break;

    default:
      return null;
  }
};
