import styled from 'styled-components';

const AlertContainer = styled.div`
  width: 100%;
  height: 70vh;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;

export {AlertContainer};
