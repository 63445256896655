import React, {useEffect, useRef} from 'react';

import {VideoPlayer} from './styles';

const VideoPreview = ({src, photoIndex, index}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    console.log('photoIndex', photoIndex);
    console.log('index', index);

    if (photoIndex !== index) {
      videoRef.current.pause();
    }
  }, [photoIndex, index]);

  return (
    <VideoPlayer ref={videoRef} src={src} controls>
      Your browser doesn&apos;t support the <code>video</code> element.
    </VideoPlayer>
  );
};

export default VideoPreview;
