import styled from 'styled-components';

import ServerContent from 'hoc/ServerContent';

import device from 'mediaQueries';

const Base = styled(ServerContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: 1400px;

  @media (min-width: 640px) {
    width: 96%;
  }

  margin-top: 1rem;
`;

export {Base};
