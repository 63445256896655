import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  letter-spacing: 1.68px;
  color: #ebecef;
  margin-bottom: 15px;
  font-weight: 300;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Result = styled.div`
  background-color: #2e358b;
  padding: 7px 25px;
  border-radius: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #3841a6;
  }
`;

export {Base, Label, ResultContainer, Result};
