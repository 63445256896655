// import React, {useCallback, useEffect} from 'react';
// import useFetchContent from '../../../hooks/useFetchContent';
// import MemberHomepageService from '../../../Services/memberHomepage';
// import {ReactComponent as NotificationSVG} from '../../../assets/svg/notification1.svg';
// import './styles.scss';
// import styled from 'styled-components';
// import ServerContent from '../../../hoc/ServerContent';
// import device, {customDevice} from '../../../mediaQueries';
// import * as userActions from '../../../Store/sagas/UserSaga/actions';
// import {withRouter} from 'react-router-dom';
// import {connect} from 'react-redux';
// import EntityService from '../../../utils/EntityService';
// import List from "@mui/material/List";
// import NotificationItem from "./NotificationItem";
// import HistoryService from "../../../utils/HistoryService";
//
// function NotificationList(props) {
//   const [
//     notificationsSummary,
//     loadingNotificationsSummary,
//     errorNotificationsSummary,
//     callNotificationsSummary,
//   ] = useFetchContent(
//       [],
//       MemberHomepageService.getNotificationsSummaryDisplay,
//       useCallback(({data}) => {
//         return data.categories;
//       }, []),
//   );
//
//   useEffect(() => {
//     callNotificationsSummary();
//   }, []);
//   const handleClick = notification => {
//     const {entityLink, entityAuthorID} = notification;
//     EntityService.handleClickEntity(
//         entityLink,
//         entityAuthorID,
//         props.actions,
//         props.displayCategories,
//     );
//   };
//
//   const goCategory = (category_id, mode_id, request_type) => {
//     HistoryService.push({
//       pathname: 'notifications/category',
//       state: {
//         notification_request_type: request_type,
//         category_id: category_id,
//         mode_id: mode_id
//       },
//     });
//   };
//
//   return (
//       <Base loading={loadingNotificationsSummary} error={errorNotificationsSummary}>
//         {notificationsSummary != null && (
//             <div className={'flex flex-col w-full items-center content-center'}>
//               <div
//                   className={
//                     'grid grid-cols-1 lg:grid-cols-1  items-center justify-center mb-8'
//                   }>
//                 <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
//                 {notificationsSummary.map((item, index) => {
//                   return (
//                         <NotificationItem category={item} key={index} goCategory={goCategory}/>
//                   );
//                 })}
//                 </List>
//               </div>
//             </div>
//         )}
//       </Base>
//   );
// }
//
// const mapStateToProps = state => ({
//   displayModes: state.UserData.displayModes,
//   displayCategories: state.UserData.displayCategories,
// });
//
// const mapDispatchToProps = dispatch => ({
//   actions: {
//     updateCreationModes: (modes, categories) => {
//       dispatch(userActions.updateCreationModes(modes, categories));
//     },
//   },
// });
//
// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(NotificationList),
// );
//
// const Base = styled(ServerContent)`
//   width: 75%;
//
//   @media ${customDevice(1280)} {
//     width: 85%;
//   }
//
//   @media ${customDevice(1024)} {
//     width: 75%;
//   }
//
//   @media ${device.tablet} {
//     width: 75%;
//   }
//
//   @media ${device.mobileM} {
//     width: 90%;
//   }
//
//   @media ${device.mobileS} {
//     width: 95%;
//   }
// `;


import React, {useCallback, useEffect} from 'react';
import useFetchContent from '../../../hooks/useFetchContent';
import MemberHomepageService from '../../../Services/memberHomepage';
import {ReactComponent as NotificationSVG} from '../../../assets/svg/notification1.svg';
import './styles.scss';
import styled from 'styled-components';
import ServerContent from '../../../hoc/ServerContent';
import device, {customDevice} from '../../../mediaQueries';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import EntityService from '../../../utils/EntityService';

function NotificationList(props) {
  const [
    notifications,
    loadingNotifications,
    errorNotifications,
    callNotifications,
  ] = useFetchContent(
      [],
      MemberHomepageService.getNotificationsDisplay,
      useCallback(({data}) => {
        let nots = [];
        if (data.categories != null) {
          for (const cat of data.categories) {
            nots = [...nots, ...cat.entities.map(e => ({...e, mode: cat.mode}))];
          }
        }
        return nots;
      }, []),
  );

  useEffect(() => {
    callNotifications();
  }, []);

  console.log('notifications', notifications);

  const handleClick = notification => {
    const {entityLink, entityAuthorID} = notification;
    EntityService.handleClickEntity(
        entityLink,
        entityAuthorID,
        props.actions,
        props.displayCategories,
    );
  };

  return (
      <Base loading={loadingNotifications} error={errorNotifications}>
        {notifications != null && (
            <div className={'flex flex-col w-full'}>
              <div
                  className={
                    'flex flex-row text-giga-gray items-center justify-center mb-6'
                  }>
                <NotificationSVG className={'w-6 h-6 notification-icon mr-1'} />
                <span className={'font-semibold text-sm'}>
              {notifications.length} new notifications
            </span>
              </div>
              <div
                  className={
                    'grid grid-cols-1 lg:grid-cols-2 w-full justify-center mb-8'
                  }>
                {notifications.map((item, index) => {
                  let color;
                  switch (item.mode) {
                    case 'assessment':
                      color = '#FFB74D';
                      break;
                    case 'posts':
                      color = '#E57373';
                      break;
                    case 'podcasts':
                      color = '#E1BEE7';
                      break;
                    case 'photos':
                      color = '#D1C4E9';
                      break;
                    case 'videos':
                      color = '#C5CAE9';
                      break;
                    case 'short_vids':
                      color = '#BBDEFB';
                      break;
                    case 'message':
                      color = '#B2EBF2';
                      break;
                    case 'music':
                      color = '#B2DFDB';
                      break;
                    case 'document':
                      color = '#C8E6C9';
                      break;
                    default:
                      color = 'white';
                      break;
                  }
                  return (
                      <div
                          key={`${index}`}
                          onClick={() => handleClick(item)}
                          className={
                            'rounded-2xl flex flex-row shadow-2xl px-4 py-5 mx-6 mt-3 cursor-pointer'
                          }
                          style={{backgroundColor: color}}>
                        <div>
                          <div
                              className={
                                'notification-list-bg rounded-2xl overflow-hidden'
                              }>
                            {item.entityImage ? (
                                <img
                                    src={item.entityImage}
                                    alt={'content'}
                                    className={'w-16 h-12 object-cover'}
                                />
                            ) : (
                                <NotificationSVG className="w-8 h-8 mx-4 my-2" />
                            )}
                          </div>
                        </div>
                        <div className={'flex flex-col flex-1 ml-3 space-y-2'}>
                          <div className={'flex flex-row items-center'}>
                            <span className={'bg-giga-orange rounded-full w-2 h-2'} />
                            <span className={'text-giga-gray font-bold text-sm ml-1'}>
                        11:00 AM
                      </span>
                            <div className={'ml-auto'}>
                        <span
                            className={
                              'notification-list-bg px-2 py-1 rounded-xl text-white text-sm font-semibold'
                            }>
                          {1}
                        </span>
                            </div>
                          </div>
                          <span className={'text-giga-gray text-sm'}>
                      {item.entityName ?? ''}
                    </span>
                        </div>
                      </div>
                  );
                })}
              </div>
            </div>
        )}
      </Base>
  );
}

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateCreationModes: (modes, categories) => {
      dispatch(userActions.updateCreationModes(modes, categories));
    },
  },
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NotificationList),
);

const Base = styled(ServerContent)`
  width: 70%;

  @media ${customDevice(1280)} {
    width: 85%;
  }

  @media ${customDevice(1024)} {
    width: 75%;
  }

  @media ${device.tablet} {
    width: 75%;
  }

  @media ${device.mobileM} {
    width: 90%;
  }

  @media ${device.mobileS} {
    width: 95%;
  }
`;

