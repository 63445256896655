import React, {useEffect, useState} from 'react';

import {Base, RatingContainer, Text} from './styles';

import RatingOptions from './RatingOptions';
import RatingDropDown from './RatingDropDown'

const RatingsBlock = ({
  ratingTitle,
  ratingOptions,
  selectedRating,
  setNewSelectedRating,
  isEditable = true,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(
    selectedRating ? ratingOptions.indexOf(selectedRating) : -1,
  );

  // useEffect(() => {
  //   if (selectedIndex !== -1) {
  //     setNewSelectedRating(ratingOptions[selectedIndex]);
  //   } else {
  //     setNewSelectedRating('');
  //   }
  // }, [selectedIndex]);

  return (
    <Base>
      <RatingContainer>
        <div>{ratingTitle ?? 'Topic Rating / Classification'}</div>
        {/*<RatingOptions*/}
        {/*  selectedIndex={selectedIndex}*/}
        {/*  setSelectedIndex={setSelectedIndex}*/}
        {/*  options={ratingOptions}*/}
        {/*  onlyView={!isEditable}*/}
        {/*/>*/}
        <RatingDropDown
          // selectedIndex={selectedIndex}
          // setSelectedIndex={setSelectedIndex}
          selectedRating={selectedRating}
          setNewSelectedRating={setNewSelectedRating}
          options={ratingOptions}
          onlyView={!isEditable}
        />
        {isEditable && <Text size={14}>Click to make a selection</Text>}
      </RatingContainer>
    </Base>
  );
};

export default RatingsBlock;
