import {all, put, takeLatest} from 'redux-saga/effects';

import authFormSaga from './SignIn/authForm';
import initFormSaga from './SignUp/initForm';
import {getItem, getUserToken} from '../../utils/HelperService';
import {changeUser} from '../actions';
import UserSaga from './UserSaga/sagas';
import Constants from '../../Constants/Constants';
import {updateCreationModes} from './UserSaga/actions';

function* fetchInfoSaga() {
  const userToken = getUserToken();
  console.log('userToken', userToken);
  if (userToken != null) {
    yield put(
      changeUser(
        userToken.isAuth,
        userToken.giga_user_id,
        userToken.giga_session_id,
      ),
    );
  }

  const creationModes = getItem(Constants.AS_CREATION_MODES);
  console.log('creation', creationModes);
  if (creationModes != null) {
    yield put(
      updateCreationModes(
        creationModes.creationModes,
        creationModes.creationCategories,
      ),
    );
  }
}

export default function* rootSaga() {
  yield all([
    authFormSaga(),
    initFormSaga(),
    UserSaga,
    takeLatest('StartUp/FetchInfo', fetchInfoSaga),
  ]);
}
