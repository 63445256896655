import React from 'react';
import {withRouter} from 'react-router-dom';

import SignButtons from './SignButtons';
import Logo from 'Components/UI/Logo';

import {HeaderBase, LogoContainer} from './styles';
import {connect} from 'react-redux';

const Header = ({history, isDana = false, variant, channel}) => {
  const color = channel?.mainColor ?? '#2CA3FF';
  const renderLogo = () => (
    <Logo
      size="30"
      text
      title={isDana ? 'Dana Barros Basketball' : 'giga.'}
      titleStyles={{color: color ?? '#2CA3FF'}}
      noNeedIcon={isDana}
      variant={variant}
    />
  );
  return (
    <HeaderBase>
      {isDana ? <LogoContainer>{renderLogo()}</LogoContainer> : renderLogo()}
      <SignButtons
        history={history}
        isDana={isDana}
        variant={variant}
        channel={channel}
      />
    </HeaderBase>
  );
};

const mapStateToProps = ({UserData: {channel}}) => ({
  channel,
});

export default connect(mapStateToProps, null)(withRouter(Header));
