import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';

import {Base} from './styles';
import {
  getAssessmentMode,
  useAssessmentTitle,
  useGetAssessment,
  useGetCommentary,
  useRedirectToSubject,
  useStoreLocalStorageAssessment,
} from './bl';

import AssesmentSummary from './AssesmentSummary';
import AssesmentContent from './AssesmentContent';
import GoBackSubject from './GoBackSubject';
import Commentary from './Commentary';
import {getUserMode} from '../../../utils/HelperService';

const AssesmentView = ({history}) => {
  const [prevCurrentSectionId, setPrevCurrentSectionId] = useState(null);
  const [reloading, setReloading] = useState(false);
  const assessmentMode = getAssessmentMode();
  const {assessmentResult, loadingAssessment, errorAssessment} =
    useGetAssessment(history);
  console.log('assessmentResult12', assessmentResult);
  console.log('ronaldo', history);
  const {commentary, loadingCommentary, errorCommentary} = useGetCommentary(
    reloading,
    setReloading,
  );
  console.log('commentary', commentary);

  // const [locationKeys, setLocationKeys] = useState([])

  // useEffect(() => {
  //   return history.listen(location => {
  //     if (history.action === 'PUSH') {
  //       setLocationKeys([location.key])
  //     }
  //
  //     if (history.action === 'POP') {
  //       if (locationKeys[1] === location.key) {
  //         setLocationKeys(([_, ...keys]) => keys)
  //
  //         console.log('Handle forward event')
  //       } else {
  //         setLocationKeys(keys => [location.key, ...keys])
  //
  //         console.log('Handle back event')
  //       }
  //     }
  //   })
  // }, [locationKeys])

  const {mode_name: mode} = getUserMode();

  const isBackNeeded = mode === 'assessment';

  useAssessmentTitle(assessmentResult);
  useStoreLocalStorageAssessment(assessmentResult);

  const {assessment, assessorProfile, subject_info} = assessmentResult;

  const goBackToSubject = useRedirectToSubject(history.push);

  const reload = currentSectionID => {
    setPrevCurrentSectionId(currentSectionID);
    setReloading(true);
  };

  return (
    <Base
      loading={loadingAssessment || loadingCommentary}
      error={errorAssessment || errorCommentary}>
      {isBackNeeded && <GoBackSubject handleClick={goBackToSubject} />}
      <AssesmentSummary
        assesmentName={subject_info?.subject_name}
        assesmentImage={subject_info?.subject_image}
        assessmentDescription={subject_info?.subject_description}
      />
      <AssesmentContent
        assesorName={assessorProfile?.assessor_name}
        assesorProfilePic={assessorProfile?.assessor_image}
        assesmentTopics={assessment}
        mode={assessmentMode}
      />
      {isBackNeeded && <GoBackSubject handleClick={goBackToSubject} />}
      <Commentary
        commentaryTopics={commentary.commentary_elements}
        prevComments={commentary.previous_comments}
        onSaved={currentSectionID => reload(currentSectionID)}
        prevCurrentSectionId={prevCurrentSectionId}
      />
    </Base>
  );
};

export default withRouter(AssesmentView);
