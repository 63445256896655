import React from 'react';

import PageContainer from 'hoc/PageContainer';

import useTitle from 'hooks/useTitle';

import Logo from 'Components/UI/Logo';

import {Container, SubTitle, Title} from './styles';

const Page404 = () => {
  useTitle('Page Not Found', true);

  return (
    <PageContainer>
      <Container>
        <Logo size="150" fixedTopLeft="true" specialSize={'150'} />

        <Title>404</Title>

        <SubTitle>Oops! This page can not be found at the moment.</SubTitle>
      </Container>
    </PageContainer>
  );
};

export default Page404;
