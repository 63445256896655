import React from 'react';
import xss from 'xss';

import {Base} from './styles';
import config from './config';

const ShowHTMLString = ({htmlString}) => {
  return (
    <Base
      dangerouslySetInnerHTML={{__html: xss(htmlString, config)}}
      style={{textAlign: 'left'}}
    />
  );
};

export default ShowHTMLString;
