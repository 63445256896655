// Signup

/* SIGNUP */

export const CHANGE_SIGNUP_PHASE = 'CHANGE_SIGNUP_PHASE';

/* INITIAL FORM */

export const SIGNUP_INIT_SAGA = 'SIGNUP_INIT_SAGA';
export const SIGNUP_INIT_START = 'SIGNUP_INIT_START';
export const SIGNUP_INIT_SUCCESS = 'SIGNUP_INIT_SUCCESS';
export const SIGNUP_INIT_FAIL = 'SIGNUP_INIT_FAIL'; // Fails on user input
export const SIGNUP_INIT_FAIL_REQ = 'SIGNUP_INIT_FAIL_REQ'; // Fails on request

// Signin

/* AUTH FORM */

export const SIGNIN_AUTH_SAGA = 'SIGNIN_AUTH_SAGA';
export const SIGNIN_AUTH_START = 'SIGNIN_AUTH_START';
export const SIGNIN_AUTH_SUCCESS = 'SIGNIN_AUTH_SUCCESS';
export const SIGNIN_AUTH_FAIL = 'SIGNIN_AUTH_FAIL'; // Fails on user input
export const SIGNIN_AUTH_FAIL_REQ = 'SIGNIN_AUTH_FAIL_REQ'; // Fails on request

// User

export const CHANGE_USER = 'CHANGE_USER';
export const RESET_USER = 'RESET_USER';
