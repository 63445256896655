import React, {memo, useCallback} from 'react';
import {Base} from './styles';
import AttachInput from '../AttachInput';
import {isValidHttpURL} from 'Helpers/api';

const AttachHyperlink = ({
  hidden,
  errorLink,
  setLink,
  link,
  setErrorLink,
  addNewAttachment,
}) => {
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      setErrorLink('');

      if (link.trim().length > 0 && isValidHttpURL(link)) {
        addNewAttachment('link', link, link);
        setLink('');
      } else {
        setErrorLink('The URL is not valid');
      }
    },
    [link, setErrorLink, addNewAttachment],
  );

  const handleAttachChange = useCallback(
    ({target: {value}}) => setLink(value),
    [setLink],
  );

  return (
    <Base hidden={hidden}>
      <AttachInput
        value={link}
        type="text"
        forId="Attach Hyperlink"
        onChange={handleAttachChange}
        errorMessage={errorLink}
        handleSubmit={handleSubmit}>
        Insert the URL that you want to attach:
      </AttachInput>
    </Base>
  );
};

export default memo(AttachHyperlink);
