import React, {forwardRef} from 'react';

import Button from '@material-ui/core/Button';

const ButtonComp = ({children, fontSize, style = {}, ...props}, ref) => (
  <Button
    style={{
      color: '#2CA3FF',
      textTransform: 'capitalize',
      fontFamily: `'Poppins', sans-serif`,
      fontWeight: '600',
      fontSize,
      ...style,
    }}
    ref={ref}
    {...props}>
    {children}
  </Button>
);

export default forwardRef(ButtonComp);
