export default {
  GIGA_GUEST: 'Giga Guest',
  GIGA_INTELLIGENCE: 'Giga Intelligence',
  DANA_BARROS_BASKETBALL: 'Dana Barros Basketball',
  DANA_BARROS_BASKETBALL_PODCASTS: 'Dana Barros Podcasts',

  //Common
  GIGA_RAISINGYOURINNERVOICE: 'GigaRaisingYourInnerVoice',
  GIGA_MEDIA: 'GigaMedia',
  GIGA_SPORTS: 'GigaSports',
  GIGA_ATHLETICS: 'GigaAthletics',
  GIGA_WEATHER: 'GigaWeather',

  AS_USER_TOKEN: 'AS_USER_TOKEN',
  AS_USER_MODE: 'AS_USER_MODE',
  AS_USER_CATEGORY: 'AS_USER_CATEGORY',
  AS_ENTITY_PARAMS: 'AS_ENTITY_PARAMS',

  AS_MESSAGE_ROOM_TOPIC: 'AS_MESSAGE_ROOM_TOPIC',
  AS_CREATION_MODES: 'AS_CREATION_MODES',

  AS_CURRENT_SECTION: 'AS_CURRENT_SECTION',
  AS_MESSAGE_SELECTED: 'AS_MESSAGE_SELECTED',

  AS_CHANNEL_INFO: 'AS_CHANNEL_INFO',
  AS_CHANNEL_ID: 'AS_CHANNEL_ID',

  AS_NEW_CREATION_CONTENT_ID: 'AS_NEW_CREATION_CONTENT_ID',
  AS_POSTED_STATUS: 'AS_POSTED_STATUS',
  AS_PREVIOUS_LINK: 'AS_PREVIOUS_LINK',
  AS_CURRENT_HIDDEN: 'AS_CURRENT_HIDDEN',

  SS_MCL_STEPS: 'SS_MCL_STEPS',

  SQ_HEADER_SEARCH: 'search',
  SQ_HEADER_MODES: 'mode_selector',
  SQ_ACTIVE: 'active_mode',
  SQ_FOOTER_LINKS: 'footer_links',
  // PAYPAL_CLIENT_ID: 'Acny3V5Luc7bX4REsXbPW9F9YvEBTrYPPPZ7Ly6PM-whRpr2D-orBpFlI5WXmkYeLB_-3h3HtB37zGQK'   // sandbox mode
  PAYPAL_CLIENT_ID: 'AWixuAqdBm3L_oExir-y_g4RW46LjDgbT_QdIjTpFNcjk_IjnmKFVOalclbD39GAo0M5s300nFIL5icl'     //  live mode
};

export const displayModesNames = mode => {
  if (mode === 'short_vids') {
    return 'Short Vids';
  }
  return mode.charAt(0).toUpperCase() + mode.slice(1);
};
