import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import useFetchContent from '../../../../hooks/useFetchContent'
import MemberHomepageService from '../../../../Services/memberHomepage'
import {useCallback, useEffect, useState} from 'react'
import {searchAnalyticIndex, searchIndex} from '../../bl'
import { Typography} from '@mui/material'

function ComposerDialog(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  const [options, setOptions] = useState({Ai : [], Analytics : []});
  const [aiDisables, setAiDisables] = useState([]);
  const [analyticDisables, setAnalyticDisables] = useState([]);

  const [enginesInfo, loadingEngines, errorEngines, callEngines] = useFetchContent(
    {},
    MemberHomepageService.getListOfAiEngines,
    useCallback(({data}) => data, []),
  );

  const [analyticsInfo, loadingAnalytics, errorAnalytics, callAnalytics] = useFetchContent(
    {},
    MemberHomepageService.getListOfAnalytics,
    useCallback(({data}) => data, []),
  );

  useEffect(() => {
    callEngines();
  }, [callEngines]);

  useEffect(()=> {
    callAnalytics()
  }, [])

  useEffect(() => {
    if (enginesInfo.ai_engines) {
      setOptions((prevState) => ({
        Ai: enginesInfo.ai_engines.map(
          (e) => e.ai_engine_company + ' ' + e.ai_engine_name + ' ' + e.ai_engine_version
        ),
        Analytics: prevState.Analytics // Preserving the previous Analytics value
      }));

      setAiDisables((prevState) =>
        enginesInfo.ai_engines.map((e) => e.selectable === 'No')
      );
    }
  }, [enginesInfo]);

  useEffect(() => {
    if (analyticsInfo.analytics) {
      setOptions((prevState) => ({
        Ai: prevState.Ai,
        Analytics: analyticsInfo.analytics.map(
          (e) => e.analytics_name
        ),
      }));

      setAnalyticDisables((prevState) =>
        analyticsInfo.analytics.map((e) => e.selectable === 'No')
      );
    }
  }, [analyticsInfo]);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    const parts = value.split(/\s+/); // ["OpenAI", "ChatGPT", "3.5"]
    let ai_engine_index = []
    if (options.Ai.includes(value)) {
      ai_engine_index = searchIndex(enginesInfo?.ai_engines, parts[1], parts[0], parts[2])  // name, company, version   return array
      if (ai_engine_index.length === 0) {
        onClose(value, null);
      } else {
        onClose(value, ai_engine_index[0].ai_engine_id, ai_engine_index[0].ai_engine_count);
      }
    }
    if (options.Analytics.includes(value)) {
      const analytic_index = options.Analytics.indexOf(value)
      const analytic_url = analyticsInfo?.analytics[analytic_index].analytics_source_url
      window.open(analytic_url, '_blank');
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (

    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select one resource</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          <Typography variant="h6" mt={1}>AI</Typography>
          {options.Ai.map((option, index) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
              disabled={aiDisables[index]}
            />
          ))}
          <Typography variant="h6" mt={1}>Analytics</Typography>

          {options.Analytics.map((option, index) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
              disabled={analyticDisables[index]}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={value === ''} onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ComposerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default ComposerDialog;
