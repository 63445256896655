import {lazy} from 'react';
import styled from 'styled-components';

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

const ErrorDisplay = styled.span`
  position: relative;
  display: block;
  font-size: 14px;
  color: white;
  font-weight: bold;
`;

const ReCAPTCHAContainer = styled(ReCAPTCHA)`
  margin: 20px 0;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
`;

export {ErrorDisplay, ReCAPTCHAContainer, Center};
