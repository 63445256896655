import {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import useFetchContent from 'hooks/useFetchContent';
import useTitle from 'hooks/useTitle';

import MemberHomepageService from 'Services/memberHomepage';

import {setNewViewedCategory, setNewViewedItem} from 'Helpers/localStorage';
import {convertQueryParamsToObject} from 'Helpers/api';
import {removeItem, storeItem} from '../../../utils/HelperService';
import Constants from '../../../Constants/Constants';

export const generateAssessmentLink =
  (catEntity, cidEntity, techIdEntity, push) =>
  (assesmentLink, assessorID, displayMode) => {
    const {cat, cid, tech_id} = convertQueryParamsToObject(assesmentLink);

    push(
      `/home/entity/${catEntity}/${cidEntity}/${techIdEntity}/assesment/${cat}/${cid}/${tech_id}/${assessorID}?mode=${displayMode}`,
    );
  };

export const useGetEntityIdentifiers = () => {
  const {cat, cid, techId} = useParams();

  return {cat, cid, techId};
};

export const useGetEntities = () => {
  const [entity, loadingEntity, errorEntity, setNewEntity] = useFetchContent(
    {},
    MemberHomepageService.getAssesmentsOfEntity,
    useCallback(({data}) => data, []),
  );

  const {cat, cid, techId} = useGetEntityIdentifiers();

  useEffect(
    () => setNewEntity(cat, cid, techId),
    [cat, cid, techId, setNewEntity],
  );

  useEffect(() => {
    const entityName = entity.assessment?.assessment_name;
    const categoryName = entity.category;

    if (entityName) {
      setNewViewedItem('entity', entityName, cid, cat, techId);
    }

    if (categoryName) {
      setNewViewedCategory(categoryName);
    }
  }, [entity, cat, cid, techId, loadingEntity]);

  return {entity, loadingEntity, errorEntity};
};

export const useRedirectOnInvalidEntity = (entity, loadingEntity, push) => {
  useEffect(() => {
    if (!entity.category && !loadingEntity) {
      push('/404');
    }
  }, [entity, loadingEntity, push]);
};

export const useRedirectToPerformAssessment = push => {
  const {cat, cid, techId} = useGetEntityIdentifiers();
  return useCallback(
    currentSection => {
      if (currentSection == null) {
        removeItem(Constants.AS_CURRENT_SECTION);
      } else {
        storeItem(currentSection, Constants.AS_CURRENT_SECTION);
      }

      localStorage.setItem(Constants.AS_POSTED_STATUS, 'no');
      localStorage.setItem(
        Constants.AS_PREVIOUS_LINK,
        window.location.pathname,
      );
      push(`/home/create/analysis?tech_id=${techId}&cid=${cid}&cat=${cat}`);
    },
    [cid, push, techId],
  );
};

export const useEntityTitle = entity => {
  useTitle(`${entity.assessment?.assessment_name} Assesments`, true, [entity]);
};
