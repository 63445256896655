import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: ${({direction}) =>
    direction === 'right' ? 'row' : 'row-reverse'};
  align-items: center;
  height: ${({height}) => `${height / 16}rem`};
  width: 100%;
  background: ${({bgCol}) => bgCol};
  padding: 0 50px;
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.16));

  ${({css}) => css} @media ${({breakpoint}) => customDevice(breakpoint)} {
    flex-direction: ${({direction}) =>
      direction === 'right' ? 'column' : 'column-reverse'};
    height: initial;
    padding: 50px 0;
  }
`;

const ContentContainer = styled.div`
  width: 55%;
  ${({direction}) =>
    direction === 'right' ? 'margin-right: 50px;' : 'margin-left: 50px;'}

  ${({css}) => css}

  @media ${({breakpoint}) => customDevice(breakpoint)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
`;

const BoxImg = styled.img`
  width: 45%;
  height: auto;

  ${({css}) => css}

  margin-top: 100px;

  @media ${({breakpoint}) => customDevice(breakpoint)} {
    ${({direction}) =>
      direction === 'right' ? 'margin-top: 50px;' : 'margin-bottom: 50px;'}
  }

  @media ${device.tablet} {
    width: 80%;
  }
`;

export {Base, BoxImg, ContentContainer};
