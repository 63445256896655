import {
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import {useEffect} from 'react'
import MemberHomepageService from "../../../../Services/memberHomepage";
import PaymentService from "../../../../Services/payment";

const PaypalButtonWrapper = ({ type, plan_id,  handleDoneSub }) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "subscription",
      },
    });
  }, [type]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return (<PayPalButtons
    createSubscription={(data, actions) => {
      return actions.subscription
          .create({
          plan_id: plan_id
        })
        .then((orderId) => {
          // Your code here after create the order
          return orderId;
        });
    }}
    onApprove={(data)=>{
      // sending subscriptionID to backend to fetch detailed subscription info and storing, getting result
      PaymentService.getSubscriptionInfo(data.subscriptionID).then(res => {
        if (res.data) {
          handleDoneSub(data.subscriptionID)
        } else {
          handleDoneSub(data.subscriptionID)
        }
      });
    }}
    style={{
      label: "subscribe",
    }}
  />);
}

export default PaypalButtonWrapper ;
