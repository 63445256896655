import React, {useEffect, useState} from 'react';
import BaseContainer from 'hoc/BaseFormContainer';

import HeaderForm from 'Components/UI/HeaderForm';
import Form from './Form';
import {validateEmail} from 'Helpers/validators';

import useTitle from 'hooks/useTitle';
import InitFormService from '../../../Services/initForm';
import SigninService from '../../../Services/signin';
import GigaAlert from '../../UI/GigaAlert';
import HistoryService from '../../../utils/HistoryService';

const AuthForm = ({email, setEmail, isDana, variant}) => {
  useTitle('Reset Password', true);

  const [token, setToken] = useState('');

  const [alertInfo, setAlertInfo] = React.useState({open: false});

  useEffect(() => {
    InitFormService.getChannelToken(variant)
      .then(res => {
        console.log('getChannelToken', res);
        setToken(res.data.channel_token);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const statusEmail = validateEmail(email);

  const onSubmit = e => {
    e.preventDefault();

    SigninService.postResetPassword(email, token).then(res => {
      console.log(res);
      if (res.data?.sign_up_status === 'ok') {
        HistoryService.push('/reset-password-confirm');
      } else {
        setAlertInfo({
          open: true,
          message: 'Please try again later',
          severity: 'warning',
        });
      }
    });
  };

  if (token === '') {
    return null;
  }

  return (
    <BaseContainer>
      <HeaderForm title="Enter your email" isDana={isDana} />
      <Form
        onSubmit={onSubmit}
        setEmail={setEmail}
        statusEmail={statusEmail}
        isDana={isDana}
        variant={variant}
      />
      <GigaAlert
        alertInfo={alertInfo}
        handleClose={() => setAlertInfo({open: false})}
      />
    </BaseContainer>
  );
};

export default AuthForm;
