import React from 'react';
import './styles.scss';
import clsx from 'clsx';
import {CgSearch} from 'react-icons/cg';

export default function SearchBy(props) {
  const {className, searchInputProps, placeholder} = props;
  const [focused, setFocused] = React.useState(false);
  return (
    <div
      className={clsx(
        'flex items-center px-2 giga-search-by-container',
        focused && 'giga-search-by-container-focus',
        className,
      )}>
      <CgSearch className="ml-3 w-5 h-5" />
      <input
        id="search"
        type="search"
        className="flex-1 py-2 px-2 text-base focus:outline-hidden border-0 focus:shadow-transparent focus:ring-transparent focus:ring-offset-transparent"
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{borderRadius: 'inherit', outline: 'none'}}
        {...searchInputProps}
      />
    </div>
  );
}
