import styled from 'styled-components';

import device, {customDevice} from 'mediaQueries';

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: auto;

  @media ${device.laptop} {
    margin-left: 0;
    margin-top: 15px;
  }

  // @media ${customDevice(450)} {
  //   flex-direction: column;
  // }
`;

export {ButtonsContainer};
