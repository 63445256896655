import React, {memo} from 'react';

import SubjectProfile from 'Components/UI/SubjectProfile';

import Attachments from 'Components/Create/Attachments';

const TopicExample = ({
  CardItem,
  topics,
  subjectInfo: {assessment_image},
  assessment_description,
  assessment_name,
  topicContent,
  newImage,
}) => {
  const topicsElements = topics.map(({id, assessment_topic}) => {
    const currentTopicContent = topicContent.find(
      topic => topic.assessment_topic === assessment_topic,
    );

    return (
      <CardItem
        key={id}
        explanation={`${assessment_topic} ${
          currentTopicContent?.selectedRating
            ? '- ' + currentTopicContent?.selectedRating
            : ''
        }`}
        htmlContent={currentTopicContent?.htmlContent}>
        <Attachments
          attachments={currentTopicContent?.assetsBlockData}
          hideRemove
        />
      </CardItem>
    );
  });

  return (
    <div>
      <SubjectProfile
        name={assessment_name}
        image={assessment_image}
        newImage={newImage}
        description={assessment_description}
        imgSize="250px"
        bottomGap
      />
      {topicsElements}
    </div>
  );
};

export default memo(TopicExample);
