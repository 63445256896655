import React from 'react';
import styled from 'styled-components';
import LandingButton from '../Components/Homepage/UI/LandingButton';
import chaticon from './Photos/chaticon.svg';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #2ca3ff;
  padding: 16vh 4vw;
  justify-content: center;
`;
const FeedbackHeader = styled.h1`
  color: white;
`;
const FeedbackText = styled.h2`
  align-self: center;
  text-align: center;
  color: white;
`;

const FeedbackInput = styled.textarea`
  justify-self: center;
  border-radius: 20px;
  border: none;
  padding: 16px 10px;
  height: 16vh;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const ButtonPositioning = styled.div`
  align-items: center;
  justify-self: center;
  align-self: center;
`;
const ChatIcon = styled.img`
  display: grid;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  justify-self: center;
`;
export default function MessageUs() {
  return (
    <MessageContainer>
      <div className={'flex flex-row space-x-4 justify-center'}>
        <ChatIcon src={chaticon} />
        <FeedbackHeader>Get in touch with us</FeedbackHeader>
      </div>

      <FeedbackText>
        Please leave us any feedback on Giga, or just drop us a friendly
        message. We appreciate it.
      </FeedbackText>

      <FeedbackInput />

      <ButtonPositioning>
        <LandingButton Backgroundcolor="rgba(0,0,0, 0.8)">
          Send Message
        </LandingButton>
      </ButtonPositioning>
    </MessageContainer>
  );
}
