import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

import device, {customDevice} from 'mediaQueries';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 30px;
  background-color: #f2f2f2;
  border-radius: 4px;

  width: 94%;
  max-width: 1280px;

  @media (min-width: 640px) {
    width: 96%;
  }

  @media (min-width: 1024px) {
    width: 90%;
  }

  // @media ${device.mobile} {
  //   margin-top: 20px;
  //   width: 94%;
  //   padding: 0.5rem;
  // }

  .activeSection {
    opacity: 1;
  }
`;

const HeaderSection = styled.div`
  align-items: center;
  width: 45%;
  //background-color: ${({mode}) =>
    mode === 'regular' ? '#2CA3FF' : '#2E358B'};
  border-radius: 30px;

  display: flex;
  justify-content: space-between;
  padding: 10px 4%;

  @media ${device.laptop} {
    padding: 10px 5%;
    width: 80%;
  }

  @media ${device.tablet} {
    width: 70%;
    padding: 10px 4%;
  }

  @media ${customDevice(620)} {
    width: 80%;
    padding: 10px 8%;
  }

  @media ${device.mobileL} {
    width: 100%;
  }

  &:first-child {
    @media ${customDevice(1024)} {
      margin-bottom: 10px;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  color: ${({mode}) => (mode === 'regular' ? '#fff' : 'black')};
  background-color: ${({mode}) =>
    mode === 'regular' ? '#1236ff' : 'lightgray'};
  padding: 0.3rem 0.7rem;
  border-radius: 1000px;
  cursor: pointer;
  opacity: 0.6;
  font-size: 12px;
  letter-spacing: 1.3px;
  font-weight: 600;
  margin: 0.5rem 0.5rem;

  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));

  transition-duration: 200ms;

  &:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
`;

export {Base, HeaderSection, StyledNavLink};
