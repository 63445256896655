import React, {useCallback, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LandingButton from '../Components/Homepage/UI/LandingButton';
import moonlight from './Photos/Moonlight.svg';
import useFetchContent from '../hooks/useFetchContent';
import CommonService from '../Services/CommonService';
import ServerContent from '../hoc/ServerContent';

const AboutText = styled.h6`
  color: white;
  font-size: 0.9rem;
  line-height: 3vh;
  text-align: left;
  white-space: pre-line;
`;

export default function About() {
  const [aboutData, loadingAboutData, errorAboutData, callLoadAboutData] =
    useFetchContent(
      {},
      CommonService.getAboutData,
      useCallback(({data}) => data, []),
    );

  useEffect(() => {
    callLoadAboutData();
  }, []);

  return (
    <ServerContent
      loading={loadingAboutData}
      error={errorAboutData}
      className={
        'flex flex-col w-screen h-auto sm:h-screen bg-giga-primary-blue_500 items-center'
      }>
      <div className={'flex flex-col max-w-3xl p-8'}>
        <span
          className={
            'font-semibold text-3xl text-white self-center mb-8 mt-12'
          }>
          About Us
        </span>
        <img src={moonlight} alt="logo" className={'px-16 sm:px-36 pb-4'} />
        <AboutText>
          {aboutData.section_narrative
            ? aboutData.section_narrative[0].section_html_narrative.replaceAll(
                '\n<br>',
                '',
              )
            : ''}
        </AboutText>
        <div
          className={
            'flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8 items-center justify-center mt-12'
          }>
          <div>
            <Link style={{textDecoration: 'none', color: 'white'}} to="/home">
              <LandingButton Backgroundcolor="rgba(0,0,0, 0.5)">
                <h3 style={{padding: '0', margin: '0'}}>Go Home</h3>
              </LandingButton>
            </Link>
          </div>

          <div>
            <LandingButton Backgroundcolor="rgba(0,0,0, 0.8)">
              <Link
                style={{textDecoration: 'none', color: 'white'}}
                to="/messageus">
                <h3 style={{padding: '0', margin: '0'}}>Message Us</h3>
              </Link>
            </LandingButton>
          </div>
        </div>
      </div>
    </ServerContent>
  );
}
