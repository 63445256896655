import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {ThemeProvider} from '@material-ui/core/styles';

import {CheckboxContainer} from './styles';

// Override Material-UI themes
import theme from './theme';

const CheckPrivacy = ({checked, change}) => (
  <ThemeProvider theme={theme}>
    <CheckboxContainer
      control={
        <Checkbox
          checked={checked}
          onChange={({target: {checked}}) => change(checked)}
          icon={<CircleUnchecked style={{fill: '#fff'}} />}
          checkedIcon={<CircleCheckedFilled style={{fill: '#fff'}} />}
        />
      }
      label="By checking this box I agree to Giga's Privacy Terms"
    />
  </ThemeProvider>
);

export default CheckPrivacy;
