import React from 'react';

import {Base, SubtitleText, TextContainer, TitleText} from './styles';

import {ReactComponent as TextLogo} from 'assets/general/giga-logo-01.svg';
import NoTextLogo from 'assets/general/giga-logo-noText.png';
import NoTextLogoMedia from 'assets/general/giga-logo-noText-media.png';
import JFLogo from 'assets/general/jf_logo.png';
import Constants from '../../../Constants/Constants';
import {connect} from 'react-redux';

const Logo = ({
  to,
  center,
  size,
  fixedTopLeft,
  text,
  title,
  subtitle,
  subTitleColor,
  titleStyles = {},
  reversed = false,
  noNeedIcon = false,
  isBlue = false,
  variant = Constants.GIGA_INTELLIGENCE,
  channel,
  specialSize,
  poweredByNeeded = false,
}) => {
  console.log('channel', channel);

  const isCommon =
    channel?.name != null ||
    (variant !== Constants.GIGA_INTELLIGENCE &&
      variant !== Constants.GIGA_GUEST);

  const gVariant = channel?.name ?? variant;
  const isPrivateChannel = !gVariant.includes('Giga') && !poweredByNeeded;

  const color = channel?.mainColor ?? '#2CA3FF';

  return (
    <Base
      to={to ? to : '/home'}
      center={center}
      fixedtopleft={fixedTopLeft}
      withtext={text ? 'true' : 'false'}
      size={specialSize ?? (isCommon ? 40 : size)}
      reversed={reversed}>
      {isPrivateChannel ? (
        <img
          src={channel.private_channel_logo_image_url}
          className={'w-12 cursor-pointer'}
          alt="Logo"
        />
      ) : (
        <>
          {text ? (
            !noNeedIcon && (
              // <img
              //   src={
              //     isBlue
              //       ? NoTextLogoMedia
              //       : isCommon
              //       ? NoTextLogoMedia
              //       : NoTextLogo
              //   }
              //   className={`LogoImg mt-2 ${isBlue ? 'mr-2 mb-1' : ''}`}
              //   alt="Logo"
              // />
              <div
                className={'flex shadow-lg mr-2'}
                style={{width: 32, height: 32, borderRadius: 9999}}>
                <span
                  className={'flex flex-1 rounded-l-full'}
                  style={{backgroundColor: color}}
                />
                <span
                  className={'flex flex-1 rounded-r-full'}
                  style={{backgroundColor: 'white'}}
                />
              </div>
            )
          ) : (
            <TextLogo className="LogoImg" />
          )}

          <TextContainer show={text} marginLeft={0}>
            {title && (
              <TitleText style={titleStyles}>
                {isCommon && !poweredByNeeded ? (
                  <>
                    <span>giga</span>
                    <span className={'font-medium'}>
                      {channel?.logoSubTitle ?? ''}
                    </span>
                  </>
                ) : (
                  title
                )}
              </TitleText>
            )}
            {subtitle && (
              <SubtitleText subTitleColor={subTitleColor}>
                {subtitle}
              </SubtitleText>
            )}
          </TextContainer>
        </>
      )}
    </Base>
  );
};

const mapStateToProps = ({UserData: {channel}}) => ({
  channel,
});

export default connect(mapStateToProps, null)(Logo);
