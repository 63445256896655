import React from 'react';
import {Route} from 'react-router-dom';

import OnboardingWelcome from './OnboardingWelcome';
import OnboardingCategories from './OnboardingCategories';
import OnboardingAssessments from './OnboardingAssessments';

import useTitle from 'hooks/useTitle';

const Onboarding = ({path}) => {
  // useTitle('Onboarding Flow', true);

  return (
    <>
      <Route
        exact
        path={`${path}/onboarding/welcome`}
        component={OnboardingWelcome}
      />

      <Route
        exact
        path={`${path}/onboarding/categories`}
        component={OnboardingCategories}
      />

      <Route
        exact
        path={`${path}/onboarding/assessments`}
        component={OnboardingAssessments}
      />
    </>
  );
};

export default Onboarding;
