import TextFieldsIcon from '@material-ui/icons/TextFields';
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import Emoji from '@material-ui/icons/EmojiEmotions';

export const tabs = [
  {
    id: 'text',
    icon: TextFieldsIcon,
    hoverLabel: 'Edit Text',
  },
  {
    id: 'files',
    icon: PermMediaOutlinedIcon,
    hoverLabel: 'Add Attachments',
  },
  {
    id: 'hyperlink',
    icon: LinkOutlinedIcon,
    hoverLabel: 'Attach Hyperlinks',
  },
  {
    id: 'composer',
    title: 'composer',
    hoverLabel: 'Add AI and Analytics',
  },
  {
    id: 'emoji',
    icon: Emoji,
    hoverLabel: 'Add emojis',
  },
];
