import React from 'react';

import {indicators} from './info';

import {ErrorDisplay, IndicatorsContainer} from './styles';

import ErrorIndicator from 'Components/DesignSystem/error/error-indicator';

const Indicators = ({password}) => {
  const errorIndicators = indicators.map(({label, validator}) => (
    <ErrorDisplay key={label}>
      <ErrorIndicator status={validator(password) ? 'success' : 'error'} />
      {label}
    </ErrorDisplay>
  ));

  return <IndicatorsContainer>{errorIndicators}</IndicatorsContainer>;
};

export default Indicators;
