import styled from 'styled-components';

const Base = styled.div`
  width: 90%;
  height: 2px;
  background-color: #f4f4f4;
  margin: 10px auto;
`;

export default Base;
