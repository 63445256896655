import React from 'react';

import FormContainer from 'hoc/FormContainer';

import Inputs from './Inputs';
import Indicators from './Indicators';

const Form = ({setEmail, statusEmail, onSubmit, loading, isDana}) => {
  let currentError = null;

  return (
    <FormContainer
      onSubmit={onSubmit}
      disabledSubmit={!statusEmail}
      currentError={currentError}
      loading={loading}
      isDana={isDana}>
      <Inputs changeEmail={setEmail} statusEmail={statusEmail} />

      <Indicators statusEmail={statusEmail} />
    </FormContainer>
  );
};

export default Form;
