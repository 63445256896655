import styled from 'styled-components';

import ServerContent from 'hoc/ServerContent';

import device from 'mediaQueries';

const Base = styled(ServerContent)`
  width: 94%;
  max-width: 1280px;

  @media (min-width: 640px) {
    width: 96%;
  }

  @media (min-width: 1024px) {
    width: 90%;
  }
`;

export {Base};
