import MemberHomepageService from 'Services/memberHomepage';

export const handleCommentarySubmit = async (
  ratingValue,
  narrativeValue,
  ratingTopicName,
  narrativeTopicName,
  topicID,
  cid,
  techID,
  assessorID,
  setErrorMessage,
  setLoadingSubmit,
  handleSaved,
) => {
  setErrorMessage('');
  setLoadingSubmit(true);

  if (!ratingValue) {
    setErrorMessage(
      'You need to select a rating at least to save a Commentary Topic',
    );
    setLoadingSubmit(false);

    return;
  }

  // Submission

  try {
    await MemberHomepageService.postCommentarySubmit(
      ratingValue,
      narrativeValue,
      ratingTopicName,
      narrativeTopicName,
      cid,
      techID,
      topicID,
      assessorID,
    );
    setLoadingSubmit(false);
    setErrorMessage('Commentary Topic saved correctly.');
    handleSaved();
  } catch (e) {
    console.error(e);
    setErrorMessage('There was an error. Please try again later.');
    setLoadingSubmit(false);
    handleSaved();
  }
};
