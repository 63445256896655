import React, {useState} from 'react';

import {Base, Title} from './styles';

import Button from 'Components/UI/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import SortIcon from '@material-ui/icons/Sort';

const CommentaryHeader = ({
  commentaryTopics,
  setCurrentSectionID,
  currentTopicName,
}) => {
  const [anchorSortBy, setAnchorSortBy] = useState(null);

  const handleTabClick = tabID => {
    setAnchorSortBy(null);
    setCurrentSectionID(tabID);
  };

  const tabs = commentaryTopics?.map(tab => (
    <MenuItem
      onClick={handleTabClick.bind(null, tab.id)}
      key={tab.id}
      value={tab.id}>
      {tab.assessment_topic}
    </MenuItem>
  ));

  return (
    <Base>
      <Title>Commentary</Title>
      <Button
        id="sortBy-label"
        onClick={({currentTarget}) => setAnchorSortBy(currentTarget)}>
        <SortIcon style={{marginRight: '10px'}} />
        Sort By {currentTopicName}
      </Button>

      <Menu
        id="sortBy-menu"
        anchorEl={anchorSortBy}
        keepMounted
        open={Boolean(anchorSortBy)}
        onClose={() => setAnchorSortBy(null)}>
        {tabs}
      </Menu>
    </Base>
  );
};

export default CommentaryHeader;
