import React, {useCallback, useEffect, useState} from 'react';
import MemberHomepageService from '../../../Services/memberHomepage';
import './styles.css';
import ShowHTMLString from '../../../hoc/ShowHTMLString';
import DateTimeUtil from '../../../utils/DateTimeUtil';
import {HiPlus} from 'react-icons/hi';
import {connect} from 'react-redux';
import * as userActions from '../../../Store/sagas/UserSaga/actions';
import moment from 'moment';
import Attachments from '../../../Components/Create/Attachments';
import {
  areThereSavedAttachments,
  formatSavedAttachments,
  getAttachments,
} from '../../../Components/MemberHomepage/Helpers/attachments';

function GigaMessage({
                       pubnub,
                       showHistory = true,
                       messageThreadHeight = 400,
                       historyList,
                       chatMessageCount,
                       message,
                       topic,
                       setTopic,
                       attachments,
                       onSent,
                       displayModes,
                       displayCategories,
                       actions,
                       messageClassName,
                     }) {
  let prevDate = '';

  const [accountProfile, setAccountProfile] = useState();
  const [messageList, setMessageList] = useState([]);

  const [channels] = useState(['awesome-channel']);

  useEffect(() => {
    MemberHomepageService.getAccountProfile().then(res => {
      setAccountProfile(res?.data ?? {});
    });

    MemberHomepageService.fetchMessageThread(topic.channel_id)
      .then(async res => {

        /**
         * file_attachment_0:
         file_attachment_id: 0
         file_attachment_mime_type: "image/jpeg"
         file_attachment_name: "binFile_5711[]"
         file_attachment_url: "https://api.gigaintelligence.com/giga_assessment_file_attachments/2018-05-21.jpeg"
         file_attachment_value: "2018-05-21.jpeg"
         [[Prototype]]: Object
         id: 0
         url_attachment_0:
         url_attachment_id: 0
         url_attachment_name: "urlLink_5711[]"
         url_attachment_url: "https://gigaintelligence.com"
         */

        if (res?.data?.message_postings != null) {
          const newMessageList = [];
          if (res?.data?.message_postings != null) {
            for (let i = 0; i < res?.data?.message_postings.length; i++) {
              const r = res?.data?.message_postings[i];
              let savedAttachments = r.message_posting_files
                ? getAttachments(r.message_posting_files)
                : [];
              savedAttachments = r.message_posting_urls
                ? [
                  ...savedAttachments,
                  ...getAttachments(r.message_posting_urls),
                ]
                : savedAttachments;
              let results = [];
              if (areThereSavedAttachments(savedAttachments)) {
                results = await formatSavedAttachments(savedAttachments);
              }


              newMessageList.push({
                chat_user: {
                  name: r.message_posting_contributor_full_name,
                  image: r.message_posting_contributor_picture,
                },
                chat_box_message: {
                  message: r.message_posting_narrative,
                  sent_at: r.message_posting_timestamp,
                },
                attachments: results,
              });
            }
          }
          setMessageList([...messageList, ...newMessageList]);
          // setMessageList([
          //   ...messageList,
          //   ...res?.data?.message_postings.map(r => {
          //     // let savedAttachments = getAttachments(r);
          //     // let results = [];
          //     // if (areThereSavedAttachments(savedAttachments)) {
          //     //   results = await formatSavedAttachments(savedAttachments);
          //     // }
          //     //
          //     // let attachments = [];
          //     // if (r.message_posting_files) {
          //     //   const mpfKeys = Object.keys(r.message_posting_files);
          //     //   mpfKeys.forEach(k => {
          //     //     attachments.push({
          //     //       type: r.message_posting_files[k].file_attachment_mime_type,
          //     //       src: r.message_posting_files[k].file_attachment_url,
          //     //       fileName: r.message_posting_files[k].file_attachment_name,
          //     //       id: r.message_posting_files[k].file_attachment_id,
          //     //     });
          //     //   });
          //     // }
          //     return {
          //       chat_user: {
          //         name: r.message_posting_contributor_full_name,
          //         image: r.message_posting_contributor_picture,
          //       },
          //       chat_box_message: {
          //         message: r.message_posting_narrative,
          //         sent_at: r.message_posting_timestamp,
          //       },
          //       attachments: [],
          //     };
          //   }),
          // ]);
        }
      })
      .catch(error => {
        console.log('fetchMessageThread', error);
      });
  }, []);

  const handleMessage = useCallback(async msg => {
    let messageData = JSON.parse(msg.message);
    console.log('onMessage', messageData);
    if (
      messageData.chat_box_message == null ||
      messageData.chat_room !== topic.channel_id
    ) {
      return;
    }

    const r = messageData.chat_box_message.attachments;
    let savedAttachments = r.message_posting_files
      ? getAttachments(r.message_posting_files)
      : [];
    savedAttachments = r.message_posting_urls
      ? [...savedAttachments, ...getAttachments(r.message_posting_urls)]
      : savedAttachments;

    let results = [];
    if (areThereSavedAttachments(savedAttachments)) {
      results = await formatSavedAttachments(savedAttachments);
    }

    setMessageList(msgList => [
      ...msgList,
      {...messageData, attachments: results},
    ]);
    // setTopic();
  }, [messageList]);

  // useEffect(() => {
  //   setMessageList([])
  // }, [topic])

  useEffect(() => {
    if (chatMessageCount === 0) {
      return;
    }

    if (message === '<p></p>\n') {
      return;
    }
    let messageJSON = {
      chat_user: {
        name: accountProfile?.user_full_name,
        image: accountProfile?.url_file,
      },
      chat_box_message: {
        message,
        attachments,
        sent_at: new Date().toISOString(),
      },
      chat_room: topic.channel_id,
    };
    pubnub.publish({
      channel: channels[0],
      message: JSON.stringify(messageJSON),
    });
    onSent();
  }, [chatMessageCount]);

  useEffect(() => {
    pubnub.addListener({message: handleMessage});
    pubnub.subscribe({channels});
  }, [pubnub, channels]);

  // const onCreateThread = e => {
  //   e.preventDefault();
  //
  //   const availableModes = displayModes.filter(m => m.mode_name === 'message');
  //   if (availableModes.length === 0) {
  //     return;
  //   }
  //
  //   const mode = availableModes[0];
  //
  //   const selCategories = displayCategories.filter(
  //     c => c.category === mode.mode_label,
  //   );
  //
  //   if (selCategories.length === 0) {
  //     return;
  //   }
  //
  //   const selCategory = selCategories[0];
  //
  //   const tech_id = -1;
  //   storeEntityParams({
  //     mode_id: mode.mode_id,
  //     mode_name: mode.mode_name,
  //     category_id: selCategory.category_id,
  //     tech_id,
  //   });
  //   storeUserMode(mode);
  //   storeCategoryMode(selCategory);
  //   actions.updateCreationModes([mode], [selCategory]);
  //   console.log('go creation');
  //   const querySessionID = getUserToken()?.giga_session_id;
  //   removeItem(Constants.AS_CURRENT_SECTION);
  //   localStorage.setItem(Constants.AS_POSTED_STATUS, 'no');
  //   HistoryService.push(
  //     `/home/create/analysis?tech_id=${tech_id}&cid=${selCategory.cid}&giga_session_id=${querySessionID}`,
  //   );
  // };

  return (
    <>
      {showHistory && (
        <div
          className={'giga-message-container row-span-2 relative'}
          style={{height: 800}}>
          <div
            className={
              'flex flex-1 flex-col bg-blue-500 rounded-lg overflow-y-scroll shadow-xl'
            }>
            {accountProfile?.user_full_name != null &&
            historyList.map((h, index) => (
              <div
                key={index}
                onClick={() => setTopic(h)}
                className={`flex p-4 m-4 flex-row space-x-2 rounded-2xl ${
                  topic.channel_id === h.channel_id
                    ? 'bg-blue-300'
                    : 'bg-blue-400'
                }`}>
                <img
                  src={h.entityImage}
                  className={
                    'w-12 h-12 rounded-xl object-contain bg-blue-200'
                  }
                  alt={'image'}
                />
                <div className={'flex flex-col space-y-0.5'}>
                    <span className={'font-semibold text-lg text-white'}>
                      {h.entityName}
                    </span>
                  <span className={'text-sm text-white'}>
                      {h.entityDescription}
                    </span>
                  {/*<div className={'text-white'}>*/}
                  {/*  <ShowHTMLString htmlString={h.narrative} />*/}
                  {/*</div>*/}
                </div>
              </div>
            ))}
          </div>
          <div
            className={'giga-message-new-create-msg-btn'}
            // onClick={e => onCreateThread(e)}
          >
            <HiPlus className={'w-6 h-6 text-blue-600'} />
          </div>
        </div>
      )}
      <div
        className={messageClassName ?? 'giga-message-container'}
        // style={{height: messageThreadHeight}}
      >
        <div
          className={
            // 'flex flex-1 w-50 h-full flex-col-reverse overflow-y-scroll p-2 shadow rounded-lg'
            'flex flex-1 w-50 h-full flex-col-reverse overflow-y-scroll pr-6'
          }>
          {accountProfile?.user_full_name != null && (
            <div className={'flex flex-col'}>
              {messageList
                .sort(
                  (x, y) =>
                    moment.utc(x?.chat_box_message?.sent_at) -
                    moment.utc(y?.chat_box_message?.sent_at),
                )
                .map((_, i) => {
                  // const m = messageList[messageList.length - 1 - i]
                  const m = messageList[i];
                  const isMe =
                    m?.chat_user?.name === accountProfile?.user_full_name;

                  const dateString = DateTimeUtil.getChatDate(
                    m?.chat_box_message?.sent_at,
                  );
                  const dateNeeded = dateString !== prevDate;

                  if (dateNeeded) {
                    prevDate = dateString;
                  }

                  if (m?.chat_box_message?.message === '<p></p>\n') {
                    return null;
                  }

                  return (
                    <div key={`${i}`} className={'flex flex-col'}>
                      <div className={'flex flex-row justify-center'}>
                        {dateNeeded && (
                          <p className={'text-sm font-semibold text-gray-400'}>
                            {dateString}
                          </p>
                        )}
                      </div>
                      <div
                        className={`mt-2 flex flex-row space-x-3 mb-2 ${
                          isMe ? 'self-end' : 'self-start'
                        }`}
                        style={{maxWidth: 'calc(100% - 100px)'}}>
                        {accountProfile?.url_file && !isMe && (
                          <img
                            src={m?.chat_user?.image}
                            alt={'avatar'}
                            className="w-10 h-10 object-cover p-0.5 rounded-full"
                          />
                        )}
                        <div
                          className={`text-base font-semibold text-white mt-3 rounded-2xl`}>
                          <div
                            className={`rounded-2xl px-4 py-2 ${
                              isMe ? 'bg-blue-400' : 'bg-blue-800'
                            }`}>
                            <ShowHTMLString
                              htmlString={m?.chat_box_message?.message}
                            />
                            {m?.attachments?.length > 0 && (
                              <Attachments
                                attachments={m?.attachments}
                                removeAttachment={() => {
                                }}
                                hideRemove={true}
                                variant={2}
                              />
                            )}
                          </div>
                          <div className={'flex flex-col items-end'}>
                            <span className={'text-xs mt-1 text-gray-400'}>
                              {DateTimeUtil.getChatTime(
                                m?.chat_box_message?.sent_at,
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateCreationModes: (modes, categories) => {
      dispatch(userActions.updateCreationModes(modes, categories));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GigaMessage);
