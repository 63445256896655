import React from 'react';

import {Base} from './styles';

import ShowHTMLString from 'hoc/ShowHTMLString';

const CommentaryViewNarrative = ({narrative}) => (
  <Base>
    <ShowHTMLString htmlString={narrative} />
  </Base>
);

export default CommentaryViewNarrative;
