import {areThereSavedAttachments, formatSavedAttachments, getAttachments} from "../../../../Helpers/attachments";

export const callCurrentTopicInfo = (
  currentTopic,
  callTopicInfo,
  techId,
  currentSection,
) => {
  const {isSummary} = currentSection;
  if (Object.keys(currentTopic).length > 0) {
    if (isSummary) {
      const {
        assessment_cid,
        assessment_freeform_id,
        assessment_topic_id,
        assessment_subtopics_url,
        assessment_parent_id,
        assessment_parent_type,
      } = currentTopic;

      callTopicInfo(
        techId,
        assessment_cid,
        assessment_freeform_id,
        assessment_topic_id,
        assessment_subtopics_url,
        assessment_parent_id,
        assessment_parent_type,
      );
    } else {
      const {assessment_cid, assessment_freeform_id, assessment_topic_id} =
        currentTopic;

      const {id, kf_cid} = currentSection;

      callTopicInfo(
        id,
        kf_cid,
        assessment_freeform_id,
        assessment_topic_id,
        'react_gi_v1_edit_kpi_commentary_display.php',
        0,
        0,
        {kpid: kf_cid, ocid: assessment_cid, otech_id: techId},
      );
    }
  }
};

export const initTopicContent =  async (topicInfo) => {
  if (Object.keys(topicInfo).length > 0) {
    const {assessment_summary} = topicInfo;
    // setTopicContent(
    //   assessment_summary.map(({assessment_topic}) => ({
    //     assessment_topic,
    //     htmlContent: '',
    //     selectedRating: '',
    //     assetsBlockData: [],
    //   })),
    // );
let totalResults = [];
    for (let index = 0; index < assessment_summary.length; index++) {

      let savedAttachments = getAttachments(assessment_summary[index]);

      let results = [];
      if (areThereSavedAttachments(savedAttachments)) {
        results = await formatSavedAttachments(savedAttachments);
      }
      totalResults.push(results)
    }
    return assessment_summary.map(({assessment_topic}, index) => ({
      assessment_topic,
      htmlContent: '',
      selectedRating: '',
      assetsBlockData: totalResults[index],
    }));
  }
};

export const setNewTopicContentData = (
  propChange,
  prevTopicContent,
  assessment_topic,
  newValue,
) => {
  const topicIndex = prevTopicContent.findIndex(
    topic => topic.assessment_topic === assessment_topic,
  );

  if (topicIndex !== -1) {
    const newTopicValue = {
      ...prevTopicContent[topicIndex],
      [propChange]: newValue,
    };

    return Object.assign([], prevTopicContent, {
      [topicIndex]: newTopicValue,
    });
  }

  return prevTopicContent;
};
