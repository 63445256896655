import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Base, EntitiesContainer, SeeMoreText, Title} from './styles';
import Entity from 'Components/UI/Entity';
import useSplitContent from 'hooks/useSplitContent';
import * as userActions from '../../../../Store/sagas/UserSaga/actions';
import {connect} from 'react-redux';
import EntityService from '../../../../utils/EntityService';
import {setNewBlock} from '../../../../hooks/useSplitContent/bl';

const Category = ({
  title,
  entities,
  history,
  actions,
  displayCategories,
  newEntityDisplayRequired = false,
}) => {
  const [currentEntities, setCurrentEntities] = useState(entities.slice(0, 8));
  const [index, setIndex] = useState(8);
  const [maxEntities, setMaxEntities] = useState(false);

  useEffect(() => {
    setCurrentEntities(entities.slice(0, 8));
    setIndex(8);
    setMaxEntities(false);
  }, [entities]);

  const setNewEntities = () =>
    setNewBlock(
      entities,
      currentEntities,
      setCurrentEntities,
      index,
      setIndex,
      maxEntities,
      setMaxEntities,
      8,
    );

  if (entities.length === 0) {
    return null;
  }

  const entityItems = currentEntities.map(
    ({
      entityName,
      entityImage,
      entityLink,
      entityAuthorID,
      entityAuthorName,
      entityAuthorImage,
      handleClick,
    }) => (
      <Entity
        key={entityName}
        title={entityName}
        portraitUrl={entityImage}
        redirectToEntity={
          handleClick
            ? handleClick
            : () =>
                EntityService.handleClickEntity(
                  entityLink,
                  entityAuthorID,
                  actions,
                  displayCategories,
                )
        }
        userName={entityAuthorName}
        userImage={entityAuthorImage}
      />
    ),
  );

  return (
    <Base>
      <Title>{title}</Title>

      <EntitiesContainer>
        {newEntityDisplayRequired && (
          <Entity
            title={'Add New'}
            portraitUrl={''}
            redirectToEntity={() => {}}
            userName={null}
            userImage={''}
          />
        )}
        {entityItems}
      </EntitiesContainer>

      {entities.length > 8 && !maxEntities ? (
        <SeeMoreText onClick={setNewEntities}>See more...</SeeMoreText>
      ) : null}
    </Base>
  );
};

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    updateCreationModes: (modes, categories) => {
      dispatch(userActions.updateCreationModes(modes, categories));
    },
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Category),
);
