import React from 'react';
import AuthButton from 'Components/DesignSystem/button/button';
import {Error, LinkPrivacy} from './styles';

const FormContainer = ({
  onSubmit,
  children,
  disabledSubmit,
  currentError,
  errorMessage,
  loading,
}) => (
  <form onSubmit={onSubmit}>
    {children}

    <AuthButton type="submit" disabled={disabledSubmit} loading={loading}>
      Submit
    </AuthButton>

    {currentError && <Error>{errorMessage || currentError}</Error>}

    <LinkPrivacy to="/privacy">Privacy Policy</LinkPrivacy>
  </form>
);

export default FormContainer;
