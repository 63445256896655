import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        '&:hover': {
          color: '#fff',
        },
        '&$checked': {
          color: '#fff',
          '&:hover': {
            color: '#fff',
          },
          '&.Mui-focusVisible': {
            color: '#fff',
          },
        },
        '&.Mui-focusVisible': {
          color: '#fff',
        },
        '&.focused:not(.Mui-focusVisible):not($checked)': {
          color: '#fff',
        },
      },
    },
  },
});

export default theme;
