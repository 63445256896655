import styled from 'styled-components';

export const Title = styled.div`
  color: ${({theme}) => theme.colors.main};
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
  margin: 0;
  opacity: 0.6;
`;
