import React, {useEffect, useState} from 'react';
import Button from 'Components/DesignSystem/button/button';
import {connect} from 'react-redux';
import {BsSearch} from 'react-icons/bs';
import {IoMdClose} from 'react-icons/io';
import MemberHomepageService from '../../../Services/memberHomepage';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ReactComponent as MessageSVG} from '../../../assets/svg/member/msg.svg';

function CreateNewMessage(props) {
  const {handleCloseModal, handleContinue} = props;

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    MemberHomepageService.fetchAllUsers().then(res => {
      if (res?.data?.members != null) {
        setUsers(res.data.members);
      }
    });
  }, []);

  return (
    <div
      className={
        'flex flex-col relative create-new-msg-modal-container rounded-lg'
      }>
      <div className={'flex flex-col flex-1 mt-8 space-y-4 items-center px-4'}>
        <div className={'flex flex-row space-x-2 items-center'}>
          {/*<div*/}
          {/*  className={'pl-1.5 pr-2.5 py-2 rounded-full'}*/}
          {/*  style={{backgroundColor: 'rgb(83,155,212)'}}>*/}
          {/*  <GrSend className={'w-5 h-5'} />*/}
          {/*</div>*/}
          <MessageSVG style={{width: 36, height: 36}} />
          <p className={'text-lg font-semibold text-center text-white'}>
            Select recipients
          </p>
        </div>
      </div>
      <IoMdClose
        className={'w-6 h-6 absolute top-4 right-4 text-white cursor-pointer'}
        onClick={() => handleCloseModal()}
      />
      <div
        className={
          'flex flex-row w-80 bg-white rounded-full items-center px-4 self-center mt-6 py-2 space-x-2'
        }>
        <BsSearch className={'w-4 h-4'} />
        <input
          id="search"
          name="search"
          className="focus:outline-none text-sm flex-1"
          type="search"
          placeholder={'Search'}
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
      </div>
      <div
        className={`create-message-users-list-container ${
          users.length === 0 ? 'items-center justify-center' : 'items-start'
        }`}>
        {users.length === 0 ? (
          <CircularProgress thickness={5} className="Loading" disableShrink />
        ) : (
          <div className={'grid grid-cols-3 flex-1'}>
            {users
              .filter(u =>
                u.user_full_name
                  .toLowerCase()
                  .includes(searchString.toLowerCase().trim()),
              )
              .map((user, index) => {
                return (
                  <div
                    key={`${index}`}
                    onClick={() => {
                      if (selectedUsers.includes(user.userinfo_id)) {
                        setSelectedUsers(
                          selectedUsers.filter(s => s !== user.userinfo_id),
                        );
                      } else {
                        setSelectedUsers([...selectedUsers, user.userinfo_id]);
                      }
                    }}
                    className={`flex flex-row items-center space-x-3 my-4 rounded cursor-pointer p-2 mx-1 ${
                      selectedUsers.includes(user.userinfo_id)
                        ? 'bg-giga-primary-blue_spe'
                        : ''
                    }`}>
                    <span
                      className={`w-4 h-4 rounded-full ${
                        selectedUsers.includes(user.userinfo_id)
                          ? 'bg-gray-50'
                          : 'bg-gray-300'
                      }`}
                    />
                    <img
                      src={user.url_file}
                      alt={'avatar'}
                      className={'w-12 h-12 rounded-full object-cover'}
                    />
                    <span className={'font-medium text-white'}>
                      {user.user_full_name}
                    </span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <span className={'font-medium text-white text-sm self-center mt-4'}>
        {selectedUsers.length} Member{selectedUsers.length > 1 ? 's' : ''}{' '}
        selected
      </span>
      <div className={'flex flex-row justify-center mb-8 mt-12'}>
        <Button
          onClick={() => handleContinue(selectedUsers)}
          style={{width: '24rem'}}>
          Submit
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  displayModes: state.UserData.displayModes,
  displayCategories: state.UserData.displayCategories,
});

export default connect(mapStateToProps)(CreateNewMessage);
