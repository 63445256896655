import styled from 'styled-components';

const Base = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    margin-right: 0.9375rem;
    margin-bottom: 4px;
  }
`;

export {Base};
