import React from 'react';

import {Base} from './styles';
import RatingOptions from '../../../../Definitions/AnalysisFlow/Topics/TopicSubmission/TopicDefinition/RatingsBlock/RatingOptions';
import RatingDropDown
  from '../../../../Definitions/AnalysisFlow/Topics/TopicSubmission/TopicDefinition/RatingsBlock/RatingDropDown'

const PreviewRatings = ({
  title,
  className,
  currentRating,
  futureRating,
  ratingOptions = 'Awesome,Good,Fair,Bad,Terrible,Podcast',
}) => (
  <Base className={className}>
    <div className={'mr-2'}>{title ?? currentRating}</div>
    {/*<RatingOptions*/}
    {/*  options={ratingOptions.split(',')}*/}
    {/*  selectedIndex={ratingOptions.split(',').indexOf(currentRating)}*/}
    {/*  onlyView={true}*/}
    {/*  style={{margin: '0 1rem'}}*/}
    {/*/>*/}

    {/*<RatingDropDown*/}
    {/*  // selectedIndex={selectedIndex}*/}
    {/*  // setSelectedIndex={setSelectedIndex}*/}
    {/*  selectedRating={currentRating}*/}
    {/*  setNewSelectedRating={selRating =>*/}
    {/*    console.log('extraRating', selRating)}*/}
    {/*  options={ratingOptions.split(',')}*/}
    {/*  onlyView={true}*/}
    {/*/>*/}
    {/*<div>*/}
    {/*<div>Current:</div>*/}
    {/*</div>*/}

    {/*<div>*/}
    {/*  <div>Future:</div>*/}
    {/*  <div>{futureRating}</div>*/}
    {/*</div>*/}
  </Base>
);

export default PreviewRatings;
