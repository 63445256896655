import React, {memo} from 'react';

import {ErrorDisplay, IndicatorsContainer} from './styles';

import ErrorIndicator from 'Components/DesignSystem/error/error-indicator';

const Indicators = ({statusEmail}) => (
  <IndicatorsContainer>
    <ErrorDisplay>
      <ErrorIndicator status={statusEmail ? 'success' : 'error'} />
      Valid email
    </ErrorDisplay>
  </IndicatorsContainer>
);

export default memo(Indicators);
