import React from 'react';

import {Base, HeroText, TextContainer} from './styles';

import LandingButton from '../../UI/LandingButton';
import jf_logo_white from 'assets/img/landing/jf_logo_white.png';

import device, {customDevice} from 'mediaQueries';
import HistoryService from '../../../../utils/HistoryService';
import Constants from '../../../../Constants/Constants';

const mainTextCSS = `
  @media ${device.laptop} {
    font-size: 44px
  }

  @media ${customDevice(840)} {
    font-size: 39px;
  }

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 30px;
  }

  @media ${customDevice(450)} {
    font-size: 30px;
  }
`;

const Hero = ({
  title = 'Why Join Giga?',
  description = 'Giga is a digital services platform where people can understand and be understood in privacy.',
  renderTitle,
  bgImage,
  isDana,
  variant,
  descriptionSize = 3.375,
  bodyLogo,
}) => (
  <Base bgImage={bgImage}>
    <TextContainer>
      <div className={'flex flex-row'}>
        <div>
          {renderTitle ? (
            renderTitle()
          ) : (
            <HeroText size={2.125}>{title}</HeroText>
          )}
          <HeroText css={mainTextCSS} size={descriptionSize}>
            {description}
          </HeroText>

          {variant.includes('Giga') && (
            <LandingButton
              bgCol="rgba(0,0,0, 0.8)"
              onClick={() =>
                HistoryService.push({
                  pathname: '/learnMore',
                  state: {
                    isDana,
                    variant,
                  },
                })
              }>
              What is Giga?
            </LandingButton>
          )}
        </div>
        {!variant.includes('Giga') && (
          <img
            src={bodyLogo}
            alt={'jf'}
            className={'ml-24 h-80 hidden 2xl:block'}
          />
        )}
      </div>
    </TextContainer>
  </Base>
);

export default Hero;
