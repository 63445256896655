import React from 'react';

import {Base, ContentBoxText, HeaderDesc, TextHeader, Title} from '../styles';

import ContentBox from 'Components/Homepage/UI/LandingContentBox';
import Button from 'Components/DesignSystem/button/button';
import ProgressCircles from 'Components/UI/ProgressCircles';

import GetStartedPortrait from 'assets/img/3d/complete-definition.png';

const OnboardingWelcome = ({history}) => {
  return (
    <Base>
      <TextHeader>
        <Title>Assessments</Title>

        <HeaderDesc>Let&apos;s learn how to assess a subject</HeaderDesc>
      </TextHeader>

      <ContentBox
        height={450}
        bgCol="linear-gradient(#2ca3ff 0%, #424dd1 100%)"
        srcImage={GetStartedPortrait}
        directionImg="right">
        <ContentBoxText>
          We have a rubric that has 11 topics to analyze a subject. Using the 11
          topics you can throughly write your ideas and share your viewpoint
          with the world.
          <br />
          <br />
          There is also a capability to rate and share your work privately. We
          think once you complete one you.
        </ContentBoxText>

        <Button
          style={{marginTop: '30px', width: '60%'}}
          onClick={() => history.push('/home/user')}>
          Continue
        </Button>

        <ProgressCircles
          circlesLength={3}
          selectedCircle={3}
          style={{marginTop: '20px'}}
        />
      </ContentBox>
    </Base>
  );
};

export default OnboardingWelcome;
