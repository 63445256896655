import React, {useCallback, useEffect, useState} from 'react';

import FormGenerator from 'Components/UI/FormGenerator';
import Button from 'Components/DesignSystem/button/button';
import TextErrorContainer from 'Components/UI/TextErrorContainer';

import {Base} from './styles';

import {userInputs} from './info';
import {putSavedValues} from './bl';

import useFormGeneratorData from 'hooks/useFormGeneratorData';
import useFetchContent from 'hooks/useFetchContent';

import MemberHomepageService from 'Services/memberHomepage';
import {getUserToken} from '../../../utils/HelperService';
import HistoryService from '../../../utils/HistoryService';

const UserManagement = ({history}) => {
  const [
    savedInputValues,
    loadingInputValues,
    errorInputValues,
    callInputValues,
  ] = useFetchContent(
    {},
    MemberHomepageService.getAccountInputValues,
    useCallback(({data}) => data, []),
  );

  useEffect(() => {
    if (getUserToken() == null) {
      HistoryService.push('/home');
    }
  }, []);

  const [
    submittedInputValues,
    loadingSubmittedInputValues,
    errorSubmittedInputValues,
    callSubmittedInputValues,
  ] = useFetchContent(
    null,
    MemberHomepageService.postAccountUpdates,
    useCallback(({data}) => data, []),
    false,
  );

  const [contentInputs, changeInputProp, validateForm] =
    useFormGeneratorData(userInputs);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(callInputValues, [callInputValues]);

  useEffect(() => {
    putSavedValues(savedInputValues, changeInputProp);
  }, [changeInputProp, savedInputValues]);

  useEffect(() => {
    if (errorSubmittedInputValues) {
      setErrorMessage(
        'There was a problem with the submission. Please try again later.',
      );
    }
  }, [errorSubmittedInputValues]);

  useEffect(() => {
    if (
      !loadingSubmittedInputValues &&
      !errorSubmittedInputValues &&
      submittedInputValues != null
    ) {
      history.push('/home');
    }
  }, [
    errorSubmittedInputValues,
    history,
    loadingSubmittedInputValues,
    submittedInputValues,
  ]);

  const handleSettingsSubmission = () => {
    const [isFormValid, errorMessage] = validateForm();

    setErrorMessage('');

    if (isFormValid) {
      callSubmittedInputValues(contentInputs);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  let newContentInputs = [];
  if (contentInputs.some(v => v.label === 'Profile Picture')) {
    newContentInputs.push(
      contentInputs.filter(v => v.label === 'Profile Picture')[0],
    );
  }

  newContentInputs = [
    ...newContentInputs,
    ...contentInputs.filter(v => v.label !== 'Profile Picture'),
  ];

  return (
    <Base loading={loadingInputValues} error={errorInputValues}>
      <FormGenerator
        inputsArr={newContentInputs}
        changeInputProp={changeInputProp}
      />
      <TextErrorContainer>{errorMessage}</TextErrorContainer>
      <Button
        style={{
          width: '40%',
          minWidth: '20rem',
          margin: '0 auto',
          marginBottom: '60px',
        }}
        onClick={handleSettingsSubmission}
        loading={loadingSubmittedInputValues}>
        Save Changes
      </Button>
    </Base>
  );
};

export default UserManagement;
